import gql from 'graphql-tag';

export default gql`
  query condominiumSelect {
    condominiumSelect {
      value
      label
      contextId
    }
  }
`;
