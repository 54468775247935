import { useState, useContext, useMemo } from 'react';
import { useFormikContext, Field } from 'formik';
import { FaSpinner } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import {
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  CustomInput,
} from 'reactstrap';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import propTypes from 'prop-types';
import { ButtonSubmit, ButtonLink } from '../Login/styles';
import context from '../../../services/auth/context';

const initialCustomErrorState = {
  lowercase: false,
  number: false,
  minAmount: false,
  specialCharacter: false,
  uppercase: false,
  required: false,
};

const ResetPasswordForm = ({ hideLink, submitText }) => {
  const formikBag = useFormikContext();

  const auth = useContext(context);

  const [t] = useTranslation('reset-password');
  const history = useHistory();
  const [customErrors, setCustomErrors] = useState({
    lowercase: true,
    number: true,
    minAmount: true,
    specialCharacter: true,
    uppercase: true,
    required: true,
  });
  const { handleSubmit, handleChange, values, errors, touched } = formikBag;

  const verifyCheckErrors = () =>
    !customErrors.required &&
    Object.keys(customErrors)
      .filter(x => x !== 'required')
      .some(x => customErrors[x]);

  const goToLogin = () =>
    history.push({ pathname: '/', search: `?email=${values.username}` });

  const validatePassword = async value => {
    const schema = yup
      .string()
      .matches(/^(?=.*[a-z])/, {
        excludeEmptyString: true,
        message: 'lowercase',
      })
      .matches(/^(?=.*[A-Z])/, {
        excludeEmptyString: true,
        message: 'uppercase',
      })
      .matches(/^(?=.*[0-9])/, { excludeEmptyString: true, message: 'number' })
      .matches(/(?=.{8,})/, { excludeEmptyString: true, message: 'minAmount' })
      .matches(/^(?=.*[!@#$%^&*])/, {
        excludeEmptyString: true,
        message: 'specialCharacter',
      })
      .required('required')
      .nullable();

    try {
      await schema.validate(value, { abortEarly: false });
      setCustomErrors(initialCustomErrorState);
      return undefined;
    } catch (e) {
      if (e && e.errors.length === 1 && e.errors[0] === 'required')
        e.errors.push(...Object.keys(initialCustomErrorState));
      const parsedErrors = e.errors.reduce(
        (acc, curr) => ({ ...acc, [curr]: true }),
        initialCustomErrorState,
      );
      setCustomErrors(parsedErrors);
      return parsedErrors;
    }
  };

  const submitting = useMemo(() => auth.submitting, [auth]);

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <FormGroup>
        <CustomInput
          type="checkbox"
          id="check-lowercase"
          label={t('check.lowercase')}
          disabled
          checked={!customErrors.lowercase}
        />
        <CustomInput
          type="checkbox"
          id="check-uppercase"
          label={t('check.uppercase')}
          disabled
          checked={!customErrors.uppercase}
        />
        <CustomInput
          type="checkbox"
          id="check-number"
          label={t('check.number')}
          disabled
          checked={!customErrors.number}
        />
        <CustomInput
          type="checkbox"
          id="check-special-character"
          label={t('check.special-character')}
          disabled
          checked={!customErrors.specialCharacter}
        />
        <CustomInput
          type="checkbox"
          id="check-min-amount"
          label={t('check.min-amount')}
          disabled
          checked={!customErrors.minAmount}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="confirmationCode">{t('code-confirmation')}</Label>
        <Input
          type="text"
          id="confirmationCode"
          name="confirmationCode"
          onChange={handleChange}
          maxLength={6}
          required
          value={values.confirmationCode}
          invalid={touched.confirmationCode && !!errors.confirmationCode}
        />
        <FormFeedback>{t('errors.code')}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="password">{t('label')}</Label>
        <Field
          autoComplete="new-password"
          name="password"
          type="password"
          validate={validatePassword}
          className={classnames(
            'form-control',
            touched.password &&
              Object.values(customErrors).some(x => x) &&
              'is-invalid',
          )}
        />
        <FormFeedback>
          {customErrors.required && t('errors.password')}
          {verifyCheckErrors(customErrors) && t('errors.others')}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="passwordConfirmation">{t('label-confirm')}</Label>
        <Input
          type="password"
          autoComplete="new-password"
          id="passwordConfirmation"
          name="passwordConfirmation"
          onChange={handleChange}
          required
          value={values.passwordConfirmation}
          invalid={
            touched.passwordConfirmation && !!errors.passwordConfirmation
          }
        />
        <FormFeedback>
          {errors.passwordConfirmation &&
          errors.passwordConfirmation.includes('required')
            ? t('errors.password')
            : t('errors.password-confirmation')}
        </FormFeedback>
      </FormGroup>
      <br />
      <ButtonSubmit
        block
        color="primary"
        type="submit"
        loading={submitting ? 1 : 0}
      >
        {t(`${submitText}${submitting ? 'ting' : ''}`)}
        {submitting && <FaSpinner color="#FFF" size={14} />}
      </ButtonSubmit>
      {!hideLink && (
        <ButtonLink block color="link" onClick={goToLogin}>
          {t('back-to-login')}
        </ButtonLink>
      )}
    </Form>
  );
};

export default ResetPasswordForm;

ResetPasswordForm.propTypes = {
  hideLink: propTypes.bool,
  submitText: propTypes.string,
};

ResetPasswordForm.defaultProps = {
  hideLink: false,
  submitText: 'submit',
};
