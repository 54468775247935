import gql from 'graphql-tag';

export const enableCondominium = gql`
  mutation guidedAttendanceEnableCondominium(
    $guidedAttendanceSettings: GuidedAttendanceSettingsInput!
  ) {
    guidedAttendanceEnableCondominium(
      guidedAttendanceSettings: $guidedAttendanceSettings
    )
  }
`;

export const disableCondominium = gql`
  mutation guidedAttendanceDisableCondominium(
    $guidedAttendanceSettings: GuidedAttendanceSettingsInput!
  ) {
    guidedAttendanceDisableCondominium(
      guidedAttendanceSettings: $guidedAttendanceSettings
    )
  }
`;
