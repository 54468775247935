import gql from 'graphql-tag';

export const applications = gql`
  query applications($personId: Int!) {
    applications(personId: $personId) {
      name
      applicationKey
      icon
      mainUrl
      id
      creationDate
    }
  }
`;
