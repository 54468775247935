import gql from 'graphql-tag';

const videoSettingsFragment = gql`
  fragment videoSettings_Part on VideoSettings {
    serializedParams {
      userName
      password
      baseUrl
      gmt
    }
    id
  }
`;

const kiperVoiceSettingsFragment = gql`
  fragment kiperVoiceSettings_Part on KiperVoiceSettings {
    serializedParams {
      kiperVoiceUrl
      newVersion
      kiperVoiceStorageUrl
      isDefault
    }
    id
    name
  }
`;

export const listAlarmSettings = gql`
  query alarmSettings {
    alarmSettings {
      deviceTypeId
      name
      id
    }
  }
`;

export const listVideoSettings = gql`
  query listVideoSettings {
    listVideoSettings {
      ...videoSettings_Part
    }
  }
  ${videoSettingsFragment}
`;

export const singleVideoSetting = gql`
  query singleVideoSetting($id: ID) {
    singleVideoSetting(id: $id) {
      ...videoSettings_Part
    }
  }

  ${videoSettingsFragment}
`;

export const alarmSettingsToken = gql`
  query alarmSettingsToken($data: AlarmSettingsTokenInput) {
    alarmSettingsToken(data: $data) {
      token
    }
  }
`;

export const createAlarmSetting = gql`
  mutation createAlarmSetting($data: AlarmSettingsInput) {
    createAlarmSetting(data: $data) {
      name
      id
    }
  }
`;

export const updateAlarmSetting = gql`
  mutation updateAlarmSetting($id: ID, $data: AlarmSettingsInput) {
    updateAlarmSetting(id: $id, data: $data) {
      name
      id
    }
  }
`;

export const removeAlarmSetting = gql`
  mutation removeAlarmSetting($id: ID) {
    removeAlarmSetting(id: $id)
  }
`;

export const createVideoSetting = gql`
  mutation createVideoSetting($data: VideoSettingsInput) {
    createVideoSetting(data: $data) {
      ...videoSettings_Part
    }
  }
  ${videoSettingsFragment}
`;

export const updateVideoSetting = gql`
  mutation updateVideoSetting($id: ID, $data: VideoSettingsInput) {
    updateVideoSetting(id: $id, data: $data) {
      ...videoSettings_Part
    }
  }
  ${videoSettingsFragment}
`;

export const removeVideoSetting = gql`
  mutation removeVideoSetting($id: ID) {
    removeVideoSetting(id: $id)
  }
`;

export const kiperVoiceSettings = gql`
  query kiperVoiceSettings {
    kiperVoiceSettings {
      ...kiperVoiceSettings_Part
    }
  }
  ${kiperVoiceSettingsFragment}
`;

export const allKiperVoiceSettings = gql`
  query allKiperVoiceSettings {
    allKiperVoiceSettings {
      ...kiperVoiceSettings_Part
    }
  }
  ${kiperVoiceSettingsFragment}
`;

export const kiperVoiceSettingsById = gql`
  query kiperVoiceSettingsById {
    kiperVoiceSettingsById {
      ...kiperVoiceSettings_Part
    }
  }
  ${kiperVoiceSettingsFragment}
`;

export const createKiperVoiceSettings = gql`
  mutation createKiperVoiceSettings($data: KiperVoiceSettingsInput) {
    createKiperVoiceSettings(data: $data) {
      ...kiperVoiceSettings_Part
    }
  }
  ${kiperVoiceSettingsFragment}
`;

export const updateKiperVoiceSettings = gql`
  mutation updateKiperVoiceSettings($data: KiperVoiceSettingsInput) {
    updateKiperVoiceSettings(data: $data) {
      ...kiperVoiceSettings_Part
    }
  }
  ${kiperVoiceSettingsFragment}
`;

export const removeKiperVoiceSettings = gql`
  mutation removeKiperVoiceSettings($kiperVoiceId: ID) {
    removeKiperVoiceSettings(kiperVoiceId: $kiperVoiceId)
  }
`;
