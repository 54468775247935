import styled from 'styled-components';

export const WrapperFallbackError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: fit-content;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.secondary100};
  padding: 8px;
  gap: 8px;
`;
