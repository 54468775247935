import colors from './colors';

export { colors };

export default {
  colors,

  colorContainer: '#f7fef8',
  // Brand Colors
  colorBrandPrimary: '#29AD71',
  colorBrandSecondary: '#177B4E',
  colorBrandTertiary: 'rgba(41, 173, 113, 0.4)',
  colorNavbar: '#0d5b61',
  colorNavbarActive: '#027e76',
  // Inverse Colors
  colorInversePrimary: '#001737',
  colorInverseSecondary: '#1b2e4b',
  // Text Colors
  colorText01: '#ff0',
  colorText02: '#1b2e4b',
  colorText03: '#8392a5',
  colorText04: '#c0ccda',
  colorUi01: '#f5f6fa',
  colorUi02: '#e5e9f2',
  colorUi03: '#c0ccda',
  colorUi04: '#8392a5',
  // Buttons Colors
  colorDangerButton: '#ff0000',
  // Height
  heightBase: '38px',
  heightHeader: '60px',
  heightHeaderMobile: '55px',
  heightFooter: '46px',
  // Width
  widthSidebar: '240px',
  // Marker
  markerIconSize: '16px',
  // Fonts
  fontFamilyRoboto: '"Roboto", sans-serif',
  fontFamilyInterui: '"Inter UI", sans-serif',
  fontFamilyLato: '"Lato", sans-serif',
  fontFamilyRubik: '"Rubik", sans-serif',
  fontFamilySystem:
    '-apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif',
  fontWeightMedium: 500,
  fontWeightSemibold: 600,
  fontWeightBold: 700,
};
