import propTypes from 'prop-types';
import * as S from './styles';

export const ErrorComponent = ({ message }) => (
  <S.ErrorWrapper>{message}</S.ErrorWrapper>
);

ErrorComponent.propTypes = {
  message: propTypes.string.isRequired,
};
