export default function apolloErrorHandler({ graphQLErrors, networkError }) {
  // eslint-disable-next-line no-console
  console.error(graphQLErrors, networkError);
  if (networkError) return [`[Network error]: ${networkError}`];

  if (graphQLErrors) {
    return graphQLErrors.reduce((errors, current) => {
      errors.push(
        ...((current.extensions &&
          current.extensions.response &&
          current.extensions.response.length &&
          Array.isArray(current.extensions.response) &&
          current.extensions.response.map(x => x.message || x.Message)) || [
          current.message,
        ]),
      );
      return errors;
    }, []);
  }

  return undefined;
}
