import propTypes from 'prop-types';
import { Flex } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import {
  ButtonDescription,
  RegisterFacialButton,
  ButtonTitle,
  EditFacialButton,
  EventMonth,
  EventDay,
  HeaderDisclaimer,
  RegularTextHeader,
  BoldTextHeader,
  SmallTextHeader,
} from '../styles';
import logoSummitFacialRegister from '../../../../../assets/images/summit-facial-register-logo.svg';
import logoSummitFacialRegisterSuccess from '../../../../../assets/images/summit-register-facial-success.svg';

const prefix = 'porter-summit-invite.facial-registration';

const DefaultHeader = () => {
  const [t] = useTranslation('invite');

  return (
    <HeaderDisclaimer>
      <RegularTextHeader>
        {t('porter-summit-invite.header.greeting')}
      </RegularTextHeader>
      <BoldTextHeader>
        {t('porter-summit-invite.header.ticket-title')}
      </BoldTextHeader>
      <RegularTextHeader>
        {t('porter-summit-invite.header.event-title')}
      </RegularTextHeader>
      <SmallTextHeader>
        {t('porter-summit-invite.header.disclaimer')}
      </SmallTextHeader>
    </HeaderDisclaimer>
  );
};

const ExpiredHeader = () => {
  const [t] = useTranslation('invite');

  return (
    <HeaderDisclaimer>
      <RegularTextHeader>
        {t('porter-summit-invite.expired-header.facial-registration')}
      </RegularTextHeader>
      <BoldTextHeader>
        {t('porter-summit-invite.expired-header.closed-status')}
      </BoldTextHeader>
    </HeaderDisclaimer>
  );
};

const Create = ({ onRegisterFacial }) => {
  const [t] = useTranslation('invite');

  const registerButtonCta = t(`${prefix}.register-button.cta`);
  const registerButtonDescription = t(`${prefix}.register-button.description`);
  const registerButtonAlt = t(`${prefix}.register-button.alt`);

  return (
    <RegisterFacialButton type="button" onClick={onRegisterFacial}>
      <img src={logoSummitFacialRegister} alt={registerButtonAlt} />
      <ButtonDescription>
        <strong>{registerButtonCta}</strong>
        <span>{` ${registerButtonDescription}`}</span>
      </ButtonDescription>
    </RegisterFacialButton>
  );
};

const Edit = ({ onEditFacial }) => {
  const [t] = useTranslation('invite');

  return (
    <Flex flexDirection="column" gridGap="8px">
      <RegisterFacialButton type="button" disabled>
        <img
          src={logoSummitFacialRegisterSuccess}
          alt={t(`${prefix}.success-message.alt`)}
        />
        <Flex flexDirection="column" gridGap="8px">
          <ButtonTitle>{t(`${prefix}.success-message.title`)}</ButtonTitle>
          <ButtonDescription>
            {t(`${prefix}.success-message.description`)}
          </ButtonDescription>
        </Flex>
      </RegisterFacialButton>
      <EditFacialButton onClick={onEditFacial}>
        {t(`${prefix}.success-message.update-prompt`)}
      </EditFacialButton>
    </Flex>
  );
};

const Expired = () => {
  const [t] = useTranslation('invite');

  return (
    <RegisterFacialButton type="button" disabled>
      <Flex
        px="16px"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <EventDay>{t(`${prefix}.expired-message.event-day`)}</EventDay>
        <EventMonth>
          {t(`${prefix}.expired-message.event-month-abrev`)}
        </EventMonth>
      </Flex>
      <ButtonDescription>
        {t(`${prefix}.expired-message.description`)}
      </ButtonDescription>
    </RegisterFacialButton>
  );
};

Create.propTypes = {
  onRegisterFacial: propTypes.func.isRequired,
};

Edit.propTypes = {
  onEditFacial: propTypes.func.isRequired,
};

export const RegisterFacial = {
  ExpiredHeader,
  DefaultHeader,
  Create,
  Edit,
  Expired,
};
