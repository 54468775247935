import { actionTypes } from './actions';
import { initialFlags } from './context';

export default function authReducer(state, action) {
  switch (action.type) {
    case actionTypes.login.request:
      return {
        ...state,
        token: null,
        error: null,
        submitting: true,
      };
    case actionTypes.login.success:
      return {
        ...state,
        loggedIn: true,
        token: action.payload,
        submitting: false,
      };
    case actionTypes.login.error:
      return {
        ...state,
        loggedIn: false,
        token: null,
        error: action.payload,
        submitting: false,
      };
    case actionTypes.forgot.request:
      return {
        ...state,
        sent: false,
        reseted: false,
        submitting: true,
        error: null,
        token: null,
        loggedIn: false,
      };
    case actionTypes.forgot.success:
      return {
        ...state,
        submitting: false,
        sent: true,
        error: null,
      };
    case actionTypes.forgot.goBack:
      return {
        ...state,
        sent: false,
        error: null,
      };
    case actionTypes.forgot.error:
      return {
        ...state,
        sent: false,
        submitting: false,
        error: action.payload,
      };
    case actionTypes.reset.request:
      return {
        ...state,
        submitting: true,
        reseted: false,
        sent: false,
        error: null,
      };
    case actionTypes.reset.success:
      return {
        ...state,
        submitting: false,
        reseted: true,
      };
    case actionTypes.reset.error:
      return {
        ...state,
        submitting: false,
        error: action.payload,
      };
    case actionTypes.redirectUri:
      return {
        ...state,
        redirectUri: action.payload, // || state.redirectUri
      };
    case actionTypes.resetFlags:
      return {
        ...state,
        ...initialFlags,
      };
    default:
      return state;
  }
}
