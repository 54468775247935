import http from './api';

function dataURItoBlob(dataURI) {
  const binary = atob(dataURI.split(',')[1]);
  const array = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
}

export const uploadFacial = async ({ data }) => {
  const { face, hash, storePhoto } = data;
  const formData = new FormData();
  formData.append('face', dataURItoBlob(face));
  formData.append('hash', hash);
  formData.append('storePhoto', String(storePhoto));

  const headers = { 'Content-Type': 'multipart/form-data' };
  const res = await http.post('/Mobile/Guest/Face', formData, { headers });

  return res.status === 200;
};

export default { uploadFacial };
