import propTypes from 'prop-types';

import Text from '../Text';
import Flex from '../Flex';

const Error = ({ error }) => {
  return (
    <Flex m={15} justifyContent="center">
      <Flex
        maxWidth={200}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Text mb={10} fontSize="large" fontWeight="bold">
          Opa! Algo deu errado.
        </Text>

        <Text textAlign="center" mb={10} fontSize="large">
          {error?.message || 'Algo inesperado aconteceu!'}
        </Text>
      </Flex>
    </Flex>
  );
};

export default Error;

Error.propTypes = {
  error: propTypes.string,
};

Error.defaultProps = {
  error: null,
};
