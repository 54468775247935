import { PhoneNumberUtil } from 'google-libphonenumber';

const mask = phone => {
  try {
    const phoneUtil = new PhoneNumberUtil();
    const number = phoneUtil.parseAndKeepRawInput(phone);
    const region = phoneUtil.getRegionCodeForNumber(number);

    return {
      region,
      countryCode: number.getCountryCode(),
      numberType: phoneUtil.getNumberType(number),
      nationalNumber: number.getNationalNumber(),
      formatted: phoneUtil.formatInOriginalFormat(number, region),
    };
  } catch (e) {
    return {
      formatted: phone,
      error: e,
    };
  }
};

export default mask;
