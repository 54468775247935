import gql from 'graphql-tag';

export const dwellerAccessDataProfiles = gql`
  query dwellerAccessDataProfiles($applicationKey: String!) {
    dwellerAccessDataProfiles(applicationKey: $applicationKey) {
      value
      label
    }
  }
`;

export const dwellerAccessDataProfileFeatures = gql`
  query dwellerAccessDataProfileFeatures(
    $profileId: Int!
    $applicationKey: String!
  ) {
    dwellerAccessDataProfileFeatures(
      profileId: $profileId
      applicationKey: $applicationKey
    ) {
      applicationFeatureId
      profilePermissionId
      name
      fieldName
      ableToRead
      ableToWrite
      allowsEdit
    }
  }
`;

export const resendEmail = gql`
  query resendEmail($email: String!) {
    resendEmail(email: $email)
  }
`;
