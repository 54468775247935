import styled, { css } from 'styled-components';

export const StepWrapper = styled.div`
  .direction-input__highlighter {
    max-height: 120px;
  }
  .direction-input-wrapper {
    border: none;
    ${props =>
      props.bordered &&
      css`
        border: 1px solid ${props.theme.colors.secondary300};
      `};
    border-radius: 8px;
    position: relative;
    .input-wrapper {
      padding: 0 10px 0 10px;
      width: 100%;
      height: 100%;
      max-width: 100%;
    }

    textarea {
      overflow: auto !important;
      border: none;
      appearance: none;
      box-shadow: none;
      width: 100%;
      transition: all 0.2s ease;
      font-size: 10px;
      padding: 0;
      margin: 0;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .direction-input {
    display: flex !important;
    flex-direction: column-reverse !important;
    font-weight: 600;
    height: 30px;
    font-size: 14px;
    overflow: visible;
    height: fit-content;
    max-height: 110px;
    min-height: 50px;

    .direction-input__suggestions {
      width: fit-content;
      position: initial !important;
      flex: 1;
      max-height: 300px;
      margin-bottom: 40px !important;
      display: flex !important;
      top: 0 !important;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      .direction-input__suggestions__list {
        max-height: 350px;
        min-width: 350px;
        max-width: 600px;
        max-width: 100%;
        overflow: auto;
        border-radius: 8px;
        font-weight: 400;
      }
      .direction-input__suggestions__item {
        ${props =>
          props.disabled &&
          css`
            opacity: 0.8 !important;
            pointer-events: none !important;
          `}
        padding: 16px 16px;
        :first-child {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }

        :last-child {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
      .direction-input__suggestions__item--focused {
        background-color: lightgray;
      }
    }
  }
`;
