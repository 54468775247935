import { authenticate } from '@kiper/fns';
import { create, update, remove, updateRoute } from './eventSettings.manager';

export const eventSettingCreate = authenticate(({ args, context }) =>
  create({ ...args, ...context }),
);

export const eventSettingUpdate = authenticate(({ args, context }) =>
  update({ ...args, ...context }),
);

export const eventSettingRemove = authenticate(({ args, context }) =>
  remove({ ...args, ...context }),
);

export const eventSettingRouteUpdate = authenticate(({ args, context }) =>
  updateRoute({ ...args, ...context }),
);
