import { forwardRef, useContext } from 'react';
import propTypes from 'prop-types';
import { ThemeContext } from 'styled-components';

import { handleNormalizeString } from '@kiper/fns';
import AsyncSelectStyled from './styles';

const AsyncSelect = forwardRef(
  ({ onChange, value, delay, name, options, ...props }, ref) => {
    const { colors } = useContext(ThemeContext);
    const handleFilterOptions = inputValue => {
      return options?.filter(option => {
        const normalizedInputValue = handleNormalizeString(inputValue);

        const normalizedOptionLabel = handleNormalizeString(
          option?.label || option?.name,
        );

        return normalizedOptionLabel?.includes(normalizedInputValue);
      });
    };

    const controlBaseStyles = {
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused
          ? colors.primary500
          : baseStyles.borderColor,
        boxShadow: state.isFocused
          ? `0 0 0 0.2rem ${colors.primary100}`
          : baseStyles.boxShadow,
      }),
    };

    const handleLoadOptions = (inputValue, callback) => {
      setTimeout(() => {
        callback(handleFilterOptions(inputValue));
      }, delay);
    };

    return (
      <AsyncSelectStyled
        {...props}
        ref={ref}
        loadOptions={handleLoadOptions}
        onChange={onChange}
        value={value}
        openMenuOnClick={false}
        styles={controlBaseStyles}
        cacheOptions
      />
    );
  },
);
export default AsyncSelect;

AsyncSelect.displayName = 'AsyncSelect';

AsyncSelect.propTypes = {
  value: propTypes.oneOfType([
    propTypes.any,
    propTypes.object,
    propTypes.array,
  ]),
  delay: propTypes.number,
  onChange: propTypes.func.isRequired,
  options: propTypes.array.isRequired,
  name: propTypes.string.isRequired,
};

AsyncSelect.defaultProps = {
  value: '',
  delay: 0,
};
