import styled from 'styled-components';
import { H3 } from '../Heading';

const Page = styled.div`
  display: block;
  width: 100%;
  padding-top: 25px;
`;

Page.Title = H3;

Page.Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default Page;
