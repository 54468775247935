import { Flex } from '@kiper/ui';
import propTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import context from '../context';
import * as S from './styles';

const prefix = 'face-invitation.error-cam-alert';

const ErrorCamAlert = ({ onToggle }) => {
  const { t } = useTranslation('invite');
  const { handleChangeStep, setUserMediaError } = useContext(context);

  const handleChangeFaceCaptureStep = () => {
    setUserMediaError(false);
    handleChangeStep(2);
  };

  const handleToggle = () => {
    setUserMediaError(false);
    onToggle();
  };

  return (
    <S.ModalBlockedCamAlert open toggle={onToggle} size="sm" centered>
      <S.ModalBody>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          gridGap="36px"
        >
          <S.VideoCamOffIcon />
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gridGap="24px"
            textAlign="center"
          >
            <S.BlockedCamMessageTitle>
              {t(`${prefix}.title`)}
            </S.BlockedCamMessageTitle>
            <Trans>
              <S.BlockedCamMessageDescription>
                {t(`${prefix}.description`)}
              </S.BlockedCamMessageDescription>
            </Trans>
          </Flex>
        </Flex>
        <Flex flexDirection="column" gridGap="8px">
          <S.ButtonBlockedCam
            variant="contained"
            color="white"
            block
            centered
            onClick={handleChangeFaceCaptureStep}
          >
            {t(`${prefix}.try-again`)}
          </S.ButtonBlockedCam>
          <S.ButtonBlockedCam
            variant="text"
            color="white"
            block
            centered
            onClick={handleToggle}
          >
            {t(`${prefix}.close`)}
          </S.ButtonBlockedCam>
        </Flex>
      </S.ModalBody>
    </S.ModalBlockedCamAlert>
  );
};

export default ErrorCamAlert;

ErrorCamAlert.propTypes = {
  onToggle: propTypes.func.isRequired,
};
