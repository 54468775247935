import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useQuery, useSwal } from '@kiper/hooks';
import { useContext, useEffect } from 'react';
import { apolloErrorHandler } from '@kiper/fns';
import Component from './ResetPassword';
import authContext from '../../../services/auth/context';

export default function ResetPassword(props) {
  const [t, i18n] = useTranslation('reset-password');
  const query = useQuery();
  const { toast } = useSwal();

  const auth = useContext(authContext);

  const validationSchema = yup.object({
    confirmationCode: yup
      .string()
      .length(6)
      .required(),
    password: yup
      .string()
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/)
      .required(),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'passwordConfirmation')
      .required(),
  });

  const onSubmit = async data => auth.reset(data);

  const formikBag = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
      confirmationCode: '',
      username: query.get('email'),
    },

    initialErrors: {
      lowercase: true,
      uppercase: true,
      required: true,
      specialCharacter: true,
      number: true,
      minAmount: true,
    },
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (auth.reseted) {
      props.history.push({
        pathname: '/',
        search: `?email=${formikBag.values.username}`,
      });
      toast.fire({ title: t('success'), icon: 'success' });
    }
  }, [auth.reseted]);

  useEffect(() => {
    if (auth?.error) {
      const formattedErrors = apolloErrorHandler(auth?.error);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    }
  }, [auth.error]);

  return Component({
    ...props,
    t,
    i18n,
    formikBag,
  });
}
