import propTypes from 'prop-types';
import * as UI from '..';
import * as S from './styles';

const SideMenu = ({
  title,
  list,
  loading,
  itemSelected,
  changeSelectedItem,
}) => {
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.ListGroup flexDirection="column" px={loading && '16px'}>
        {!list.length && loading ? (
          <UI.PlaceHolder type="line" lines={5} />
        ) : (
          list.map(item => (
            <S.ListGroupItem
              key={item.id}
              active={itemSelected === item.value}
              onClick={() => changeSelectedItem(item.value)}
            >
              {item.description}
            </S.ListGroupItem>
          ))
        )}
      </S.ListGroup>
    </S.Container>
  );
};

export default SideMenu;

SideMenu.propTypes = {
  title: propTypes.string.isRequired,
  list: propTypes.array,
  itemSelected: propTypes.oneOfType([propTypes.string, propTypes.number]),
  changeSelectedItem: propTypes.func.isRequired,
  loading: propTypes.bool.isRequired,
};

SideMenu.defaultProps = {
  list: [],
  itemSelected: null,
};
