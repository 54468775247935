import { useCallback } from 'react';
import propTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import * as S from './styles';

const keyPressValuesAddTag = ['Enter', ';', ','];

const MultiValueInput = ({
  name,
  label,
  error,
  icon: Icon,
  onAddTag,
  onDeleteTag,
  values,
  ...props
}) => {
  const addTagValue = event => {
    if (event.target.value) {
      onAddTag(event.target.value, event.target.name);
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  const handleAddTag = useCallback(
    event => {
      if (keyPressValuesAddTag.includes(event.key)) {
        addTagValue(event);
        event.preventDefault();
      }
    },
    [onAddTag],
  );

  const handleRemoveTag = useCallback(
    index => {
      onDeleteTag(index);
    },
    [onDeleteTag],
  );

  return (
    <S.Wrapper error={error}>
      {!!label && (
        <S.LabelWrapper>
          <S.Label htmlFor={name}>{label}</S.Label>
          {props.required && '*'}
        </S.LabelWrapper>
      )}
      <S.InputGroup error={error}>
        {Icon && <S.InputIcon>{Icon}</S.InputIcon>}
        <S.WrapperMultiValues>
          {!!values.length &&
            values.map((tagValue, tagIndex) => (
              <S.Tag key={tagIndex + Math.random()}>
                <S.TagValue>{tagValue}</S.TagValue>
                <S.TagClose onMouseDown={() => handleRemoveTag(tagIndex)}>
                  <MdClose />
                </S.TagClose>
              </S.Tag>
            ))}
          <S.Input
            aria-label={name}
            {...props}
            $hasValues={!!values.length}
            name={name}
            id={name}
            onKeyPress={handleAddTag}
            onBlur={addTagValue}
          />
        </S.WrapperMultiValues>
      </S.InputGroup>
      {error && typeof error === 'string' && <S.Error>{error}</S.Error>}
    </S.Wrapper>
  );
};

export default MultiValueInput;

MultiValueInput.propTypes = {
  onAddTag: propTypes.func.isRequired,
  onDeleteTag: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  values: propTypes.arrayOf(propTypes.string),
  delay: propTypes.number,
  pattern: propTypes.oneOfType([
    propTypes.instanceOf(RegExp),
    propTypes.string,
  ]),
  label: propTypes.string,
  icon: propTypes.element,
  error: propTypes.string,
  required: propTypes.bool,
};

MultiValueInput.defaultProps = {
  delay: 0,
  icon: <></>,
  values: [],
  pattern: '',
  required: false,
  error: '',
  label: '',
};
