import { useState, useEffect } from 'react';
import { useDebounce } from '@kiper/hooks';
import propTypes from 'prop-types';
import { StyledInputMask } from './styles';
import Label from '../Label';

const MASK_CHAR = ' ';

const InputMask = ({
  onChange,
  delay,
  value,
  isEditActive,
  maxwidth,
  ...props
}) => {
  const debounce = useDebounce({ delay });
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = e => {
    const eventValue = e.target.value;
    setInputValue(eventValue);
    const value = eventValue; // eslint-disable-line
    debounce(() => onChange(value, setInputValue));
  };

  if (!isEditActive) return <Label>{value}</Label>;

  return (
    <StyledInputMask
      {...props}
      maxwidth={maxwidth}
      value={inputValue}
      className="form-control"
      maskChar={MASK_CHAR}
      onChange={handleChange}
    />
  );
};

export default InputMask;

InputMask.propTypes = {
  onChange: propTypes.func.isRequired,
  delay: propTypes.number,
  value: propTypes.string,
  isEditActive: propTypes.bool,
  maxwidth: propTypes.bool,
};

InputMask.defaultProps = {
  isEditActive: true,
  maxwidth: false,
  delay: 0,
  value: '',
};
