import LoginPage from './Login';
import ResetPasswordPage from './ResetPassword';
import ConfirmPage from './Confirm';

export default [
  {
    name: 'Login',
    path: '/',
    exact: true,
    component: LoginPage,
  },
  {
    name: 'Reset password',
    path: '/reset-password',
    component: ResetPasswordPage,
  },
  {
    name: 'Confirm account',
    path: '/confirmation',
    component: ConfirmPage,
  },
];
