import styled from 'styled-components';
import { Flex, Modal as modal } from '@kiper/ui';
import * as webcam from 'react-webcam';

export const Modal = styled(modal)`
  @media (min-width: 320px) and (max-width: 480px) {
    margin: auto;
    height: 100%;
    width: 100%;
  }

  @media (min-width: 600px) {
    max-width: 332px;
  }
`;

export const Container = styled(Flex)`
  align-items: center;
  flex-direction: column;
  gap: 10px;
  min-height: 540px;
  justify-content: space-between;
`;

export const WebCam = styled(webcam)`
  border-radius: 8px;
  height: 400px;
  width: 300px;
`;
