import { useContext } from 'react';
import { Flex, Spinner } from '@kiper/ui';
import { useMutation } from 'react-apollo';
import { mutations } from '@kiper/account-graphql/package-delivery';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { context } from '../../store/PorterDelivery/DepositPackage';
import Layout from './Layout';
import fittingPiece2 from '../../assets/images/fitting-place-2.svg';
import * as S from './styles';

const StepConfirmDeposit = () => {
  const { toast } = useSwal();

  const [t] = useTranslation('porter-delivery');

  const { handleNextStep, handleBackStep, uuidDelivery } = useContext(context);

  const handleApolloError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (formattedErrors && formattedErrors.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  const [registerDelivery, { loading }] = useMutation(
    mutations.deliveryPackageToUnity,
    {
      variables: { deliveryUuid: uuidDelivery },
      onCompleted: () => {
        handleNextStep();
      },
      onError: err => {
        handleApolloError(err);
      },
    },
  );

  const [cancelDelivery, { loading: canceling }] = useMutation(
    mutations.cancelDelivery,
    {
      variables: { deliveryUuid: uuidDelivery },
      onCompleted: () => {
        handleBackStep();
      },
      onError: err => handleApolloError(err),
    },
  );

  return (
    <Layout onStepBack={handleBackStep}>
      <Flex
        width="100%"
        height="fit-content"
        gridGap="24px"
        alignItems="flex-start"
        justifyContent="center"
        flexDirection="column"
      >
        <S.Img src={fittingPiece2} alt="Delivery Endereço" />
        <S.Label>{t('confirm.understand')}</S.Label>

        <Flex
          width="100%"
          height="fit-content"
          flexDirection="column"
          gridGap="24px"
        >
          <Flex
            width="100%"
            gridGap="16px"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <S.NumberMark>1</S.NumberMark>
            <S.ListItem>
              <Trans>{t('confirm.deposit')}</Trans>
            </S.ListItem>
          </Flex>

          <Flex
            width="100%"
            gridGap="16px"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <S.NumberMark>2</S.NumberMark>
            <S.ListItem>
              <Trans>{t('confirm.close')}</Trans>
            </S.ListItem>
          </Flex>

          <Flex
            width="100%"
            gridGap="16px"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <S.NumberMark>3</S.NumberMark>
            <S.ListItem>{t('confirm.confirm')}</S.ListItem>
          </Flex>
        </Flex>

        <Flex
          width="100%"
          height="fit-content"
          flexDirection="column"
          gridGap="16px"
        >
          <S.Button disabled={loading} onClick={registerDelivery}>
            {t('confirm.success')}
            {loading && <Spinner size={14} />}
          </S.Button>

          <S.ButtonOutlined disabled={loading} onClick={cancelDelivery}>
            {t('confirm.cancel')}
            {canceling && <Spinner size={14} />}
          </S.ButtonOutlined>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default StepConfirmDeposit;
