import styled from 'styled-components';
import {
  Popover as ReactStrapPopover,
  PopoverHeader as ReactStrapPopoverHeader,
  PopoverBody as ReactStrapPopoverBody,
} from 'reactstrap';

export const Popover = styled(ReactStrapPopover)`
  .popover {
    display: flex;
    z-index: 10000;
    background-color: ${props =>
      props.theme.colors[
        props.variant === 'danger' ? 'dangerSecondary' : 'whiteSurface'
      ]};
  }

  .customInner {
    display: flex;
    flex-direction: column;
    min-width: 400px;
  }

  .customPopper {
    min-width: 420px;
    padding: 10px;
  }
`;

export const PopoverHeader = styled(ReactStrapPopoverHeader)`
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  border-bottom: ${props =>
    props.variant === 'danger' ? 'none' : '1px solid #c3c3c3'};

  font-size: 20px;
  color: ${props =>
    props.variant === 'danger' ? props.theme.colors.whiteSurface : 'inherit'};
`;

export const PopoverBody = styled(ReactStrapPopoverBody)`
  flex: 1;
  padding: 20px;
  color: ${props =>
    props.variant === 'danger' ? props.theme.colors.whiteSurface : 'inherit'};
`;

export const PopoverFooter = styled.div`
  padding: 10px 20px;
`;
