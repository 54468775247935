import { Flex } from '@kiper/ui';
import propTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import * as S from './styles';

const prefix = 'face-invitation.blocked-cam-alert';

const BlockedCamAlert = ({ onToggle }) => {
  const { t } = useTranslation('invite');

  return (
    <S.ModalBlockedCamAlert open toggle={onToggle} size="sm" centered>
      <S.ModalBody>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gridGap="36px"
        >
          <S.VideoCamOffIcon />
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gridGap="24px"
          >
            <S.BlockedCamMessageTitle>
              {t(`${prefix}.title`)}
            </S.BlockedCamMessageTitle>
            <Trans>
              <S.BlockedCamMessageDescription>
                {t(`${prefix}.description`)}
              </S.BlockedCamMessageDescription>
            </Trans>
          </Flex>
        </Flex>
        <S.Button
          variant="contained"
          color="white"
          block
          centered
          onClick={onToggle}
        >
          {t(`${prefix}.close`)}
        </S.Button>
      </S.ModalBody>
    </S.ModalBlockedCamAlert>
  );
};

export default BlockedCamAlert;

BlockedCamAlert.propTypes = {
  onToggle: propTypes.func.isRequired,
};
