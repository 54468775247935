import styled from 'styled-components';

const ErrorMessage = styled.div`
  width: 100%;
  font-size: 12px;
  margin: 5px;
  color: ${props => props.theme.colors.dangerPrimary};
`;

export default ErrorMessage;
