import gql from 'graphql-tag';

export const deliveryPackageToUnity = gql`
  mutation deliveryPackageToUnity($deliveryUuid: String!) {
    deliveryPackageToUnity(deliveryUuid: $deliveryUuid)
  }
`;

export const openBoxDrawer = gql`
  mutation openBoxDrawer($uuid: String!, $phone: String!, $unityId: Int) {
    openBoxDrawer(uuid: $uuid, phone: $phone, unityId: $unityId)
  }
`;

export const cancelDelivery = gql`
  mutation cancelDelivery($deliveryUuid: String!) {
    cancelDelivery(deliveryUuid: $deliveryUuid)
  }
`;
