import { get, types } from '@kiper/cookie';
import { runtimeConfig } from '@kiper/fns';

export default function authorizationHeaders(request) {
  const [authorization, userContextId, userPartnerContextId] = get(
    [types.authorization, types.context, types.partnerContext],
    request,
  );
  return {
    userContextId,
    userPartnerContextId,
    authorization: (authorization && `Bearer ${authorization}`) || '',
    applicationKey: runtimeConfig.APPLICATION_KEY_ACCOUNT,
  };
}
