import { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { MdHelp, MdQrCode2 } from 'react-icons/md';
import { useToggle } from '@kiper/hooks';
import { Modal, TabsMobile, Flex } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import { InfoGuest } from './components';
import FacialRegisterButton from '../../../../components/FacialRegisterButton';
import QrCode from '../../../../components/QrCode';
import FacialInvitation from '../../../../components/Modals/FacialInvitation/context/FacialInvitationProvider';
import {
  CalendarMonth,
  FamiliarFaceAndZoneIcon,
  HomePin,
} from '../../../../components/SvgIcons';
import inviteGuestExpired from '../../../../assets/images/invite-guest-expired.svg';
import inviteGuestInvalid from '../../../../assets/images/invite-guest-invalid.svg';
import facialInitialPageIcon from '../../../../assets/images/facial-initial-page-icon.svg';
import facialConfirmRegister from '../../../../assets/images/facial-confirm-register.svg';
import logoKiper from '../../../../assets/images/white-logo-kiper.svg';
import logoPorter from '../../../../assets/images/white-logo-porter.svg';
import helpUseAppGif from '../../../../assets/animations/helpUseAppGif.gif';
import * as S from './styles';
import { InviteGuestContext } from '../../../../store/InviteGuest';

const TabsTypes = Object.freeze({
  FACIAL: 'Facial',
  QR_CODE: 'QR Code',
});

export default function MainInvite() {
  const [t] = useTranslation('invite');
  const [openModal, toggleModal] = useToggle(false);

  const {
    isIOS,
    hasQrCodeInvite,
    hasFacialInvite,
    inviteValid,
    inviteExpired,
    inviteInvalid,
    refetchInviteGuest,
    getPass,
    loadingPass,
    formattedValues,
    language,
    guestHash,
  } = useContext(InviteGuestContext);

  const [activeTab, setActiveTab] = useState(0);
  const [toggleModalFaceCapture, setToggleModalFaceCapture] = useState(false);

  const tabs = [
    {
      title: TabsTypes.FACIAL,
      icon: (
        <FamiliarFaceAndZoneIcon
          color={activeTab === 0 ? 'white' : 'black'}
          size={20}
        />
      ),
    },
    {
      title: TabsTypes.QR_CODE,
      icon: <MdQrCode2 size={20} />,
    },
  ];

  const facialTabSelected = useMemo(
    () => tabs[activeTab].title === TabsTypes.FACIAL,
    [activeTab],
  );

  const handleChangeTab = useCallback(
    tab => {
      const activeTabIndex = tabs.findIndex(
        tabItem => tabItem.title === tab.title,
      );
      setActiveTab(activeTabIndex);
    },
    [activeTab],
  );

  useEffect(() => {
    if (hasFacialInvite) {
      setActiveTab(tabs.findIndex(tab => tab.title === TabsTypes.FACIAL));
    } else if (hasQrCodeInvite) {
      setActiveTab(tabs.findIndex(tab => tab.title === TabsTypes.QR_CODE));
    }
  }, [hasFacialInvite, hasQrCodeInvite]);

  const handleToggleModalFaceCapture = () =>
    setToggleModalFaceCapture(prevState => !prevState);

  const handleToggleModalHelpMe = () => {
    toggleModal();
  };

  const inviteRegisterStatusMessage = formattedValues.hasFaceId
    ? [
        {
          title: t('facial.confirm-register-title'),
          description: t('facial.confirm-register-message'),
          status: 'success',
          disabled: true,
          show: true,
        },
        {
          title: t('facial.update-button-title'),
          description: t('facial.update-button-message'),
          status: 'edit',
          disabled: false,
          show: formattedValues.allowFaceEdit,
        },
      ]
    : [
        {
          title: t('facial.register-button-title'),
          description: t('facial.register-button-message'),
          status: 'register',
          disabled: false,
          show: true,
        },
      ];

  return (
    <>
      {toggleModalFaceCapture && (
        <FacialInvitation
          onToggle={() => setToggleModalFaceCapture(prevState => !prevState)}
          guestHash={guestHash}
          refetch={refetchInviteGuest}
        />
      )}
      {openModal && (
        <Modal
          open={openModal}
          toggle={toggleModal}
          size="sm"
          title={t('feedback.modal-title')}
        >
          <Flex alignItems="center" justifyContent="center">
            <img src={helpUseAppGif} alt="helpAppKiper" />
          </Flex>
        </Modal>
      )}

      {formattedValues && (
        <S.Wrapper isPorter={formattedValues.isPorterPartner}>
          <S.Content>
            <S.InviteHeader>
              <S.Img
                src={formattedValues.isPorterPartner ? logoPorter : logoKiper}
                alt="kiper"
              />
              <S.Button onClick={handleToggleModalHelpMe}>
                <MdHelp size={32} color="white" />
              </S.Button>
            </S.InviteHeader>
            {!(inviteExpired || inviteInvalid) &&
              hasFacialInvite &&
              hasQrCodeInvite && (
                <TabsMobile
                  tabs={tabs}
                  active={activeTab}
                  onChangeTab={handleChangeTab}
                />
              )}
            <S.InviteContainer>
              <S.Invite>
                {(inviteExpired || inviteInvalid) && (
                  <Flex alignItems="center" justifyContent="center">
                    <S.InviteInvalid
                      background={
                        inviteExpired ? inviteGuestExpired : inviteGuestInvalid
                      }
                    />
                  </Flex>
                )}
                {!(inviteExpired || inviteInvalid) &&
                  (facialTabSelected ? (
                    <S.FacialRegisterIconContainer>
                      <S.FacialRegisterIcon
                        icon={
                          formattedValues.hasFaceId
                            ? facialConfirmRegister
                            : facialInitialPageIcon
                        }
                      />
                    </S.FacialRegisterIconContainer>
                  ) : (
                    <S.QrCodeContainer>
                      <S.QrCodeArea>
                        {inviteValid && (
                          <QrCode
                            value={formattedValues.hash}
                            size={250}
                            marginSize={15}
                          />
                        )}
                      </S.QrCodeArea>
                    </S.QrCodeContainer>
                  ))}

                <S.InviteInfo>
                  {inviteExpired || inviteInvalid ? (
                    <>
                      <S.Span warning>
                        {t(`feedback.${inviteExpired ? 'expired' : 'invalid'}`)}
                      </S.Span>
                      <S.Span>
                        {t(
                          `feedback.${
                            inviteExpired
                              ? 'expired-context'
                              : 'invalid-context'
                          }`,
                        )}
                      </S.Span>
                    </>
                  ) : (
                    <>
                      {hasFacialInvite && (
                        <Flex
                          flexDirection="column"
                          alignItems="stretch"
                          gridGap="6px"
                        >
                          {inviteRegisterStatusMessage.map(item => (
                            <FacialRegisterButton
                              {...item}
                              key={`${item.title}-${item.status}`}
                              onClick={() => handleToggleModalFaceCapture()}
                            />
                          ))}
                        </Flex>
                      )}
                      <div>
                        <S.InviteTitle expired={+inviteExpired}>
                          {formattedValues.title}
                        </S.InviteTitle>
                        <InfoGuest
                          icon={<CalendarMonth color="#374AA0" size={32} />}
                          label={t('info.event.start')}
                          value={formattedValues.startInvite}
                          expired={inviteExpired}
                          isPorter={formattedValues.isPorterPartner}
                        />
                      </div>
                      {formattedValues.address && (
                        <InfoGuest
                          icon={<HomePin color="#374AA0" size={32} />}
                          label={t('info.event.place')}
                          value={formattedValues?.condominiumName}
                          expired={inviteExpired}
                          isPorter={formattedValues.isPorterPartner}
                          linkAddress={`${formattedValues?.addressLink}`}
                          address={`${formattedValues?.address}`}
                        />
                      )}
                    </>
                  )}
                </S.InviteInfo>
              </S.Invite>
            </S.InviteContainer>
            {!isIOS && !(inviteExpired || inviteInvalid) && (
              <S.GoogleWalletButton
                className={loadingPass && 'disabled'}
                onClick={() => getPass()}
                lang={language}
                isPorter={formattedValues.isPorterPartner}
              />
            )}
          </S.Content>
        </S.Wrapper>
      )}
    </>
  );
}
