import gql from 'graphql-tag';

export const getGuidedAttendanceSettings = gql`
  query guidedAttendanceSettings {
    guidedAttendanceSettings {
      guidedAttendanceSettings {
        eventType
        condominium {
          id
          name
        }
      }
    }
  }
`;
