import gql from 'graphql-tag';

export const sendRegisterInviteToDwellersEmail = gql`
  mutation sendRegisterInviteToDwellersEmail(
    $condominiumContextId: ID!
    $emails: [String]!
  ) {
    sendRegisterInviteToDwellersEmail(
      condominiumContextId: $condominiumContextId
      emails: $emails
    )
  }
`;
