import gql from 'graphql-tag';

export const applicationProfiles = gql`
  query ApplicationProfiles($applicationKey: String!) {
    applicationProfiles(applicationKey: $applicationKey) {
      id
      applicationId
      profile {
        id
        name
        fieldName
        personContextId
      }
    }
  }
`;
