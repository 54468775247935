import gql from 'graphql-tag';

export const eventPutOnTechnicalReview = gql`
  mutation eventPutOnTechnicalReview(
    $eventId: ID!
    $untreatedEventBody: UntreatedEventInput!
  ) {
    eventPutOnTechnicalReview(
      eventId: $eventId
      untreatedEventBody: $untreatedEventBody
    )
  }
`;

export const eventCreateServiceOrderAndFinish = gql`
  mutation eventCreateServiceOrderAndFinish(
    $eventId: ID!
    $untreatedEventBody: UntreatedEventInput!
  ) {
    eventCreateServiceOrderAndFinish(
      eventId: $eventId
      untreatedEventBody: $untreatedEventBody
    )
  }
`;
