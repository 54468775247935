import { Input } from '@kiper/ui';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Form, FormGroup, Button } from './styles';

export default function ConfirmForm() {
  const [t] = useTranslation('confirm');

  const {
    values,
    handleSubmit,
    setFieldValue,
    touched,
    errors,
  } = useFormikContext();

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Input
          name="email"
          placeholder={t('placeholder-email')}
          type="text"
          value={values.email}
          onChange={setFieldValue}
          invalid={errors.email && touched.email}
        />
      </FormGroup>
      <Button color="primary" type="submit">
        {t('next')}
      </Button>
    </Form>
  );
}
