import propTypes from 'prop-types';
import { ChevronLeft, ChevronRight } from 'react-feather';
import KiperButton from '../KiperButton';
import { Container, FormGroup, Label, SelectPageSize } from './styles';

const Pagination = ({
  pagination,
  fixedPageSize,
  handlePageSize,
  next,
  previous,
  translate: t,
}) => {
  const { page, pageSize, totalResults, totalPages } = pagination;

  const from = (page - 1) * pageSize + 1;
  const to = (page - 1) * pageSize + pageSize;
  const infoPage = `${from}-${to > totalResults ? totalResults : to} ${t(
    'common:pagination.of',
  )} ${totalResults}`;

  const countPage = `${page} / ${totalPages}`;

  return (
    <Container>
      <FormGroup>
        {!fixedPageSize && (
          <>
            <Label for="letterPage">
              {t('common:pagination.lines-per-page')}
            </Label>
            <SelectPageSize
              type="select"
              name="pageSize"
              value={pageSize}
              onChange={e => handlePageSize(e ? e.target.value : 10)}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </SelectPageSize>
          </>
        )}
        <Label>{infoPage}</Label>
        <KiperButton
          disabled={page === 1}
          variant="text"
          color="secondary"
          name="page"
          rounded
          icon={<ChevronLeft />}
          value={page}
          onClick={previous}
        />
        <>{countPage}</>
        <KiperButton
          disabled={totalPages === page || totalResults === 0}
          variant="text"
          color="secondary"
          name="page"
          value={page}
          onClick={next}
          rounded
          icon={<ChevronRight />}
        />
      </FormGroup>
    </Container>
  );
};

export default Pagination;

Pagination.propTypes = {
  pagination: propTypes.shape({
    pageSize: propTypes.number.isRequired,
    totalResults: propTypes.number,
    page: propTypes.number,
    totalPages: propTypes.number,
  }),
  fixedPageSize: propTypes.bool,
  handlePageSize: propTypes.func.isRequired,
  next: propTypes.func.isRequired,
  previous: propTypes.func.isRequired,
  translate: propTypes.func.isRequired,
};

Pagination.defaultProps = {
  pagination: {
    page: 1,
    pageSize: 10,
    totalResults: 0,
    totalPages: 0,
  },
  fixedPageSize: false,
};
