import propTypes from 'prop-types';
import * as S from './styles';

/**
 * @param mdIconName String - (snake_case) MD Icon Fonts Names by: https://fonts.google.com/icons
 * @param className String - Oficial MD icons types className - Default "material-symbols-outlined"
 * @param color String - Optional custom color for MdIcons
 * @param size Number - Optional custom size for MdIcons
 * @returns {JSX.Element}
 * @constructor
 */
const MdIcons = ({ mdIconName, color, size, className }) => (
  <S.Icon color={color} size={size} className={className}>
    {mdIconName}
  </S.Icon>
);
export default MdIcons;

MdIcons.propTypes = {
  mdIconName: propTypes.string.isRequired,
  color: propTypes.string,
  size: propTypes.string,
  className: propTypes.string,
};

MdIcons.defaultProps = {
  color: 'black',
  size: '24px',
  className: 'material-symbols-outlined',
};
