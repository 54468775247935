import styled, { css } from 'styled-components';
import { Input as InputRS } from 'reactstrap';
import { MdOutlineArrowForwardIos } from 'react-icons/md';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

export const Input = styled(InputRS)`
  font-family: 'Nunito Sans', sans-serif;
  flex: 1;
  width: auto;
  box-sizing: border-box;
  height: 24px;
  padding: 8px;
  border-radius: 8px;
  border: none;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  outline: none;

  color: ${({ theme }) => theme.colors.blackShades(0.8)};

  &:disabled {
    background-color: ${({ theme }) => theme.colors.secondary200};
    opacity: 50%;
  }

  :focus,
  :focus-within,
  :focus-visible {
    outline: none;
    box-shadow: none !important;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary400};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    color: ${({ theme }) => theme.colors.blackShades(0.8)};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const LabelWrapper = styled.div`
  display: inline-flex;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.blackShades(0.8)};
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
`;

export const Error = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.colors.danger500};
`;

export const InputGroup = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  height: 36px;
  width: auto;
  transition: border 0.1s ease-in;
  gap: 8px;
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.danger500 : theme.colors.secondary400};

  &:focus-visible,
  &:focus-within,
  &:focus {
    outline: none;

    ${({ theme }) => css`
      border: 1px solid ${theme.colors.primary500};
      svg {
        color: ${theme.colors.primary500};
        transition: color 0.2s ease-in;
      }

      button {
        border-left-color: ${theme.colors.primary500};
        transition: border-left-color 0.2s ease-in;
      }
    `}
  }
`;

export const InputIcon = styled.div`
  pointer-events: none;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.blackShades(0.8)};

  svg {
    height: 16px;
    width: auto;
  }
`;

export const IconButton = styled.button`
  all: unset;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary500};

  svg {
    height: 16px;
    width: auto;
  }

  cursor: pointer;
`;

export const WrapperSearchInput = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

export const ExpandMoreIcon = styled(MdOutlineArrowForwardIos)`
  transform: rotate(90deg);
`;

export const WrapperSelectOptions = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: auto;
  top: 44px;
  box-sizing: border-box;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 0px 2px ${({ theme }) => theme.colors.blackShades(0.48)};
  border-radius: 8px;

  overflow-y: auto;
  overflow-x: hidden;
  max-height: ${props => !!props.maxHeight && `calc(${props.maxHeight} - 8px)`};
`;

export const GroupSearchTitle = styled.span`
  all: unset;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.blackShades(0.8)};
  padding: 8px 16px;
`;

export const OptionsList = styled.ul`
  all: unset;
  box-sizing: border-box;
  width: 100%;
  height: auto;
`;

export const ListItem = styled.li`
  all: unset;
  display: grid;
  align-items: flex-start;
  justify-content: flex-start;
  grid-template-columns: 2fr 4fr;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  color: ${({ theme }) => theme.colors.blackShades(0.8)};
  padding: 8px 16px;
  gap: 32px;
  cursor: pointer;
  z-index: 0;

  &:hover {
    background-color: ${({ theme }) => theme.colors.tableHover};
  }

  transition: background-color 0.15s ease-in-out;
`;

export const GroupOption = styled.div`
  all: unset;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  height: auto;
  color: ${({ theme }) => theme.colors.blackShades(0.7)};
  overflow: hidden;
`;

export const GroupOptionLabel = styled.label`
  all: unset;
  display: flex;
  flex: 1;
  align-items: center;
  height: auto;
  color: ${({ theme }) => theme.colors.blackShades(0.7)};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

export const GroupOptionValue = styled.span`
  display: inline-block;
  margin: 0;
  box-sizing: border-box;
  width: max-content;
  height: auto;

  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: ${({ theme }) => theme.colors.blackShades(0.7)};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

export const GroupOptionValuesChip = styled.span`
  all: unset;
  display: inline-block;
  box-sizing: border-box;
  padding: 4px 8px;

  color: ${({ theme }) => theme.colors.blackShades(0.7)};
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;

  border: 1px solid ${({ theme }) => theme.colors.secondary300};
  border-radius: 24px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  height: auto;
  width: auto;
`;

export const ChipsContainer = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`;

export const ChipButtonMoreItems = styled.button`
  all: unset;
  box-sizing: border-box;
  padding: 4px 8px;

  color: ${({ theme }) => theme.colors.blackShades(0.7)};
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  border: ${({ seeMore, theme }) =>
    !seeMore ? `1px solid ${theme.colors.secondary300}` : 'none'};
  border-radius: 24px;

  height: auto;
  width: max-content;
  z-index: 2;
`;

export const EmptyOptionsContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 24px 8px;
  color: ${({ theme }) => theme.colors.blackShades(0.7)};
`;

export const ChipWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
`;

export const EmptyDwellersText = styled.span`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: ${props => props.theme.colors.dangerSecondary};
`;
