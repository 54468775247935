import gql from 'graphql-tag';
import {
  accessFingerprintCondominiumFragment,
  accessFingerprintCondominiumV2Fragment,
} from './fragments';

// TODO: Remover método findFingerprintTerminalByCondominium
export const findFingerprintTerminalByCondominium = gql`
  query findFingerprintTerminalByCondominium($userId: Int!) {
    findFingerprintTerminalByCondominium(userId: $userId) {
      id
      name
      place {
        id
        name
        fpTerminalName
        fpTerminalId
      }
    }
  }
`;

export const fingerprintRemove = gql`
  mutation fingerprintRemove($personId: Int!) {
    fingerprintRemove(personId: $personId)
  }
`;

export const fingerprintInsert = gql`
  mutation fingerprintInsert($params: FingerprintInput!) {
    fingerprintInsert(params: $params) {
      condominiumContextId
      startDate
      status
      personId
      id
      creationDate
    }
  }
`;

export const fingerprintPut = gql`
  mutation fingerprintPut($personId: Int!) {
    fingerprintPut(personId: $personId) {
      condominiumContextId
      startDate
      status
      personId
      id
      creationDate
    }
  }
`;

export const fingerprintRemoveV2 = gql`
  mutation fingerprintRemoveV2($accessFingerprintId: Int!) {
    fingerprintRemoveV2(accessFingerprintId: $accessFingerprintId)
  }
`;

export const fingerprintInsertV2 = gql`
  mutation fingerprintInsertV2($params: FingerprintParams!) {
    fingerprintInsertV2(params: $params) {
      condominiumContextId
      startDate
      status
      personId
      id
      creationDate
    }
  }
`;

export const fingerprintPutV2 = gql`
  mutation fingerprintPutV2($accessFingerprintId: Int!, $deviceId: Int!) {
    fingerprintPutV2(
      accessFingerprintId: $accessFingerprintId
      deviceId: $deviceId
    ) {
      condominiumContextId
      startDate
      status
      personId
      id
      creationDate
    }
  }
`;

// TODO: Remover
export const accessFingerprintCondominiums = gql`
  query accessFingerprintCondominiums($personId: Int!) {
    accessFingerprintCondominiums(personId: $personId) {
      ...accessFingerprintCondominiumParts
    }
  }
  ${accessFingerprintCondominiumFragment}
`;

export const accessFingerprintCondominiumsV2 = gql`
  query accessFingerprintCondominiumsV2($personId: Int!) {
    accessFingerprintCondominiumsV2(personId: $personId) {
      ...accessFingerprintCondominiumV2Parts
    }
  }
  ${accessFingerprintCondominiumV2Fragment}
`;
