import styled from 'styled-components';

export const RootWrapper = styled.div`
  display: flex;
  gap: 24px;
  max-width: 1200px;
  min-height: 100dvh;
  height: 100%;
  flex-direction: column;
  padding: 16px;
  overflow-y: auto;
  margin: 0 auto;
`;
