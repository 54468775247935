import ReactDatePicker from 'react-date-picker';
import styled, { css } from 'styled-components';

export const StyledReactDatePicker = styled(ReactDatePicker)`
  ${props =>
    props.invalid &&
    css`
      border-color: ${props.theme.colors.dangerPrimary};
    `}
`;
