import styled from 'styled-components';
import { Col as col, Row as row } from 'reactstrap';

const Grid = {};

const Col = styled(col)`
  padding: ${props => (props.separator ? '0 15px 0 0' : '0')};
  & + & {
    padding: 0;
  }
`;

const Row = styled(row)`
  margin: 0;
`;

export default Grid;

Grid.Col = Col;
Grid.Row = Row;
