import gql from 'graphql-tag';

const paginationFragment = gql`
  fragment paginationParts on Pagination {
    pageSize
    totalResults
    totalPages
    page
    previous
    next
  }
`;

export default paginationFragment;
