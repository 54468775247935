import gql from 'graphql-tag';
import { subtriageFragment, triageFragment } from './fragments';

export const triages = gql`
  query triages {
    triages {
      ...triageParts
    }
  }
  ${triageFragment}
`;

export const subtriages = gql`
  query subtriages($triageId: Int!) {
    subtriages(triageId: $triageId) {
      ...subtriageParts
    }
  }
  ${subtriageFragment}
`;
