import gql from 'graphql-tag';
import { eventTypeFragment } from './fragments';

export default gql`
  query allEvents {
    eventList {
      critical {
        ...eventTypeParts
      }
      notCritical {
        ...eventTypeParts
      }
      withMe {
        ...eventTypeParts
      }
    }
  }
  ${eventTypeFragment}
`;
