import propTypes from 'prop-types';

const GroupUnityButtonIcon = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 17.5V4H4V0H13.5V8H17.5V17.5H10V13.5H7.49995V17.5H0ZM1.49995 16H4V13.5H1.49995V16ZM1.49995 12H4V9.49998H1.49995V12ZM1.49995 8H4V5.49997H1.49995V8ZM5.49995 12H8V9.49998H5.49995V12ZM5.49995 8H8V5.49997H5.49995V8ZM5.49995 4H8V1.49997H5.49995V4ZM9.49995 12H12V9.49998H9.49995V12ZM9.49995 8H12V5.49997H9.49995V8ZM9.49995 4H12V1.49997H9.49995V4ZM13.5 16H16V13.5H13.5V16ZM13.5 12H16V9.49998H13.5V12Z"
        fill={color}
      />
    </svg>
  );
};

GroupUnityButtonIcon.propTypes = {
  size: propTypes.number,
  color: propTypes.string,
};

GroupUnityButtonIcon.defaultProps = {
  size: 20,
  color: '#9CACF8',
};

export default GroupUnityButtonIcon;
