import { useContext } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSwal } from '@kiper/hooks';
import context from '../context';
import { uploadFacial } from '../../../../services/api/invite';
import * as S from './styles';

const timeOut = t => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, t);
  });
};

const StepFaceCaptureConfirm = props => {
  const { guestHash } = props;
  const {
    photoUrl,
    setPhotoUrl,
    handleChangeStep,
    loading,
    setLoading,
  } = useContext(context);

  const { t } = useTranslation('invite');

  const storePhoto = false;

  const { toast } = useSwal();

  const handleError = error => {
    const [{ message }] = error?.response?.data;

    if (message) {
      return toast.fire({ title: message, icon: 'error' });
    }
    return toast.fire({ title: error.message, icon: 'error' });
  };

  const handleFinally = () => {
    setLoading(false);
  };

  const handleRedoPhoto = () => {
    setPhotoUrl(null);
    handleChangeStep(-1);
  };

  const handleSubmit = async () => {
    setLoading(true);
    handleChangeStep(1);

    const data = {
      storePhoto,
      face: photoUrl,
      hash: guestHash,
    };

    Promise.all([await uploadFacial({ data }), await timeOut(2000)])
      .catch(handleError)
      .finally(handleFinally);
  };

  return (
    <>
      <S.FormFacialConfirm>
        <S.Title>
          {t('porter-summit-invite.modal.face-capture-confirm.confirm-title')}
        </S.Title>
        <S.WebCamContainer>
          <S.Img
            src={photoUrl}
            alt={t('porter-summit-invite.modal.face-capture-confirm.photo-alt')}
            width={300}
            height={400}
          />
        </S.WebCamContainer>
        <S.ButtonGroup>
          <S.ButtonRemake variant="out" centered onClick={handleRedoPhoto}>
            {t('porter-summit-invite.modal.face-capture-confirm.remake')}
          </S.ButtonRemake>
          <S.ButtonSendPhoto disabled={loading} centered onClick={handleSubmit}>
            {t('porter-summit-invite.modal.face-capture-confirm.send')}
          </S.ButtonSendPhoto>
        </S.ButtonGroup>
      </S.FormFacialConfirm>
    </>
  );
};

export default StepFaceCaptureConfirm;

StepFaceCaptureConfirm.propTypes = {
  guestHash: propTypes.string.isRequired,
};
