import styled from 'styled-components';
import { Button as btn, Col as col } from 'reactstrap';

export const Container = styled(col)`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
`;

export const Img = styled.img``;

export const Button = styled(btn)`
  margin-bottom: 20px;
  &:active {
    transform: translateY(2px);
  }
`;

export const Col = styled(col)`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const Title = styled.span`
  height: 30px;
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: ${props => props.theme.colors.blackShades(0.87)};
`;

export const Message = styled.span`
  height: auto;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  color: ${props => props.theme.colors.blackShades(0.87)};
  text-align: center;
`;
