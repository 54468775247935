import styled, { css } from 'styled-components';
import {
  NavLink as RSNavLink,
  NavItem as RSNavItem,
  Nav as RSNav,
  TabContent as RSTabContent,
  TabPane as RSTabPane,
} from 'reactstrap';

export const NavLink = styled(RSNavLink)`
  display: flex;
  cursor: pointer;
  border-bottom: 2px solid
    ${props =>
      props.active
        ? props.theme.colors.primary500
        : props.theme.colors.gray5} !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  background-color: transparent !important;

  ${props =>
      props.active
        ? css`
            color: ${props.theme.colors.primary500} !important;
          `
        : css`
            color: ${props.theme.colors.lowBlack} !important;
          `}
    :focus {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    background-color: transparent !important;
  }
  :hover {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    background-color: transparent !important;
  }

  padding: 0px 10px !important;
`;

export const NavItem = styled(RSNavItem)`
  & + & {
    margin-left: 12px;
  }
  display: flex;
`;

export const Nav = styled(RSNav)`
  border-bottom: 2px solid ${props => props.theme.colors.gray5};
  width: 100%;
`;

export const TabContent = styled(RSTabContent)``;

export const TabPane = styled(RSTabPane)``;
