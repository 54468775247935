import propTypes from 'prop-types';

import { TimePickerStyled } from './styles';

const TimePickerComponent = ({ value, ...props }) => {
  return <TimePickerStyled {...props} value={value} showSecond={false} />;
};

export default TimePickerComponent;

TimePickerComponent.propTypes = {
  value: propTypes.object.isRequired,
};
