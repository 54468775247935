import gql from 'graphql-tag';

export const unityCollectionFragment = gql`
  fragment unityCollectionParts on UnityCollection {
    id
    name
    serializedParams
    commercialUnityPersonContextId
    placeTypeLabelId
  }
`;
