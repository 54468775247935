import gql from 'graphql-tag';

const eventCamerasFragment = gql`
  fragment eventCamerasEventParts on CameraData {
    thumbnail
    placeId
    deviceTypeId
    parentDeviceId
    serialNumber
    ip
    name
    serializedParams
    isOnline
    id
    creationDate
    canTakeFacePhoto
  }
`;

export default gql`
  query singleEventCameras($eventId: ID!) {
    eventSingleCameras(eventId: $eventId) {
      ...eventCamerasEventParts
    }
  }
  ${eventCamerasFragment}
`;
