import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import styled from 'styled-components';

export const TimePickerStyled = styled(TimePicker)`
  input {
    text-align: center;
    min-height: 38px;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
  }
`;
