import gql from 'graphql-tag';

export default gql`
  query untreatedEventSelect {
    untreatedEventSelect {
      id
      description
      order
      textLog
    }
  }
`;
