import gql from 'graphql-tag';

const deviceCondoMapFragment = gql`
  fragment eventDeviceCondoMapParts on EventDeviceCondoMapType {
    id
    parentDeviceId
    deviceTypeId
    serialNumber
    ip
    name
    serializedParams
    doorId
    doorStatus
    keepOpenedReason
  }
`;

const interlockedPlacesCondoMapFragment = gql`
  fragment interlockedPlacesCondoMapParts on EventInterlockedCondoMapPlace {
    id
    name
    places {
      id
      name
    }
  }
`;

const placeCondoMapFragment = gql`
  fragment eventPlaceCondoMapParts on EventPlaceCondoMapType {
    id
    name
    comments
    serializedParams
    interlockedPlaces {
      ...interlockedPlacesCondoMapParts
    }
    devices {
      ...eventDeviceCondoMapParts
    }
  }
  ${interlockedPlacesCondoMapFragment}
  ${deviceCondoMapFragment}
`;

const personContextCondoMapFragment = gql`
  fragment personContextCondoMapParts on PersonContextCondoMapType {
    id
    params {
      name
      value
    }
    places {
      ...eventPlaceCondoMapParts
    }
    treeNodeId
  }
  ${placeCondoMapFragment}
`;

export const condominiumEventCondoMapFragment = gql`
  fragment condominiumEventCondoMapParts on CondoMapResponseData {
    id
    name
    personContext {
      ...personContextCondoMapParts
    }
  }
  ${personContextCondoMapFragment}
`;
