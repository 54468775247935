import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  margin-top: 59px;
  width: 540px;
  margin: 59px auto;
`;

export default Container;
