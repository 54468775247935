import styled, { css } from 'styled-components';
import ScrollBar from '../ScrollBar';

export const Wrapper = styled.aside`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: auto;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.white};
  border-left: 1px solid ${({ theme }) => theme.colors.secondary300};
  overflow: hidden;
`;

export const PaneTools = styled.div`
  height: 100%;
  width: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0;
  background-color: inherit;
  border-left: 1px solid ${({ theme }) => theme.colors.secondary300};
  gap: 16px;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary300};
`;

export const ContentPane = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  height: ${({ height }) => height};
  max-height: ${({ maxHeight }) => maxHeight};
  width: 100%;
  background-color: inherit;
  overflow-y: ${({ noScroll }) => (noScroll ? 'hidden' : 'auto')};
  overflow-x: hidden;
  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}
`;

export const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: inherit;
  overflow-y: none;

  @media screen and (min-width: 1980px) {
    width: 500px;
  }

  @media screen and (max-width: 1920px) {
    width: 500px;
  }

  @media screen and (max-width: 1600px) {
    width: 290px;
  }
`;

export const ToolItemsButton = styled.button`
  all: unset;
  width: 30px;
  height: 30px;
  color: ${({ theme, active }) =>
    !active ? theme.colors.blackShades(0.7) : theme.colors.primary500};
  background-color: ${({ theme, active }) =>
    !active ? 'white' : theme.colors.primary50};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  transition: background-color 0.2s ease-in;

  :hover {
    background-color: ${({ theme, active }) =>
      !active && theme.colors.secondary100};
    cursor: pointer;
  }

  :focus-visible,
  :focus-within,
  :focus {
    outline: none;
  }
`;

export const HeaderPane = styled.div`
  font-family: Lato;
  box-sizing: border-box;
  display: inline-flex;
  padding: 12px;
  width: 100%;
  height: 40px;
  gap: 8px;
  font-size: 16px;
  font-weight: 700;

  ${({ theme, justifyContent, alignItems, hasBorder }) => css`
    background-color: ${theme.colors.secondary50};
    border-bottom: ${hasBorder
      ? `1px solid ${theme.colors.secondary300}`
      : 'none'};
    color: ${theme.colors.highBlack};
    justify-content: ${justifyContent};
    align-items: ${alignItems};
  `}

  @media screen and (max-width: 1600px) {
    font-size: 14px;
  }
`;

export const Scroll = styled(ScrollBar)`
  width: 100%;
`;
