import { useState } from 'react';
import propTypes from 'prop-types';

import { FiPhone, FiHome } from 'react-icons/fi';

import { Button, Flex, MdIcons, Spinner, Text } from '..';

import * as S from './styles';

function CallPriorities({
  options,
  t,
  actionButtons,
  callStatus,
  clickToCall,
  loadingCall,
  destinationTypes,
  getProfileName,
  getProfileColor,
  title,
  subtitle,
  handleGetCallPriorities,
  contactTypes,
  typeOfContacts,
  loading,
}) {
  const [currentContact, setCurrentContact] = useState(options[0]);
  const [contactAlreadyCalled, setContactAlreadyCalled] = useState([]);

  const columns = [
    {
      name: t('contact'),
      align: 'left',
      width: '46%',
    },
    {
      name: t('observation'),
      align: 'left',
      width: '44%',
    },
    {
      name: t('call'),
      align: 'left',
      width: '10%',
    },
  ];

  const handleClickToCall = (dweller, phone) => {
    if (phone) {
      const callObject = {
        ...phone,
        personContextId: dweller.personContextId,
      };
      setCurrentContact(dweller);
      clickToCall(callObject);
      setContactAlreadyCalled(callers => [...callers, phone.destination]);
    }
  };

  const handleChange = item => item.action(item.value);

  const getActionColor = value => {
    const colorMap = {
      [callStatus.NOT_AUTHORIZED]: 'danger',
      [callStatus.AUTHORIZED]: 'primary',
      [callStatus.AT_THE_CONCIERGE]: 'primary',
    };
    return colorMap[value];
  };

  const current = contact =>
    currentContact?.personContextId === contact?.personContextId;

  const hasCalled = phone => contactAlreadyCalled.includes(phone.destination);

  const isIntercom = option =>
    option.phones?.[0]?.destinationKind === destinationTypes.intercomAta;

  return (
    <S.Container>
      <Flex flexDirection="column">
        <S.Text>{title}</S.Text>
        <Flex gridGap="4px">
          <MdIcons mdIconName="error" size="20px" color="orange500" />
          <S.Text size="12px" color="orange500">
            {subtitle}
          </S.Text>
        </Flex>
      </Flex>
      <Flex gridGap="8px">
        <Button
          size="sm"
          variant={typeOfContacts === contactTypes.UNITY ? 'contained' : 'out'}
          onClick={() => handleGetCallPriorities(contactTypes.UNITY)}
          disabled={loading || loadingCall}
        >
          {t('unit-contacts')}
        </Button>
        <Button
          size="sm"
          variant={
            typeOfContacts === contactTypes.CONDOMINIUM ? 'contained' : 'out'
          }
          onClick={() => handleGetCallPriorities(contactTypes.CONDOMINIUM)}
          disabled={loading || loadingCall}
        >
          {t('condominium-contacts')}
        </Button>
      </Flex>
      <S.ContainerCall>
        <S.TableContainer>
          {!loading && !!options?.length && (
            <S.Table columns={columns}>
              <tbody>
                {options?.map(contact => (
                  <S.TR
                    key={contact?.personContextId}
                    current={current(contact)}
                  >
                    <td>
                      <Flex flexDirection="column">
                        {!isIntercom(contact) && (
                          <Text>
                            {`${contact?.callPriority} - ${contact.name}`}
                          </Text>
                        )}
                        {isIntercom(contact) && <Text>{contact.name}</Text>}
                        {!isIntercom(contact) && (
                          <Text
                            color={getProfileColor(contact?.profile?.fieldName)}
                          >
                            {getProfileName(contact?.profile?.fieldName)}
                          </Text>
                        )}
                      </Flex>
                    </td>
                    <td title={contact?.observation} width="45%">
                      <S.TextEllipsis>{contact?.observation}</S.TextEllipsis>
                    </td>
                    <td>
                      <Flex flexDirection="row" gridGap="4px">
                        {contact?.phones?.map(phone => (
                          <S.CallButton
                            key={phone?.destination}
                            icon={
                              isIntercom(contact) ? (
                                <FiHome size={20} />
                              ) : (
                                <FiPhone size={20} />
                              )
                            }
                            color="primary"
                            onClick={() => handleClickToCall(contact, phone)}
                            variant={
                              current(contact) && hasCalled(phone)
                                ? 'contained'
                                : 'out'
                            }
                            size="sm"
                            called={!current(contact) && hasCalled(phone)}
                            loading={current(contact) && loadingCall}
                            disabled={loadingCall}
                          />
                        ))}
                      </Flex>
                    </td>
                  </S.TR>
                ))}
              </tbody>
            </S.Table>
          )}
          {loading && (
            <S.LoaderContainer>
              <Spinner size={24} />
            </S.LoaderContainer>
          )}
        </S.TableContainer>
      </S.ContainerCall>
      <Flex width="100%" gridGap="4px" flexWrap="wrap">
        {actionButtons.map(action => (
          <S.ActionButton
            key={action.value}
            checked={action.checked}
            value={action.value}
            variant="out"
            status={callStatus}
            color={action.checked ? getActionColor(action.value) : 'neutral'}
            name="callStatus"
            onChange={() => handleChange(action)}
            label={action.description}
            icon={action.icon}
            id={`action-button-${action.id}`}
            title={action.description}
            disabled={loadingCall || loading}
          />
        ))}
      </Flex>
    </S.Container>
  );
}

export default CallPriorities;

CallPriorities.propTypes = {
  options: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      priority: propTypes.number,
      name: propTypes.name,
    }),
  ),
  t: propTypes.func.isRequired,
  actionButtons: propTypes.arrayOf(
    propTypes.shape({
      value: propTypes.number,
      description: propTypes.string,
      icon: propTypes.any,
      checked: propTypes.bool,
      action: propTypes.func,
    }).isRequired,
  ).isRequired,
  callStatus: propTypes.object.isRequired,
  clickToCall: propTypes.func.isRequired,
  destinationTypes: propTypes.object.isRequired,
  getProfileName: propTypes.func.isRequired,
  title: propTypes.element.isRequired,
  subtitle: propTypes.element.isRequired,
  handleGetCallPriorities: propTypes.func.isRequired,
  getProfileColor: propTypes.func,
  loadingCall: propTypes.bool,
  loading: propTypes.bool,
  contactTypes: propTypes.object,
  typeOfContacts: propTypes.string,
};

CallPriorities.defaultProps = {
  options: [],
  loadingCall: false,
  loading: false,
  contactTypes: { UNITY: 'unit', CONDOMINIUM: 'condominium' },
  typeOfContacts: 'unit',
  getProfileColor: () => 'highBlack',
};
