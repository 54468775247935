import propTypes from 'prop-types';

import styled from 'styled-components';

import { useToggle } from '@kiper/hooks';

import { Tooltip as tooltip } from 'reactstrap';

const StyledTooltip = styled(tooltip)``;

export const Tooltip = ({ target, children, placement, ...props }) => {
  const [isTooltipOpened, toggleTooltip] = useToggle(false);

  return (
    <StyledTooltip
      placement={placement}
      isOpen={isTooltipOpened}
      toggle={toggleTooltip}
      target={target}
      {...props}
    >
      {children}
    </StyledTooltip>
  );
};

Tooltip.propTypes = {
  target: propTypes.string.isRequired,
  children: propTypes.any.isRequired,
  placement: propTypes.string,
};

Tooltip.defaultProps = {
  placement: 'right',
};
