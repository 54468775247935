import { useContext, useEffect } from 'react';
import StepWelcome from './StepWelcome';
import { context } from '../../store/PorterDelivery/DepositPackage';
import StepPhoneNumber from './StepPhoneNumber';
import StepUnity from './StepUnity';
import StepOpenDrawer from './StepOpenDrawer';
import StepConfirmDeposit from './StepConfirmDeposit';
import StepGroupUnity from './StepGroupUnity';
import StepFeedbackSuccessDelivery from './StepFeedbackSuccessDelivery';
import * as S from './styles';

const DepositPackageSteps = props => {
  const { step } = useContext(context);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const steps = [
    <StepWelcome key={step} {...props} />,
    <StepPhoneNumber key={step} {...props} />,
    <StepGroupUnity key={step} {...props} />,
    <StepUnity key={step} {...props} />,
    <StepOpenDrawer key={step} {...props} />,
    <StepConfirmDeposit key={step} {...props} />,
    <StepFeedbackSuccessDelivery key={step} {...props} />,
  ];

  return <S.Wrapper>{steps[step]}</S.Wrapper>;
};

export default DepositPackageSteps;
