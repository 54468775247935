import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 75px;

  ${props =>
    props.invalid &&
    css`
      .form-control {
        border-color: ${props.theme.colors.dangerPrimary};
      }
    `}

  input {
    width: 75px !important;
  }
`;
