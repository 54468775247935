import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputMask from '../InputMask';
import { Wrapper } from './styles';

const Time = ({ value, onChange, ...props }) => {
  const [timeValue, setTimeValue] = useState(value);

  useEffect(() => {
    setTimeValue(value);
  }, [value]);

  const handleChange = eventValue => {
    setTimeValue(eventValue);
    if (onChange) onChange(eventValue);
  };

  return (
    <Wrapper {...props}>
      <InputMask
        {...props}
        mask="Hh:Mm"
        placeholder="HH:MM"
        value={timeValue}
        onChange={handleChange}
        formatChars={{
          H: '[0-2]',
          h: timeValue?.startsWith('2') ? '[0-3]' : '[0-9]',
          M: '[0-5]',
          m: '[0-9]',
        }}
      />
    </Wrapper>
  );
};

Time.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

Time.defaultProps = {
  value: '',
  onChange: undefined,
};

export default Time;
