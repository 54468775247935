import { runtimeConfig } from '@kiper/fns';
import cookie from 'js-cookie';

const types = {
  authorization: 'authorization',
  refresh: 'refresh',
  context: 'context',
  lastContext: 'lastContext',
  partnerContext: 'partnerContext',
  topNodeId: 'topNodeId',
  topContextId: 'topContextId',
  CloudFrontPolicy: 'CloudFrontPolicy',
  CloudFrontSignature: 'CloudFrontSignature',
  CloudFrontKeyPairId: 'CloudFrontKeyPairId',
};

const values = {
  [types.authorization]: 'kiper-authorization',
  [types.refresh]: 'kiper-refresh',
  [types.context]: 'kiper-context',
  [types.lastContext]: 'kiper-last-context',
  [types.partnerContext]: 'kiper-partner-context',
  [types.topNodeId]: 'kiper-top-node-id',
  [types.topContextId]: 'kiper-top-context-id',
  [types.CloudFrontPolicy]: 'CloudFront-Policy',
  [types.CloudFrontSignature]: 'CloudFront-Signature',
  [types.CloudFrontKeyPairId]: 'CloudFront-Key-Pair-Id',
};

const domain = runtimeConfig.RAZZLE_COOKIE_DOMAIN;

const isArray = obj => Object.prototype.toString.call(obj) === '[object Array]';

export { types, values };

const check = type => {
  if (values[type]) return;
  throw new Error('invalid cookie type');
};

export const remove = type => {
  const fn = x => {
    check(x);
    cookie.remove(values[x], { domain });
  };

  if (isArray(type)) type.forEach(x => fn(x));
  else fn(type);
};

export const set = (type, value) => {
  check(type);
  if (typeof value === 'undefined') return remove(type);

  cookie.set(values[type], value, { domain, sameSite: 'None', secure: true });
  return value;
};

export const setMap = map =>
  Object.keys(map).forEach(key => set(key, map[key]));

export const get = (type, request) => {
  const fn = x => {
    check(x);
    if (request && request.cookies && request.cookies[values[x]])
      return request.cookies[values[x]];

    return cookie.get(values[x]);
  };

  if (isArray(type)) {
    return type.map(x => fn(x));
  }

  return fn(type);
};
