import gql from 'graphql-tag';
import { unityCollectionFragment } from './fragments';

export const getUnities = gql`
  query unitiesByUnityGroup($unityGroupId: ID!) {
    unitiesByUnityGroup(unityGroupId: $unityGroupId) {
      ...unityCollectionParts
    }
  }
  ${unityCollectionFragment}
`;
