import gql from 'graphql-tag';

export const getWaitTimers = gql`
  query waitingEventTimers {
    waitingEventTimers {
      waitTimers
    }
  }
`;

export const putOnHold = gql`
  mutation eventPutOnHold($eventId: ID!, $putOnHoldBody: EventPutOnHoldInput!) {
    eventPutOnHold(eventId: $eventId, putOnHoldBody: $putOnHoldBody) {
      id
      activityType
      activityTypeId
      additionalInformation
      creationDate
    }
  }
`;
