import { authenticate } from '@kiper/fns';
import { list, get } from './eventSettings.manager';

export const eventSettings = authenticate(({ args, context }) =>
  list({ ...args, ...context }),
);

export const eventSetting = authenticate(({ args, context }) =>
  get({ ...args, ...context }),
);
