import { useContext, useEffect, useCallback } from 'react';
import propTypes from 'prop-types';
import { useReducer, useQuery as useQueryString } from '@kiper/hooks';
import { useQuery, useMutation } from 'react-apollo';
import { get, setMap, types, remove } from '@kiper/cookie';
import { apolloErrorHandler } from '@kiper/fns';
import authContext from './context';

import {
  actionTypes,
  setRedirectUri as setRedirectUriAction,
  resetFlags as resetFlagsAction,
  signInMutation,
  whoAmI as whoAmIGql,
  forgotMutation,
  resetMutation,
} from './actions';

import authReducer from './reducer';

function AuthProvider({ children }) {
  const query = useQueryString();
  const [state, dispatch] = useReducer(authReducer, useContext(authContext));

  useQuery(whoAmIGql, {
    skip: !get(types.authorization),
    fetchPolicy: 'cache-first',
  });

  const [loginFc] = useMutation(signInMutation, {
    onCompleted: ({ signIn }) => {
      setMap({
        [types.authorization]: signIn.accessToken,
        [types.refresh]: signIn.refreshToken,
      });
      dispatch({ type: actionTypes.login.success, payload: signIn });
    },
  });

  const login = useCallback(
    ({ username, password }) =>
      loginFc({ variables: { credentials: { username, password } } }),
    [],
  );

  const [forgotFc] = useMutation(forgotMutation, {
    onCompleted: ({ forgot }) => {
      dispatch({
        type: actionTypes.forgot.success,
        payload: forgot,
      });
    },
    onError: error => {
      const formattedError = apolloErrorHandler(error);
      dispatch({ type: actionTypes.forgot.error, payload: formattedError });
    },
  });
  const forgot = useCallback(
    ({ username }) => forgotFc({ variables: { username } }),
    [],
  );

  const [resetFc] = useMutation(resetMutation, {
    onError: e => dispatch({ type: actionTypes.reset.error, payload: e }),
    onCompleted: ({ resetPassword }) => {
      dispatch({
        type: actionTypes.reset.success,
        payload: resetPassword,
      });
    },
  });

  const reset = useCallback(({ username, password, confirmationCode }) => {
    dispatch({ type: actionTypes.reset.request });
    resetFc({
      variables: { resetPassword: { username, password, confirmationCode } },
    });
  }, []);

  const goBackLogin = useCallback(() => {
    dispatch({ type: actionTypes.forgot.goBack });
  }, []);

  const resetFlags = useCallback(() => dispatch(resetFlagsAction()), []);

  useEffect(() => {
    dispatch(setRedirectUriAction(query.get('redirect_uri')));
  }, []);

  const signOut = () => {
    remove([
      types.authorization,
      types.refresh,
      types.context,
      types.partnerContext,
      types.topNodeId,
      types.topContextId,
    ]);

    window.location = '/';
  };

  return (
    <authContext.Provider
      value={{
        ...state,
        login,
        forgot,
        reset,
        resetFlags,
        goBackLogin,
        signOut,
      }}
    >
      {children}
    </authContext.Provider>
  );
}

export default AuthProvider;

AuthProvider.propTypes = {
  children: propTypes.element.isRequired,
};
