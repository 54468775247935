export default {
  NODE_ENV: process.env.NODE_ENV,
  CLOUD_URL: process.env.RAZZLE_CLOUD_URL,
  MONITORING_URL: process.env.RAZZLE_MONITORING_URL,
  APPLICATION_KEY_MONITORING: process.env.RAZZLE_APPLICATION_KEY_MONITORING,
  APPLICATION_KEY_APP: process.env.RAZZLE_APPLICATION_KEY_APP,
  APPLICATION_KEY_ACCOUNT: process.env.RAZZLE_APPLICATION_KEY_ACCOUNT,
  APPLICATION_KEY_ADMIN: process.env.RAZZLE_APPLICATION_KEY_ADMIN,
  RAZZLE_WS_URL: process.env.RAZZLE_WS_URL,
  RAZZLE_GRAPHQL_URL: process.env.RAZZLE_GRAPHQL_URL,
  RAZZLE_GRAPHQL_WS_URL: process.env.RAZZLE_GRAPHQL_WS_URL,
  RAZZLE_COOKIE_DOMAIN: process.env.RAZZLE_COOKIE_DOMAIN,
  RAZZLE_COOKIE_KEY: process.env.RAZZLE_COOKIE_KEY,
  RAZZLE_COOKIE_CURRENT_CONTEXT_KEY:
    process.env.RAZZLE_COOKIE_CURRENT_CONTEXT_KEY,
  RAZZLE_KIPER_ACCOUNT: process.env.RAZZLE_KIPER_ACCOUNT,
  RAZZLE_KIPER_ACCOUNT_REDIRECT: process.env.RAZZLE_KIPER_ACCOUNT_REDIRECT,
  RAZZLE_ACCOUNT_REDIRECT_TO: process.env.RAZZLE_ACCOUNT_REDIRECT_TO,
  RAZZLE_ENV: process.env.RAZZLE_ENV,
  FIREBASE_API_KEY: process.env.RAZZLE_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.RAZZLE_FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL: process.env.RAZZLE_FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID: process.env.RAZZLE_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env.RAZZLE_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID: process.env.RAZZLE_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: process.env.RAZZLE_FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID: process.env.RAZZLE_FIREBASE_MEASUREMENT_ID,
  FIREBASE_PRODUCT_NAME: process.env.RAZZLE_FIREBASE_PRODUCT_NAME,
  ACCOUNT_GOOGLE_TAG_MANAGER_ID:
    process.env.RAZZLE_ACCOUNT_GOOGLE_TAG_MANAGER_ID,
  ADMIN_GOOGLE_TAG_MANAGER_ID: process.env.RAZZLE_ADMIN_GOOGLE_TAG_MANAGER_ID,
  MONITORING_GOOGLE_TAG_MANAGER_ID:
    process.env.RAZZLE_MONITORING_GOOGLE_TAG_MANAGER_ID,
  MONITORING_SMARTLOOK_KEY: process.env.RAZZLE_MONITORING_SMARTLOOK_KEY,
  ACCOUNT_SMARTLOOK_KEY: process.env.RAZZLE_ACCOUNT_SMARTLOOK_KEY,
  NEW_RELIC_LICENSE_KEY: process.env.NEW_RELIC_LICENSE_KEY,
  AWS_CLOUDFRONT_ASSETS_URL: process.env.RAZZLE_AWS_CLOUDFRONT_ASSETS_URL,
};
