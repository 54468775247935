import gql from 'graphql-tag';

const eventSchedulerSettingsPlaceFragment = gql`
  fragment eventSchedulerSettingsPlaceParts on EventSchedulerSettingsPlace {
    id
    name
  }
`;

export const eventSchedulerSettingsFragment = gql`
  fragment eventSchedulerSettingsParts on EventSchedulerSettings {
    id
    time
    days
    eventType
    observation
    place {
      ...eventSchedulerSettingsPlaceParts
    }
  }
  ${eventSchedulerSettingsPlaceFragment}
`;

export const eventSchedulerSettingsResponseFragment = gql`
  fragment eventSchedulerSettingsResponseParts on EventSchedulerSettingsResponse {
    eventSchedulerSettings {
      ...eventSchedulerSettingsParts
    }
  }
  ${eventSchedulerSettingsFragment}
`;

export const eventSchedulerSettingResponseFragment = gql`
  fragment eventSchedulerSettingResponseParts on EventSchedulerSettingResponse {
    eventSchedulerSettings {
      ...eventSchedulerSettingsParts
    }
  }
  ${eventSchedulerSettingsFragment}
`;
