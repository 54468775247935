import gql from 'graphql-tag';

export const eventGenerate = gql`
  mutation eventGenerate($template: EventGeneratorTemplate!) {
    eventGenerate(template: $template)
  }
`;

export const answeredCall = gql`
  mutation answeredCall($voiceToken: String!, $body: AnsweredCallInput!) {
    answeredCall(voiceToken: $voiceToken, body: $body)
  }
`;

export const eventTemplateInsert = gql`
  mutation eventTemplateInsert($eventTemplate: EventTemplateInput) {
    eventTemplateInsert(eventTemplate: $eventTemplate) {
      id
      eventType
      description
      template
      creationDate
    }
  }
`;
