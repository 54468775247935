import propTypes from 'prop-types';
import Flex from '../Flex';
import {
  PlaceholderLine as PlaceholderLineStyled,
  PlaceholderMedia as PlaceholderMediaStyled,
  PlaceholderParagraph as PlaceholderParagraphStyled,
} from './styles';

const renderLines = (lines, props) => {
  const linesToReturn = [];

  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < lines; index++) {
    linesToReturn.push(<Flex {...props} key={index} className="line" />);
  }

  return linesToReturn;
};

export function PlaceHolderLine({ lines, ...props }) {
  return (
    <PlaceholderLineStyled>{renderLines(lines, props)}</PlaceholderLineStyled>
  );
}

PlaceHolderLine.propTypes = {
  lines: propTypes.number.isRequired,
};

export function PlaceHolderParagraph({ lines }) {
  return (
    <PlaceholderParagraphStyled>
      {renderLines(lines)}
    </PlaceholderParagraphStyled>
  );
}

PlaceHolderParagraph.propTypes = {
  lines: propTypes.number.isRequired,
};

export function PlaceHolderMediaObject({ lines }) {
  return <PlaceholderMediaStyled>{renderLines(lines)}</PlaceholderMediaStyled>;
}

PlaceHolderMediaObject.propTypes = {
  lines: propTypes.number.isRequired,
};
