import styled, { css } from 'styled-components';

export const StyledRadio = styled.input`
  position: absolute;
  appearance: none;
  border: 1px solid ${props => props.theme.colors.mediumBlack};
  border-radius: 50%;
  transition: border-color 0.2s ease-in-out;
  cursor: pointer;

  ${props =>
    props.show
      ? css`
          top: calc(50% - 6px);
          right: 4%;
          width: 12px;
          height: 12px;
        `
      : css`
          opacity: 0;
          width: 0;
          height: 0;
        `}

  &:checked {
    background-color: ${props => props.theme.colors.primary500};
    border: 1px solid ${props => props.theme.colors.primary500};
    box-shadow: inset 0 0 0px 2px ${props => props.theme.colors.white};
  }
`;
