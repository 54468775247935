import ProductHubPage from './ProductHub';

export default [
  {
    name: 'Product hub',
    path: '/products',
    exact: true,
    component: ProductHubPage,
  },
];
