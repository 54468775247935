import gql from 'graphql-tag';

export const personTypeFragment = gql`
  fragment personTypeParts on PersonType {
    id
    name
    phone
    email
    treeNodeId
    personId
    personContextId
  }
`;
