import propTypes from 'prop-types';
import theme from '@kiper/theme';
import * as S from './styles';

export default function ContainedTab({
  activeTab,
  tabs,
  onChangeTab,
  colorActiveTab = theme.colors.primary500,
  fontFamilyTab = null,
}) {
  const handleChangeTab = (tab, link) => {
    if (tab !== activeTab) {
      onChangeTab(tab, link);
    }
  };

  const activeTabs = tabs.filter(tab => tab.showTab);

  if (!activeTabs || !activeTabs.length) return null;

  return (
    <S.Nav>
      {activeTabs.map(tab => (
        <S.NavLink
          colorActiveTab={colorActiveTab}
          fontFamilyTab={fontFamilyTab}
          key={tab.tabId}
          tabItemsQuantity={activeTabs.length}
          active={activeTab === tab.tabId}
          onClick={() => {
            handleChangeTab(tab.tabId, tab.link);
          }}
        >
          {tab.icon ?? <></>}
          {tab.title && (
            <S.TabTitle
              fontFamilyTab={fontFamilyTab}
              active={activeTab === tab.tabId}
            >
              {tab.title}
            </S.TabTitle>
          )}
        </S.NavLink>
      ))}
    </S.Nav>
  );
}

ContainedTab.propTypes = {
  onChangeTab: propTypes.func.isRequired,
  activeTab: propTypes.string.isRequired,
  colorActiveTab: propTypes.string,
  fontFamilyTab: propTypes.string,
  tabs: propTypes.arrayOf(
    propTypes.shape({
      tabId: propTypes.string.isRequired,
      showTab: propTypes.bool.isRequired,
      icon: propTypes.element,
      count: propTypes.number,
      title: propTypes.string,
      link: propTypes.string,
    }),
  ).isRequired,
};

ContainedTab.defaultProps = {
  colorActiveTab: theme.colors.primary500,
  fontFamilyTab: null,
};
