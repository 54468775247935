import gql from 'graphql-tag';
import { eventFragment } from './fragments';

export const onStart = gql`
  mutation onStartEvent(
    $event: EventType!
    $eventId: ID!
    $channelIds: [Int]!
    $isCritical: Boolean
  ) {
    startPlayEvent(event: $event) @client
    putEventWithMe(event: $event) @client
    eventStart(
      eventId: $eventId
      isCritical: $isCritical
      channelIds: $channelIds
    ) {
      ...eventParts
    }
  }
  ${eventFragment}
`;

export const onPlayPause = gql`
  mutation onPlayPauseEvent($event: EventType!) {
    startPlayEvent(event: $event) @client
  }
`;
