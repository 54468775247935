import gql from 'graphql-tag';
import { lockerDeliveryFragment, paginationFragment } from './fragments';

export const lockerDeliveryHistory = gql`
  query lockerDeliveryHistory(
    $filters: FilterHistoryInput
    $condominiumPersonContextId: ID!
  ) {
    lockerDeliveryHistory(
      filters: $filters
      condominiumPersonContextId: $condominiumPersonContextId
    ) {
      collection {
        ...lockerDeliveryParts
      }
      pagination {
        ...paginationParts
      }
    }
  }
  ${lockerDeliveryFragment}
  ${paginationFragment}
`;
