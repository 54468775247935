import styled, { css } from 'styled-components';
import InputMask from 'react-input-mask';

export const StyledInputMask = styled(InputMask)`
  width: 100%;
  height: 56px;
  border: 1px solid ${props => props.theme.colors.blackShades(0.16)};
  border-radius: 8px;
  padding: 24px;

  outline: 0;

  :focus {
    border: 1px solid ${props => props.theme.colors.invitePorterPrimary};
    box-shadow: 0 0 0 5px
      ${props => props.theme.colors.invitePorterPrimaryShades(0.2)};
  }

  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.5px;
  color: ${props => props.theme.colors.blackShades(0.64)};

  &:disabled {
    cursor: not-allowed;
    background-color: ${props => props.theme.colors.secondary100};
    border-color: ${props => props.theme.colors.secondary100};
  }

  ${props =>
    props.invalid &&
    css`
      border-color: ${props.theme.colors.dangerPrimary};
    `}
`;
