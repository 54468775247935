import { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-apollo';
import { formatDate, compareDateFromNow } from '@kiper/fns/src/timezone';
import { apolloErrorHandler, getUserLang } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { mutations } from '@kiper/account-graphql/invite';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { InviteGuestContext } from './context';
import { inviteStatus } from '../../constants';

const concatenateAddress = ({
  unityGroupName,
  unityName,
  streetName,
  addressNumber,
}) => {
  let result = '';

  if (unityGroupName) result = result.concat(`${unityGroupName} - `);
  if (unityName) result = result.concat(`${unityName} - `);
  if (streetName) result = result ? result.concat(streetName) : streetName;
  if (addressNumber) result = result.concat(`, ${addressNumber}`);

  return result;
};

const InviteGuestProvider = ({ children, inviteGuestQueryData, guestHash }) => {
  const {
    data: { getInviteGuest },
    loading: loadingInviteGuest,
    refetch: refetchInviteGuest,
  } = inviteGuestQueryData || {};

  const [t] = useTranslation('invite');
  const { toast } = useSwal();

  const [hasFacialInvite, setHasFacialInvite] = useState(false);
  const [hasQrCodeInvite, setHasQrCodeInvite] = useState(false);

  const handleApolloError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (formattedErrors && formattedErrors.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  useEffect(() => {
    if (!getInviteGuest) return;

    const enabledFacialInvite = getInviteGuest.hasFacial;
    const enabledQrCodeInvite = getInviteGuest.hasQrCode;

    setHasFacialInvite(enabledFacialInvite);
    setHasQrCodeInvite(enabledQrCodeInvite);
  }, [getInviteGuest]);

  const [language] = useMemo(() => getUserLang().split('-'), []);

  const formatPass = useMemo(() => {
    let pass;
    if (getInviteGuest?.hashId) {
      pass = {
        hashId: getInviteGuest.qrCode,
        guestId: getInviteGuest.id,
        inviteName: getInviteGuest.invite?.name,
        startText:
          getInviteGuest.invite.start &&
          formatDate(getInviteGuest.invite.start, 'L - HH:mm').formatted,
        start: getInviteGuest.invite?.start,
        end: getInviteGuest.invite?.end,
        condominiumName: getInviteGuest.condominium?.name || '',
        unityGroup: getInviteGuest.unityGroup?.name || '',
        unity: getInviteGuest.unity?.name || '',
        streetName: getInviteGuest.address?.streetName || '',
        addressNumber: getInviteGuest.address?.addressNumber || '',
        isPorterPartner: getInviteGuest.isPorterPartner,
        i18n: {
          language,
          title: t('pass.title'),
          subheader: t('pass.subheader'),
          date: t('pass.date'),
          local: t('pass.local'),
          address: t('pass.address'),
        },
      };
    }

    return pass;
  }, [getInviteGuest]);

  const formattedValues = useMemo(() => {
    if (getInviteGuest?.hashId) {
      const {
        condominium,
        address: addressInvite,
        invite,
        unityGroup,
        unity,
        isPorterPartner,
        hasFaceId,
        qrCode,
        allowFaceEdit,
        accessProfileName,
      } = getInviteGuest;

      const { name: condominiumName } = condominium;
      const { streetName, addressNumber } = addressInvite;
      const { start, end, name } = invite;
      const { name: unityGroupName } = unityGroup || { name: '' };
      const { name: unityName } = unity || { name: '' };

      return {
        hash: qrCode,
        allowFaceEdit,
        title: name,
        startInvite: start && formatDate(start, 'L - HH:mm').formatted,
        address: concatenateAddress({
          unityGroupName,
          unityName,
          streetName,
          addressNumber,
        }),
        addressLink: concatenateAddress({ streetName, addressNumber }),
        status:
          inviteStatus[compareDateFromNow(end).isAfter ? 'valid' : 'expired'],
        condominiumName: accessProfileName
          ? `${accessProfileName} - ${condominiumName}`
          : condominiumName,
        isPorterPartner,
        hasFaceId,
      };
    }
    return {
      status: inviteStatus.invalid,
      isPorterPartner: getInviteGuest?.isPorterPartner,
    };
  }, [getInviteGuest, inviteGuestQueryData?.data]);

  const [getPass, { loading: loadingPass }] = useMutation(
    mutations.createPass,
    {
      variables: { data: formatPass },
      onCompleted: res => {
        window.open(res?.getInvitePass?.hash, '_blank');
      },
      onError: err => handleApolloError(err),
    },
  );

  const inviteValid = useMemo(
    () => formattedValues.hash && formattedValues.status === inviteStatus.valid,
    [formattedValues.hash, formattedValues.status],
  );

  const inviteExpired = useMemo(
    () => formattedValues.status === inviteStatus.expired,
    [formattedValues.status],
  );

  const inviteInvalid = useMemo(
    () => formattedValues.status === inviteStatus.invalid,
    [formattedValues.status],
  );

  const isIOS = useMemo(() => {
    const userAgent = navigator?.userAgent || window?.opera;
    return /iPad|iPhone|iPod/i.test(userAgent);
  }, []);

  const values = useMemo(
    () => ({
      isIOS,
      language,
      hasQrCodeInvite,
      hasFacialInvite,
      loadingInviteGuest,
      refetchInviteGuest,
      getPass,
      loadingPass,
      formattedValues,
      inviteValid,
      inviteExpired,
      inviteInvalid,
      guestHash,
      condominium: {
        personContextId: getInviteGuest?.condominiumPersonContextId,
      },
    }),
    [
      hasQrCodeInvite,
      hasFacialInvite,
      getInviteGuest,
      formattedValues,
      inviteGuestQueryData?.data,
    ],
  );

  return (
    <InviteGuestContext.Provider value={values}>
      {children}
    </InviteGuestContext.Provider>
  );
};

InviteGuestProvider.propTypes = {
  children: propTypes.node.isRequired,
  inviteGuestQueryData: propTypes.shape({
    data: propTypes.object.isRequired,
    loading: propTypes.bool.isRequired,
    refetch: propTypes.func.isRequired,
  }).isRequired,
  guestHash: propTypes.string.isRequired,
};

export default InviteGuestProvider;
