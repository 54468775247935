import gql from 'graphql-tag';

export const attendantFragment = gql`
  fragment attendantPart on Person {
    personContextId
    name
    email
  }
`;

export const eventTypeDescriptionFragment = gql`
  fragment eventTypeDescriptionParts on EventTypeDescription {
    en
    es
    pt
  }
`;

export const eventFragment = gql`
  fragment eventParts on EventType {
    partnerId
    eventId
    eventType
    eventDate
    personId
    isCritical
    channelIds
    eventTypeDescription {
      ...eventTypeDescriptionParts
    }
  }
  ${eventTypeDescriptionFragment}
`;

export const condominiumFragment = gql`
  fragment condominiumParts on CondominiumEvent {
    name
    personContextId
  }
`;

export const eventSettingsFragment = gql`
  fragment eventSettingsParts on EventSettings {
    personContextId
    eventType
    priority
    isCritical
    showPopUp
    showAlert
    attendantScript
  }
`;

export const eventTypeFragment = gql`
  fragment eventTypeParts on EventType {
    ...eventParts
    attendant {
      ...attendantPart
    }
    condominium {
      ...condominiumParts
    }
    eventSettings {
      ...eventSettingsParts
    }
  }
  ${eventFragment}
  ${condominiumFragment}
  ${eventSettingsFragment}
  ${attendantFragment}
`;

export const eventTimelineDeviceFragment = gql`
  fragment eventTimelineDeviceParts on EventTimelineDevice {
    id
    placeId
    deviceTypeId
    parentDeviceId
    serialNumber
    ip
    name
    serializedParams
    isOnline
    creationDate
    lastUpdate
  }
`;

export const eventSimpleEntityFragment = gql`
  fragment eventSimpleEntityParts on EventSimpleEntity {
    id
    name
  }
`;

export const eventTimelinePlaceFragment = gql`
  fragment eventTimelinePlaceParts on EventTimelinePlace {
    id
    treeNodeId
    placeTypeId
    name
    serializedParams
    creationDate
    lastUpdate
  }
`;

export const eventAttendantPersonFragment = gql`
  fragment eventAttendantPersonParts on EventAttendantPerson {
    id
    username
    name
    email
    phone
    creationDate
    lastUpdate
  }
`;

export const eventAttendantProfileFragment = gql`
  fragment eventAttendantProfileParts on EventAttendantProfile {
    name
    fieldName
    personContextId
    id
    creationDate
  }
`;

export const eventAttendantFragment = gql`
  fragment eventAttendantParts on EventAttendant {
    id
    personId
    treeNodeId
    profileId
    creationDate
    person {
      ...eventAttendantPersonParts
    }
    profile {
      ...eventAttendantProfileParts
    }
  }
  ${eventAttendantPersonFragment}
  ${eventAttendantProfileFragment}
`;

export const eventTimelineDwellerPersonFragment = gql`
  fragment eventTimelineDwellerPersonParts on EventTimelineDwellerPerson {
    id
    name
    email
    phone
    creationDate
    lastUpdate
  }
`;

export const eventTimelineDwellerProfileFragment = gql`
  fragment eventTimelineDwellerProfileParts on EventTimelineDwellerProfile {
    name
    fieldName
    personContextId
    id
    creationDate
  }
`;

export const eventTimelineDwellerFragment = gql`
  fragment eventTimelineDwellerParts on EventTimelineDweller {
    id
    personId
    treeNodeId
    profileId
    creationDate
    person {
      ...eventTimelineDwellerPersonParts
    }
    profile {
      ...eventTimelineDwellerProfileParts
    }
  }
  ${eventTimelineDwellerPersonFragment}
  ${eventTimelineDwellerProfileFragment}
`;

export const eventTimelineFragment = gql`
  fragment eventTimelineParts on EventTimeline {
    eventId
    eventType
    treatmentStatus
    treatmentStatusDescription
    treatment
    creationDate
    treatmentEndDate
    isCritical
    eventTypeDescription {
      ...eventTypeDescriptionParts
    }
    device {
      ...eventSimpleEntityParts
    }
    place {
      ...eventSimpleEntityParts
    }
    attendant {
      ...eventSimpleEntityParts
    }
    dweller {
      ...eventSimpleEntityParts
    }
  }
  ${eventTypeDescriptionFragment}
  ${eventSimpleEntityFragment}
`;

export const eventKiperVoiceServerFragment = gql`
  fragment eventKiperVoiceServerParts on EventKiperVoiceServer {
    url
  }
`;

export const eventActivitiesFragment = gql`
  fragment eventActivitiesParts on EventActivities {
    id
    activityType
    activityTypeId
    creationDate
    additionalInformation
    attendantPersonContext {
      ...eventAttendantParts
    }
  }
  ${eventAttendantFragment}
`;

export const eventActivityFragment = gql`
  fragment eventActivityParts on EventActivity {
    eventId
    creationDate
    kiperVoiceServer {
      ...eventKiperVoiceServerParts
    }
    activities {
      ...eventActivitiesParts
    }
  }
  ${eventKiperVoiceServerFragment}
  ${eventActivitiesFragment}
`;

export const eventTypeTreatmentMenuFragments = gql`
  fragment eventTypeTreatmentMenuParts on EventTypeTreatmentMenu {
    id
    eventType
    fieldName
    creationDate
  }
`;
