import { useState, useEffect } from 'react';
import { useDebounce, useFilterSearchParams } from '@kiper/hooks';
import propTypes from 'prop-types';

import Input from './styles';

const CustomInput = ({
  onChange,
  value,
  name,
  delay,
  component: Component,
  pattern,
  minChar,
  withoutArrowNumber,
  filterLinkedFields,
  isSearchFilter,
  ...props
}) => {
  const {
    appendParam,
    removeParam,
    setInputFilterValueFromQuery,
  } = useFilterSearchParams({
    key: name,
    filterLinkedFields,
    onChange: ({ key, queryValue }) => onChange(key, queryValue),
  });

  const debounce = useDebounce({ delay });
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    if (isSearchFilter) setInputFilterValueFromQuery();
    setInputValue(value);
  }, [value]);

  const handleChange = e => {
    const eventValue = e.target.value;
    setInputValue(eventValue);

    if (isSearchFilter)
      if (eventValue) appendParam({ queryValue: eventValue });
      else removeParam();

    if (pattern && eventValue && !eventValue.match(pattern)) return;

    debounce(() => {
      if (
        !minChar ||
        (minChar && eventValue?.length >= minChar) ||
        (minChar && eventValue?.length === 0)
      ) {
        onChange(name, eventValue);
      }
    });
  };

  return (
    <Component
      {...props}
      value={inputValue}
      name={name}
      id={name}
      onChange={handleChange}
      $withoutArrowNumber={withoutArrowNumber}
    />
  );
};

export default CustomInput;

CustomInput.propTypes = {
  onChange: propTypes.func.isRequired,
  value: propTypes.any,
  name: propTypes.string.isRequired,
  component: propTypes.oneOfType([
    propTypes.element,
    propTypes.object,
    propTypes.func,
  ]),
  delay: propTypes.number,
  pattern: propTypes.oneOfType([
    propTypes.instanceOf(RegExp),
    propTypes.string,
  ]),
  minChar: propTypes.number,
  isSearchFilter: propTypes.bool,
  withoutArrowNumber: propTypes.bool,
  filterLinkedFields: propTypes.array,
};

CustomInput.defaultProps = {
  delay: 0,
  component: Input,
  value: '',
  pattern: '',
  minChar: 0,
  isSearchFilter: false,
  withoutArrowNumber: false,
  filterLinkedFields: [],
};
