import gql from 'graphql-tag';
import { triageFragment } from './fragments';

export const triageCreate = gql`
  mutation triageCreate($triage: TriageInput!) {
    triageCreate(triage: $triage) {
      ...triageParts
    }
  }
  ${triageFragment}
`;
