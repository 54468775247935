import styled, { css } from 'styled-components';
import Flex from '../Flex';
import KiperButton from '../KiperButton';

export const DaysOfWeekContainer = styled(Flex)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 8px;

  ${props =>
    props.onlyText &&
    props.disabled &&
    css`
      justify-content: unset;
      gap: 3px;
    `}
`;
export const Container = styled(Flex)`
  flex-direction: column;
  gap: 8px;
  width: 100%;

  ${props =>
    !props.fullwidth &&
    css`
      max-width: 340px;
    `}
  background-color: ${props => props.theme.colors.white};
  border: 1px solid
    ${props =>
      !props.invalid
        ? props.theme.colors.secondary300
        : props.theme.colors.danger500};
  border-radius: 8px;
  padding: 10px;
  color: ${({ color, theme }) => color && theme.colors[color]};
`;

export const RemoveButton = styled(KiperButton)`
  align-self: flex-start;
`;
