import { useQuery } from 'react-apollo';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { queries } from '@kiper/account-graphql/invite';

export const useFetchInviteGuest = ({ guestHash }) => {
  const { toast } = useSwal();

  const handleApolloError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (formattedErrors && formattedErrors.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  const queryData = useQuery(queries.get, {
    fetchPolicy: 'network-only',
    skip: !guestHash,
    variables: { guestHash },
    onError: err => handleApolloError(err),
  });

  return queryData;
};
