import styled from 'styled-components';

export const LoaderContainer = styled.div`
  position: relative;

  .line {
    margin-bottom: 15px;
  }
`;

export const Transparence = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
`;
