import Spinner from '../Spinner';
import * as S from './styles';

const GenericLoader = () => {
  return (
    <S.LoaderWrapper>
      <Spinner size={60} />
    </S.LoaderWrapper>
  );
};

export default GenericLoader;
