import styled, { css, keyframes } from 'styled-components';
import { MdFaceUnlock, MdOutlineVideocamOff } from 'react-icons/md';
import { CheckBox, Flex, KiperButton, Text, Modal as modal } from '@kiper/ui';
import * as webcam from 'react-webcam';
import { GlassesOff, LightBulbOn, RedHatOff } from '../../../SvgIcons';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const StepContainer = styled(Flex)`
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

export const StepLoadingContainer = styled(StepContainer)`
  justify-content: center;
  flex: 1;
  margin-bottom: 40px;
`;

export const FormFacialConfirm = styled.form`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  gap: 10px;
`;

export const WebCam = styled(webcam)`
  border-radius: 8px;
  max-height: 400px;
  max-width: 300px;

  -webkit-mask-size: cover;
  mask-size: cover;
  mask-repeat: no-repeat;

  -webkit-mask-image: ${props => `url(${props.mask})`};
  mask-image: ${props => `url(${props.mask})`};
`;

export const WebCamContainer = styled(Flex)`
  height: 400px;
  width: 300px;

  &:before {
    ${props =>
      !!props.mask &&
      css`
        content: '';
        width: 181px;
        height: 251px;
        position: absolute;
        z-index: 1;
        margin: auto;
        border: 2px dashed white;
        border-radius: 50%;
        margin-left: 60px;
        margin-top: 70px;
      `}
  }
`;

export const Button = styled(KiperButton)`
  padding: 20px;
  font-weight: 700;
  width: 100%;

  span {
    font-size: 16px;
  }
`;

export const Img = styled.img`
  border-radius: 8px;
  object-fit: cover;
`;

export const ButtonGroup = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  gap: 16px;
`;

export const Checkbox = styled(CheckBox)`
  width: 16px;
  height: 16px;
  accent-color: ${({ theme }) => theme.colors.invitePorterSecondary};
`;

export const Label = styled.label`
  margin: 0;
  color: ${({ theme }) => theme.colors.invitePorterPrimary};
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  line-height: 16px;
`;

export const LabelTerm = styled.label`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: -0.03em;
  color: ${({ theme }) => theme.colors.highBlack};
  a {
    color: ${({ theme }) => theme.colors.invitePorterSecondary};
    text-decoration: underline;
  }
`;

export const CheckboxContainer = styled(Flex)`
  align-items: center;
  gap: 8px;
  height: 100%;
`;

export const Title = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: ${({ theme }) => theme.colors.highBlack};
`;

export const Description = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.mediumHighBlack};
`;

export const FacialRegisterIcon = styled.div`
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  height: 220px;
  width: 200px;
  color: transparent;
`;

export const FeedbackSuccessIcon = styled.img``;

export const LoadingBaseImage = styled.div`
  height: 193px;
  width: 314px;
  background-image: url(${props => props.src});
`;

export const LoadingBlueIcon = styled.img`
  position: absolute;
  z-index: 1;
  animation: ${rotate} 2s linear infinite;

  margin-left: 68px;
  margin-top: 60px;
`;

export const LoadingWhiteIcon = styled.img`
  position: absolute;
  z-index: 1;
  animation: ${rotate} 2s linear infinite;

  margin-left: 217px;
  margin-top: 24px;
`;

export const FacialIconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
`;

export const WrapperCaptureTips = styled(Flex)`
  gap: 36px;
  flex-direction: column;
  width: 100%;
  height: auto;
`;

export const IconWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  min-width: 48px;
  min-height: 48px;
  background: #eee;
  border-radius: 8px;
`;

export const TipsWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: 24px;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
`;

export const Tip = styled.li`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
  height: auto;
`;

export const TipDescription = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #5f5f69;
`;

export const VideoCamOffIcon = styled(MdOutlineVideocamOff).attrs(
  ({ theme }) => ({
    color: theme.colors.white,
    size: 78,
  }),
)``;

export const FaceUnlockIcon = styled(MdFaceUnlock).attrs(({ theme }) => ({
  color: theme.colors.invitePorterPrimary,
  size: 24,
}))``;

export const GlassesOffIcon = styled(GlassesOff).attrs(({ theme }) => ({
  color: theme.colors.invitePorterPrimary,
  size: 24,
}))``;

export const LightBulbOnIcon = styled(LightBulbOn).attrs(({ theme }) => ({
  color: theme.colors.invitePorterPrimary,
  size: 24,
}))``;

export const RedHatOffIcon = styled(RedHatOff).attrs(({ theme }) => ({
  color: theme.colors.invitePorterPrimary,
  size: 24,
}))``;

export const ModalBlockedCamAlert = styled(modal)`
  .close {
    color: white;
  }

  .modal-content {
    background: #c83b1e;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    margin: auto;
    height: 100%;
    width: 100%;
  }

  @media (min-width: 600px) {
    max-width: 332px;
  }
`;

export const ModalBody = styled(Flex).attrs({
  flexDirection: 'column',
  alignItems: 'space-between',
  justifyContent: 'space-between',
  gridGap: '44px',
})`
  height: 100%;
  color: white;
  padding: 24px 0 8px;
  max-height: 490px;
`;

export const BlockedCamMessageTitle = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: white;
  margin: 0;
`;

export const BlockedCamMessageDescription = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: white;
  margin: 0;
  text-align: center;
  letter-spacing: 3;
`;
