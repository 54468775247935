import styled, { css } from 'styled-components';
import {
  Button,
  Flex,
  Text as text,
  Table as table,
  KiperRadioCustomButton,
} from '..';

const statusStyles = props => {
  const styles = {
    [props.status.NOT_AUTHORIZED]: {
      backgroundColor: props.theme.colors.danger50,
      borderColor: props.theme.colors.danger500,
      textColor: props.theme.colors.danger500,
      hoverBackgroundColor: props.theme.colors.danger70,
      hoverBorderColor: props.theme.colors.danger900,
      hoverTextColor: props.theme.colors.danger900,
    },
    default: {
      backgroundColor: props.theme.colors.primary20,
      borderColor: props.theme.colors.primary500,
      textColor: props.theme.colors.primary500,
      hoverBackgroundColor: props.theme.colors.primary50,
      hoverBorderColor: props.theme.colors.primary900,
      hoverTextColor: props.theme.colors.primary900,
    },
  };
  return styles;
};

export const Container = styled(Flex)`
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

export const ContainerCall = styled(Flex)`
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.secondary300};
  border-radius: 8px;
  gap: 16px;
`;

export const Text = styled(text)`
  font-family: 'Nunito Sans';
  font-weight: ${({ weight }) => weight || 400};
  font-size: ${({ size }) => size || '14px'};
  opacity: 1;
  color: ${props =>
    props.color
      ? props.theme.colors[props.color]
      : props.theme.colors.highBlack};

  -webkit-transition: opacity 0.6s;
  transition: opacity 0.6s;
`;

export const ActionButton = styled(KiperRadioCustomButton)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 4px;
  height: 32px;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.secondary800};
  transition: all 0.3s;

  ${props =>
    props.checked &&
    css`
      background-color: ${statusStyles(props)[props.value]?.backgroundColor ||
        statusStyles(props).default.backgroundColor} !important;
      border-color: ${statusStyles(props)[props.value]?.borderColor ||
        statusStyles(props).default.borderColor} !important;

      > span,
      > svg {
        color: ${statusStyles(props)[props.value]?.textColor ||
          statusStyles(props).default.textColor} !important;
      }

      &:hover {
        background-color: ${statusStyles(props)[props.value]
          ?.hoverBackgroundColor ||
          statusStyles(props).default.hoverBackgroundColor} !important;
        color: ${statusStyles(props)[props.value]?.hoverTextColor ||
          statusStyles(props).default.hoverTextColor} !important;
        border-color: ${statusStyles(props)[props.value]?.hoverBorderColor ||
          statusStyles(props).default.hoverBorderColor} !important;
      }
    `};
`;

export const Table = styled(table)`
  border-bottom: none;
  thead {
    background: ${({ theme }) => theme.colors.tableHeader};
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondary400};
  }
`;

export const LoaderContainer = styled(Flex)`
  justify-content: center;
  width: 100%;
  align-items: center;
  @media screen and (min-height: 625px) {
    height: 220px;
  }
  @media screen and (min-height: 700px) {
    height: 294px;
  }
  @media screen and (min-height: 768px) {
    height: 374px;
  }
  @media screen and (min-height: 937px) {
    height: 544px;
  }
`;

export const TableContainer = styled(Flex)`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100px;

  @media screen and (min-height: 625px) {
    max-height: 220px;
  }
  @media screen and (min-height: 700px) {
    max-height: 294px;
  }
  @media screen and (min-height: 768px) {
    max-height: 374px;
  }
  @media screen and (min-height: 937px) {
    max-height: 544px;
  }
`;

export const TR = styled.tr`
  ${({ current, theme }) =>
    current &&
    css`
      background-color: ${theme.colors.tableBg};
    `}
`;

export const TextEllipsis = styled.span`
  display: inline-block;
  max-width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (width: 1366px) {
    max-width: 162px;
  }

  @media screen and (width: 1600px) {
    max-width: 220px;
  }
`;

export const CallButton = styled(Button)`
  border-radius: 500px;
  padding-left: 7px;
  padding-right: 7px;

  background: ${({ theme, called }) => called && theme.colors.primary50};

  &:hover {
    ${({ disabled, theme }) =>
      !disabled &&
      css`
        background: ${theme.colors.primary50};
        svg {
          color: ${theme.colors.primary600} !important;
          stroke: ${theme.colors.primary600} !important;
        }
      `}
  }

  svg {
    height: 16px;
    width: 16px;
  }
`;

export const ObservationButton = styled(Button)`
  border-radius: 500px;
  cursor: help !important;
  padding-left: 7px;
  padding-right: 7px;

  span {
    color: ${props => props.theme.colors.highBlack} !important;
  }
`;
