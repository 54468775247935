import { useContext } from 'react';
import { Flex } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import { context } from '../../store/PorterDelivery/DepositPackage';
import { porterDeliveryStepNames } from '../../constants';
import feedbackSuccessDelivery from '../../assets/images/feedback-success-delivery.svg';
import Layout from './Layout';
import * as S from './styles';

const StepFeedbackSuccessDelivery = () => {
  const { handleSetStep, uuidDelivery, groupUnities } = useContext(context);

  const [t] = useTranslation('porter-delivery');

  const hasOnlyOneGroupUnities = groupUnities.length <= 1;

  const handleOpenReceipt = () => {
    window.open(`/package-delivery/${uuidDelivery}`);
  };

  const handleNewDelivery = () => {
    if (hasOnlyOneGroupUnities)
      return handleSetStep(porterDeliveryStepNames.unity);
    return handleSetStep(porterDeliveryStepNames.groupUnity);
  };

  return (
    <Layout>
      <Flex
        width="100%"
        height="fit-content"
        gridGap="24px"
        alignItems="space-between"
        justifyContent="center"
        flexDirection="column"
      >
        <S.Img src={feedbackSuccessDelivery} alt="Delivery Success" />
        <Flex
          width="100%"
          height="fit-content"
          flexDirection="column"
          gridGap="12px"
          alignItems="center"
        >
          <S.FeedbackSuccessTitle>{t('feedback.title')}</S.FeedbackSuccessTitle>
          <S.FeedbackSuccessDescription>
            {t('feedback.receipt')}
          </S.FeedbackSuccessDescription>
          <S.FeedbackSuccessDescription>
            {t('feedback.notification')}
          </S.FeedbackSuccessDescription>
        </Flex>
        <Flex
          width="100%"
          height="fit-content"
          flexDirection="column"
          gridGap="8px"
        >
          <S.Button onClick={handleOpenReceipt}>
            {t('feedback.receipt-delivery')}
          </S.Button>
          <S.ButtonOutlined onClick={handleNewDelivery}>
            {t('feedback.delivery')}
          </S.ButtonOutlined>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default StepFeedbackSuccessDelivery;
