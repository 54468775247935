import firebase from 'firebase/app';
import 'firebase/analytics';
import logEvent from './analytics';
import remoteConfig from './remote-config';

let firebaseInstance;
const getFirebase = (config, appName) => {
  if (firebaseInstance) return firebaseInstance;

  firebase.initializeApp(config, appName);

  firebaseInstance = firebase;

  return firebase;
};

export default {
  getFirebase,
  logEvent,
  remoteConfig,
};
