import { authenticate } from '@kiper/fns';
import { create, update, remove } from './events.manager';

export const eventToAction = authenticate(({ args, context }) => {
  const { isEdit } = args;
  if (isEdit) return update({ ...args, ...context });
  return create({ ...args, ...context });
});

export const eventToActionRemove = authenticate(({ args, context }) =>
  remove({ ...args, ...context }),
);
