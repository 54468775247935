import { useContext } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import context from '../context';
import facialSuccessIcon from '../../../../assets/images/facial-feedback-success.svg';

import * as S from './styles';

const prefix = 'face-invitation.feedback.success';

const StepFeedbackSuccess = props => {
  const { refetch, onToggle } = props;
  const { handleResetStep } = useContext(context);
  const { t } = useTranslation('invite');

  const handleBackToHome = () => {
    refetch();
    handleResetStep();
    onToggle();
  };

  return (
    <>
      <S.StepContainer>
        <S.FacialIconContainer>
          <S.FeedbackSuccessIcon
            src={facialSuccessIcon}
            alt="facial-feedback-success-svg"
          />
        </S.FacialIconContainer>
        <S.Title>{t(`${prefix}.title`)}</S.Title>
        <S.Description>{t(`${prefix}.description`)}</S.Description>
      </S.StepContainer>
      <S.Button
        variant="contained"
        color="porterPrimary"
        block
        centered
        onClick={handleBackToHome}
      >
        {t(`${prefix}.back-to-home`)}
      </S.Button>
    </>
  );
};

export default StepFeedbackSuccess;

StepFeedbackSuccess.propTypes = {
  refetch: propTypes.func.isRequired,
  onToggle: propTypes.func.isRequired,
};
