import styled from 'styled-components';
import { H4 as h4, UserAvatar } from '@kiper/ui';

export const TitleProduct = styled(h4)`
  margin: 0;
`;

export const Button = styled.button`
  all: unset;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: flex-start;

  justify-self: center;
  align-self: center;

  gap: 8px;
  padding: 8px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.secondary300};
  border-radius: 8px;
  width: 100%;
  min-width: 150px;
  max-width: 200px;
  height: 150px;

  :hover {
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.secondary200};

    transition: border-color 0.2s ease-in-out;
  }
`;

export const Icon = styled(UserAvatar)``;
