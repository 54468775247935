import propTypes from 'prop-types';
import Text from '../Text';
import * as S from './styles';

const CustomErrorBoundaryFallback = ({ error, errorMessageProps }) => {
  return (
    <S.WrapperFallbackError>
      {errorMessageProps?.defaultTitleMessage && (
        <Text fontSize="large" fontWeight="bold">
          {errorMessageProps.defaultTitleMessage}
        </Text>
      )}

      <Text textAlign="center" fontSize="large">
        {errorMessageProps?.customErrorMessage || error?.message}
      </Text>
    </S.WrapperFallbackError>
  );
};

export default CustomErrorBoundaryFallback;

CustomErrorBoundaryFallback.propTypes = {
  error: propTypes.string,
  errorMessageProps: propTypes.shape({
    defaultTitleMessage: propTypes.string.isRequired,
    customErrorMessage: propTypes.string,
  }),
};

CustomErrorBoundaryFallback.defaultProps = {
  error: null,
  errorMessageProps: {
    defaultTitleMessage: '',
    customErrorMessage: '',
  },
};
