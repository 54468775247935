import { useMemo, useCallback } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as S from '../styles';

const FormattedValue = ({ expired, link, address }) => {
  if (!address) return <S.Label expired={Number(expired)}>{address}</S.Label>;
  return (
    <>
      {!expired ? (
        <S.LinkMaps href={`//maps.google.com/maps?q=${link}`}>
          {address}
        </S.LinkMaps>
      ) : (
        <S.Label expired={Number(expired)}>{address}</S.Label>
      )}
    </>
  );
};

FormattedValue.propTypes = {
  expired: propTypes.bool.isRequired,
  link: propTypes.string,
  address: propTypes.string,
};

FormattedValue.defaultProps = {
  link: null,
  address: null,
};

const InfoGuest = ({ icon, label, value, expired, linkAddress, address }) => {
  const [t] = useTranslation('invite');
  const inviteExpired = useMemo(() => !!expired, [expired]);

  const formatValues = useCallback(() => {
    if (value)
      return (
        <FormattedValue
          value={value}
          expired={inviteExpired}
          link={linkAddress}
          address={address}
        />
      );

    return <S.Label>{t('feedback.nonexistent-data')}</S.Label>;
  }, [value]);

  return (
    <S.InfoGuestContainer>
      <S.InfoGuestIcon expired={Number(inviteExpired)}>{icon}</S.InfoGuestIcon>
      <S.InfoGuestContent>
        <S.Label title={label} expired={Number(inviteExpired)}>
          {label}
        </S.Label>
        {value}
        <S.CollapseContainer>
          <S.CollapseInfo>{formatValues()}</S.CollapseInfo>
        </S.CollapseContainer>
      </S.InfoGuestContent>
    </S.InfoGuestContainer>
  );
};

InfoGuest.propTypes = {
  icon: propTypes.node.isRequired,
  label: propTypes.string.isRequired,
  value: propTypes.string.isRequired,
  expired: propTypes.bool.isRequired,
  linkAddress: propTypes.string,
  address: propTypes.string,
};

InfoGuest.defaultProps = {
  linkAddress: null,
  address: null,
};

export default InfoGuest;
