import gql from 'graphql-tag';

export const transientPersonCameraPlaceFragment = gql`
  fragment transientPersonCameraPlaceParts on TransientPersonCameraPlace {
    placeId
    cameraId
  }
`;

export const transientPersonAccessSettingFragment = gql`
  fragment transientPersonAccessSettingParts on TransientPersonAccessSetting {
    accessProfileId
    accessType
    periodSetting
    accessPeriod
  }
`;

export const transientPersonRuleSettingFragment = gql`
  fragment transientPersonRuleSettingParts on TransientPersonSettingRule {
    id
    condominiumPersonContextId
    documentCpfRequirement
    vehiclePlateRequirement
    documentRgRequirement
    contactPhoneNumberRequirement
    transientPersonType
    transientPersonAccessSetting {
      ...transientPersonAccessSettingParts
    }
    transientPersonCameraPlaces {
      ...transientPersonCameraPlaceParts
    }
  }
  ${transientPersonCameraPlaceFragment}
  ${transientPersonAccessSettingFragment}
`;
