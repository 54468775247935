import propTypes from 'prop-types';
import KiperButton from '../KiperButton';

const Button = ({ children, outline, radius, ...props }) => {
  const variant = outline ? 'out' : 'contained';
  const rounded = radius;

  return (
    <KiperButton variant={variant} rounded={rounded} {...props}>
      {children}
    </KiperButton>
  );
};

Button.propTypes = {
  children: propTypes.node,
  outline: propTypes.bool,
  radius: propTypes.bool,
};

Button.defaultProps = {
  outline: false,
  radius: false,
  children: null,
};

export default Button;
