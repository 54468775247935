import styled from 'styled-components';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

export const ScrollBarStyled = styled(PerfectScrollbar)`
  div.ps__rail-y {
    width: 5px !important;
    opacity: 0.4 !important;
    border-radius: 8px !important;
  }
  div.ps__thumb-y {
    background-color: ${props => props.theme.colors.lowBlack} !important;
    width: 5px !important;
    border-radius: 8px !important;
  }

  div.ps__rail-x {
    height: 8px !important;
    opacity: 0.4 !important;
    border-radius: 8px !important;
  }
  div.ps__thumb-x {
    background-color: ${props => props.theme.colors.lowBlack} !important;
    border-radius: 8px !important;
  }

  &:hover {
    div.ps__rail-y {
      opacity: 0.9 !important;
    }
    div.ps__rail-x {
      opacity: 0.9 !important;
    }
    div.ps__thumb-x {
      height: 8px !important;
    }
    /* div.ps__rail-x {
      opacity: 0.9 !important;
      background-color: ${props => props.theme.colors.lowBlack} !important;
    } */
  }
`;
