import DeliveryReceiptPage from './DeliveryReceipt';
import DepositPackagePage from './DepositPackage';

export default [
  {
    name: 'Package Delivery',
    path: '/package-delivery',
    exact: true,
    component: DeliveryReceiptPage,
  },
  {
    name: 'Package Delivery',
    path: '/package-delivery/:uuid',
    component: DeliveryReceiptPage,
  },
  {
    name: 'Deposit Package',
    path: '/deposit-package/:uuid',
    component: DepositPackagePage,
  },
  {
    name: 'Deposit Package Classic',
    path: '/deposit-package',
    exact: true,
    component: DepositPackagePage,
  },
];
