import styled from 'styled-components';
import { Input, Label as label, FormGroup as formGroup } from 'reactstrap';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SelectPageSize = styled(Input)`
  width: 45px;
  border: none;
  padding: 2px;
  text-align-last: center;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  margin-right: 35px;

  &:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const Label = styled(label)`
  margin-bottom: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  & ~ & {
    margin-right: 35px;
  }
`;

export const FormGroup = styled(formGroup)`
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
`;
