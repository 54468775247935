import styled, { css } from 'styled-components';
import { MdOutlineVideocamOff } from 'react-icons/md';
import { CheckBox, Flex, KiperButton, Modal as modal } from '@kiper/ui';
import * as webcam from 'react-webcam';

export const StepContainer = styled(Flex)`
  align-items: center;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  font-family: 'Poppins', serif;
`;

export const StepLoadingContainer = styled(StepContainer)`
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  flex: 1;
  gap: 36px;
`;

export const FacialIconContainer = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  border-radius: 8px;
  background-color: #282828;
`;

export const FacialSuccessIconContainer = styled(FacialIconContainer)`
  background-color: #72bd9f;
`;

export const FormFacialConfirm = styled.form`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  gap: 16px;
  font-family: 'Poppins', serif;
`;

export const WebCam = styled(webcam)`
  border-radius: 8px;
  max-height: 400px;
  max-width: 300px;

  -webkit-mask-size: cover;
  mask-size: cover;
  mask-repeat: no-repeat;

  -webkit-mask-image: ${props => `url(${props.mask})`};
  mask-image: ${props => `url(${props.mask})`};
`;

export const WebCamContainer = styled(Flex)`
  height: 400px;
  width: 300px;

  &:before {
    ${props =>
      !!props.mask &&
      css`
        content: '';
        width: 181px;
        height: 251px;
        position: absolute;
        z-index: 1;
        margin: auto;
        border: 2px dashed white;
        border-radius: 50%;
        margin-left: 60px;
        margin-top: 70px;
      `}
  }
`;

const baseButton = css`
  padding: 24px;
  font-weight: 600;
  width: 100%;
  border: none;
  font-family: 'Poppins', serif;

  &:hover {
    opacity: 0.85;
  }

  span {
    font-size: 18px;
  }
`;

export const Button = styled(KiperButton)`
  ${baseButton};

  background-color: #9696ca;

  &:hover {
    background-color: #9696ca;
  }
`;

export const ButtonBlockedCam = styled(KiperButton)`
  padding: 20px;
  font-weight: 700;
  width: 100%;

  span {
    font-size: 16px;
  }
`;

export const ButtonTakePhoto = styled(KiperButton)`
  ${baseButton};

  background-color: #72bd9f;

  &:hover {
    background-color: #72bd9f;
  }
`;

export const ButtonSendPhoto = styled(KiperButton)`
  ${baseButton};

  background-color: #4d7abc;

  &:hover {
    background-color: #4d7abc;
  }
`;

export const ButtonBackInitialPage = styled(ButtonSendPhoto)``;

export const ButtonRemake = styled(KiperButton)`
  ${baseButton};

  padding: 22px;

  border: 2px solid #4d7abc;
  background-color: #fff;

  &:hover {
    background-color: #fff;
    border: 2px solid #4d7abc;

    span {
      color: #4d7abc !important;
    }
  }

  span {
    color: #4d7abc !important;
  }
`;

export const Img = styled.img`
  border-radius: 8px;
  object-fit: cover;
`;

export const ButtonGroup = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  gap: 16px;
`;

export const Checkbox = styled(CheckBox)`
  width: 16px;
  height: 16px;
  accent-color: #282828;
`;

export const Label = styled.label`
  margin: 0;
  color: #282828;
  font-weight: 400;
  font-size: 10px;
  font-style: normal;
  line-height: 1;
`;

export const LabelTerm = styled.label`
  margin: 0;
  font-weight: 400;
  font-size: 10px;
  line-height: 1;
  letter-spacing: -0.03em;
  color: #282828;
  a {
    color: #4d7abc;
    text-decoration: underline;
  }
`;

export const CheckboxContainer = styled(Flex)`
  align-items: center;
  gap: 8px;
  height: 100%;
  width: 100%;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  color: #282828;
  margin: 0;
  font-family: 'Poppins', serif;
`;

export const SubTitle = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  color: #282828;
  margin: 0;
`;

export const Description = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  text-align: left;
  color: #282828;
  width: 100%;
  margin: 0;
`;

export const FacialRegisterIcon = styled.div`
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  height: 220px;
  width: 200px;
  color: transparent;
`;

export const FeedbackSuccessIcon = styled.img``;

export const LoadingScreenIcon = styled.img`
  width: 212px;
  height: auto;
`;

export const WrapperCaptureTips = styled(Flex)`
  gap: 36px;
  padding: 24px;
  flex-direction: column;
  width: 100%;
  height: auto;
  font-family: 'Poppins', serif;
`;

export const IconsImg = styled.img`
  width: 32px;
  height: auto;
`;

export const IconWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  min-width: 50px;
  min-height: 50px;
  background: #282828;
  border-radius: 16px;
`;

export const TipsWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: 24px;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
`;

export const Tip = styled.li`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
  height: auto;
`;

export const TipDescription = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: #282828;
  margin: 0;
`;

export const VideoCamOffIcon = styled(MdOutlineVideocamOff).attrs(
  ({ theme }) => ({
    color: theme.colors.white,
    size: 78,
  }),
)``;

export const ModalBlockedCamAlert = styled(modal)`
  .close {
    color: white;
  }

  .modal-content {
    background: #c83b1e;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    margin: auto;
    height: 100%;
    width: 100%;
  }

  @media (min-width: 600px) {
    max-width: 332px;
  }
`;

export const ModalBody = styled(Flex).attrs({
  flexDirection: 'column',
  alignItems: 'space-between',
  justifyContent: 'space-between',
  gridGap: '44px',
})`
  height: 100%;
  color: white;
  padding: 24px 0 8px;
  max-height: 490px;
`;

export const BlockedCamMessageTitle = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: white;
  margin: 0;
`;

export const BlockedCamMessageDescription = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: white;
  margin: 0;
  text-align: center;
  letter-spacing: 3;
`;
