import gql from 'graphql-tag';
import {
  allPreRegisterUnitiesPendingApprovalsFragment,
  preRegisterCondominiumFilterFragment,
  preRegisterUnityFilterFragment,
} from './fragments';

export const preRegisterCondominiumsFilter = gql`
  query preRegisterCondominiumsFilter {
    preRegisterCondominiumsFilter {
      ...preRegisterCondominiumFilterParts
    }
  }
  ${preRegisterCondominiumFilterFragment}
`;

export const preRegisterUnitiesFilter = gql`
  query preRegisterUnitiesFilter($condominiumContextId: ID!) {
    preRegisterUnitiesFilter(condominiumContextId: $condominiumContextId) {
      ...preRegisterUnityFilterParts
    }
  }
  ${preRegisterUnityFilterFragment}
`;

export const allPreRegisterUnitiesPendingApprovals = gql`
  query allPreRegisterUnitiesPendingApprovals($unityId: ID!) {
    allPreRegisterUnitiesPendingApprovals(unityId: $unityId) {
      ...allPreRegisterUnitiesPendingApprovalsParts
    }
  }
  ${allPreRegisterUnitiesPendingApprovalsFragment}
`;

export const preRegisteredUsersCount = gql`
  query preRegisteredUsersCount {
    preRegisteredUsersCount {
      usersQuantity
    }
  }
`;
