import gql from 'graphql-tag';

export const actionTypes = {
  login: {
    request: '@auth/LOGIN_REQUEST',
    success: '@auth/LOGIN_SUCCESS',
    error: '@auth/LOGIN_ERROR',
  },
  forgot: {
    request: '@auth/FORGOT_REQUEST',
    success: '@auth/FORGOT_SUCCESS',
    error: '@auth/FORGOT_ERROR',
    goBack: '@auth/FORGOT_GO_BACK',
  },
  reset: {
    request: '@auth/RESET_REQUEST',
    success: '@auth/RESET_SUCCESS',
    error: '@auth/RESET_ERROR',
  },
  redirectUri: '@auth/SET_REDIRECT_URI',
  resetFlags: '@auth/RESET_FLAGS',
};

export const signInMutation = gql`
  mutation signIn($credentials: CredentialsInput!) {
    signIn(credentials: $credentials) {
      idToken
      accessToken
      refreshToken
      user {
        personId
        name
        email
        phone
      }
    }
  }
`;

export const whoAmI = gql`
  query whoAmI {
    whoAmI {
      personId
      name
      email
      phone
    }
  }
`;

export const forgotMutation = gql`
  mutation forgotPassword($username: String!) {
    forgot(username: $username)
  }
`;

export const resetMutation = gql`
  mutation resetPassword($resetPassword: ResetPasswordInput!) {
    reset(resetPassword: $resetPassword)
  }
`;

export const setRedirectUri = uri => ({
  type: actionTypes.redirectUri,
  payload: uri,
});

export const resetFlags = () => ({ type: actionTypes.resetFlags });
