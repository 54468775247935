import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';

export default styled(ReactSelect)`
  & > div > div:first-child > div:first-child {
    min-height: 20px;
  }

  .select__menu {
    z-index: 5;
  }

  .select__control {
    border-radius: 8px;
    border-color: ${props => props.theme.colors.secondary400};
  }

  .select__placeholder {
    font-size: 12px;
    top: 18px;
  }

  .select__control:hover {
    border-color: ${props => props.theme.colors.primary500} !important;
  }

  width: ${props => (props.width ? props.width : '250px')};
  div {
    ${props =>
      props.invalid &&
      css`
        border-color: ${props.theme.colors.dangerPrimary} !important;
      `}
  }

  .select__control--is-focused {
    box-shadow: 0 0 0 0.2rem ${props => props.theme.colors.primary100};
  }
`;
