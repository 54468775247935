import { useRef } from 'react';
import propTypes from 'prop-types';

import { StyledRadio } from './styles';
import KiperButton from '../KiperButton/KiperButton';
import { Box } from '../Box/Box';

export const KiperRadioCustomButton = ({
  checked,
  onChange,
  value,
  name,
  label,
  icon,
  showRadioInput,
  width,
  ...props
}) => {
  const ref = useRef(null);
  return (
    <Box position={showRadioInput ? 'relative' : 'initial'} width={width}>
      <StyledRadio
        ref={ref}
        key={value}
        type="radio"
        id={value}
        name={name}
        value={value}
        checked={checked}
        onChange={() => onChange(ref)}
        show={showRadioInput}
      />
      <KiperButton
        type="button"
        variant={checked ? 'contained' : 'out'}
        onClick={() => onChange(ref)}
        icon={icon}
        width={width}
        {...props}
      >
        {label}
      </KiperButton>
    </Box>
  );
};

export default KiperRadioCustomButton;

KiperRadioCustomButton.propTypes = {
  checked: propTypes.bool.isRequired,
  onChange: propTypes.func.isRequired,
  value: propTypes.any,
  name: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  icon: propTypes.element,
  showRadioInput: propTypes.bool,
  width: propTypes.string,
};

KiperRadioCustomButton.defaultProps = {
  icon: null,
  showRadioInput: false,
  value: '',
  width: '',
};
