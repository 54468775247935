import styled from 'styled-components';

export const CollapseContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.secondary300};
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const CollapseHeader = styled.div`
  display: flex;
  align-items: center;
  height: max-content;
  justify-content: space-between;
  cursor: pointer;
  padding: 16px 32px;
  border-radius: 8px;
  gap: 32px;
`;

export const CollapseContent = styled.div`
  display: flex;
  height: ${({ isOpen }) => (isOpen ? 'auto' : 0)};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  flex-direction: column;
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`;

export const TableHead = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.secondary300};
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary300};
  background-color: ${({ theme }) => theme.colors.secondary50};
  padding: 8px 32px;
  gap: 8px;
  flex: 1;
`;

export const TableRowHeader = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
`;

export const TableHeaderCell = styled.div`
  display: flex;
  text-align: left;
  color: ${({ theme }) => theme.colors.secondary800};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  flex: 1;
  width: ${props =>
    props.isActionCell ? 'fit-content' : `${100 / props.columnsLength}%`};
`;

export const TableBody = styled.div`
  padding: 0;
`;

export const ActionRow = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: transparent;
  box-sizing: border-box;
`;

export const TableRow = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 16px 32px;
  flex: 1;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondary300};
  }
`;

export const TableCell = styled.div`
  text-align: left;
  margin: 0;
  flex: 1;
  display: flex;
  align-items: center;
  width: ${props =>
    props.isActionCell ? 'fit-content' : `${100 / props.columnsLength}%`};

  strong {
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.blackShades(0.7)};
    font-size: 12px;
    line-height: 16px;
    padding: 0;
    margin: 0;
  }
`;
