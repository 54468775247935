import { ApolloClient } from 'apollo-client';
import { from } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import links from './links';

export default function createClient(request) {
  const link = from([
    links.error(request),
    links.auth(request),
    links.http(request),
  ]);

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });

  return client;
}
