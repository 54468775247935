import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: stretch;
  overflow: hidden;
  border-radius: 3px;
  background-color: ${props => props.theme.colors.secondary100};
  transition: background-color ease 0.1s;
  border: 1px solid ${props => props.theme.colors.secondary300};
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  outline: none !important;
  padding: 3px 5px;
  font-size: 12px;
  transition: background-color ease 0.1s, color ease 0.1s;
`;

export const OptionButton = styled(Button)`
  flex: 1;
`;

export const SwitchButton = styled(Button)`
  background-color: ${props => props.theme.colors.hoverGrey};
  border-radius: 3px;

  &:hover,
  &:focus {
    background-color: ${props => props.theme.colors.lightGrey};
  }

  ${props =>
    props.state
      ? css`
          background-color: ${props.theme.colors.success50};
          color: ${props.theme.colors.success500};

          &:hover,
          &:focus {
            background-color: ${props.theme.colors.success100};
            color: ${props.theme.colors.success900};
          }
        `
      : css`
          background-color: ${props.theme.colors.danger50};
          color: ${props.theme.colors.danger500};

          &:hover,
          &:focus {
            background-color: ${props.theme.colors.danger100};
            color: ${props.theme.colors.danger900};
          }
        `}
`;
