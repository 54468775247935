import gql from 'graphql-tag';

import { transientPersonRuleSettingFragment } from './fragment';

export const getTransientPersonRuleSetting = gql`
  query transientPersonRuleSetting(
    $condominiumPersonContextId: Int!
    $transientPersonType: Int!
  ) {
    transientPersonRuleSetting(
      condominiumPersonContextId: $condominiumPersonContextId
      transientPersonType: $transientPersonType
    ) {
      ...transientPersonRuleSettingParts
    }
  }
  ${transientPersonRuleSettingFragment}
`;

export const deleteTransientPersonRuleSetting = gql`
  mutation deleteTransientPersonRuleSetting(
    $condominiumPersonContextId: Int!
    $transientPersonType: Int!
  ) {
    deleteTransientPersonRuleSetting(
      condominiumPersonContextId: $condominiumPersonContextId
      transientPersonType: $transientPersonType
    )
  }
`;

export const insertTransientPersonRuleSetting = gql`
  mutation insertTransientPersonRuleSetting(
    $condominiumPersonContextId: Int!
    $transientPersonType: Int!
  ) {
    insertTransientPersonRuleSetting(
      condominiumPersonContextId: $condominiumPersonContextId
      transientPersonType: $transientPersonType
    ) {
      ...transientPersonRuleSettingParts
    }
  }
  ${transientPersonRuleSettingFragment}
`;

export const updateTransientPersonRuleSetting = gql`
  mutation updateTransientPersonRuleSetting(
    $transientPersonRuleSetting: TransientPersonRuleSettingInput!
    $condominiumPersonContextId: Int!
    $transientPersonType: Int!
  ) {
    updateTransientPersonRuleSetting(
      transientPersonRuleSetting: $transientPersonRuleSetting
      condominiumPersonContextId: $condominiumPersonContextId
      transientPersonType: $transientPersonType
    ) {
      ...transientPersonRuleSettingParts
    }
  }
  ${transientPersonRuleSettingFragment}
`;
