import { KiperButton } from '@kiper/ui';
import styled, { css } from 'styled-components';

const hoverButtonModifiers = {
  outlined: () => css`
    :hover {
      background-color: transparent;
      border: 1px solid ${props => props.theme.colors.invitePorterSecondary};
      color: ${props => props.theme.colors.invitePorterSecondary};
    }
  `,
  filled: () => css`
    :hover {
      background-color: ${({ theme }) => theme.colors.invitePorterSecondary};
      color: ${({ theme }) => theme.colors.white};
    }
  `,
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: ${props => props.theme.colors.white};
  font-family: 'Poppins', sans-serif;
  overflow: auto;
`;

export const WrapperStepWelcome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: ${props => props.theme.colors.backgroundWelcomeDelivery};
`;

export const WrapperStepOpenDrawer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: fit-content;
  width: 100%;
  gap: 24px;
`;

export const WrapperStepGroupUnity = styled.div`
  width: 100%;
  height: fit-content;
  gap: 24px;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  display: flex;
`;

export const WrapperStepUnity = styled.div`
  width: 100%;
  height: fit-content;
  gap: 24px;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  display: flex;
`;

export const ImageWelcomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  gap: 24px;
  position: absolute;
  bottom: ${props => props.modalMobileHeight}px;
`;

export const Button = styled(KiperButton)`
  background-color: ${props => props.theme.colors.invitePorterSecondary};
  outline: none;
  border: none;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;

  ${hoverButtonModifiers.filled}

  span {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 16px;
  }
`;

export const ButtonOutlined = styled(KiperButton)`
  background-color: transparent;
  border: 1px solid ${props => props.theme.colors.invitePorterSecondary};
  outline: none;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  ${hoverButtonModifiers.outlined}

  span {
    color: ${props => props.theme.colors.invitePorterSecondary} !important;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
`;

export const OpenDrawerButton = styled(KiperButton)`
  background-color: ${props => props.theme.colors.invitePorterSecondary};
  outline: none;
  border: none;
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;

  ${hoverButtonModifiers.filled}

  span {
    width: 100%;
    font-weight: 600;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  svg {
    color: ${props => props.theme.colors.white};
    height: 28px;
    width: 28px;
  }
`;

export const UnityButton = styled.button`
  box-sizing: border-box;
  outline: none;
  background-color: ${({ theme }) => theme.colors.invitePorterSecondary};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  font-size: 18px;
  font-weight: 400;
  border-radius: 8px;
  flex-wrap: wrap;
  word-break: break-word;

  ${hoverButtonModifiers.filled}
`;

export const UnityOptions = styled.div`
  box-sizing: border-box;
  display: grid;
  gap: 8px;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
`;

export const GroupUnityOptions = styled.div`
  box-sizing: border-box;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

export const Img = styled.img`
  width: 100%;
  max-width: 290px;
  height: auto;
  align-self: center;
`;

export const Label = styled.label`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  width: 100%;
  color: ${props => props.theme.colors.black};

  strong {
    font-weight: 700;
  }
`;

export const OpenDrawerTitle = styled(Label)`
  text-align: center;
`;

export const Description = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${props => props.theme.colors.blackShades(0.5)};

  margin: 0;
  width: 100%;
`;

export const TipsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  gap: 24px;
`;

export const TipDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
`;

export const TipItemsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const TipIcon = styled.img`
  width: 30px;
  height: auto;
`;

export const TitleTips = styled.h3`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  width: 100%;
  color: ${props => props.theme.colors.black};
`;

export const TipItem = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const TipTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: ${props => props.theme.colors.blackShades(0.7)};
`;

export const TipDescription = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${props => props.theme.colors.blackShades(0.7)};
`;

export const NumberMark = styled.span`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.colors.secondary200};
  min-height: 24px;
  height: 24px;
  min-width: 24px;
  width: 24px;
  border-radius: 50%;
  color: ${props => props.theme.colors.invitePorterSecondary};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const ListItem = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  strong {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: ${props => props.theme.colors.invitePorterSecondary};
  }
`;

export const FeedbackSuccessTitle = styled.span`
  font-size: 24px;
  font-weight: 500;
  color: ${props => props.theme.colors.black};
  width: 100%;
  text-align: center;
`;

export const FeedbackSuccessDescription = styled.span`
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  color: ${props => props.theme.colors.blackShades(0.64)};
  text-align: center;
`;

export const FormNumberPhone = styled.form`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 36px;
`;

export const EmptyStateUnities = styled.span`
  color: ${({ theme }) => theme.colors.secondary400};
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  width: 100%;
  padding: 8px;
  text-align: center;
`;
