import gql from 'graphql-tag';

export const dwellerAccessMediaTypesAvailableFragment = gql`
  fragment dwellerAccessMediaTypesAvailableParts on AccessMediaTypes {
    description
    fieldName
    personHasAccessFacial
  }
`;

export const dwellerUnityReportFragment = gql`
  fragment dwellerUnityReportParts on DwellerUnityReport {
    id
    name
    placeTypeLabel
    treeNodeId
  }
`;

export const dwellerProfileReportFragment = gql`
  fragment dwellerProfileReportParts on DwellerProfileReport {
    id
    name
    fieldName
  }
`;

export const dwellerForReportFragment = gql`
  fragment dwellerForReportParts on DwellerForReport {
    name
    email
    personContextId
    profileId
    profileName
    treeNodeId
    profile {
      ...dwellerProfileReportParts
    }
    unity {
      ...dwellerUnityReportParts
    }
    unityGroup {
      ...dwellerUnityReportParts
    }
  }
  ${dwellerProfileReportFragment}
  ${dwellerUnityReportFragment}
`;
