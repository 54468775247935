import { useState, useContext } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSwal } from '@kiper/hooks';
import context from '../context';
import { uploadFacial } from '../../../../services/api/invite';
import * as S from './styles';

const prefix = 'face-invitation.face-capture';

const timeOut = t => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, t);
  });
};

const StepFaceCaptureConfirm = props => {
  const { guestHash } = props;
  const {
    photoUrl,
    setPhotoUrl,
    handleChangeStep,
    loading,
    setLoading,
  } = useContext(context);

  const { t } = useTranslation('invite');

  const [storePhoto, setStorePhoto] = useState(true);

  const { toast } = useSwal();

  const handleError = error => {
    const [{ message }] = error?.response?.data;

    if (message) {
      return toast.fire({ title: message, icon: 'error' });
    }
    return toast.fire({ title: error.message, icon: 'error' });
  };

  const handleFinally = () => {
    setLoading(false);
  };

  const handleRedoPhoto = () => {
    setPhotoUrl(null);
    handleChangeStep(-1);
  };

  const handleSubmit = async () => {
    setLoading(true);
    handleChangeStep(1);

    const data = {
      storePhoto,
      face: photoUrl,
      hash: guestHash,
    };

    Promise.all([await uploadFacial({ data }), await timeOut(2000)])
      .catch(handleError)
      .finally(handleFinally);
  };

  return (
    <>
      <S.FormFacialConfirm>
        <S.Title>{t(`${prefix}.confirm-title`)}</S.Title>
        <S.WebCamContainer>
          <S.Img src={photoUrl} alt="Screenshot" width={300} height={400} />
        </S.WebCamContainer>
        <S.CheckboxContainer>
          <S.Checkbox
            name="storePhoto"
            value={storePhoto}
            id="checkbox-save-photo-future"
            onChange={e => setStorePhoto(e.target.checked)}
            checked={storePhoto}
          />
          <S.Label htmlFor="checkbox-save-photo-future">
            {t(`${prefix}.save-photo-future-access`)}
          </S.Label>
        </S.CheckboxContainer>
        <S.ButtonGroup>
          <S.Button
            variant="out"
            color="porterPrimary"
            centered
            onClick={handleRedoPhoto}
          >
            {t(`${prefix}.remake`)}
          </S.Button>
          <S.Button
            disabled={loading}
            color="porterPrimary"
            centered
            onClick={handleSubmit}
          >
            {t(`${prefix}.send`)}
          </S.Button>
        </S.ButtonGroup>
      </S.FormFacialConfirm>
    </>
  );
};

export default StepFaceCaptureConfirm;

StepFaceCaptureConfirm.propTypes = {
  guestHash: propTypes.string.isRequired,
};
