// function to remove accentuation and change to lower case
const handleNormalizeString = str => {
  const REMOVE_ACCENTS_REGEX = /[\u0300-\u036f]/g;

  const normalizedString = str
    .normalize('NFD')
    .replace(REMOVE_ACCENTS_REGEX, '')
    .toLowerCase();

  return normalizedString;
};

export default handleNormalizeString;
