import { cloneElement } from 'react';
import propTypes from 'prop-types';
import * as S from './styles';

const SplitSidebar = ({ children, toolItems }) => {
  return (
    <S.Wrapper>
      <S.ContentWrapper>{children}</S.ContentWrapper>
      {toolItems && !!toolItems.length && (
        <S.PaneTools>{toolItems.map(item => cloneElement(item))}</S.PaneTools>
      )}
    </S.Wrapper>
  );
};

const SplitContentPane = ({
  children,
  maxHeight = '100%',
  height = 'auto',
  flex = 1,
  noScroll,
}) => {
  return (
    <S.ContentPane
      flex={flex}
      maxHeight={maxHeight}
      height={height}
      noScroll={noScroll}
    >
      {noScroll ? children : <S.Scroll>{children}</S.Scroll>}
    </S.ContentPane>
  );
};

const ToolItem = ({ onSelect, active, value, children }) => {
  const handleSelect = () => {
    onSelect(value);
  };

  return (
    <S.ToolItemsButton onClick={handleSelect} active={active}>
      {children}
    </S.ToolItemsButton>
  );
};

SplitSidebar.ToolItem = ToolItem;
SplitSidebar.SplitContentPane = SplitContentPane;
SplitSidebar.Divider = S.Divider;
SplitSidebar.HeaderPane = S.HeaderPane;

export default SplitSidebar;

SplitSidebar.propTypes = {
  children: propTypes.element.isRequired,
  toolItems: propTypes.arrayOf(propTypes.element).isRequired,
};

ToolItem.propTypes = {
  onSelect: propTypes.func.isRequired,
  active: propTypes.bool.isRequired,
  value: propTypes.any.isRequired,
  children: propTypes.element.isRequired,
};

SplitContentPane.propTypes = {
  children: propTypes.element.isRequired,
  maxHeight: propTypes.string,
  height: propTypes.string,
  flex: propTypes.number,
  noScroll: propTypes.bool,
};

SplitContentPane.defaultProps = {
  maxHeight: '100%',
  height: 'auto',
  flex: 1,
  noScroll: false,
};

S.HeaderPane.propTypes = {
  justifyContent: propTypes.string,
  alignItems: propTypes.string,
  hasBorder: propTypes.bool,
};

S.HeaderPane.defaultProps = {
  justifyContent: 'flex-start',
  alignItems: 'center',
  hasBorder: false,
};
