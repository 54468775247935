import { setContext } from 'apollo-link-context';
import authHeaders from './headers';

export default request =>
  setContext((_, { headers }) => {
    const authorizationHeaders = authHeaders(request);

    return {
      headers: {
        ...headers,
        ...authorizationHeaders,
      },
    };
  });
