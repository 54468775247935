import gql from 'graphql-tag';

export const getLastChangeLog = gql`
  query getLastChangeLog($entityName: String!, $entityId: String!) {
    getLastChangeLog(entityName: $entityName, entityId: $entityId) {
        username
        profile
        creationDate
    }
  }
`;
