/* eslint-disable react/forbid-prop-types */
import { useEffect } from 'react';

import { Form, Input, Label, Button, FormFeedback } from 'reactstrap';
import { FaEnvelope, FaSpinner } from 'react-icons/fa';

import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Title,
  Subtitle,
  ButtonSubmit,
  Col,
  Badge,
  FormGroup,
  ButtonLink,
} from './styles';

const LoginPassword = ({
  values,
  handleSubmit,
  handleChange,
  touched,
  errors,
  setCurrentStep,
  isSubmitting,
  steps,
  resetForm,
  submitCount,
  setFieldValue,
}) => {
  const [t] = useTranslation('login');

  useEffect(() => {
    resetForm();
    setFieldValue('email', values.email);
  }, []);
  return (
    <Col>
      <Title>{t('password.title')}</Title>
      <Subtitle>{t('password.subtitle')}</Subtitle>
      <Badge>
        <span>
          <FaEnvelope color="#50b986" />
          &nbsp;
          {values.email}
          &nbsp;
        </span>
        <Button size="sm" close onClick={() => setCurrentStep(steps.email)} />
      </Badge>
      <Form noValidate onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="password">{t('password.label')}</Label>
          <Input
            type="password"
            id="password"
            name="password"
            value={values.password}
            onChange={handleChange}
            required
            invalid={!!submitCount && touched.password && !!errors.password}
            autoFocus
            autoComplete="password"
          />
          <FormFeedback>{t('errors.password')}</FormFeedback>
          <Label>
            <p>
              {t('password.forgot')}
              &nbsp;
              <ButtonLink
                color="link"
                onClick={() => setCurrentStep(steps.forgot)}
              >
                {t('password.click-here')}
              </ButtonLink>
            </p>
          </Label>
        </FormGroup>
        <ButtonSubmit
          block
          color="primary"
          type="submit"
          loading={isSubmitting ? 1 : 0}
        >
          {t(`submit${isSubmitting ? 'ting' : ''}`)}
          {isSubmitting && <FaSpinner color="#FFF" size={14} />}
        </ButtonSubmit>
      </Form>
    </Col>
  );
};

export default LoginPassword;

LoginPassword.propTypes = {
  setCurrentStep: propTypes.func.isRequired,
  handleSubmit: propTypes.func.isRequired,
  handleChange: propTypes.func.isRequired,
  values: propTypes.object.isRequired,
  touched: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
  isSubmitting: propTypes.bool.isRequired,
  steps: propTypes.object.isRequired,
  resetForm: propTypes.func.isRequired,
  submitCount: propTypes.number.isRequired,
  setFieldValue: propTypes.func.isRequired,
};
