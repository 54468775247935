import { useTranslation } from 'react-i18next';
import Container from './styles';
import logoImage from '../../assets/images/logo-kiper.svg';

const Header = () => {
  const [t] = useTranslation('login');

  return (
    <Container>
      <div>
        <img src={logoImage} alt="kiper" />
      </div>
      <div>
        <ul className="nav">
          <li className="nav-item">
            <a
              className="nav-link"
              target="_blank"
              rel="noreferrer"
              href="https://kiper.com.br"
            >
              {t('about-us')}
            </a>
          </li>
        </ul>
      </div>
    </Container>
  );
};

export default Header;
