import styled, { css } from 'styled-components';

const cardModifier = theme => ({
  register: css`
    background: ${theme.colors.invitePorterSecondary};
  `,
  edit: css`
    background: ${theme.colors.invitePorterSecondary};
  `,
  success: css`
    background: ${theme.colors.inviteFacialSuccess};
  `,
});

export const Wrapper = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  text-decoration: none;
  outline: none;

  margin: 0;
  padding: 12px;
  border-radius: 8px;
  border: none;
  color: #fff;
  gap: 8px;
  color: #fff;

  ${({ theme, status }) => cardModifier(theme)[status]}

  &:focus {
    outline: none;
  }
`;

export const IconContainer = styled.div`
  width: auto;
  height: auto;
  padding: 6px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.invitePorterSecondary};
`;

export const SVGIcon = styled.div`
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.span`
  font-size: ${({ isRegister }) => (isRegister ? '14px' : '16px')};
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.5;
  margin: 0;
  text-align: start;
`;

export const Description = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.5;
  margin: 0;
  text-align: start;
`;
