import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { useMutation } from 'react-apollo';
import { removeProperty, apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { mutations } from '@kiper/account-graphql/auth';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { Container, Card, CardHeader, CardBody, Text } from './styles';
import PasswordForm from '../ResetPassword/ResetPasswordForm';
import ConfirmForm from './ConfirmForm';

const step = (component, key) => ({ component, key });

const steps = {
  main: step(ConfirmForm, 'main'),
  password: step(
    () => <PasswordForm hideLink submitText="confirm:confirm" />,
    'password',
  ),
};

const validationSchema = yup.object({
  currentStep: yup.object({}),
  email: yup
    .string()
    .email()
    .matches(/^[\d\w@.\-/+]*$/) // yup's email won't validate some characters
    .required(),
  confirmationCode: yup.string().when('currentStep.key', key => {
    switch (key) {
      case 'main':
        return yup.string();
      default:
        return yup
          .string()
          .length(6)
          .required();
    }
  }),
  password: yup.string().when('currentStep.key', key => {
    switch (key) {
      case 'main':
        return yup.string();
      default:
        return yup
          .string()
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
          )
          .required()
          .nullable();
    }
  }),
  passwordConfirmation: yup.string().when('currentStep.key', {
    is: 'main',
    then: yup.string().nullable(),
    otherwise: yup
      .string()
      .oneOf([yup.ref('password'), null], 'passwordConfirmation')
      .required()
      .nullable(),
  }),
});

export default function Confirm({ history }) {
  const { toast } = useSwal();
  const [t] = useTranslation('confirm');

  const [submitFn] = useMutation(mutations.confirm, {
    onCompleted: () => {
      toast.fire({ title: t('success'), icon: 'success' });
      setTimeout(() => history.push('/'), 200);
    },
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });
  const onSubmit = values => {
    if (values.currentStep.key === 'main') {
      formikBag.setFieldValue('currentStep', steps.password); //eslint-disable-line
      return formikBag.setTouched({}); //eslint-disable-line
    }

    return submitFn({
      variables: removeProperty(
        removeProperty(values, 'passwordConfirmation'),
        'currentStep',
      ),
    });
  };

  const formikBag = useFormik({
    validationSchema,
    onSubmit,
    initialValues: {
      currentStep: steps.main,
      confirmationCode: '',
      email: '',
      password: '',
      passwordConfirmation: '',
    },
  });
  return (
    <Container>
      <Card>
        <CardHeader>
          <Card.Title>{t('title')}</Card.Title>
        </CardHeader>
        <CardBody>
          <FormikProvider value={formikBag}>
            <Text>{t('subtitle')}</Text>
            <formikBag.values.currentStep.component />
          </FormikProvider>
        </CardBody>
      </Card>
    </Container>
  );
}

Confirm.propTypes = {
  history: propTypes.object.isRequired,
};
