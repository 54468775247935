import propTypes from 'prop-types';

import KiperButton from '../KiperButton';
import Text from '../Text';

import { DaysOfWeekContainer } from './styles';

const daysOfWeek = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

const DayOfWeek = ({
  handleSelectDayOfWeek,
  indexInterval,
  label,
  day,
  active,
  disabled,
}) => (
  <KiperButton
    block
    icon={<Text>{label}</Text>}
    variant={active ? 'contained' : 'out'}
    onClick={() => handleSelectDayOfWeek(indexInterval, day)}
    disabled={disabled}
  />
);

const DaysOfWeek = ({
  handleSelectDayOfWeek,
  index,
  daysActivated,
  disabled,
  onlyText,
  t,
  ...props
}) => {
  const handleMapDaysActivated = data =>
    data.reduce(
      (result, activated, i) => (activated ? [...result, i] : result),
      [],
    );

  const daysOfWeekText = (activatedIndex, i, array) =>
    `${t(`common:week.${daysOfWeek[activatedIndex]}`)}${
      array.length !== i + 1 ? ',' : ''
    }`;

  if (onlyText && disabled)
    handleMapDaysActivated(daysActivated).map((activatedIndex, i, array) => (
      <Text key={activatedIndex}>
        {daysOfWeekText(activatedIndex, i, array)}
      </Text>
    ));

  return (
    <DaysOfWeekContainer {...props} onlyText={onlyText} disabled={disabled}>
      {daysActivated.map((active, day) => (
        <DayOfWeek
          key={String(day)}
          handleSelectDayOfWeek={handleSelectDayOfWeek}
          indexInterval={index}
          label={t(`common:week.${daysOfWeek[day]}`)}
          day={day}
          active={active}
          disabled={disabled}
        />
      ))}
    </DaysOfWeekContainer>
  );
};

DaysOfWeek.propTypes = {
  handleSelectDayOfWeek: propTypes.func.isRequired,
  index: propTypes.number.isRequired,
  daysActivated: propTypes.arrayOf(propTypes.bool).isRequired,
  disabled: propTypes.bool.isRequired,
  onlyText: propTypes.bool,
  t: propTypes.func.isRequired,
};

DaysOfWeek.defaultProps = {
  onlyText: false,
};

DayOfWeek.propTypes = {
  handleSelectDayOfWeek: propTypes.func.isRequired,
  indexInterval: propTypes.number.isRequired,
  label: propTypes.string.isRequired,
  day: propTypes.number.isRequired,
  active: propTypes.bool.isRequired,
  disabled: propTypes.bool.isRequired,
};

export default DaysOfWeek;
