import styled from 'styled-components';
import {
  // Card as card,
  CardBody as cardBody,
  CardHeader as cardHeader,
  Form as form,
  FormGroup as formGroup,
} from 'reactstrap';
import { Button as button, Card as card } from '@kiper/ui';

export const Card = styled(card)`
  width: 40rem;
`;

export const CardBody = styled(cardBody)``;

export const CardHeader = styled(cardHeader)`
  background: transparent;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  margin-top: 3.6rem;
`;

export const Text = styled.p`
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.25px;
  color: ${props => props.theme.colors.mediumBlack};
`;

export const Form = styled(form)``;

export const FormGroup = styled(formGroup)``;

export const Button = styled(button)`
  display: block;
  width: 100%;
`;
