import gql from 'graphql-tag';

const selectFragment = gql`
  fragment select_Part on Select {
    value
    label
  }
`;

const actionFragment = gql`
  fragment action_Part on ActionType {
    label
    value
    description
  }
`;

const eventToActionTemplateFragment = gql`
  fragment eventToActionTemplate_Part on TemplateType {
    action {
      ...action_Part
    }
    priority
    actionParams
    messageTemplate
  }
  ${actionFragment}
`;

const eventToActionFragment = gql`
  fragment eventToAction_Part on EventToActionType {
    event {
      ...select_Part
    }
    templates {
      ...eventToActionTemplate_Part
    }
  }
  ${selectFragment}
  ${eventToActionTemplateFragment}
`;

export const eventSelect = gql`
  query eventSelect($onlyAvaliable: Boolean) {
    eventSelect(onlyAvaliable: $onlyAvaliable) {
      ...select_Part
    }
  }
  ${selectFragment}
`;

export const eventCategorySelect = gql`
  query eventCategorySelect($onlyAvaliable: Boolean) {
    eventCategorySelect(onlyAvaliable: $onlyAvaliable) {
      categories {
        name
        fieldName
      }
      value
      label
    }
  }
`;

export const eventsToAction = gql`
  query eventsToAction($search: String) {
    eventsToAction(search: $search) {
      ...eventToAction_Part
      actions
    }
  }
  ${eventToActionFragment}
`;

export const eventToAction = gql`
  query eventToAction($eventId: ID!) {
    eventToAction(eventId: $eventId) {
      ...eventToAction_Part
    }
  }
  ${eventToActionFragment}
`;

export const eventToActionPut = gql`
  mutation eventToAction($eventAction: EventToActionInput!, $isEdit: Boolean) {
    eventToAction(eventAction: $eventAction, isEdit: $isEdit) {
      ...eventToAction_Part
    }
  }
  ${eventToActionFragment}
`;

export const eventToActionRemove = gql`
  mutation eventToActionRemove($eventId: ID!) {
    eventToActionRemove(eventId: $eventId)
  }
`;
