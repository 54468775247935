import styled, { css } from 'styled-components';
import { Input } from 'reactstrap';

export default styled(Input)`
  border-color: ${props => props.theme.colors.secondary400};
  padding: 8px 12px;

  ::placeholder {
    line-height: 1.14;
    letter-spacing: 0.5px;
    color: ${props => props.theme.colors.secondary400};
  }

  ${props =>
    props.invalid &&
    css`
      border-color: ${props.theme.colors.danger500};
    `}

  &:disabled {
    cursor: not-allowed;
    background-color: ${props => props.theme.colors.secondary100};
    border-color: ${props => props.theme.colors.secondary100};
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem ${props => props.theme.colors.primary100};
  }

  ${props =>
    props.$withoutArrowNumber &&
    `
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &[type=number]{
      -moz-appearance: textfield;
    }
  `}
`;
