import gql from 'graphql-tag';

export const deviceModelFragment = gql`
  fragment deviceModelParts on DeviceModel {
    id
    deviceTypeId
    name
    capabilities
    creationDate
  }
`;

export const placeTypeFragment = gql`
  fragment placeTypeParts on PlaceType {
    id
    name
    fieldName
    creationDate
  }
`;

export const deviceTypeFragment = gql`
  fragment deviceTypeParts on DeviceType {
    id
    name
    fieldName
    isCpu
    isController
    creationDate
  }
`;

export const parentDeviceFragment = gql`
  fragment parentDeviceParts on ParentDevice {
    id
    name
    placeId
    deviceType {
      ...deviceTypeParts
    }
    serialNumber
    ip
    isOnline
  }
  ${deviceTypeFragment}
`;

export const deviceFragment = gql`
  fragment deviceParts on Device {
    id
    placeId
    parentDeviceId
    deviceTypeId
    deviceType {
      ...deviceTypeParts
    }
    parentDevice {
      ...parentDeviceParts
    }
    serialNumber
    ip
    protocolVersion
    firmwareVersion
    name
    isOnline
    serializedParams
    creationDate
    lastUpdate
    updateStatus
    chipsetType
    syncStatus
    immutableData
    deviceModel {
      ...deviceModelParts
    }
  }
  ${deviceModelFragment}
  ${deviceTypeFragment}
  ${parentDeviceFragment}
`;

export const placeFragment = gql`
  fragment placeParts on Place {
    id
    name
    treeNodeId
    placeTypeId
    creationDate
    serializedParams
    comments
    placeType {
      ...placeTypeParts
    }
    devices {
      ...deviceParts
    }
  }
  ${placeTypeFragment}
  ${deviceFragment}
`;

export const interlockedPlaceFragment = gql`
  fragment interlockedPlaceParts on InterlockedPlace {
    id
    name
    places {
      place {
        ...placeParts
      }
    }
    creationDate
  }
  ${placeFragment}
`;

export const placeInterlockableFragment = gql`
  fragment placeInterlockableParts on PlaceInterlockable {
    id
    name
    treeNodeId
    placeTypeId
    creationDate
    serializedParams
    comments
    totalCameras
    order
    accessRf {
      id
      placeId
      parentDeviceId
      deviceTypeId
      serialNumber
      ip
      protocolVersion
      firmwareVersion
      name
      isOnline
      serializedParams
      creationDate
      lastUpdate
    }
    placeType {
      ...placeTypeParts
    }
    devices {
      ...deviceParts
    }
    interlockedPlaces {
      ...interlockedPlaceParts
    }
  }
  ${placeTypeFragment}
  ${deviceFragment}
  ${interlockedPlaceFragment}
`;

export const deviceScheduleFragment = gql`
  fragment deviceScheduleParts on DeviceSchedule {
    time {
      hours
      minutes
      time
    }
    relayOpen
    gmt
    daysOfWeek
  }
`;

export const deviceRulesFragment = gql`
  fragment deviceRulesParts on DeviceRules {
    relay
    schedule {
      ...deviceScheduleParts
    }
    sensor
    sensorActive
    relayActive
  }
  ${deviceScheduleFragment}
`;

export const deviceRuleFragment = gql`
  fragment deviceRuleParts on DeviceRule {
    id
    ruleType
    description
    pushNotification
    rule {
      ...deviceRulesParts
    }
  }
  ${deviceRulesFragment}
`;

export const condominiumRuleCategoryFragment = gql`
  fragment condominiumRuleCategoryParts on CondominiumRuleCategory {
    id
    name
    order
  }
`;

export const condominiumRuleTypeFragment = gql`
  fragment condominiumRuleTypeParts on CondominiumRuleType {
    id
    title
    description
    condoRuleCategory {
      ...condominiumRuleCategoryParts
    }
  }
  ${condominiumRuleCategoryFragment}
`;

export const condominiumPlaceCamerasFragment = gql`
  fragment condominiumPlaceCamerasPart on CondominiumPlaceCamerasType {
    name
    vmsId
    screenStyleId
    screenViewName
    isPrincipal
    isOnline
    forMobile
    isVirtualControlEnabled
    condominiumPersonContextId
    thumbnail
    thumbnailBase64
    porterVideoChannel
    isSelected
    id
    creationDate
  }
`;

export const condominiumAccessHoursFragment = gql`
  fragment condominiumAccessHoursParts on CondominiumAccessHoursResponse {
    allowedAccesses
    accessAfterHours
  }
`;

export const condominiumNoteCategoryFragment = gql`
  fragment condominiumNoteCategoryParts on CondominiumNoteCategoryType {
    id
    name
  }
`;

export const personNoteAuthorFragment = gql`
  fragment personNoteAuthorParts on PersonNoteAuthorType {
    id
    name
  }
`;

export const createdByPersonContextFragment = gql`
  fragment createdByPersonContextParts on CreatedByPersonContextType {
    person {
      ...personNoteAuthorParts
    }
  }
  ${personNoteAuthorFragment}
`;

export const condominiumHistoryNotesFragment = gql`
  fragment condominiumHistoryNotesParts on CondominiumHistoryNotesResponse {
    id
    description
    url
    condominiumId
    categoryId
    condominiumNoteCategory {
      ...condominiumNoteCategoryParts
    }
    createdByPersonContextId
    createdByPersonContext {
      ...createdByPersonContextParts
    }
    creationDate
  }
  ${createdByPersonContextFragment}
  ${condominiumNoteCategoryFragment}
`;

export const createHistoryNoteFragment = gql`
  fragment createHistoryNoteParts on CreateHistoryNoteResponse {
    id
    description
    url
    categoryId
    condominiumId
    createdByPersonContextId
    creationDate
  }
`;

export const updateHistoryNoteFragment = gql`
  fragment updateHistoryNoteParts on UpdateHistoryNoteResponse {
    id
    description
    url
    categoryId
    condominiumId
    createdByPersonContextId
    creationDate
    lastUpdate
  }
`;

export const condominiumWorkerProfileFragment = gql`
  fragment condominiumWorkerProfileParts on CondominiumWorkersProfile {
    id
    name
    fieldName
  }
`;

export const condominiumWorkerParamsFragment = gql`
  fragment condominiumWorkerParamsParts on CondoWorkerPersonContextParams {
    id
    personContextId
    name
    value
  }
`;
export const condominiumWorkersFragment = gql`
  fragment condominiumWorkersParts on CondominiumWorkersResponse {
    id
    name
    personId
    profileId
    phone
    profile {
      ...condominiumWorkerProfileParts
    }
    personContextParams {
      ...condominiumWorkerParamsParts
    }
  }
  ${condominiumWorkerProfileFragment}
  ${condominiumWorkerParamsFragment}
`;

export const condominiumInfoAddressesFragment = gql`
  fragment condominiumInfoAddressesParts on Address {
    id
    addressTypeId
    zipNumber
    addressNumber
    streetName
    isPrincipal
    district {
      name
    }
    city {
      name
    }
    region {
      name
    }
    country {
      name
    }
  }
`;

export const condominiumInfoAttendanceFragment = gql`
  fragment condominiumInfoAttendanceParts on CondominiumInfoAttendanceResponse {
    addresses {
      ...condominiumInfoAddressesParts
    }
    managementCompany
    condominiumWorkers {
      ...condominiumWorkersParts
    }
    condominiumLicenses {
      licenseType
    }
  }
  ${condominiumInfoAddressesFragment}
  ${condominiumWorkersFragment}
`;
