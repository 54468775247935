import gql from 'graphql-tag';
import { eventTypeFragment } from './fragments';

export default gql`
  query getInAttendanceEvent {
    inAttendance @client {
      ...eventTypeParts
    }
  }
  ${eventTypeFragment}
`;
