import styled, { keyframes } from 'styled-components';
import { FaSpinner } from 'react-icons/fa';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const AnimatedSpinner = styled(FaSpinner).attrs(props => ({
  size: props.size && props.size,
}))`
  animation: ${rotate} 2s linear infinite;
`;

export default AnimatedSpinner;
