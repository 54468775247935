import styled from 'styled-components';
import { Input } from 'reactstrap';

export default styled(Input).attrs({ type: 'checkbox' })`
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  position: relative;
  margin: 0;
  border-color: ${props => props.theme.colors.secondary400};

  &:disabled {
    background-color: ${props => props.theme.colors.secondary100};
    border-color: ${props => props.theme.colors.secondary100};
  }

  border: solid 1px ${props => props.theme.colors.secondary400};
  padding: 2px;
  color: ${props => props.theme.colors.mediumBlack};

  &:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: ${props => props.theme.colors.mediumBlack};
  }
`;
