import styled, { css } from 'styled-components';
import {
  space,
  color,
  border,
  width,
  height,
  display,
  flexDirection,
  justifyContent,
  alignItems,
  backgroundImage,
  maxWidth,
  flex,
  minWidth,
  grid,
  flexWrap,
  minHeight,
  backgroundSize,
  maxHeight,
  position,
  overflow,
  opacity,
  boxShadow,
  background,
  textAlign,
  flexShrink,
  overflowY,
  order,
  gridGap,
  padding,
  borderColor,
  alignSelf,
} from 'styled-system';

export const Box = styled.div`
  ${flexShrink}
  ${padding}
  ${opacity}
  ${overflow}
  ${background}
  ${backgroundSize}
  ${position}
  ${grid}
  ${space}
  ${flex}
  ${color}
  ${border}
  ${borderColor}
  ${width}
  ${height}
  ${display}
  ${flexDirection}
  ${flexWrap}
  ${justifyContent}
  ${alignItems}
  ${alignSelf}
  ${backgroundImage}
  ${maxWidth}
  ${maxHeight}
  ${minWidth}
  ${minHeight}
  ${boxShadow}
  ${textAlign}
  ${overflowY}
  ${order}
  ${gridGap}
  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `}
`;
