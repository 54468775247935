import { Fragment, useCallback, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import Flex from '../Flex';
import Spinner from '../Spinner';
import * as S from './styles';
import { Tooltip } from '../Tooltip/Tooltip';

function CollapsableTable({
  id,
  header,
  initialOpen = false,
  columnsHeader,
  onToggle,
  data,
  columnsBlackList,
  loadingDataTable,
  actionRowContent: ActionRowContent,
}) {
  const [isOpen, setIsOpen] = useState(initialOpen);

  const handleClick = useCallback(() => {
    if (!isOpen && !loadingDataTable) {
      onToggle(id);
      setIsOpen(true);
    } else {
      onToggle(null);
      setIsOpen(false);
    }
  }, [isOpen, loadingDataTable]);

  useEffect(() => {
    if (initialOpen) {
      onToggle(id);
      setIsOpen(true);
    } else if (!initialOpen && !data) {
      setIsOpen(false);
    }
  }, [initialOpen, data]);

  return (
    <S.CollapseContainer>
      <S.CollapseHeader onClick={handleClick}>
        <>{header}</>
        <>{isOpen ? <MdExpandLess size={24} /> : <MdExpandMore size={24} />}</>
      </S.CollapseHeader>
      <S.CollapseContent isOpen={isOpen}>
        <S.Table>
          <S.TableHead>
            {columnsHeader.map(column => (
              <S.TableHeaderCell
                columnsLength={columnsHeader.length}
                key={column.title}
              >
                {column.title}
              </S.TableHeaderCell>
            ))}
            {!!ActionRowContent && (
              <S.TableHeaderCell isActionCell key="action-header-cell">
                {' '}
              </S.TableHeaderCell>
            )}
          </S.TableHead>
          {loadingDataTable ? (
            <Flex
              width="100%"
              padding="16px"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner />
            </Flex>
          ) : (
            <>
              {data.map(rowData => {
                const cellEntries = Object.entries(rowData);
                return (
                  <S.TableRow key={rowData.id}>
                    {cellEntries.map((cellEntry, _, self) => {
                      if (columnsBlackList.includes(cellEntry[0])) return null;
                      const cellId = crypto.randomUUID();
                      return (
                        <Fragment key={cellEntry[1] + crypto.randomUUID()}>
                          <S.TableCell columnsLength={self.length}>
                            <strong id={`tr-${cellId}`}>{cellEntry[1]}</strong>
                          </S.TableCell>
                          <Tooltip placement="top" target={`tr-${cellId}`}>
                            {cellEntry[1]}
                          </Tooltip>
                        </Fragment>
                      );
                    })}
                    {!!ActionRowContent && (
                      <S.TableCell isActionCell key={crypto.randomUUID()}>
                        <ActionRowContent
                          collapseGroupId={id}
                          rowData={rowData}
                        />
                      </S.TableCell>
                    )}
                  </S.TableRow>
                );
              })}
            </>
          )}
        </S.Table>
      </S.CollapseContent>
    </S.CollapseContainer>
  );
}

export default CollapsableTable;

CollapsableTable.propTypes = {
  initialOpen: propTypes.bool,
  loadingDataTable: propTypes.bool,
  columnsBlackList: propTypes.arrayOf(propTypes.string),
  onToggle: propTypes.func,
  actionRowContent: propTypes.any,
  id: propTypes.any,
  header: propTypes.any.isRequired,
  columnsHeader: propTypes.arrayOf(
    propTypes.shape({ title: propTypes.any.isRequired }),
  ).isRequired,
  data: propTypes.arrayOf(propTypes.object),
};

CollapsableTable.defaultProps = {
  initialOpen: false,
  loadingDataTable: false,
  actionRowContent: null,
  id: null,
  data: [],
  columnsBlackList: [],
  onToggle: () => null,
};
