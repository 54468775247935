import propTypes from 'prop-types';

const LightBulbOn = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3337 11.8327H25.8337V14.166H22.3337V11.8327ZM0.166992 11.8327H3.66699V14.166H0.166992V11.8327ZM14.167 0.166016V3.66602H11.8337V0.166016H14.167ZM4.74033 3.08268L7.22533 5.57935L5.56866 7.22435L3.08366 4.75101L4.74033 3.08268ZM18.7753 5.56768L21.2487 3.08268L22.917 4.75101L20.432 7.22435L18.7753 5.56768ZM13.0003 5.99935C14.8568 5.99935 16.6373 6.73685 17.9501 8.0496C19.2628 9.36236 20.0003 11.1428 20.0003 12.9993C20.0003 15.5893 18.5887 17.8527 16.5003 19.066V21.166C16.5003 21.4754 16.3774 21.7722 16.1586 21.991C15.9398 22.2098 15.6431 22.3327 15.3337 22.3327H10.667C10.3576 22.3327 10.0608 22.2098 9.84203 21.991C9.62324 21.7722 9.50033 21.4754 9.50033 21.166V19.066C7.41199 17.8527 6.00033 15.5893 6.00033 12.9993C6.00033 11.1428 6.73782 9.36236 8.05058 8.0496C9.36333 6.73685 11.1438 5.99935 13.0003 5.99935ZM15.3337 23.4993V24.666C15.3337 24.9754 15.2107 25.2722 14.9919 25.491C14.7732 25.7098 14.4764 25.8327 14.167 25.8327H11.8337C11.5242 25.8327 11.2275 25.7098 11.0087 25.491C10.7899 25.2722 10.667 24.9754 10.667 24.666V23.4993H15.3337ZM11.8337 19.9993H14.167V17.5143C16.1853 17.001 17.667 15.1693 17.667 12.9993C17.667 11.7617 17.1753 10.5747 16.3002 9.69952C15.425 8.82435 14.238 8.33268 13.0003 8.33268C11.7626 8.33268 10.5757 8.82435 9.70049 9.69952C8.82532 10.5747 8.33366 11.7617 8.33366 12.9993C8.33366 15.1693 9.81532 17.001 11.8337 17.5143V19.9993Z"
        fill={color}
      />
    </svg>
  );
};

LightBulbOn.propTypes = {
  size: propTypes.number,
  color: propTypes.string,
};

LightBulbOn.defaultProps = {
  size: 24,
  color: '#000',
};

export default LightBulbOn;
