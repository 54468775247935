import { useState, useEffect, useCallback } from 'react';

export default function usePermission(permissionName) {
  const PermissionStateEnum = Object.freeze({
    DEFAULT: 'prompt',
    ALLOWED: 'granted',
    BLOCKED: 'denied',
  });

  const [permissionState, setPermissionState] = useState(
    PermissionStateEnum.DEFAULT,
  );

  const handlePermissionState = useCallback(async () => {
    const permission = await navigator.permissions.query({
      name: permissionName,
    });
    setPermissionState(permission.state);
  }, []);

  const requestPermission = ({ audio = false, video = false }) => {
    navigator.mediaDevices
      .getUserMedia({ audio, video })
      .then(handlePermissionState)
      .catch(handlePermissionState);
  };

  useEffect(() => {
    if (!permissionState) handlePermissionState();
  }, [permissionState]);

  return { permissionState, requestPermission, PermissionStateEnum };
}
