import styled, { css } from 'styled-components';
import { KiperButton } from '@kiper/ui';
import porterSummitInviteBg from '../../../../assets/images/summit-invite-background.webp';

export const Wrapper = styled.div`
  background-image: url(${porterSummitInviteBg});
  background-repeat: no-repeat;
  background-position: center;
  background-color: #282828;
  width: 100%;
  min-height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  max-width: 350px;
  height: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const Img = styled.img`
  height: 58px;
  width: auto;
`;

export const InviteFooter = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
`;

export const InviteContainer = styled.div`
  border-radius: 8px;
  background: #fff;
  width: 100%;
  height: fit-content;
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AnchorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const HeaderDisclaimer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  gap: 12px;
`;

const headerText = css`
  text-transform: uppercase;
  line-height: 1;
  color: #282828;
  margin: 0;
`;

export const RegularTextHeader = styled.h1`
  ${headerText};
  font-size: 16px;
  font-weight: 400;
`;

export const BoldTextHeader = styled.h1`
  ${headerText};
  font-size: 24px;
  font-weight: 700;
`;

export const SmallTextHeader = styled.h3`
  line-height: 1;
  color: #282828;
  font-size: 10px;
  font-weight: 400;
  margin: 0;
`;

const buttonLinksBase = css`
  color: #fff;
  text-decoration: none;
  border: none;
  outline: 0;
  &:focus {
    outline: 0;
  }
  width: 100%;
  height: fit-content;

  border-radius: 8px;
  text-align: start;
`;

export const EventMonth = styled.h1`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  color: #fff;
  margin: 0;
  text-transform: uppercase;
`;

export const EventDay = styled.h2`
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
  margin: 0;
`;

export const RegisterFacialButton = styled.button`
  ${buttonLinksBase};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #282828;
  padding: 24px 16px;
  gap: 16px;
`;

export const ButtonTitle = styled.span`
  width: 100%;
  color: #fff;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 600;
`;

export const ButtonDescription = styled.p`
  width: 100%;
  color: #fff;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 400;
  margin: 0;
`;

export const EditFacialButton = styled(KiperButton).attrs({
  variant: 'out',
  color: 'black',
  centered: true,
})`
  padding: 16px;
  width: 100%;

  span {
    font-size: 14px;
    font-weight: 600;
  }
`;

const anchorBase = css`
  display: grid;
  grid-template-columns: 25% 75%;
  align-items: center;
  gap: 16px;
  padding: 16px;
`;

export const SummitAppDownloadAnchor = styled.a`
  ${buttonLinksBase};
  ${anchorBase};
  background: #9696ca;
`;

export const PorterLandingPageAnchor = styled.a`
  ${buttonLinksBase};
  ${anchorBase};
  background: #4d7abc;
`;

export const WrapperIcon = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AnchorDescription = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 4px;
`;

export const AnchorTitleText = styled.strong`
  width: 100%;
  color: #fff;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 600;
`;

export const AnchorDescriptionText = styled.span`
  width: 100%;
  color: #fff;
  font-size: 10px;
  line-height: 1.2;
  font-weight: 400;
`;

export const BrandAreaFooter = styled.div`
  border: 1px solid #232323;
  border-radius: 8px;
  padding: 16px 8px;
  gap: 16px;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
`;

export const DateChip = styled.strong`
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  line-height: 1;
  font-weight: 400;

  background: #72bd9f;
  color: #fff;
  border-radius: 24px;
`;

export const ScheduleTimeText = styled.span`
  font-size: 10px;
  line-height: 1.2;
  font-weight: 400;
  color: #232323;
`;

const brandWrapperBase = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
`;

export const BrandWrapper = styled.a`
  ${brandWrapperBase};
  text-decoration: none;
  gap: 8px;
`;

export const LinkMaps = styled.span`
  font-size: 8px;
  line-height: 1.2;
  text-decoration: none;
  color: #232323;
  width: 100%;
`;

export const Span = styled.span`
  text-align: center;

  ${({ warning, theme }) => `
    font-size: ${warning ? '16px' : '14px;'};
    color: ${
      warning
        ? `${theme.colors.dangerPrimary}`
        : `${theme.colors.mediumHighBlack}`
    };
    font-weight: ${warning ? 'bold' : 'normal'};
    letter-spacing: ${warning ? '0.5px' : '0.25px'};
    line-height: ${warning ? '1.5' : '1.43'};
  `}
`;

export const InviteInvalid = styled.div`
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-position: center;
  width: 200px;
  height: 200px;
`;
