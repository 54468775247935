import styled from 'styled-components';

export const CanvasQrCodeContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MarginQrCode = styled.canvas`
  position: absolute;
  background: #fff;
  z-index: 0;
`;
