import PropTypes from 'prop-types';

import { Text } from '@kiper/ui';

import { MdCheck } from 'react-icons/md';

import Flex from '../Flex';
import { StyledKiperButton } from './styles';

const StepButton = ({ step, isChecked, icon, isActive, number, onClick }) => (
  <Flex alignItems="center">
    <StyledKiperButton
      variant={isActive ? 'contained' : 'outlined'}
      color={isChecked ? 'neutral' : 'primary'}
      onClick={() => onClick(number)}
      icon={isChecked ? <MdCheck /> : icon || null}
    >
      {isChecked || icon ? null : number + 1}
    </StyledKiperButton>
    <Text ml="12px" fontSize="16px">
      {step.title}
    </Text>
  </Flex>
);

const Stepper = ({ steps, activeStep, setActiveStep, ...props }) => {
  return (
    <>
      <Flex justifyContent="space-between" flex={1} width="100%" {...props}>
        {steps?.map((step, i) => (
          <StepButton
            key={i}
            icon={step?.icon}
            isActive={activeStep === i}
            isChecked={activeStep > i}
            number={i}
            step={step}
            onClick={() => setActiveStep(i)}
          />
        ))}
      </Flex>

      <div>
        {steps
          ?.filter((step, i) => i === activeStep)
          .map((item, index) => {
            const Component = item.component;
            return <Component key={index} {...item.props} />;
          })}
      </div>
    </>
  );
};

Stepper.propTypes = {
  steps: PropTypes.array,
  activeStep: PropTypes.number,
  setActiveStep: PropTypes.func.isRequired,
};

Stepper.defaultProps = {
  activeStep: 0,
  steps: [],
};

StepButton.propTypes = {
  step: PropTypes.object,
  isChecked: PropTypes.bool,
  icon: PropTypes.node,
  isActive: PropTypes.bool,
  number: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

StepButton.defaultProps = {
  step: {},
  isChecked: false,
  icon: null,
  isActive: false,
  number: null,
};

export default Stepper;
