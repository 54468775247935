import { MdArrowBack } from 'react-icons/md';
import propTypes from 'prop-types';
import * as S from './styles';

const Layout = ({ children, onStepBack, footer }) => {
  return (
    <S.Wrapper>
      <S.ContentWrapper>
        {onStepBack && (
          <S.LayoutHeader>
            <S.IconButton onClick={onStepBack}>
              <MdArrowBack />
            </S.IconButton>
          </S.LayoutHeader>
        )}
        {children}
      </S.ContentWrapper>
      {footer && <S.Footer>{footer}</S.Footer>}
    </S.Wrapper>
  );
};

export default Layout;

Layout.propTypes = {
  children: propTypes.any.isRequired,
  onStepBack: propTypes.func,
  footer: propTypes.any,
};

Layout.defaultProps = {
  onStepBack: null,
  footer: null,
};
