const logEvent = async (firebase, event, params = {}) => {
  const analytics = firebase.analytics();
  analytics.setAnalyticsCollectionEnabled(true);
  try {
    await analytics.logEvent(event, params);
  } catch (error) {
    throw new Error(
      `An error occurred while creating an event in firebase: ${error}`,
    );
  }
};

export default logEvent;
