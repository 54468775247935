import styled, { css } from 'styled-components';
import { Input as InputRS, Button as ButtonRS } from 'reactstrap';

export const Input = styled(InputRS)`
  font-family: 'Nunito Sans', sans-serif;
  flex: 1;
  width: auto;
  box-sizing: border-box;
  height: 24px;
  padding: 8px;
  border-radius: 8px;
  border: none;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  outline: none;

  color: ${({ theme }) => theme.colors.blackShades(0.8)};

  &:disabled {
    background-color: ${({ theme }) => theme.colors.secondary200};
    opacity: 50%;
  }

  :focus,
  :focus-within,
  :focus-visible {
    outline: none;
    box-shadow: none !important;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary400};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    color: ${({ theme }) => theme.colors.blackShades(0.8)};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const LabelWrapper = styled.div`
  display: inline-flex;
  margin-bottom: 8px;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.blackShades(0.8)};
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
`;

export const Error = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.colors.danger500};
`;

export const InputGroup = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  overflow: hidden;
  min-height: 42px;
  height: auto;
  width: auto;
  transition: border 0.1s ease-in;
  gap: 8px;
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.danger500 : theme.colors.secondary400};

  &:focus-visible,
  &:focus-within,
  &:focus {
    outline: none;

    ${({ theme }) => css`
      border: 1px solid ${theme.colors.primary500};
      svg {
        color: ${theme.colors.primary500};
        transition: color 0.2s ease-in;
      }

      button {
        border-left-color: ${theme.colors.primary500};
        transition: border-left-color 0.2s ease-in;
      }
    `}
  }
`;

export const InputIcon = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  padding-top: 4px;

  color: ${({ theme }) => theme.colors.blackShades(0.8)};

  svg {
    height: 16px;
    width: auto;
  }
`;

export const WrapperMultiValues = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  gap: 8px;
`;

export const Tag = styled.li`
  padding: 0 8px;
  border-radius: 8px;
  height: auto;
  color: ${props => props.theme.colors.primary500};
  border: 1px solid ${props => props.theme.colors.primary500};
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  svg {
    color: ${props => props.theme.colors.primary500};
    height: 14px;
    width: auto;
  }
`;

export const TagValue = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

export const TagClose = styled(ButtonRS)`
  padding: 0;
  background-color: white;
  border: none;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  height: max-content;
  cursor: pointer;
  border-radius: 50%;

  :hover {
    background-color: transparent;
    opacity: 0.7;
  }
`;
