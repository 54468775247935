import gql from 'graphql-tag';

const phraseFragment = gql`
  fragment phrase_Part on Phrase {
    id
    shortCut
    message
    excludedDate
  }
`;

const eventSettingFragment = gql`
  fragment eventSetting_Part on EventSetting {
    id
    eventType
    priority
    showPopUp
    showAlert
    automaticClose
    automaticCloseMessage
    isCritical
    closeEvent
    closeEventMessage
    attendantScript
    personContextId
    attendantDefaultMessages {
      ...phrase_Part
    }
    configuredby {
      configuredByType
      personContext {
        person {
          name
        }
        profile {
          name
        }
      }
    }
    routedTo {
      routedToType
      id
    }
  }
  ${phraseFragment}
`;

const eventSettingRouteFragment = gql`
  fragment eventSettingRoute_Part on EventSettingRouteResponse {
    personContextId
    eventType
    name
    value
    id
  }
`;

const paginationFragment = gql`
  fragment pagination_Part on Pagination {
    pageSize
    totalResults
    totalPages
    page
    hasPrevious
    hasNext
  }
`;

const eventSettingResponseFragment = gql`
  fragment eventSettingResponse_Part on EventSettingResponse {
    collection {
      ...eventSetting_Part
    }
    pagination {
      ...pagination_Part
    }
  }

  ${eventSettingFragment}
  ${paginationFragment}
`;

export const eventSettingCreate = gql`
  mutation eventSettingCreate($eventSetting: EventSettingInput!) {
    eventSettingCreate(eventSetting: $eventSetting) {
      ...eventSetting_Part
    }
  }
  ${eventSettingFragment}
`;

export const eventSettingRoute = gql`
  mutation eventSettingRouteUpdate(
    $eventSettingRoute: EventSettingRouteInput!
  ) {
    eventSettingRouteUpdate(eventSettingRoute: $eventSettingRoute) {
      ...eventSettingRoute_Part
    }
  }
  ${eventSettingRouteFragment}
`;

export const eventSettingUpdate = gql`
  mutation eventSettingUpdate($eventSetting: EventSettingInput!) {
    eventSettingUpdate(eventSetting: $eventSetting) {
      ...eventSetting_Part
    }
  }
  ${eventSettingFragment}
`;

export const eventSettingRemove = gql`
  mutation eventSettingRemove($id: ID!) {
    eventSettingRemove(id: $id)
  }
`;

export const eventSettings = gql`
  query eventSettings($search: EventSettingsSearchInput) {
    eventSettings(search: $search) {
      ...eventSettingResponse_Part
    }
  }
  ${eventSettingResponseFragment}
`;

export const eventSetting = gql`
  query eventSetting($id: ID!) {
    eventSetting(id: $id) {
      ...eventSetting_Part
    }
  }
  ${eventSettingFragment}
`;
