import propTypes from 'prop-types';
import { MdOutlineExpandLess } from 'react-icons/md';
import * as S from './styles';

const OptionRow = ({
  option,
  handleSelect,
  openedRow,
  handleSeeMore,
  emptyStateDwellersText,
  secondColumnTitle,
}) => {
  return (
    <S.OptionsList>
      <S.ListItem onClick={e => handleSelect(e, option)}>
        <S.GroupOption>
          <S.GroupOptionLabel>{option.description}</S.GroupOptionLabel>
          <S.GroupOptionValue>
            {option?.unityGroup?.description}
          </S.GroupOptionValue>
        </S.GroupOption>
        <S.GroupOption>
          <S.GroupOptionLabel>
            {!!option?.dwellers?.length && secondColumnTitle}
          </S.GroupOptionLabel>
          <S.ChipWrapper>
            <S.ChipsContainer>
              {!option?.dwellers?.length ? (
                <S.EmptyDwellersText>
                  {emptyStateDwellersText}
                </S.EmptyDwellersText>
              ) : (
                <>
                  {option?.dwellers
                    ?.slice(0, openedRow[option.id] ? undefined : 2)
                    .map(dweller => (
                      <S.GroupOptionValuesChip
                        key={dweller.id}
                        title={dweller.name}
                      >
                        {dweller.name}
                      </S.GroupOptionValuesChip>
                    ))}
                </>
              )}
            </S.ChipsContainer>
            {option?.dwellers?.length > 2 && (
              <S.ChipButtonMoreItems
                onClick={e => handleSeeMore(e, option)}
                seeMore={+openedRow[option.id]}
              >
                {!openedRow[option.id] ? (
                  `+${option.dwellers.length - 2}`
                ) : (
                  <MdOutlineExpandLess size={16} />
                )}
              </S.ChipButtonMoreItems>
            )}
          </S.ChipWrapper>
        </S.GroupOption>
      </S.ListItem>
    </S.OptionsList>
  );
};

export default OptionRow;

OptionRow.propTypes = {
  option: propTypes.object.isRequired,
  secondColumnTitle: propTypes.string,
  emptyStateDwellersText: propTypes.string.isRequired,
  handleSeeMore: propTypes.func.isRequired,
  handleSelect: propTypes.func.isRequired,
  openedRow: propTypes.object.isRequired,
};

OptionRow.defaultProps = {
  secondColumnTitle: '',
};
