import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';
import { useQuery as useQueryString } from '@kiper/hooks';
import { useHistory } from 'react-router-dom';
import Component from './Login';
import LoginEmail from './LoginEmail';
import LoginPassword from './LoginPassword';
import LoginForgot from './LoginForgot';
import authContext from '../../../services/auth/context';

const whoAmIGql = gql`
  {
    whoAmI {
      personId
      name
      email
      phone
    }
  }
`;

const steps = {
  email: { component: LoginEmail, key: 'email' },
  password: { component: LoginPassword, key: 'password' },
  forgot: { component: LoginForgot, key: 'forgot' },
};

export default function LoginRoute(props) {
  const [t] = useTranslation('login');
  const [currentStep, setCurrentStep] = useState(steps.email);
  const [checkEmail] = useMutation(LoginRoute.checkUserExistMutation);
  const auth = useContext(authContext);
  const query = useQueryString();
  const history = useHistory();

  const { data: whoImData } = useQuery(whoAmIGql, {
    fetchPolicy: 'cache-only',
  });

  const emailValidation = async ({ email }) => {
    const { data } = await checkEmail({ variables: { username: email } });
    const { checkUserExist } = data;
    formikBag.setFieldError('email', !checkUserExist && 'userDoesntExists'); // eslint-disable-line no-use-before-define
    if (checkUserExist) {
      setCurrentStep(steps.password);
      history.push({ pathname: '/', search: `?email=${email}` });
    }
  };

  const signIn = async ({ email: username, password }) =>
    auth
      .login({ username, password })
      .catch(() => formikBag.setFieldError('password', 'invalid')); // eslint-disable-line no-use-before-define

  const forgot = async ({ email: username }) =>
    auth.forgot({ username }).catch(() => formikBag.setFieldError('password')); // eslint-disable-line no-use-before-define

  const onSubmit = values => {
    switch (currentStep.key) {
      case 'email':
        return emailValidation(values);
      case 'password':
        return signIn(values);
      case 'forgot':
        return forgot(values);
      default:
        return null;
    }
  };

  const formikBag = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: yup.object({
      email: yup
        .string()
        .required()
        .email('email'),
      password: yup.string().when('email', email =>
        currentStep.key === 'password' && email
          ? yup
              .string()
              .min(4)
              .required()
          : yup.string(),
      ),
    }),
    onSubmit,
  });

  useEffect(() => {
    if (query.has('email')) {
      formikBag.setFieldValue('email', query.get('email'));
      setCurrentStep(steps.password);
    }

    return () => {
      auth.resetFlags();
    };
  }, []);

  useEffect(() => {
    if (whoImData) {
      const { whoAmI } = whoImData;
      props.history.push({
        pathname: 'products',
        state: {
          ...whoAmI,
          redirectUri: auth.redirectUri,
        },
      });
    }
  }, [whoImData]);

  const openTerms = () =>
    window.open('https://www.portergroup.com.br/termos-de-uso/');

  const openPrivacy = () =>
    window.open('https://www.portergroup.com.br/politica-de-privacidade/');

  return Component({
    ...props,
    t,
    currentStep,
    setCurrentStep,
    formikBag,
    steps,
    openTerms,
    openPrivacy,
  });
}

LoginRoute.checkUserExistMutation = gql`
  mutation checkUserExistMutation($username: String!) {
    checkUserExist(username: $username)
  }
`;
