import gql from 'graphql-tag';
import accessRfFragment from '../shared/accessRfFragment';
import {
  eventTypeDescriptionFragment,
  eventSettingsFragment,
} from './fragments';

const placeEventFragment = gql`
  fragment placeDataParts on Place {
    id
    name
    serializedParams
  }
`;

const dwellerEventFragment = gql`
  fragment dwellerEventParts on DwellerEvent {
    id
    name
    email
    phone
    personContexts {
      id
      profileId
      personId
      treeNodeId
      profile {
        id
        name
        fieldName
      }
    }
    unity {
      id
      name
      treeNodeId
      serializedParams
    }

    unityGroup {
      id
      name
      treeNodeId
      serializedParams
    }
    additionalContacts {
      id
      contact
      contactTypeId
    }
  }
`;

const deviceFragment = gql`
  fragment eventDeviceParts on EventDeviceType {
    id
    parentDeviceId
    deviceTypeId
    serialNumber
    ip
    name
    serializedParams
    doorId
    doorStatus
    keepOpenedReason
  }
`;

const interlockedPlacesFragment = gql`
  fragment interlockedPlacesParts on EventInterlockedPlace {
    id
    name
    places {
      id
      name
    }
  }
`;

const placeFragment = gql`
  fragment eventPlaceParts on EventPlaceType {
    id
    name
    comments
    serializedParams
    interlockedPlaces {
      ...interlockedPlacesParts
    }
    devices {
      ...eventDeviceParts
    }
  }
  ${interlockedPlacesFragment}
  ${deviceFragment}
`;

const personContextFragment = gql`
  fragment personContextParts on PersonContextType {
    id
    params {
      name
      value
    }
    places {
      ...eventPlaceParts
    }
    treeNodeId
  }
  ${placeFragment}
`;

const condominiumEventFragment = gql`
  fragment condominiumEventParts on CondominiumDetailEvent {
    id
    name
    personContext {
      ...personContextParts
    }
  }
  ${personContextFragment}
`;

const mainCameraEventFragment = gql`
  fragment mainCameraParts on CameraData {
    id
    name
    isOnline
    serializedParams
    thumbnail
    placeId
  }
`;

const eventAdditionalInformationTriggeredByFragment = gql`
  fragment eventAdditionalInformationTriggeredByParts on EventAdditionalInformationTriggeredBy {
    rfNotFound
    tagNotFound
  }
`;

const eventAdditionalInformationIotFragment = gql`
  fragment eventAdditionalInformationIotParts on EventAdditionalInformationIot {
    activatedBy
  }
`;

const eventAdditionalInformationContactIdFragment = gql`
  fragment eventAdditionalInformationContactIdParts on EventAdditionalInformationContactId {
    partitionId
    partition
    argumentId
    argument
    argumentType
  }
`;

const eventAdditionalInformationFragment = gql`
  fragment eventAdditionalInformationParts on EventAdditionalInformation {
    iotEvent {
      ...eventAdditionalInformationIotParts
    }
    contactId {
      ...eventAdditionalInformationContactIdParts
    }
    triggeredBy {
      ...eventAdditionalInformationTriggeredByParts
    }
    callLogId
    videoCall
    unityTreeNodeId
  }
  ${eventAdditionalInformationIotFragment}
  ${eventAdditionalInformationContactIdFragment}
  ${eventAdditionalInformationTriggeredByFragment}
`;

const iotDeviceFragment = gql`
  fragment iotDeviceParts on IotDevice {
    id
    name
    deviceTypeId
    parentDeviceTypeId
    serialNumber
    ip
    isOnline
    serializedParams
  }
`;

const alarmDeviceFragment = gql`
  fragment alarmDeviceParts on AlarmDevice {
    id
    name
    deviceTypeId
    parentDeviceTypeId
    serialNumber
    ip
    isOnline
    serializedParams
  }
`;

const onHoldFragment = gql`
  fragment onHoldParts on OnHold {
    additionalInformation
    onHoldStatus
    attendant {
      id
      username
      name
      email
      phone
      creationDate
      lastUpdate
      personContexts {
        id
        profileId
        personId
        treeNodeId
        profile {
          fieldName
        }
      }
    }
  }
`;

const condominiumAttendanceAttendantFragment = gql`
  fragment condominiumAttendanceAttendantParts on CondominiumAttendanceAttendant {
    id
    name
    email
  }
`;

const condominiumAttendanceUnityFragment = gql`
  fragment condominiumAttendanceUnityParts on CondominiumAttendanceUnity {
    id
    name
  }
`;

const condominiumAttendanceFragment = gql`
  fragment condominiumAttendanceParts on CondominiumAttendance {
    eventId
    status
    identifier
    dweller
    creationDate
    triageId
    placeName
    treatment
    unityGroup {
      ...condominiumAttendanceUnityParts
    }
    unity {
      ...condominiumAttendanceUnityParts
    }
    attendant {
      ...condominiumAttendanceAttendantParts
    }
  }
  ${condominiumAttendanceUnityFragment}
  ${condominiumAttendanceAttendantFragment}
`;

const eventDataFragment = gql`
  fragment eventDataParts on EventData {
    eventId
    eventType
    eventDate
    creationDate
    channelIds
    inEmergency
    hasLocker
    videoStartDate
    coPartner
    triage
    device {
      name
      serialNumber
      serializedParams
      id
      deviceTypeId
    }
    place {
      ...placeDataParts
    }
    person {
      ...dwellerEventParts
    }
    partner {
      ...dwellerEventParts
    }
    condominiumManager {
      ...dwellerEventParts
    }
    condominium {
      ...condominiumEventParts
    }
    mainCamera {
      ...mainCameraParts
    }
    personDevices {
      ...accessRf_Part
    }
    eventTypeDescription {
      ...eventTypeDescriptionParts
    }
    eventSettings {
      ...eventSettingsParts
    }
    iotDevices {
      ...iotDeviceParts
    }
    alarmDevices {
      ...alarmDeviceParts
    }
    additionalInformation {
      ...eventAdditionalInformationParts
    }
    onHold {
      ...onHoldParts
    }
    attendances {
      ...condominiumAttendanceParts
    }
  }
  ${mainCameraEventFragment}
  ${dwellerEventFragment}
  ${condominiumEventFragment}
  ${placeEventFragment}
  ${accessRfFragment}
  ${eventTypeDescriptionFragment}
  ${eventSettingsFragment}
  ${iotDeviceFragment}
  ${alarmDeviceFragment}
  ${onHoldFragment}
  ${eventAdditionalInformationFragment}
  ${condominiumAttendanceFragment}
`;

export default gql`
  query singleEventV2($eventId: ID!) {
    eventSingleV2(eventId: $eventId) {
      ...eventDataParts
    }
  }
  ${eventDataFragment}
`;
