import {
  useContext,
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { getCondominiumInfo } from '@kiper/account-graphql/package-delivery/query';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { GenericLoader } from '@kiper/ui';
import { Trans, useTranslation } from 'react-i18next';
import { porterDeliveryStepNames } from '../../constants';
import ModalMobile from '../ModalMobile';
import { context } from '../../store/PorterDelivery/DepositPackage';
import deliveryAddress from '../../assets/images/delivery-address.svg';
import * as S from './styles';

const StepWelcome = () => {
  const {
    handleNextStep,
    setCondominiumInfo,
    condominiumInfo,
    setUuidCondominium,
    groupUnities,
    handleSetStep,
    phoneNumber,
  } = useContext(context);
  const modalMobileRef = useRef();
  const location = useLocation();
  const [modalMobileHeight, setModalMobileHeight] = useState(360);
  const uuid =
    location.pathname.split('/deposit-package/')[1] ??
    'c3b17e1c-577a-d6b0-5102-7259aa8ed7ca';
  const { toast } = useSwal();

  const [t] = useTranslation('porter-delivery');

  useLayoutEffect(() => {
    if (modalMobileRef?.current) {
      const height = modalMobileRef.current.clientHeight;
      setModalMobileHeight(height);
    }
  }, [modalMobileRef?.current]);

  const handleApolloError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (formattedErrors && formattedErrors.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  const { data, loading } = useQuery(getCondominiumInfo, {
    fetchPolicy: 'cache-first',
    skip: !uuid,
    variables: { uuid },
    onError: err => handleApolloError(err),
    onCompleted: response => setCondominiumInfo(response),
  });

  useEffect(() => {
    if (
      condominiumInfo &&
      condominiumInfo.getCondominiumInfo &&
      phoneNumber?.raw
    ) {
      if (groupUnities.length <= 1)
        handleSetStep(porterDeliveryStepNames.unity);
      else handleSetStep(porterDeliveryStepNames.groupUnity);
    }
  }, [groupUnities]);

  const handleStartDeposit = () => {
    handleNextStep();
  };

  useEffect(() => {
    setUuidCondominium(uuid);
  }, []);

  if (loading) return <GenericLoader />;

  return (
    !loading &&
    !phoneNumber?.raw && (
      <S.WrapperStepWelcome>
        <S.ImageWelcomeWrapper modalMobileHeight={modalMobileHeight}>
          <S.Img src={deliveryAddress} alt="Delivery Address" />
        </S.ImageWelcomeWrapper>
        <ModalMobile
          ref={modalMobileRef}
          title={t('welcome.title')}
          subtitles={[
            t('welcome.subtitle'),
            <span key="condominium-name">
              <Trans>
                {t('welcome.condominium', {
                  condominiumName: data?.getCondominiumInfo?.name,
                })}
              </Trans>
            </span>,
          ]}
          buttonText={t('welcome.button')}
          onClick={handleStartDeposit}
          loading={loading}
        />
      </S.WrapperStepWelcome>
    )
  );
};

export default StepWelcome;
