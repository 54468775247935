import propTypes from 'prop-types';
import { MdClose, MdAdd } from 'react-icons/md';
import { Wrapper, SwitchButton, OptionButton } from './styles';

const Button = ({
  option,
  optionOnClick = false,
  switchOnClick,
  addState = false,
}) => {
  return (
    <Wrapper>
      <OptionButton onClick={optionOnClick}>{option}</OptionButton>
      <SwitchButton onClick={switchOnClick} state={addState}>
        {addState ? <MdAdd /> : <MdClose />}
      </SwitchButton>
    </Wrapper>
  );
};

export default Button;

Button.propTypes = {
  option: propTypes.string.isRequired,
  optionOnClick: propTypes.oneOfType([propTypes.bool, propTypes.func]),
  switchOnClick: propTypes.func.isRequired,
  addState: propTypes.bool,
};

Button.defaultProps = {
  optionOnClick: null,
  addState: false,
};
