import styled, { css } from 'styled-components';

import { ModalHeader as modalHeader, ModalBody as modalBody } from 'reactstrap';

export const ModalHeader = styled(modalHeader)`
  ${({ hastitle }) =>
    !hastitle &&
    css`
      padding: 1rem 1rem 0 0;
      border-bottom: none;
    `}
`;

export const ModalBody = styled(modalBody)`
  ${({ hastitle }) =>
    !hastitle &&
    css`
      padding-top: 0;
    `}
`;
