import gql from 'graphql-tag';

const accessRfFragment = gql`
  fragment accessRf_Part on DwellerDevice {
    id
    type
    value
    counter
    lastCounter
    creationDate
  }
`;

export default accessRfFragment;
