import * as moment from 'moment';
import { zonedTimeToUtc } from 'date-fns-tz';
import {
  addSeconds as addSec,
  subSeconds as subSec,
  addMonths as monthAdd,
  isEqual,
  differenceInCalendarDays,
  getHours as getHour,
  getMinutes as getMinute,
  set,
  format as formatDateFns,
  addDays as daysAdd,
  differenceInMinutes as diffInMinutes,
} from 'date-fns';

const acceptedLanguages = ['pt', 'en', 'es'];

export const getUserLang = () => {
  let userLang = 'pt-br';

  if (window && window.navigator) {
    userLang = window.navigator.language || window.navigator.userLanguage;
  }

  return userLang;
};

export const getUserLanguageCode = () => {
  const languageCode = getUserLang().split('-')[0];
  return acceptedLanguages.includes(languageCode) ? languageCode : 'pt';
};

export const compareDates = (date1, date2, userLang) => {
  return {
    isBefore: date1 <= date2,
    isAfter: date1 > date2,
    calendar:
      date1 <= date2
        ? moment(date1)
            .locale(userLang)
            .calendar()
        : moment(date2)
            .locale(userLang)
            .calendar(),
  };
};

export const getTimezoneOffSet = localToUtc00 => {
  return (new Date().getTimezoneOffset() / 60) * (localToUtc00 ? -1 : 1);
};

export const formatHour = date => {
  return moment(date).format('HH:mm');
};

export const formatUtcHours = ({ time, localToUtc00 }) => {
  const offSet =
    (new Date().getTimezoneOffset() / 60) * (localToUtc00 ? 1 : -1);

  const result = moment(time, 'HH:mm')
    .add(offSet, 'hours')
    .format('HH');

  return result;
};

export const formatUtcDate = ({ time, localToUtc00 }) => {
  const offSet = getTimezoneOffSet(localToUtc00);
  const result = moment(time, 'llll').add(offSet, 'hours');
  return result;
};

export const getDayOfWeekMoment = (date, utcOffset = 0) => {
  const utcDate = moment.utc(date);
  const currentDate = utcDate.add(utcOffset, 'hours');
  const dayOfWeek = currentDate.day();

  return dayOfWeek;
};

export const getCurrentTime = (date, utcOffset = 0) => {
  const utcDate = moment.utc(date);
  const currentDate = utcDate.add(utcOffset, 'hours');
  const currentTime = currentDate.format('HH:mm');

  return currentTime;
};

export const getMomentDateUtcTimeZone = (date, localToUtc00 = true) => {
  const offset = getTimezoneOffSet(localToUtc00);
  const momentDate = moment(date).add(offset, 'hours');

  return momentDate;
};

export const formatDate = (date, format) => {
  const momentDate = getMomentDateUtcTimeZone(date);

  const userLang = getUserLang();
  momentDate.locale(userLang);

  return {
    formatted: format ? momentDate.format(format) : momentDate.format('L'),
    calendar: momentDate.calendar(),
    locale: userLang,
  };
};

export const formatDateTime = (date, format) => {
  if (format) return moment(date).format(format);

  const momentDate = moment(date);
  const userLang = getUserLang();

  momentDate.locale(userLang);

  return `${momentDate.format('L')} ${momentDate.format('LT')}`;
};

export const formatDateUtc = ({ date, utc = 0, format = 'L' }) => {
  return moment(date)
    .utcOffset(60 * +utc)
    .format(format);
};

export const toDateType = (date, format) => {
  const momentDate = getMomentDateUtcTimeZone(date);

  return format
    ? moment(momentDate)
        .format(format)
        .toDate()
    : moment(momentDate).toDate();
};

export const convertTimeStringToDate = time => {
  const hoursMinutes = time.split(':');
  return moment(new Date())
    .hours(hoursMinutes[0])
    .minutes(hoursMinutes[1]);
};

export const compareDateFromNow = (date, addOffset = true) => {
  const now = new Date(); // utc local
  const momentDate = addOffset ? getMomentDateUtcTimeZone(date) : moment(date);
  const userLang = getUserLang();

  return {
    ...compareDates(momentDate.toDate(), now, userLang),
    fromNow: momentDate.locale(userLang).fromNow(),
  };
};

const format = (days, date, locale, t) => {
  const dateFormat = new Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'short',
  });

  const timeFormat = new Intl.DateTimeFormat(locale, {
    hour: 'numeric',
    minute: 'numeric',
  });

  if (days < -1) {
    return `${dateFormat.format(date)} - ${timeFormat.format(date)}`;
  }
  if (days === -1) {
    return `${t('common:time.yesterday')} ${timeFormat.format(date)}`;
  }
  if (days === 0) {
    return `${t('common:time.today')} ${timeFormat.format(date)}`;
  }

  if (days === 1) {
    return `${t('common:time.tomorrow')} ${timeFormat.format(date)}`;
  }

  return '';
};

export const formatDistanceDate = (date, t) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const newDateUtc = zonedTimeToUtc(date, { timeZone });
  const userLang = getUserLang();
  const todayUtc = zonedTimeToUtc(new Date());
  const days = differenceInCalendarDays(newDateUtc, todayUtc);

  return format(days, newDateUtc, userLang, t);
};

export const changeDateUTC00ToLocalUTC = date => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const newDateUtc = zonedTimeToUtc(date, { timeZone });
  return newDateUtc;
};

export const formatDateTimeUtc = date => {
  if (!date) return '';
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const newDateUtc = zonedTimeToUtc(date, { timeZone });
  return formatDateFns(newDateUtc, 'dd/MM/yyyy HH:mm:ss');
};

export const formatOnlyDateUtc = date => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const newDateUtc = zonedTimeToUtc(date, { timeZone });
  return formatDateFns(newDateUtc, 'dd/MM/yyyy');
};

export const formatHoursAndMinutesUtc = date => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const newDateUtc = zonedTimeToUtc(date, { timeZone });
  return formatDateFns(newDateUtc, 'HH:mm');
};

export const addSeconds = (date, amount) => addSec(date, amount);

export const subSeconds = (date, amount) => subSec(date, amount);

export const equals = (a, b) => isEqual(a, b);

export const addDays = amount => moment().add(amount, 'days');

export const addMonths = (date, amount) => monthAdd(date, amount);

export const addDaysFns = (date, amount) => daysAdd(date, amount);

export const formatTime = timer => {
  const getSeconds = `0${timer % 60}`.slice(-2);
  const minutes = `${Math.floor(timer / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);

  return `${getMinutes} : ${getSeconds}`;
};

export const parseZonedTime = date => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  return zonedTimeToUtc(date, { timeZone });
};

export const isValidDate = (date, formatType) => {
  if (!date) return false;

  return formatType
    ? moment(date, formatType, true).isValid()
    : moment(date).isValid();
};

export const addHoursMinutes = (date, hours, minutes, formatType = 'L') => {
  return moment(date, formatType)
    .add(hours, 'hours')
    .add(minutes, 'minutes')
    .format('YYYY-MM-DD HH:mm');
};

export const addMinutes = (
  date,
  minutes,
  formatType = 'L',
  formatStringDate = 'HH:mm:ss',
) => {
  return moment(date, formatType)
    .add(minutes, 'minutes')
    .format(formatStringDate);
};

export const compare = (date1, date2) => {
  return {
    isAfter: moment(date1).isAfter(date2),
    isSame: moment(date1).isSame(date2),
  };
};

export const getLocaleFormat = date => {
  const momentDate = getMomentDateUtcTimeZone(date || new Date());

  const userLang = getUserLang();
  momentDate.locale(userLang);

  return {
    format: momentDate.localeData().longDateFormat('L'),
  };
};

export const differenceInMinutes = diffInMinutes;

export const getHours = getHour;

export const getMinutes = getMinute;

export const setTime = set;

export const toLocaleDateString = date =>
  date &&
  date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

export const formatTimerLabel = timer => {
  const formatTimer = () => {
    if (timer >= 60) {
      return timer % 60 === 0 ? 'H[h]' : 'H[h]mm[min]';
    }
    return 'mm[min]';
  };

  return moment
    .utc()
    .startOf('day')
    .add({ minutes: timer })
    .format(formatTimer());
};

export const getGreetingTime = (currentTime, utc) => {
  const offset = utc || getTimezoneOffSet(true);
  const momentDate = moment.utc(currentTime).add(offset, 'hours');
  const splitAfternoon = 12; // 24hr time to split the afternoon
  const splitEvening = 18; // 24hr time to split the evening
  const currentHour = momentDate.format('HH');

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    // Between 12 PM and 6PM
    return 'afternoon';
  }
  if (currentHour >= splitEvening) {
    // Between 6PM and Midnight
    return 'evening';
  }
  // Between dawn and noon
  return 'morning';
};
