import { Fragment } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import propTypes from 'prop-types';
import * as S from './styles';

const Wrapper = ({ to, isLast, ...props }) =>
  to && !isLast ? <Link {...props} to={to} /> : <Fragment {...props} />;

export const Item = ({
  item,
  isLast,
  searchUrlHistory,
  formatter = e => e,
}) => {
  const match = useRouteMatch({
    path: item.to,
    strict: true,
  });

  return (
    <S.BreadcrumbItem active={match && match.isExact}>
      <Wrapper to={`${item.to}${searchUrlHistory}`} isLast={isLast}>
        {formatter(item.label)}
      </Wrapper>
    </S.BreadcrumbItem>
  );
};

export default function KBreadcrumb({
  stack,
  formatter,
  searchUrlHistory,
  ...props
}) {
  return (
    <S.Breadcrumb {...props}>
      {stack.map((item, index) => (
        <Item
          item={item}
          key={item.label + crypto.randomUUID()}
          isLast={index === stack.length - 1}
          formatter={formatter}
          searchUrlHistory={searchUrlHistory}
        />
      ))}
    </S.Breadcrumb>
  );
}

const breadcrumbItemShape = propTypes.shape({
  label: propTypes.string,
  to: propTypes.string,
  params: propTypes.object,
});

Item.propTypes = {
  item: breadcrumbItemShape.isRequired,
  isLast: propTypes.bool.isRequired,
  formatter: propTypes.func,
  searchUrlHistory: propTypes.string,
};

Item.defaultProps = {
  formatter: e => e,
  searchUrlHistory: '',
};

Wrapper.propTypes = {
  to: propTypes.string,
  isLast: propTypes.bool.isRequired,
};

Wrapper.defaultProps = {
  to: '',
};

KBreadcrumb.propTypes = {
  stack: propTypes.arrayOf(breadcrumbItemShape).isRequired,
  formatter: propTypes.func,
  searchUrlHistory: propTypes.string,
};

KBreadcrumb.defaultProps = {
  formatter: e => e,
  searchUrlHistory: propTypes.string,
};
