import gql from 'graphql-tag';

export const sync = gql`
  mutation sync($type: AdamSenderTypeEnum!, $identifier: Int!) {
    adamSenderSync(type: $type, identifier: $identifier)
  }
`;

export const condominiumResync = gql`
  mutation adamSenderCondominiumResync($condominiumContextId: ID!) {
    adamSenderCondominiumResync(condominiumContextId: $condominiumContextId)
  }
`;

export const startEmergency = gql`
  mutation EmergencyStart($condominiumContextId: ID!) {
    startEmergency(condominiumContextId: $condominiumContextId)
  }
`;

export const stopEmergency = gql`
  mutation EmergencyStop($condominiumContextId: ID!) {
    stopEmergency(condominiumContextId: $condominiumContextId)
  }
`;

export const openDevice = gql`
  mutation openDevice($deviceHandler: DeviceHandlerInput) {
    openDevice(deviceHandler: $deviceHandler)
  }
`;

export const closeDevice = gql`
  mutation closeDevice($deviceHandler: DeviceHandlerInput) {
    closeDevice(deviceHandler: $deviceHandler)
  }
`;

export const keepDeviceOpened = gql`
  mutation keepDeviceOpened($deviceHandler: DeviceHandlerInput) {
    keepDeviceOpened(deviceHandler: $deviceHandler)
  }
`;

export const keepDeviceOpenedV2 = gql`
  mutation keepDeviceOpenedV2($deviceHandler: DeviceHandlerInput) {
    keepDeviceOpenedV2(deviceHandler: $deviceHandler)
  }
`;

export const handleRelay = gql`
  mutation handleRelay($relayHandler: RelayHandlerInput) {
    handleRelay(relayHandler: $relayHandler)
  }
`;
