import { useEffect, useMemo, useState } from 'react';
import { useWindowDimensions } from '@kiper/hooks';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logoKiper from '../../assets/images/logo-kiper.svg';
import logoPorter from '../../assets/images/logo-porter.svg';
import activateKeyKiperImg from '../../assets/images/activate-key-kiper.svg';
import activateKeyPorterImg from '../../assets/images/activate-key-porter.svg';
import './styles.css';

const brandTypes = {
  PORTER: 'p',
  KIPER: 'k',
};

const brandSettings = {
  [brandTypes.PORTER]: {
    brandLogoSrc: logoPorter,
    contentImgSrc: activateKeyPorterImg,
    appleId: 'porter/id1579435806',
    packageName: 'br.com.portergroup.appporter',
    brandName: 'porter',
  },
  [brandTypes.KIPER]: {
    brandLogoSrc: logoKiper,
    contentImgSrc: activateKeyKiperImg,
    appleId: 'kiper/id1090915622',
    packageName: 'br.com.segware.kiper.mobile',
    brandName: 'kiper',
  },
};

const Spinner = () => (
  <div className="spinner">
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default function ActivateKeyPage() {
  const { t } = useTranslation('activate-keys');
  const { brandSlug } = useParams();
  const { width: windowsWidth, height } = useWindowDimensions();
  const [brandSettingFromUrl, setBrandSettingFromUrl] = useState(null);
  const [loadingRedirect, setLoadingRedirect] = useState(false);
  const queryStringParams = window.location.search;

  useEffect(() => {
    if (!brandSlug || !(brandSlug in brandSettings)) window.location = '/';
    else setBrandSettingFromUrl(brandSettings[brandSlug]);
  }, [brandSlug]);

  const isIOS = useMemo(() => {
    const userAgent =
      navigator?.userAgent || navigator?.vendor || window?.opera;
    return /iPad|iPhone|iPod/i.test(userAgent);
  }, [navigator]);

  const isDesktop = useMemo(() => windowsWidth > 1024, [windowsWidth]);

  const Redirect = () => {
    setLoadingRedirect(true);
    if (isIOS) {
      window.location = `${brandSettingFromUrl?.brandName}://${queryStringParams}`;

      setTimeout(() => {
        window.location = `kiper://${queryStringParams}`;
      }, 350);

      setTimeout(() => {
        window.location = `https://itunes.apple.com/app/${brandSettingFromUrl?.appleId}`;
        setLoadingRedirect(false);
      }, 700);
    } else if (windowsWidth <= 1024) {
      window.location = `${brandSettingFromUrl?.brandName}://m.kiper.com.br/api/v1/mobileConfiguration/checkApp${queryStringParams}`;

      setTimeout(() => {
        window.location = `kiper://m.kiper.com.br/api/v1/mobileConfiguration/checkApp${queryStringParams}`;
      }, 350);

      setTimeout(() => {
        window.location = `intent://${queryStringParams}#Intent;scheme=kiper;package=${brandSettingFromUrl?.packageName};end`;
        setLoadingRedirect(false);
      }, 700);
    }
  };

  if (!brandSlug || !brandSettingFromUrl) return null;

  return (
    <main style={{ height }} className="body-page">
      {isDesktop ? (
        <span>{t('message-fallback-desktop')}</span>
      ) : (
        <>
          <img
            className="brand-logo"
            src={brandSettingFromUrl?.brandLogoSrc}
            alt="porter"
          />
          <img
            className="activate-key-brand-image"
            src={brandSettingFromUrl?.contentImgSrc}
            alt="porter"
          />
          <div className="download-area">
            <span className="activate-key-message">
              {t('redirect-message', {
                brand: brandSlug === brandTypes.PORTER ? 'Porter' : 'Kiper',
              })}
            </span>
            <button
              type="button"
              className={`download-button background-color-${brandSlug}`}
              onClick={Redirect}
              disabled={loadingRedirect}
            >
              {loadingRedirect ? <Spinner /> : t('redirect-button')}
            </button>
          </div>
        </>
      )}
    </main>
  );
}
