import propTypes from 'prop-types';
import { MdDelete } from 'react-icons/md';
import { useSwal } from '@kiper/hooks';
import Flex from '../Flex';
import Label from '../Label';
import TimeField from './TimeField';
import DaysOfWeek from './DaysOfWeek';
import * as S from './styles';

const AccessRestrictions = ({
  index,
  accessRestrictions,
  accessRestriction,
  handleChange,
  isEditable,
  handleRemove,
  onlyText,
  invalid,
  fullwidth,
  t,
}) => {
  const { toast } = useSwal();

  const handleSelectDayOfWeek = (indexPressed, indexDay) => {
    const newIntervals = [...accessRestrictions];
    const interval = newIntervals[indexPressed];

    interval.daysOfWeek[indexDay] = !interval.daysOfWeek[indexDay];

    handleChange(newIntervals);
  };

  const validateTime = (property, hours, minutes, timeObject) => {
    const { start, end } = timeObject;

    const isValidHour = hour => hour >= 0 && hour <= 23;
    const isValidMinute = minute => minute >= 0 && minute <= 59;

    const showToast = title => {
      toast.fire({
        title,
        icon: 'error',
      });
    };

    if (!isValidHour(hours) || !isValidMinute(minutes)) {
      showToast(t('common:schedule.invalid-time'));
      return false;
    }

    switch (property) {
      case 'start': {
        const currentHours = Number(end.hours);
        const currentMinutes = Number(end.minutes);
        if (
          hours > currentHours ||
          (hours === currentHours && minutes >= currentMinutes)
        ) {
          showToast(t(`common:schedule.feedback-start-time`));
          return false;
        }
        return true;
      }
      case 'end': {
        const currentHours = Number(start.hours);
        const currentMinutes = Number(start.minutes);
        if (
          hours < currentHours ||
          (hours === currentHours && minutes <= currentMinutes)
        ) {
          showToast(t(`common:schedule.feedback-start-time`));
          return false;
        }
        return true;
      }
      default:
        return true;
    }
  };

  const handleChangeTime = (property, time, resetField) => {
    const modifiedTime = [...accessRestrictions];
    let [hours, minutes] = time.split(':');

    const parsedHours = Number(hours);
    const parsedMinutes = Number(minutes);

    const validTime = validateTime(
      property,
      parsedHours,
      parsedMinutes,
      modifiedTime[index],
    );

    if (!validTime) {
      const {
        hours: oldHours,
        minutes: oldMinutes,
        time: oldTime,
      } = modifiedTime[index][property];

      modifiedTime[index][property] = {
        hours: oldHours,
        minutes: oldMinutes,
        time: oldTime,
      };

      resetField(oldTime);
    } else {
      const trimmedHours = hours?.trim();
      const trimmedMinutes = minutes?.trim();

      const hoursLength = trimmedHours?.length;
      const minutesLength = trimmedMinutes?.length;

      if (!minutesLength) {
        minutes = '00';
        time = `${trimmedHours}:00`; // eslint-disable-line
      }
      if (!hoursLength) {
        hours = '00';
        time = `00:${trimmedMinutes}`; // eslint-disable-line
      }
      if (hoursLength === 1) {
        time = `0${trimmedHours}:${trimmedMinutes}`; // eslint-disable-line
      }
      if (minutesLength === 1) {
        time = `${trimmedHours}:0${trimmedMinutes}`; // eslint-disable-line
      }

      resetField(time);

      modifiedTime[index][property] = { hours, minutes, time };
    }

    handleChange(modifiedTime);
  };

  return (
    <S.Container fullwidth={fullwidth} invalid={invalid}>
      <Flex justifyContent="space-between">
        <Flex>
          <TimeField
            value={accessRestriction.start.time}
            text={t('common:schedule.start-time')}
            isEditable={isEditable}
            handleChangeTime={(val, fn) => handleChangeTime('start', val, fn)}
          />
          <TimeField
            value={accessRestriction.end.time}
            text={t('common:schedule.end-time')}
            isEditable={isEditable}
            handleChangeTime={(val, fn) => handleChangeTime('end', val, fn)}
          />
        </Flex>
        {isEditable && (
          <S.RemoveButton
            onClick={() => handleRemove(index)}
            rounded
            icon={<MdDelete />}
            color="secondary"
            variant="text"
          />
        )}
      </Flex>
      <Flex flexDirection="column">
        <Label info block>
          {t('common:schedule.days-of-week')}
        </Label>
        <DaysOfWeek
          handleSelectDayOfWeek={handleSelectDayOfWeek}
          index={index}
          daysActivated={accessRestriction.daysOfWeek}
          onlyText={onlyText}
          disabled={!isEditable}
          t={t}
        />
      </Flex>
    </S.Container>
  );
};

export default AccessRestrictions;

AccessRestrictions.propTypes = {
  accessRestriction: propTypes.object.isRequired,
  index: propTypes.number.isRequired,
  handleRemove: propTypes.func.isRequired,
  handleChange: propTypes.func.isRequired,
  accessRestrictions: propTypes.array,
  t: propTypes.func.isRequired,
  isEditable: propTypes.bool,
  onlyText: propTypes.bool,
  fullwidth: propTypes.bool,
  invalid: propTypes.bool,
};

AccessRestrictions.defaultProps = {
  onlyText: false,
  isEditable: true,
  fullwidth: false,
  invalid: false,
  accessRestrictions: [],
};
