import propTypes from 'prop-types';
import { Cache } from 'react-avatar';
import { Container, CustomAvatar as Avatar } from './styles';

const cache = new Cache({
  // Keep cached source failures for up to 7 days
  sourceTTL: 7 * 24 * 3600 * 1000,
  // Keep a maximum of 20 entries in the source cache
  sourceSize: 20,
});

const UserAvatar = ({ src, size, name, round, id, onClick }) => (
  <Container size={size} id={id}>
    <Avatar
      name={name}
      alt={name}
      round={round}
      src={src}
      maxInitials={2}
      size={size}
      cache={cache}
      onClick={onClick}
    />
  </Container>
);

export default UserAvatar;

UserAvatar.propTypes = {
  src: propTypes.string,
  name: propTypes.string,
  size: propTypes.number,
  round: propTypes.string,
  onClick: propTypes.func,
  id: propTypes.string,
};

UserAvatar.defaultProps = {
  size: 38,
  round: '100%',
  name: '',
  src: '',
  onClick: () => {},
  id: '',
};
