import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  max-height: 100vh;
  max-width: 100vw;
  background-color: ${({ theme }) => theme.colors.secondary50};
`;

export const ContentWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: inherit;
  gap: 16px;
  padding: 24px;
  overflow-y: auto;
`;

export const LayoutHeader = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: fit-content;
  background-color: inherit;
  padding: 0;
`;

export const IconButton = styled.button`
  all: unset;
  color: ${({ theme }) => theme.colors.blackShades(0.87)};
  font-size: 24px;
  height: 24px;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Footer = styled.footer`
  height: fit-content;
  width: 100%;
`;
