import gql from 'graphql-tag';

export const placeTypeLabelsSelect = gql`
  query placeTypeLabelsSelect($fieldName: String!, $personContextId: Int!) {
    placeTypeLabelsSelect(
      fieldName: $fieldName
      personContextId: $personContextId
    ) {
      value
      label
    }
  }
`;
