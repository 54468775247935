import styled from 'styled-components';
import propTypes from 'prop-types';

/**
 * Divider component.
 *
 * @property {?string} customColor - Optional custom color for Divider
 * @default [border-bottom="theme.colors.secondary300"]
 */
const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid
    ${({ customColor = null, theme }) =>
      customColor ?? theme.colors.secondary300};
`;

Divider.propTypes = {
  /** Custom color for divider line. */
  customColor: propTypes.string,
};

Divider.defaultProps = {
  customColor: null,
};

export default Divider;
