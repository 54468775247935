import styled from 'styled-components';
import { ListGroupItem } from 'reactstrap';

export default styled(ListGroupItem).attrs(props => ({
  ...props,
  header: Number(props.header || 0),
}))`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  cursor: ${props => (props.header ? 'default' : 'pointer')};

  &:hover {
    background: ${props => !props.header && props.theme.colors.secondary200};
  }
`;
