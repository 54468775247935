import { Modal, Label } from 'reactstrap';
import propTypes from 'prop-types';
import * as S from './styles';

const CustomModal = ({ title, open, toggle, children, size, ...props }) => {
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      size={size}
      backdrop="static"
      {...props}
    >
      <S.ModalHeader toggle={toggle} hastitle={+!!title}>
        {!!title && (
          <Label style={{ margin: '0', width: '100%' }}>{title}</Label>
        )}
      </S.ModalHeader>
      <S.ModalBody hastitle={+!!title}>{children}</S.ModalBody>
    </Modal>
  );
};

export default CustomModal;

CustomModal.propTypes = {
  title: propTypes.oneOfType([propTypes.string, propTypes.element]),
  toggle: propTypes.func.isRequired,
  open: propTypes.bool.isRequired,
  children: propTypes.element.isRequired,
  size: propTypes.string,
};

CustomModal.defaultProps = {
  title: null,
  size: 'lg',
};
