import styled, { keyframes } from 'styled-components';

const spinnerAnimation = keyframes`
   0% {
    top: 100px;
    left: 100px;
    width: 0px;
    height: 0px;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 200px;
    height: 200px;
    opacity: 0;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

export const Spinner = styled.div`
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;

  div {
    position: absolute;
    border: 4px solid #29ad71;
    opacity: 1;
    border-radius: 50%;
    animation: ${spinnerAnimation} 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  div:nth-child(2) {
    animation-delay: -0.5s;
  }
`;

export const Wrapper = styled.div`
  height: 200px;
  width: 200px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  opacity: 0;
  animation: ${fadeIn} 1s;
  animation-fill-mode: forwards;

  svg {
    position: absolute;
    height: 100px;
    width: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
  }
`;
