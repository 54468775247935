import { BrowserRouter } from 'react-router-dom';
import { Suspense } from 'react';
import { hydrate } from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { runtimeConfig } from '@kiper/fns';
import { initializeGtm } from '@kiper/gtm';
import { initializeSmartLook } from '@kiper/smartlook';

import { GenericLoader } from '@kiper/ui';
import createClient from './services/apollo';
import App from './App';

const apolloClient = createClient();

initializeGtm(runtimeConfig.ACCOUNT_GOOGLE_TAG_MANAGER_ID);

initializeSmartLook(
  runtimeConfig.ACCOUNT_SMARTLOOK_KEY,
  runtimeConfig.RAZZLE_ENV === 'production',
);

const BaseApp = () => {
  return (
    <Suspense fallback={<GenericLoader />}>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </Suspense>
  );
};

hydrate(<BaseApp />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept();
}
