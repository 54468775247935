import styled from 'styled-components';

import {
  gridTemplateColumns,
  gridGap,
  grid,
  gridRowGap,
  gridColumnGap,
} from 'styled-system';

import Box from '../Box';

export const BoxGrid = styled(Box)`
  display: grid;
  cursor: inherit;
  ${grid}
  ${gridTemplateColumns}
  ${gridGap}
  ${gridRowGap}
  ${gridColumnGap}
`;
