import sweetalert from 'sweetalert2';

const toast = sweetalert.mixin({
  toast: true,
  timerProgressBar: true,
  showCloseButton: false,
  showConfirmButton: false,
  timer: 5000,
  position: 'top-right',
});

const swal = sweetalert.mixin({
  allowEscapeKey: false,
  allowOutsideClick: false,
  showConfirmButton: true,
  showCancelButton: true,
  showLoaderOnConfirm: true,
  buttonsStyling: false,
  reverseButtons: true,
  showCloseButton: true,
  focusCancel: true,
  customClass: {
    confirmButton: 'btn btn-sm btn-success w-25',
    cancelButton: 'btn btn-sm btn-outline-dark w-25 mr-3',
  },
});

const confirm = ({ title, text, ...props }) =>
  swal
    .fire({
      html: `<h5 class='text-left mb-0 font-weight-bold'>${title}</h5>
    <hr class='my-2' />
    <p class='text-left mb-0'>${text}</p>`,
      ...props,
    })
    .then(res => res.value);

const alert = ({
  icon,
  title,
  text,
  cancelButtonText,
  confirmButtonText,
  preConfirm,
  timer,
}) =>
  swal.fire({
    icon,
    title,
    text,
    cancelButtonText,
    confirmButtonText,
    preConfirm,
    timer,
  });

const linkToast = ({ title, icon, linkLabel, href, timer, showCloseButton }) =>
  sweetalert.fire({
    toast: true,
    timerProgressBar: true,
    showCloseButton: !!showCloseButton,
    showConfirmButton: false,
    timer,
    position: 'top-right',
    icon,
    title: `<span style="padding: 0 10 0 5; font-weight: bold; ">${title} ${
      linkLabel && href
        ? `<a href="${href}" target="_blank">${linkLabel}</a></span>`
        : ''
    }`,
  });

const dialog = ({ title, text, icon, confirmButtonText, ...props }) => {
  const DialogSwal = sweetalert.mixin({
    customClass: {
      confirmButton: 'btn btn-success text-white',
    },
    buttonsStyling: false,
  });

  DialogSwal.fire({
    toast: false,
    title,
    text,
    icon,
    showConfirmButton: true,
    confirmButtonText,
    position: 'center',
    showCloseButton: true,
    ...props,
  });
};

const alertWarning = ({
  title,
  text,
  icon,
  confirmButtonText,
  cancelButtonText,
  ...props
}) => {
  const DialogSwal = sweetalert.mixin({
    customClass: {
      cancelButton: 'btn btn-outline-secondary mr-3',
      confirmButton: 'btn btn-danger',
    },
    buttonsStyling: false,
  });

  DialogSwal.fire({
    toast: false,
    title: `<span style="font-size: 18px; font-weight: 700; color: #121C2D; font-family: Nunito Sans; line-height: 28px;">${title}</span>`,
    text,
    showConfirmButton: true,
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonText,
    cancelButtonText,
    position: 'center',
    showCloseButton: false,
    padding: '24px',
    focusConfirm: false,
    ...props,
  });
};

export default function useSwal() {
  return { toast, swal, confirm, alert, linkToast, dialog, alertWarning };
}
