/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const removeAllowedAccess = gql`
  mutation personContextAllowedAccessRemove(
    $personContextAllowedAccessId: Int!
  ) {
    personContextAllowedAccessRemove(
      personContextAllowedAccessId: $personContextAllowedAccessId
    )
  }
`;
