import styled, { css } from 'styled-components';
import { MdClose, MdError } from 'react-icons/md';
import { Input as InputRS } from 'reactstrap';

const iconModifiers = {
  small: () => css`
    padding: 8px 0 8px 8px;

    svg {
      height: 16px;
      width: auto;
    }
  `,
  normal: () => css`
    padding: 8px 0 8px 8px;

    svg {
      height: 16px;
      width: auto;
    }
  `,
  large: () => css`
    padding: 0;

    svg {
      height: 24px;
      width: auto;
    }
  `,
};

const clearIconModifiers = {
  small: () => css`
    font-size: 16px;
  `,
  normal: () => css`
    font-size: 16px;
  `,
  large: () => css`
    font-size: 24px;
  `,
};

const inputHeightSizeModifiers = {
  small: () => css`
    height: 32px;
    font-size: 14px;
  `,
  normal: () => css`
    height: 38px;
    font-size: 14px;
  `,
  large: () => css`
    height: 52px;
    font-size: 16px;
  `,
};

const variantModifiers = {
  rounded: () => css`
    padding: 8px 16px;
    border-radius: 32px;
  `,
  normal: () => css`
    padding: 6px 8px;
    border-radius: 8px;
  `,
};

export const Input = styled(InputRS)`
  font-family: 'Nunito Sans', sans-serif;
  width: 100%;
  box-sizing: border-box;
  box-shadow: none !important;
  padding: 6px 8px;
  border-radius: 8px;
  border: none;
  font-weight: 400;

  ${props => inputHeightSizeModifiers[props.$size]};

  caret-color: ${({ readonly }) => readonly && 'transparent'};

  color: ${({ theme }) => theme.colors.blackShades(0.8)};

  &:disabled {
    background-color: ${({ theme }) => theme.colors.secondary200};
    opacity: 50%;
  }

  &:focus-within {
    outline: none;
  }

  :focus,
  :focus-visible {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary400};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    color: ${({ theme }) => theme.colors.blackShades(0.8)};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  :read-only {
    color: inherit;
    background-color: inherit;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const LabelWrapper = styled.div`
  display: inline-flex;
  margin-bottom: 8px;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.blackShades(0.7)};
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.25px;
  margin: 0;
`;

export const Error = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.colors.danger500};
`;

export const IconError = styled(MdError).attrs({
  size: 16,
})`
  color: ${({ theme }) => theme.colors.danger500};
`;

export const InputGroup = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  transition: border 0.1s ease-in;
  background-color: ${({ theme }) => theme.colors.white};

  ${props => inputHeightSizeModifiers[props.size]};
  ${props => variantModifiers[props.variant]};

  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.danger500 : theme.colors.secondary400};

  &:focus-visible,
  &:focus-within,
  &:focus {
    outline: none;

    ${({ theme, error, primaryColor }) => css`
      border: 1px solid
        ${error ? theme.colors.danger500 : theme.colors[primaryColor]};
      svg {
        color: ${error ? theme.colors.danger500 : theme.colors[primaryColor]};
        transition: color 0.2s ease-in;
      }

      button {
        border-left-color: ${error
          ? theme.colors.danger500
          : theme.colors[primaryColor]};
        transition: border-left-color 0.2s ease-in;
      }
    `}
  }
`;

export const InputIcon = styled.span`
  display: flex;
  align-items: center;
  padding: 8px 0 8px 8px;
  height: 100%;
  color: ${({ theme, error }) =>
    error ? theme.colors.danger500 : theme.colors.blackShades(0.8)};

  ${props => iconModifiers[props.$size]};
`;

export const ClearFieldButton = styled.button`
  width: auto;
  height: 100%;
  background-color: transparent;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :focus,
  :focus-visible {
    outline: none;
  }

  :hover {
    opacity: 0.7;
  }
  transition: opacity 0.1s ease-in;
`;

export const ClearIcon = styled(MdClose)`
  color: ${({ theme }) => theme.colors.secondary400};
  transition: color 0.1s ease-in;
  ${props => clearIconModifiers[props.$size]};
`;

export const ClipboardButton = styled.button`
  width: auto;
  height: 100%;
  background-color: ${props => props.theme.colors.secondary100};
  border: none;
  border-left: 1px solid ${({ theme }) => theme.colors.secondary400};
  display: inline-flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :focus,
  :focus-visible {
    outline: none;
  }

  :hover {
    opacity: 0.7;
  }
  transition: opacity 0.1s ease-in;
`;
