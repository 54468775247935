import gql from 'graphql-tag';
import { eventSchedulerSettingsFragment } from './fragments';

export const insertEventSchedulerSettings = gql`
  mutation insertEventSchedulerSettings($eventScheduler: EventSchedulerInput!) {
    insertEventSchedulerSettings(eventScheduler: $eventScheduler) {
      ...eventSchedulerSettingsParts
    }
  }
  ${eventSchedulerSettingsFragment}
`;

export const updateEventSchedulerSettings = gql`
  mutation updateEventSchedulerSettings(
    $eventSchedulerId: Int!
    $eventScheduler: EventSchedulerInput!
  ) {
    updateEventSchedulerSettings(
      eventSchedulerId: $eventSchedulerId
      eventScheduler: $eventScheduler
    ) {
      ...eventSchedulerSettingsParts
    }
  }
  ${eventSchedulerSettingsFragment}
`;

export const deleteEventSchedulerSettings = gql`
  mutation deleteEventSchedulerSettings($eventSchedulerId: Int!) {
    deleteEventSchedulerSettings(eventSchedulerId: $eventSchedulerId)
  }
`;
