import styled from 'styled-components';

export const ButtonWrapper = styled.button`
  box-sizing: border-box;
  outline: none;
  background: ${({ theme }) => theme.colors.invitePorterSecondary};
  border: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  border-radius: 8px;

  gap: 8px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
`;
