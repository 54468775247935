import styled from 'styled-components';

export const H1 = styled.h1`
  font-family: Lato, Lato-Medium;
  font-weight: 400;
  size: 40px;
  line-height: 50px;
  letter-spacing: 0.5px;
  color: ${props => props.color || props.theme.colors.mediumBlack};
`;

export const H2 = styled.h2`
  font-family: Lato, Lato-Medium;
  font-weight: 500;
  size: 34px;
  line-height: 44px;
  letter-spacing: 0.5px;
  color: ${props => props.color || props.theme.colors.mediumBlack};
`;

export const H3 = styled.h3`
  font-family: Lato, Lato-Medium;
  font-weight: 500;
  size: 28px;
  line-height: 38px;
  letter-spacing: 0.5px;
  color: ${props => props.color || props.theme.colors.mediumBlack};
`;

export const H4 = styled.h4`
  font-family: Lato, Lato-Medium;
  font-weight: 600;
  size: 24px;
  line-height: 34px;
  letter-spacing: 0.5px;
  color: ${props => props.color || props.theme.colors.mediumBlack};
`;

export const H5 = styled.h5`
  font-family: Lato, Lato-Medium;
  font-weight: 700;
  size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: ${props => props.color || props.theme.colors.mediumBlack};
`;

export const H6 = styled.h6`
  font-family: Lato, Lato-Medium;
  font-weight: 700;
  size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: ${props => props.color || props.theme.colors.mediumBlack};
`;
