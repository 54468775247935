import styled, { css } from 'styled-components';
import {
  Card as card,
  CardHeader as cardHeader,
  CardBody as cardBody,
} from 'reactstrap';
import { H3 } from '../Heading';

const Card = styled(card)`
  margin: ${({ $withoutMargin }) => ($withoutMargin ? '0' : '10px 0')};
  ${props =>
    props.border
      ? css`
          border-color: ${props.border};
        `
      : null}

  background: ${props =>
    props.background ? props.background : props.theme.colors.white};
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;

const CardHeader = styled(cardHeader)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  color: ${props => props.theme.colors.blackShades(0.7)};

  svg {
    color: ${props => props.theme.colors.blackShades(0.7)};
  }
`;

const CardBody = styled(cardBody)`
  padding: 24px 16px;
  height: 100%;
`;

const CardTitle = styled(H3)`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 0;
  color: ${props => props.theme.colors.blackShades(0.7)};
`;

export default Card;

Card.Header = CardHeader;
Card.Body = CardBody;
Card.Title = CardTitle;
