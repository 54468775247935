import gql from 'graphql-tag';

export const createDweller = gql`
  mutation createDweller($dweller: DwellerInput!) {
    dwellerCreate(dweller: $dweller)
  }
`;

export const updateDweller = gql`
  mutation updateDweller($dweller: DwellerUpdateInput!) {
    dwellerUpdate(dweller: $dweller)
  }
`;
