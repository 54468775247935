/* eslint-disable react/forbid-prop-types */
import propTypes from 'prop-types';

import { Form, FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FaSpinner } from 'react-icons/fa';
import { Col, Title, Subtitle, ButtonSubmit } from './styles';

const LoginEmail = ({
  handleSubmit,
  values,
  handleChange,
  touched,
  errors,
  isSubmitting,
}) => {
  const [t] = useTranslation('login');
  return (
    <Col>
      <Title>{t('email.title')}</Title>
      <Subtitle>{t('email.subtitle')}</Subtitle>
      <br />
      <Form onSubmit={handleSubmit} noValidate>
        <FormGroup>
          <Label htmlFor="email">{t('email.label')}</Label>
          <Input
            type="email"
            id="email"
            name="email"
            onChange={handleChange}
            required
            value={values.email}
            invalid={touched.email && !!errors.email}
            autoFocus
            autoComplete="email"
          />
          <FormFeedback>
            {errors.email && t(`errors.${errors.email}`)}
          </FormFeedback>
        </FormGroup>
        <br />
        <ButtonSubmit
          block
          color="primary"
          type="submit"
          loading={isSubmitting ? 1 : 0}
        >
          {t(`submit${isSubmitting ? 'ting' : ''}`)}
          {isSubmitting && <FaSpinner color="#FFF" size={14} />}
        </ButtonSubmit>
      </Form>
    </Col>
  );
};

export default LoginEmail;

LoginEmail.propTypes = {
  handleSubmit: propTypes.func.isRequired,
  handleChange: propTypes.func.isRequired,
  values: propTypes.object.isRequired,
  touched: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
  isSubmitting: propTypes.bool.isRequired,
};
