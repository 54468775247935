import gql from 'graphql-tag';
import { sharedPlaceFragment } from './fragments';

export const placeSelect = gql`
  query placeSelect($filters: PlaceSelectQueryInput!) {
    placeSelect(filters: $filters) {
      label
      value
      treeNodeId
      parentTreeNodeId
      type
    }
  }
`;

export const userGuUnPlaceSelect = gql`
  query placeSelect($filters: PlaceSelectQueryInput!) {
    placeSelect(filters: $filters) {
      label
      treeNodeId
      parentTreeNodeId
      type
      defaultAccessProfile
      entityId
    }
  }
`;

export const sharedPlaceSelect = gql`
  query sharedPlaceSelect($commercialCondominiumId: Int!) {
    sharedPlaceSelect(commercialCondominiumId: $commercialCondominiumId) {
      ...sharedPlaceParts
    }
  }
  ${sharedPlaceFragment}
`;
