import propTypes from 'prop-types';

import { ScrollBarStyled } from './styles';

function ScrollBar({ children, ...props }) {
  return <ScrollBarStyled {...props}>{children}</ScrollBarStyled>;
}

export default ScrollBar;

ScrollBar.propTypes = {
  children: propTypes.any.isRequired,
};
