import gql from 'graphql-tag';

export default gql`
  query clickToCall(
    $eventId: String!
    $destination: String!
    $personContextId: Int
  ) {
    clickToCall(
      eventId: $eventId
      destination: $destination
      personContextId: $personContextId
    )
  }
`;
