import propTypes from 'prop-types';
import * as S from './styles';

const DefaultInputMask = ({ onChange, ...props }) => {
  const handleChange = e => {
    onChange(e.target.value);
  };

  return (
    <S.StyledInputMask {...props} maskChar={' '} onChange={handleChange} />
  );
};

export default DefaultInputMask;

DefaultInputMask.propTypes = {
  onChange: propTypes.func.isRequired,
};
