import propTypes from 'prop-types';

const HomePin = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.25 13.1387V8.51367L12 6.68867L14.75 8.51367V13.1387H13.25V10.1387H10.75V13.1387H9.25ZM12 20.4137C14.2167 18.397 15.8542 16.5678 16.9125 14.9262C17.9708 13.2845 18.5 11.8387 18.5 10.5887C18.5 8.622 17.8708 7.01367 16.6125 5.76367C15.3542 4.51367 13.8167 3.88867 12 3.88867C10.1833 3.88867 8.64583 4.51367 7.3875 5.76367C6.12917 7.01367 5.5 8.622 5.5 10.5887C5.5 11.8387 6.04167 13.2845 7.125 14.9262C8.20833 16.5678 9.83333 18.397 12 20.4137ZM12 22.3887C9.31667 20.1053 7.3125 17.9845 5.9875 16.0262C4.6625 14.0678 4 12.2553 4 10.5887C4 8.08867 4.80417 6.09701 6.4125 4.61367C8.02083 3.13034 9.88333 2.38867 12 2.38867C14.1167 2.38867 15.9792 3.13034 17.5875 4.61367C19.1958 6.09701 20 8.08867 20 10.5887C20 12.2553 19.3375 14.0678 18.0125 16.0262C16.6875 17.9845 14.6833 20.1053 12 22.3887Z"
        fill={color}
      />
    </svg>
  );
};

HomePin.propTypes = {
  size: propTypes.number,
  color: propTypes.string,
};

HomePin.defaultProps = {
  size: 24,
  color: '#000',
};

export default HomePin;
