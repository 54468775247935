import gql from 'graphql-tag';
import {
  eventTimelineDeviceFragment,
  eventTimelinePlaceFragment,
  eventAttendantFragment,
  eventTimelineDwellerFragment,
} from './fragments';

export default gql`
  query eventTimelineDetail($eventId: ID!) {
    eventTimelineDetail(eventId: $eventId) {
      creationDate
      treatment
      treatmentEndDate
      additionalInformation
      device {
        ...eventTimelineDeviceParts
      }
      place {
        ...eventTimelinePlaceParts
      }
      attendant {
        ...eventAttendantParts
      }
      dweller {
        ...eventTimelineDwellerParts
      }
    }
  }
  ${eventTimelineDeviceFragment}
  ${eventTimelinePlaceFragment}
  ${eventAttendantFragment}
  ${eventTimelineDwellerFragment}
`;
