import { createContext } from 'react';
import { runtimeConfig } from '@kiper/fns';
import { get, types } from '@kiper/cookie';

const kiperAccount = get(types.authorization);

const initialFlags = {
  error: null,
  submitting: false,
  sent: false,
  reseted: false,
};

const initialState = {
  token: kiperAccount,
  redirectUri: runtimeConfig.RAZZLE_ACCOUNT_REDIRECT_TO,
  error: null,
  ...initialFlags,
};

const authContext = createContext(initialState);

export default authContext;

export { initialState, initialFlags };
