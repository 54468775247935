import { useRef } from 'react';

export default function useDebounce({ delay = 0 } = {}) {
  const timeoutId = useRef();

  return (cb, delayOverride) =>
    new Promise(resolve => {
      clearTimeout(timeoutId.current);
      timeoutId.current = setTimeout(
        () => resolve(cb()),
        delayOverride !== undefined ? delayOverride : delay,
      );
    });
}
