import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getHours, getMinutes, setTime } from '@kiper/fns';

import Time from '../Time';
import { DatePicker, Flex } from './styles';

const DateTimePicker = ({ value, onChange, invalid, ...props }) => {
  const [dateTimeValue, setDateTimeValue] = useState({ date: null, time: '' });
  const [selfChange, setSelfChange] = useState(false);

  useEffect(() => {
    if (selfChange) setSelfChange(false);
    else if (!value) setDateTimeValue({ date: null, time: '' });
    else {
      const hour = String(getHours(value)).padStart(2, '0');
      const min = String(getMinutes(value)).padStart(2, '0');
      const time = `${hour}:${min}`;

      setDateTimeValue({ date: value, time });
    }
  }, [value]);

  const getDate = ({ date, time }) => {
    const [hours, minutes] = time.split(':');

    let dateValue = null;

    if (hours?.length && minutes?.length && date) {
      dateValue = setTime(date, { hours, minutes });
    }

    return dateValue;
  };

  const handleChange = ({ type, data }) => {
    const newValues = { ...dateTimeValue, [type]: data };

    if (type === 'date' && !data) newValues.time = '';

    setDateTimeValue(newValues);

    if (onChange) {
      const date = getDate(newValues);
      setSelfChange(true);
      onChange(date);
    }
  };

  return (
    <Flex p="0" flexDirection="row" {...props}>
      <DatePicker
        value={dateTimeValue.date}
        invalid={invalid}
        onChange={data => handleChange({ type: 'date', data })}
      />
      <Time
        value={dateTimeValue.time}
        invalid={invalid}
        onChange={data => handleChange({ type: 'time', data })}
      />
    </Flex>
  );
};

DateTimePicker.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  onChange: PropTypes.func,
  invalid: PropTypes.any,
};

DateTimePicker.defaultProps = {
  value: '',
  onChange: undefined,
  invalid: null,
};

export default DateTimePicker;
