import propTypes from 'prop-types';
import { CardBody, Card } from 'reactstrap';
import { FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import { Title, Subtitle } from '../Login/styles';
import ResetPasswordForm from './ResetPasswordForm';
import Container from './styles';

const ResetPassword = ({ formikBag }) => {
  const [t] = useTranslation('reset-password');
  return (
    <FormikProvider value={formikBag}>
      <Container>
        <Card>
          <CardBody>
            <Title>{t('title')}</Title>
            <Subtitle>{t('subtitle')}</Subtitle>
            <ResetPasswordForm />
          </CardBody>
        </Card>
      </Container>
    </FormikProvider>
  );
};

export default ResetPassword;

ResetPassword.propTypes = {
  formikBag: propTypes.object.isRequired,
};
