import { useEffect, useMemo, useState } from 'react';
import { porterDeliveryStepNames } from '../../../constants';
import DepositPackageSteps from '../../../components/DepositPackageSteps';
import { context as Context } from '.';

const DepositPackageProvider = props => {
  const [step, setStep] = useState(0);
  const [uuidCondominium, setUuidCondominium] = useState(null);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState({ withMask: '', raw: '' });
  const [unity, setUnity] = useState(null);
  const [groupUnity, setGroupUnity] = useState(null);
  const [uuidDelivery, setUuidDelivery] = useState(null);
  const [condominiumInfo, setCondominiumInfo] = useState({});
  const [groupUnities, setGroupUnities] = useState([]);
  const [isDeliveryToCondominium, setIsDeliveryToCondominium] = useState(false);

  const handleResetStep = () => {
    setStep(porterDeliveryStepNames.welcome);
  };

  const handleSetStep = value => {
    setStep(value);
  };

  const handleBackStep = () => {
    setIsDeliveryToCondominium(false);

    let to = step - 1;
    if (
      (step === porterDeliveryStepNames.unity && groupUnities?.length === 1) ||
      (groupUnities?.length > 1 && isDeliveryToCondominium)
    ) {
      to = step - 2;
    }
    setStep(to);
  };

  const handleNextStep = () => {
    let to = step + 1;
    if (
      step === porterDeliveryStepNames.phoneNumber &&
      groupUnities?.length === 1
    ) {
      to = step + 2;
    }
    setStep(to);
  };

  const deliveryToCondominium = () => {
    const condominiumDeliveryState = { id: null, name: '' };
    setUnity(condominiumDeliveryState);
    setGroupUnity(null);
    setIsDeliveryToCondominium(true);
    setStep(porterDeliveryStepNames.openDrawer);
  };

  const setSingleUnityGroup = () =>
    setGroupUnity(condominiumInfo.getCondominiumInfo.unityGroup[0]);

  useEffect(() => {
    if (condominiumInfo && condominiumInfo.getCondominiumInfo) {
      setGroupUnities(condominiumInfo.getCondominiumInfo.unityGroup);
      if (condominiumInfo.getCondominiumInfo.unityGroup.length >= 1)
        setSingleUnityGroup();
    }
  }, [condominiumInfo]);

  useEffect(() => {
    try {
      const phoneSavedValue = localStorage.getItem('phone');

      if (!phoneSavedValue) {
        handleSetStep(porterDeliveryStepNames.welcome);
        return;
      }

      const parsedPhoneValue = JSON.parse(phoneSavedValue);

      if (
        typeof parsedPhoneValue === 'object' &&
        parsedPhoneValue !== null &&
        'withMask' in parsedPhoneValue &&
        'raw' in parsedPhoneValue
      ) {
        setPhoneNumber(parsedPhoneValue);
        handleSetStep(porterDeliveryStepNames.welcome);
        return;
      }

      handleSetStep(porterDeliveryStepNames.welcome);
    } catch {
      handleSetStep(porterDeliveryStepNames.welcome);
    }

    handleSetStep(porterDeliveryStepNames.welcome);
  }, []);

  const values = useMemo(
    () => ({
      handleSetStep,
      handleNextStep,
      handleBackStep,
      handleResetStep,
      deliveryToCondominium,
      setSingleUnityGroup,
      setLoading,
      setUnity,
      unity,
      setPhoneNumber,
      phoneNumber,
      setUuidDelivery,
      uuidDelivery,
      loading,
      setStep,
      step,
      setCondominiumInfo,
      condominiumInfo,
      groupUnity,
      setGroupUnity,
      groupUnities,
      uuidCondominium,
      setUuidCondominium,
    }),
    [step, phoneNumber, unity, uuidDelivery, groupUnity, groupUnities],
  );

  return (
    <Context.Provider value={values}>
      <DepositPackageSteps {...props} />
    </Context.Provider>
  );
};

export default DepositPackageProvider;
