import styled from 'styled-components';
import {
  CheckBox,
  Flex,
  KiperButton,
  Modal as modal,
  Label as label,
  Text,
} from '@kiper/ui';
import * as webcam from 'react-webcam';

export const Modal = styled(modal)`
  @media (min-width: 320px) and (max-width: 480px) {
    margin: auto;
    height: 100%;
    width: 100%;
  }

  @media (min-width: 600px) {
    max-width: 332px;
  }
`;

export const FormFacialConfirm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

export const Container = styled(Flex)`
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

export const WebCam = styled(webcam)`
  border-radius: 8px;
  height: 400px;
  width: 300px;
`;

export const WebCamContainer = styled(Flex)`
  height: 400px;
  width: 300px;
`;

export const Button = styled(KiperButton)`
  padding: 13px;
  font-size: 14px;
`;
export const Img = styled.img`
  border-radius: 8px;
`;

export const ButtonGroup = styled(Flex)`
  width: 100%;
  justify-content: space-between;
`;

export const Checkbox = styled(CheckBox)`
  width: 15px;
  height: 15px;
  accent-color: ${({ theme }) => theme.colors.invitePorterPrimary};
`;

export const Label = styled(label)`
  margin: 0;
  color: ${({ theme }) => theme.colors.invitePorterPrimary};
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
`;

export const CheckboxContainer = styled(Flex)`
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 22px;
`;

export const Description = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: ${({ theme }) => theme.colors.highBlack};
`;
