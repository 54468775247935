import authRoutes from './Auth';
import inviteRoutes from './Invite';
import productHubRoutes from './ProductHub';
import activateRoutes from './Activate';
import packageDeliveryRoutes from './PackageDelivery';
import Root from '../components/Root';

export default [
  {
    component: Root,
    routes: [
      ...authRoutes,
      ...inviteRoutes,
      ...productHubRoutes,
      ...activateRoutes,
      ...packageDeliveryRoutes,
    ],
  },
];
