import gql from 'graphql-tag';

export const camerasInstall = gql`
  mutation camerasInstall(
    $mosaic: CameraMosaicInput!
    $treeNodeId: Int!
    $includes: Includes!
  ) {
    camerasInstall(
      mosaic: $mosaic
      treeNodeId: $treeNodeId
      includes: $includes
    ) {
      id
      name
      placeId
      treeNodeId
      placeType {
        id
        name
      }
    }
  }
`;

export const updateMosaic = gql`
  mutation updateMosaic($mosaic: CameraMosaicInput, $treeNodeId: Int!) {
    updateMosaic(mosaic: $mosaic, treeNodeId: $treeNodeId)
  }
`;
