import ActivateKeyPage from './ActivateKey';

export default [
  {
    name: 'Activate key',
    path: '/activate/:brandSlug',
    exact: true,
    component: ActivateKeyPage,
  },
];
