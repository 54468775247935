import {
  useRef,
  useCallback,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions } from '@kiper/hooks';
import context from '../context';
import faceMask from '../../../../assets/images/face-mask.png';
import * as S from './styles';

let videoConstraints;

const iosDimensions = {
  width: 480,
  height: 640,
  aspectRatio: 1.33,
};

const defaultDimensions = {
  width: 300,
  height: 400,
  aspectRatio: 1,
};

const mobileDimensions = {
  width: null,
  height: null,
  aspectRatio: null,
};

const prefix = 'porter-summit-invite.modal.face-capture';

const StepFaceCapture = () => {
  const {
    setPhotoUrl,
    handleChangeStep,
    permissionsCamera,
    setUserMediaError,
    handleResetStep,
  } = useContext(context);

  const { requestPermission } = permissionsCamera;

  const { t } = useTranslation('invite');
  const { width: windowsWidth } = useWindowDimensions();

  const [widthValue, setWidthValue] = useState(300);
  const [heightValue, setHeightValue] = useState(400);
  const [aspectValue, setAspectValue] = useState(1);

  const webcamRef = useRef(null);

  useEffect(() => {
    requestPermission({
      video: true,
    });
  }, []);

  const capturePhoto = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPhotoUrl(imageSrc);
    handleChangeStep(1);
  }, [webcamRef]);

  videoConstraints = {
    facingMode: 'user',
    width: widthValue,
    height: heightValue,
    aspectRatio: aspectValue,
  };

  const isIOS = useMemo(() => {
    const userAgent = navigator?.userAgent || window?.opera;
    return /iPad|iPhone|iPod/i.test(userAgent);
  }, []);

  const handleDimension = ({ width, height, aspectRatio }) => {
    setHeightValue(height);
    setWidthValue(width);
    setAspectValue(aspectRatio);
  };

  useEffect(() => {
    if (isIOS) {
      handleDimension(iosDimensions);
    } else if (windowsWidth <= 1024) {
      handleDimension(mobileDimensions);
    } else {
      handleDimension(defaultDimensions);
    }
  }, [isIOS, windowsWidth]);

  const handleUserMediaError = () => {
    setUserMediaError(true);
    handleResetStep();
  };

  return (
    <>
      <S.Title>{t(`${prefix}.title`)}</S.Title>
      <S.WebCamContainer mask={faceMask}>
        <S.WebCam
          ref={webcamRef}
          audio={false}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          mirrored
          mask={faceMask}
          screenshotQuality={1}
          onUserMediaError={handleUserMediaError}
        />
      </S.WebCamContainer>
      <S.ButtonTakePhoto
        variant="contained"
        block
        centered
        onClick={capturePhoto}
      >
        {t(`${prefix}.photograph`)}
      </S.ButtonTakePhoto>
    </>
  );
};

export default StepFaceCapture;
