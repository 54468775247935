import { useCallback, useRef, useState } from 'react';
import propTypes from 'prop-types';
import { MdOutlineEdit } from 'react-icons/md';
import * as S from './styles';

const InlineEditInput = ({ name, onChange, value, ...props }) => {
  const inputRef = useRef(null);
  const [editInlineMode, setEditInlineMode] = useState(false);
  const [backupInputValue, setBackupInputValue] = useState(value);

  const handleChange = useCallback(
    event => {
      onChange(event.target.name, event.target.value);
    },
    [onChange],
  );

  const handleKeyDown = event => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      event.target.blur();
    }
  };

  const handleFocus = useCallback(() => {
    setEditInlineMode(true);
    if (inputRef?.current) inputRef.current.focus();
  }, [inputRef?.current]);

  const handleBlur = event => {
    if (event.target.value.trim() === '')
      onChange(event.target.name, backupInputValue.trim());
    else {
      onChange(event.target.name, event.target.value.trim());
      setBackupInputValue(event.target.value);
    }
    setEditInlineMode(false);
  };

  return (
    <S.Wrapper>
      <S.InputGroup editInlineMode={editInlineMode} onClick={handleFocus}>
        {editInlineMode ? (
          <S.Input
            autoFocus
            ref={inputRef}
            aria-label={name}
            {...props}
            type="text"
            value={value}
            onChange={handleChange}
            name={name}
            id={name}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <>
            <S.InputValue>{value}</S.InputValue>
            <S.InputIcon>
              <MdOutlineEdit size={16} />
            </S.InputIcon>
          </>
        )}
      </S.InputGroup>
    </S.Wrapper>
  );
};

export default InlineEditInput;

InlineEditInput.propTypes = {
  onChange: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  value: propTypes.string.isRequired,
};

InlineEditInput.defaultProps = {};
