import { useMemo, useState } from 'react';
import { usePermission } from '@kiper/hooks';
import Context from './index';
import FacialInvitationPorterSummitModal from '../FacialInvitationPorterSummitModal';

const FacialInvitationPorterSummitProvider = props => {
  const permissionsCamera = usePermission('camera');

  const [photoUrl, setPhotoUrl] = useState(null);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userMediaError, setUserMediaError] = useState(false);

  const handleResetStep = () => {
    setStep(0);
  };

  const handleChangeStep = value => {
    setStep(x => x + value);
  };

  const value = useMemo(
    () => ({
      photoUrl,
      setPhotoUrl,
      step,
      handleResetStep,
      handleChangeStep,
      loading,
      setLoading,
      permissionsCamera,
      userMediaError,
      setUserMediaError,
    }),
    [photoUrl, step, userMediaError, loading, permissionsCamera],
  );

  return (
    <Context.Provider value={value}>
      <FacialInvitationPorterSummitModal {...props} />
    </Context.Provider>
  );
};

export default FacialInvitationPorterSummitProvider;
