import gql from 'graphql-tag';

export const unityCreate = gql`
  mutation createUnity($unity: CreateUnityInput!) {
    createUnity(unity: $unity)
  }
`;

export const unityUpdate = gql`
  mutation updateUnity($unity: UpdateUnityInput!, $unityId: Int!) {
    updateUnity(unity: $unity, unityId: $unityId)
  }
`;

export const unityDelete = gql`
  mutation deleteUnity($unityId: Int!) {
    deleteUnity(unityId: $unityId)
  }
`;
