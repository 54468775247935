import gql from 'graphql-tag';

export const eventTemplates = gql`
  query eventTemplates {
    eventTemplates {
      eventType
      description
      template
      id
      creationDate
    }
  }
`;
