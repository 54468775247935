import propTypes from 'prop-types';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Container, Th, SortContainer, Label } from './styles';

const SortIcon = ({ sort, column }) => {
  if (sort.orderby !== column.order) return null;

  return sort.ordination ? <ChevronUp size={16} /> : <ChevronDown size={16} />;
};

const Table = ({ columns, sort, children, striped, ...props }) => {
  const $columns = (columns && columns.current) || columns;

  const handleSort = column => {
    if (!sort && !sort?.handleSort && !column?.order) return;
    sort.handleSort(column.order, column.type);
  };

  return (
    <Container striped={striped} {...props}>
      {$columns && (
        <thead>
          <tr>
            {$columns.map(column => (
              <Th
                key={column.name + crypto.randomUUID()}
                width={column.width}
                align={column.align}
                onClick={handleSort}
              >
                {sort && column.order ? (
                  <SortContainer>
                    <Label>{column.name}</Label>
                    <SortIcon sort={sort} column={column} />
                  </SortContainer>
                ) : (
                  <Label>{column.name}</Label>
                )}
              </Th>
            ))}
          </tr>
        </thead>
      )}
      {children}
    </Container>
  );
};

export default Table;

Table.propTypes = {
  sort: propTypes.shape({
    handleSort: propTypes.func,
    ordination: propTypes.bool,
    orderby: propTypes.string,
  }),
  columns: propTypes.array.isRequired,
  children: propTypes.node.isRequired,
  striped: propTypes.bool,
};

SortIcon.propTypes = {
  sort: Table.propTypes.sort.isRequired,
  column: propTypes.object.isRequired,
};

Table.defaultProps = {
  sort: {
    handleSort: () => null,
    ordination: false,
    orderby: '',
  },
  striped: false,
};
