import propTypes from 'prop-types';
import * as S from './styles';

function ProductCard({ title, iconUrl, onClick }) {
  return (
    <S.Button onClick={onClick}>
      <S.TitleProduct>{title}</S.TitleProduct>
      <S.Icon src={iconUrl} alt={title} name={title} size={80} round="8px" />
    </S.Button>
  );
}

export default ProductCard;

ProductCard.propTypes = {
  title: propTypes.string.isRequired,
  iconUrl: propTypes.string.isRequired,
  onClick: propTypes.func.isRequired,
};
