import styled from 'styled-components';
import { Breadcrumb as breadcrumb, BreadcrumbItem as item } from 'reactstrap';

export const Breadcrumb = styled(breadcrumb)`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  color: ${props => props.theme.colors.breadcrumb};

  ol.breadcrumb {
    background: ${props =>
      props.background
        ? props.theme.colors[props.background]
        : props.theme.colorContainer};
    padding: 0;
    margin-bottom: 8px;
  }
`;

export const BreadcrumbItem = styled(item)`
  color: ${props => props.theme.colors.breadcrumb};

  & + &::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: ${props => props.theme.colors.breadcrumb};
    content: '>';
  }

  a {
    color: ${props => props.theme.colors.breadcrumb};
    &:hover {
      color: ${props => props.theme.colors.primary500};
    }
  }
`;

export default Breadcrumb;
