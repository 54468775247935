import { useContext, useMemo } from 'react';
import propTypes from 'prop-types';
import { Form, FormFeedback, Label, FormGroup, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FaSpinner } from 'react-icons/fa';
import { Alert } from '@kiper/ui';
import {
  Title,
  Subtitle,
  ButtonSubmit,
  Col,
  ButtonLink,
  AlertIcon,
} from './styles';
import authContext from '../../../services/auth/context';

const LoginForgot = ({
  handleSubmit,
  handleChange,
  setCurrentStep,
  values,
  touched,
  steps,
  errors,
  isSubmitting,
}) => {
  const [t] = useTranslation('login');
  const auth = useContext(authContext);

  const goBackLogin = () => {
    setCurrentStep(steps.password);

    auth.goBackLogin();
  };

  const showAlert = useMemo(
    () => ({
      sent: !auth.submitting && auth.sent,
      error: !auth.submitting && auth.error,
    }),
    [auth],
  );

  return (
    <Col>
      <Title>{t('forgot.title')}</Title>
      <Subtitle>{t('forgot.subtitle')}</Subtitle>
      <br />
      <Form onSubmit={handleSubmit} noValidate>
        <FormGroup>
          <Label htmlFor="email">{t('forgot.label')}</Label>
          <Input
            type="email"
            id="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            required
            invalid={errors.email && touched.email}
          />
          <FormFeedback>{t('errors.email')}</FormFeedback>
        </FormGroup>
        {showAlert.sent && (
          <Alert
            background="infoPrimary"
            icon={AlertIcon}
            text={t('forgot.success')}
          />
        )}
        {showAlert.error && (
          <Alert
            background="dangerSecondary"
            icon={AlertIcon}
            text={auth.error[0]}
          />
        )}
        <ButtonSubmit
          block
          color="primary"
          type="submit"
          loading={isSubmitting ? 1 : 0}
        >
          {t('forgot.submit')}
          {isSubmitting && <FaSpinner color="#FFF" size={14} />}
        </ButtonSubmit>
        <ButtonLink block color="link" onClick={goBackLogin}>
          {t('back-to-login')}
        </ButtonLink>
      </Form>
    </Col>
  );
};

export default LoginForgot;

LoginForgot.propTypes = {
  setCurrentStep: propTypes.func.isRequired,
  handleSubmit: propTypes.func.isRequired,
  handleChange: propTypes.func.isRequired,
  values: propTypes.object.isRequired,
  touched: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
  steps: propTypes.object.isRequired,
  isSubmitting: propTypes.bool.isRequired,
};
