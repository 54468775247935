import propTypes from 'prop-types';
import { ErrorBoundary as ErrorBoundaryWrapper } from 'react-error-boundary';
import CustomErrorBoundaryFallback from './CustomErrorBoundaryFallback';
import {
  defaultSettingsFallbackComponent,
  defaultSettingsWithErrorBoundary,
} from './paramsDefault';

const fallbackComponent = ({ error, ErrorComponent }) => {
  const { errorProps, t } = defaultSettingsFallbackComponent;

  return ErrorComponent ? (
    <ErrorComponent error={error} />
  ) : (
    <CustomErrorBoundaryFallback
      error={error}
      errorMessageProps={{
        defaultTitleMessage: t(errorProps?.defaultTitleMessage),
        customErrorMessage: t(errorProps?.customErrorMessage),
      }}
    />
  );
};

const ErrorBoundary = ({ children, ...props }) => (
  <ErrorBoundaryWrapper
    FallbackComponent={({ error }) => fallbackComponent({ error, ...props })}
  >
    {children}
  </ErrorBoundaryWrapper>
);

ErrorBoundary.propTypes = {
  children: propTypes.node.isRequired,
};

function withErrorBoundary(
  WrappedComponent,
  {
    i18n,
    translationKey,
    defaultTitleMessage,
    customErrorMessage,
    CustomErrorComponent,
  } = { ...defaultSettingsWithErrorBoundary },
) {
  // eslint-disable-next-line react/display-name
  return ({ ...props }) => {
    const [t] = i18n?.useTranslation(translationKey) || [null];
    return (
      <ErrorBoundary
        t={t || (() => null)}
        ErrorComponent={CustomErrorComponent}
        errorProps={{ defaultTitleMessage, customErrorMessage }}
      >
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
  };
}

export { withErrorBoundary };
export default ErrorBoundary;
