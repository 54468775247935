import gql from 'graphql-tag';

export const getDevice = gql`
  query device($deviceId: Int!) {
    device(deviceId: $deviceId) {
      id
      name
      placeId
      deviceTypeId
      parentDeviceId
      serialNumber
      ip
      isOnline
      updateStatus
      serializedParams
      creationDate
    }
  }
`;

export const deviceHomologatedFirmwares = gql`
  query deviceHomologatedFirmwares($deviceId: Int!) {
    deviceHomologatedFirmwares(deviceId: $deviceId) {
      version
      capabilities
      id
      creationDate
    }
  }
`;

export const deviceHomologatedModels = gql`
  query deviceHomologatedModels($deviceTypeId: Int!) {
    deviceHomologatedModels(deviceTypeId: $deviceTypeId) {
      deviceTypeId
      name
      fieldName
      capabilities
      id
      creationDate
    }
  }
`;

export const getRelayStatusCommand = gql`
  query relayStatusCommand($eventId: ID!, $deviceId: ID!) {
    relayStatusCommand(eventId: $eventId, deviceId: $deviceId) {
      pgms {
        number
        status
      }
    }
  }
`;

export const getDeviceFormParams = gql`
  query deviceFormParams($deviceId: Int!) {
    deviceFormParams(deviceId: $deviceId) {
      warning
      messageWarning
      sections {
        sectionName
        values {
          name
          deviceValue
          referenceValue
          options {
            label
            value
          }
          constraints {
            min
            max
            step
          }
          parameterId
          type
          unit
          warning
        }
      }
    }
  }
`;
