import gql from 'graphql-tag';

export const generateReportXls = gql`
  mutation generateReportXls(
    $reportId: Int!
    $isCustom: Boolean
    $filters: String
  ) {
    generateReportXls(
      reportId: $reportId
      isCustom: $isCustom
      filters: $filters
    ) {
      data
      contentType
      contentDisposition
    }
  }
`;

export const generateReportCsv = gql`
  mutation generateReportCsv(
    $reportId: Int!
    $isCustom: Boolean
    $filters: String
  ) {
    generateReportCsv(
      reportId: $reportId
      isCustom: $isCustom
      filters: $filters
    ) {
      data
      contentType
      contentDisposition
    }
  }
`;
