export const getEventSelect = async ({ onlyAvaliable, $http }) => {
  const res = await $http.get('/eventTypeSelect', {
    params: {
      onlyAvaliable,
    },
  });

  return res.data;
};

export const actions = async ({ $http }) => {
  const res = await $http.get('/eventToAction/actions');

  return res.data;
};

export const list = async ({ search, $http }) => {
  let params = {};

  if (search) {
    if (!Number.isNaN(Number(search))) params.eventId = search;
    else {
      params = {
        eventName: search,
        actionName: search,
      };
    }
  }

  const res = await $http.get('/eventToAction', {
    params,
  });

  if (res.status === 204) return [];

  return res.data;
};

export const get = async ({ eventId, $http }) => {
  const res = await $http.get(`/eventToAction/${eventId}`);

  return res.data;
};

export const create = async ({ eventAction, $http }) => {
  const res = await $http.post('/eventToAction', eventAction);

  return res.data;
};

export const update = async ({ eventAction, $http }) => {
  const res = await $http.put('/eventToAction', eventAction);

  return res.data;
};

export const remove = async ({ eventId, $http }) => {
  const res = await $http.delete(`/eventToAction/${eventId}`);

  return res.status === 200;
};
