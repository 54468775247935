import styled, { css } from 'styled-components';
import {
  space,
  color,
  fontFamily,
  fontSize,
  fontWeight,
  textAlign,
  display,
  fontStyle,
  maxHeight,
  lineHeight,
  opacity,
  width,
  borderRadius,
} from 'styled-system';

export const Text = styled.span`
  a {
    ${color};
    text-decoration: none;
  }

  ${opacity}
  ${fontStyle}
  ${maxHeight}
  ${display}
  ${fontSize}
  ${space};
  ${color};
  ${fontFamily};
  ${fontWeight};
  ${textAlign};
  ${fontFamily};
  ${lineHeight};
  ${width};
  ${borderRadius}

  word-break: ${({ wordBreak }) => wordBreak ?? 'normal'};

  ${props =>
    props.textTransform &&
    css`
      text-transform: ${props.textTransform};
    `};

  ${props =>
    props.truncate &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `};

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `};

    ${props =>
      props.textDecoration &&
      css`
        text-decoration: ${props.textDecoration};
      `};
`;
