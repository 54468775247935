/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import accessRfFragment from '../shared/accessRfFragment';

export const accessRf = gql`
  query accessRf($deviceId: Int!) {
    accessRf(deviceId: $deviceId) {
      ...accessRf_Part
    }
  }

  ${accessRfFragment}
`;

export const sync = gql`
  mutation accessRfSync($deviceId: Int!) {
    accessRfSync(deviceId: $deviceId) {
      ...accessRf_Part
    }
  }

  ${accessRfFragment}
`;
