import gql from 'graphql-tag';

export const accessFingerprintDeviceFragment = gql`
  fragment accessFingerprintDeviceParts on AccessFingerprintDevice {
    id
    name
    model
  }
`;

export const accessFingerprintPlacesV2Fragment = gql`
  fragment accessFingerprintPlacesV2Parts on AccessFingerprintPlacesV2 {
    id
    name
    devices {
      ...accessFingerprintDeviceParts
    }
  }
  ${accessFingerprintDeviceFragment}
`;

// TODO: Remover accessFingerprintPlaceFragment
export const accessFingerprintPlaceFragment = gql`
  fragment accessFingerprintPlaceParts on AccessFingerprintPlace {
    id
    name
    fpTerminalId
    fpTerminalName
  }
`;

// TODO: Remover accessFingerprintCondominiumFragment
export const accessFingerprintCondominiumFragment = gql`
  fragment accessFingerprintCondominiumParts on AccessFingerprintCondominium {
    id
    name
    place {
      ...accessFingerprintPlaceParts
    }
  }
  ${accessFingerprintPlaceFragment}
`;

export const accessFingerprintCondominiumV2Fragment = gql`
  fragment accessFingerprintCondominiumV2Parts on AccessFingerprintCondominiumV2 {
    condominiumId
    condominiumName
    places {
      ...accessFingerprintPlacesV2Parts
    }
  }
  ${accessFingerprintPlacesV2Fragment}
`;
