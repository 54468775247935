import propTypes from 'prop-types';
import * as S from './styles';

const GroupUnityButton = ({ onClick, icon, children }) => {
  return (
    <S.ButtonWrapper onClick={onClick}>
      {icon}
      {children}
    </S.ButtonWrapper>
  );
};

export default GroupUnityButton;

GroupUnityButton.propTypes = {
  icon: propTypes.element.isRequired,
  onClick: propTypes.func.isRequired,
  children: propTypes.any.isRequired,
};
