import gql from 'graphql-tag';

export const inviteAccessProfileFragment = gql`
  fragment inviteAccessProfileParts on AccessProfile {
    id
    name
    description
  }
`;

export const inviteHostFragment = gql`
  fragment inviteHostParts on Host {
    id
    name
    email
  }
`;

export const inviteCondominiumFragment = gql`
  fragment inviteCondominiumParts on Condominium {
    id
    name
  }
`;

export const invitedFragment = gql`
  fragment invitedFragmentParts on Invited {
    id
    name
    accessStatus
  }
`;

export const inviteFragment = gql`
  fragment inviteParts on Invite {
    id
    name
    start
    end
    isFreeAccess
    accessProfiles {
      ...inviteAccessProfileParts
    }
    host {
      ...inviteHostParts
    }
    condominium {
      ...inviteCondominiumParts
    }
    inviteds {
      ...invitedFragmentParts
    }
  }
  ${inviteAccessProfileFragment}
  ${inviteHostFragment}
  ${inviteCondominiumFragment}
  ${invitedFragment}
`;

export const invitePaginationFragment = gql`
  fragment invitePaginationParts on Pagination {
    page
    totalPages
    pageSize
    totalResults
    hasPrevious
    hasNext
  }
`;

export const inviteListResponseFragment = gql`
  fragment inviteListResponseParts on InviteListResponse {
    collection {
      ...inviteParts
    }
    pagination {
      ...invitePaginationParts
    }
  }
  ${inviteFragment}
  ${invitePaginationFragment}
`;
