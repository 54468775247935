import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
  background-color: #fff;
  border-radius: 8px;
  gap: 8px;
`;

export const WrapperLink = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: background-color 0.5 ease-in-out;
  border: none;

  &:focus {
    outline: 0;
  }

  ${({ active, tabsLength }) => `
    width: ${100 / tabsLength}%;
    background-color: ${active ? '#374AA0' : '#FFF'};
    color: ${active ? '#FFF' : '#000'};
  `}
`;
