import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import context from '../context';
import facialLoadingSvg from '../../../../assets/images/facial-capture-loading-base.svg';
import facialLoadingBlueSvg from '../../../../assets/images/facial-capture-loading-blue.svg';
import facialLoadingWhiteSvg from '../../../../assets/images/facial-capture-loading-white.svg';

import * as S from './styles';

const prefix = 'face-invitation.loading';

const StepLoading = () => {
  const { loading, handleChangeStep } = useContext(context);
  const { t } = useTranslation('invite');

  useEffect(() => {
    if (!loading) {
      handleChangeStep(1);
    }
  }, [loading]);

  return (
    <S.StepLoadingContainer>
      <S.FacialIconContainer>
        <S.LoadingBaseImage src={facialLoadingSvg} alt="facial-loading-svg">
          <S.LoadingWhiteIcon
            src={facialLoadingWhiteSvg}
            alt="facial-loading-white"
          />
          <S.LoadingBlueIcon
            src={facialLoadingBlueSvg}
            alt="facial-loading-blue"
          />
        </S.LoadingBaseImage>
      </S.FacialIconContainer>
      <S.Description>{t(`${prefix}.sending-photo`)}</S.Description>
    </S.StepLoadingContainer>
  );
};

export default StepLoading;
