/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import { responseFacialIdentificationFragment } from './fragments';

export const registerFacialIdentification = gql`
  mutation registerFacialIdentification($personId: Int!, $deviceId: Int!) {
    registerFacialIdentification(personId: $personId, deviceId: $deviceId) {
      ...responseFacialIdentificationParts
    }
  }
  ${responseFacialIdentificationFragment}
`;

export const accessFacialRemove = gql`
  mutation accessFacialRemove($deviceId: Int!) {
    accessFacialRemove(deviceId: $deviceId)
  }
`;
