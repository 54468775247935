import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyledReactDatePicker } from './styles';
import './styles.css';

const DatePicker = ({ value, onChange, invalid, ...props }) => {
  const [dateValue, setDateValue] = useState(value);

  useEffect(() => {
    setDateValue(value);
  }, [value]);

  const handleChange = eventValue => {
    setDateValue(eventValue);
    if (onChange) onChange(eventValue);
  };

  return (
    <StyledReactDatePicker
      {...props}
      invalid={+invalid}
      className="form-control date-picker"
      value={dateValue}
      onChange={handleChange}
    />
  );
};

DatePicker.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  onChange: PropTypes.func,
  invalid: PropTypes.any,
};

DatePicker.defaultProps = {
  value: '',
  onChange: undefined,
  invalid: null,
};

export default DatePicker;
