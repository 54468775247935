/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import { accessFacialCondominiumFragment } from './fragments';

export const accessFacialCondominiums = gql`
  query accessFacialCondominiums($personId: Int!) {
    accessFacialCondominiums(personId: $personId) {
      ...accessFacialCondominiumParts
    }
  }
  ${accessFacialCondominiumFragment}
`;
