import { authenticate } from '@kiper/fns';
import { getEventSelect, list, actions, get } from './events.manager';

const getEvent = authenticate(({ args, context }) =>
  getEventSelect({ ...args, ...context }),
);

export const eventSelect = getEvent;

export const eventCategorySelect = getEvent;

export const eventsToAction = authenticate(({ args, context }) =>
  list({ ...args, ...context }),
);

export const eventToAction = authenticate(({ args, context }) =>
  get({ ...args, ...context }),
);

export const actionSelect = authenticate(({ args, context }) =>
  actions({ ...args, ...context }),
);
