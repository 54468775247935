import gql from 'graphql-tag';

export const mosaicSelect = gql`
  query MosaicSelect {
    mosaicSelect {
      screenViewName
      screenStyleId
      vmsId
      vmsName
    }
  }
`;

export const monitorSelect = gql`
  query monitorSelect {
    monitorSelect {
      label
      value
    }
  }
`;

export const cameraLive = gql`
  query cameraLive(
    $cameraId: String
    $startDateTime: String
    $condominiumPersonContextId: Int
  ) {
    cameraLive(
      cameraId: $cameraId
      startDateTime: $startDateTime
      condominiumPersonContextId: $condominiumPersonContextId
    ) {
      url
    }
  }
`;

export const cameraThumbnail = gql`
  query cameraThumbnail(
    $cameraId: String
    $dateTime: String
    $condominiumPersonContextId: Int
  ) {
    cameraPlayerThumbnail: cameraThumbnail(
      cameraId: $cameraId
      dateTime: $dateTime
      condominiumPersonContextId: $condominiumPersonContextId
    ) {
      url
    }
  }
`;
