import { Fragment, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-apollo';
import propTypes from 'prop-types';
import { apolloErrorHandler } from '@kiper/fns';
import { formatDate } from '@kiper/fns/src/timezone';
import { useSwal } from '@kiper/hooks';
import { getDeliveryReceipt } from '@kiper/account-graphql/package-delivery/query';
import { Divider, Flex } from '@kiper/ui';
import { Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ErrorComponent } from './Error';
import logoPorter from '../../../assets/images/logo-porter.svg';
import cameraUnavailable from '../../../assets/images/camera-unavailable.svg';
import * as S from './styles';

const VIDEO_RESET_TIME = 30 * 1000;

const DeliveryReceiptPage = ({ match }) => {
  const uuid = match.params?.uuid ?? '';

  const imgCameras = useRef([cameraUnavailable, cameraUnavailable]);
  const timer = useRef(null);

  const { toast } = useSwal();

  const [cameras, setCameras] = useState([
    cameraUnavailable,
    cameraUnavailable,
  ]);

  const [t] = useTranslation('porter-delivery');

  const handleApolloError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (formattedErrors && formattedErrors.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  const { data, loading } = useQuery(getDeliveryReceipt, {
    fetchPolicy: 'cache-first',
    skip: !uuid,
    variables: { uuid },
    onError: err => handleApolloError(err),
  });

  const receiptData = data?.getDeliveryReceipt;

  const restartVideo = () => {
    setCameras(receiptData?.cameras);
    clearInterval(timer.current);
    timer.current = setInterval(() => restartVideo(), VIDEO_RESET_TIME);
  };

  useEffect(() => {
    if (receiptData?.cameras && receiptData?.cameras.length) {
      if (!timer?.current) setCameras(receiptData?.cameras);
      timer.current = setInterval(() => restartVideo(), VIDEO_RESET_TIME);
    }

    return () => clearInterval(timer.current);
  }, [receiptData, cameras, timer.current]);

  if (!uuid) return <ErrorComponent message={t('receipt.nonexistent')} />;

  if (loading)
    return (
      <S.SpinnerWrapper>
        <Spinner />
      </S.SpinnerWrapper>
    );

  if (!data) return <ErrorComponent message={t('receipt.nonexistent')} />;

  const unityName = receiptData?.unity?.group
    ? `${receiptData.unity.name}, ${receiptData.unity.group}`
    : `${receiptData.unity.name}`;

  const receiptForCondominiumDelivery =
    !receiptData?.unity?.name && !receiptData?.unity?.group;

  const infosDelivery = [
    {
      title: t('receipt.condominium'),
      description: receiptData.condominium.name,
    },
    {
      title: t('receipt.address'),
      description: `${receiptData?.condominium?.addressLine1}, ${receiptData?.condominium?.addressLine2}`,
    },
    {
      title: t('receipt.recipient'),
      description: receiptForCondominiumDelivery
        ? t('receipt.delivery-to-condominium')
        : unityName,
    },
    {
      title: t('receipt.date'),
      description: formatDate(receiptData.packageDeliveryDate, 'L - HH:mm')
        .formatted,
    },
  ];

  const infoDeliveryPerson = [
    {
      title: t('receipt.phone-number'),
      description: receiptData.deliveryPerson.phoneNumber,
    },
  ];

  return (
    <S.Wrapper>
      {receiptData && (
        <S.Content>
          <S.Header>
            <S.Logo src={logoPorter} alt="porter-logo" />
            <S.Title>{t('receipt.title')}</S.Title>
          </S.Header>
          <Divider />
          <S.SubTitle>{t('receipt.subtitle')}</S.SubTitle>
          <S.InfoWrapper>
            {infosDelivery.map(info => (
              <S.InfoLine key={info.title}>
                <S.InfoTitle>{info.title}</S.InfoTitle>
                <S.InfoDescription>{info.description}</S.InfoDescription>
              </S.InfoLine>
            ))}
          </S.InfoWrapper>
          <Divider />
          <S.SubTitle>{t('receipt.deliveryman-data')}</S.SubTitle>
          <S.InfoWrapper>
            {infoDeliveryPerson.map(info => (
              <S.InfoLine key={info.title}>
                <S.InfoTitle>{info.title}</S.InfoTitle>
                <S.InfoDescription>{info.description}</S.InfoDescription>
              </S.InfoLine>
            ))}
          </S.InfoWrapper>
          {imgCameras?.current.length ? (
            <>
              <Divider />
              <Flex
                gridGap="8px"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="flex-start"
                width="100%"
              >
                <S.SubTitle>{t('receipt.cameras')}</S.SubTitle>
                {!receiptData?.cameras?.length && (
                  <S.UnavailableCamerasDescription>
                    {t('receipt.unavailable-cameras')}
                  </S.UnavailableCamerasDescription>
                )}
              </Flex>
              <S.CamerasWrapper>
                {cameras.map((camera, index) => (
                  <Fragment key={`${camera}-${Math.random() * index}`}>
                    <S.ThumbnailVideo
                      src={camera}
                      alt={t('receipt.camera', { number: index + 1 })}
                      onClick={restartVideo}
                    />
                  </Fragment>
                ))}
              </S.CamerasWrapper>
            </>
          ) : (
            <Spinner />
          )}
          <Divider />
          <S.AuthCodeHash>
            {t('receipt.authentication-id', { uuid })}
          </S.AuthCodeHash>
        </S.Content>
      )}
    </S.Wrapper>
  );
};

export default DeliveryReceiptPage;

DeliveryReceiptPage.propTypes = {
  match: propTypes.shape({
    params: propTypes.shape({
      uuid: propTypes.string,
    }).isRequired,
  }).isRequired,
};
