import gql from 'graphql-tag';

export const lockerDeliveryFragment = gql`
  fragment lockerDeliveryParts on LockerDelivery {
    id
    depositDate
    unityGroupName
    unityName
    status
    withdrawalDate
    withdrawalCode
  }
`;

export const paginationFragment = gql`
  fragment paginationParts on Pagination {
    page
    totalPages
    pageSize
    totalResults
  }
`;
