import '@kiper/template/dashforge.scss';
import { ThemeProvider } from 'styled-components';
import Theme from '@kiper/theme';
import { renderRoutes } from 'react-router-config';

import pages from './pages';
import AuthProvider from './services/auth/provider';
import { FeatureFlagProvider, DEFAULT_FLAGS } from './services/feature-flag';

import './i18n';

const App = () => (
  <ThemeProvider theme={Theme}>
    <AuthProvider>
      <FeatureFlagProvider defaults={DEFAULT_FLAGS}>
        {renderRoutes(pages)}
      </FeatureFlagProvider>
    </AuthProvider>
  </ThemeProvider>
);

export default App;
