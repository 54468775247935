import propTypes from 'prop-types';
import ReactJson from 'react-json-view-ssr';
import { Container } from './styles';

function JsonTree({ values, handleChange, name }) {
  const onChange = e => {
    handleChange(e.updated_src);
  };

  return (
    <Container>
      <ReactJson
        src={values}
        onEdit={onChange}
        onAdd={onChange}
        onDelete={onChange}
        displayObjectSize
        displayDataTypes
        enableClipboard={false}
        collapsed={false}
        name={name}
        iconStyle="triangle"
      />
    </Container>
  );
}

export default JsonTree;

JsonTree.propTypes = {
  values: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  name: propTypes.string,
};

JsonTree.defaultProps = {
  name: 'messagetemplate',
};
