import gql from 'graphql-tag';

export const approvePreRegister = gql`
  mutation approvePreRegister($preRegisterId: ID!) {
    approvePreRegister(preRegisterId: $preRegisterId)
  }
`;

export const denyPreRegister = gql`
  mutation denyPreRegister($preRegisterId: ID!) {
    denyPreRegister(preRegisterId: $preRegisterId)
  }
`;
