const KiperSvg = () => {
  return (
    <svg
      width="720"
      height="800"
      viewBox="0 0 720 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="24" y="77" width="673" height="646" rx="323" fill="white" />
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.6712 173.876L320.277 9.55737H320.297C331.148 3.30001 343.453 0.00610352 355.979 0.00610352C368.504 0.00610352 380.81 3.30001 391.66 9.55737L676.245 173.876C687.094 180.135 696.105 189.14 702.37 199.986C708.635 210.831 711.935 223.135 711.937 235.661V564.318C711.937 576.844 708.638 589.149 702.373 599.995C696.107 610.841 687.096 619.846 676.245 626.103L391.64 790.422C380.792 796.689 368.486 799.989 355.958 799.989C343.43 799.989 331.124 796.689 320.277 790.422L35.6712 626.103C24.8257 619.841 15.8196 610.835 9.55797 599.99C3.29637 589.144 -5.8717e-05 576.842 7.84422e-10 564.318V235.661C0.00203262 223.138 3.29935 210.836 9.56074 199.991C15.8221 189.146 24.8271 180.139 35.6712 173.876ZM587.315 245.925C590.909 245.925 594.44 246.87 597.554 248.666C600.685 250.454 603.288 253.037 605.101 256.155C606.913 259.272 607.87 262.813 607.875 266.419V533.477C607.872 537.084 606.916 540.625 605.104 543.743C603.291 546.86 600.686 549.443 597.554 551.23L366.207 684.791C363.091 686.59 359.556 687.537 355.958 687.537C352.36 687.537 348.825 686.59 345.709 684.791L114.445 551.313C111.314 549.524 108.711 546.941 106.898 543.824C105.086 540.706 104.129 537.166 104.124 533.56V266.419C104.124 262.822 105.072 259.289 106.87 256.174C108.669 253.059 111.255 250.472 114.37 248.673C117.485 246.874 121.018 245.927 124.614 245.925C128.211 245.924 131.745 246.869 134.861 248.666L355.969 376.322L577.076 248.666C580.19 246.87 583.721 245.925 587.315 245.925ZM335.47 411.828L145.1 301.925V521.711L335.47 631.635V411.828ZM376.467 631.614L566.816 521.711V301.925L376.467 411.828V631.614Z"
          fill="url(#paint0_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="0"
          y1="399.997"
          x2="711.937"
          y2="399.997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00988A" />
          <stop offset="1" stopColor="#00F16E" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="720" height="800" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KiperSvg;
