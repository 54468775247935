import gql from 'graphql-tag';

export const triageFragment = gql`
  fragment triageParts on Triage {
    id
    description
    descriptionEs
    descriptionEn
    icon
    textLog
    order
    serializedParams
    type
  }
`;

export const subtriageFragment = gql`
  fragment subtriageParts on Subtriage {
    id
    description
    descriptionEs
    descriptionEn
    order
    triageId
    type
  }
`;
