export default function removeProperty(obj, property) {
  if (typeof obj !== 'object') return obj;
  const result = { ...obj };
  if (Object.keys(result).includes(property)) delete result[property];

  Object.keys(result).forEach(key => {
    const instanceType = Object.prototype.toString.call(result[key]);
    switch (instanceType) {
      case '[object Object]':
        result[key] = removeProperty(result[key], property);
        break;
      case '[object Array]':
        result[key] = result[key].map(x => removeProperty(x, property));
        break;
      default:
    }
  });

  return result;
}
