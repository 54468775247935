import styled, { css } from 'styled-components';
import InputMask from 'react-input-mask';

export const StyledInputMask = styled(InputMask)`
  width: ${props => (props.maxwidth ? '100%' : '90%')};
  border-color: ${props => props.theme.colors.secondary400};

  ::placeholder {
    line-height: 100%;
    letter-spacing: 0.5px;
    color: ${props => props.theme.colors.secondary400};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${props => props.theme.colors.secondary100};
    border-color: ${props => props.theme.colors.secondary100};
  }

  ${props =>
    props.invalid &&
    css`
      border-color: ${props.theme.colors.dangerPrimary};
    `}
`;
