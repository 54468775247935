export const list = async ({ $http, search = {} }) => {
  const { page = 1, pageSize = 10, personContextId, searchText } = search;

  const res = await $http.get(`/personEventSetting/getAllByPersonContext`, {
    params: {
      page,
      pageSize,
      personContextId,
      searchText,
    },
  });

  if (res.status === 204)
    return {
      pagination: {
        page: 1,
        totalPages: 1,
        pageSize,
        totalResults: 0,
        hasPrevious: false,
        hasNext: false,
      },
      collection: [],
    };

  return res.data;
};

export const get = async ({ id, $http }) => {
  const res = await $http.get(
    `/personEventSetting/${id}?include=attendantDefaultMessages`,
  );

  return res.data;
};

export const create = async ({ eventSetting, $http }) => {
  const res = await $http.post('/personEventSetting', eventSetting);

  return res.data.value;
};

export const update = async ({ eventSetting, $http }) => {
  const res = await $http.put(
    `/personEventSetting/${eventSetting.id}`,
    eventSetting,
  );

  return res.data.value;
};

export const remove = async ({ id, $http }) => {
  const res = await $http.delete(`/personEventSetting/${id}`);

  return res.status === 200;
};

export const updateRoute = async ({ eventSettingRoute, $http }) => {
  if (eventSettingRoute.id) {
    const res = await $http.put(
      `/personEventTypeParams/${eventSettingRoute.id}`,
      eventSettingRoute,
    );
    return res.data.value;
  }

  const res = await $http.post(`/personEventTypeParams/`, eventSettingRoute);
  return res.data.value;
};
