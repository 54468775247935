import gql from 'graphql-tag';

export default gql`
  mutation eventTriage(
    $eventId: ID!
    $triageId: ID!
    $serializedTriage: String
  ) {
    eventTriage(
      eventId: $eventId
      triageId: $triageId
      serializedTriage: $serializedTriage
    )
  }
`;
