import propTypes from 'prop-types';
import { Flex } from '../Flex/Flex';
import Input from '../CustomInput';
import * as S from './styles';
import InputMask from '../InputMask/InputMask';
import Text from '../Text';

function InputGeneric({
  label,
  type,
  placeholder,
  name,
  value,
  onChange,
  mask,
  isRequired,
  ...props
}) {
  return (
    <Flex width="100%" flexDirection="column" gridGap="4px">
      <Flex>
        {label && <S.Label>{label}</S.Label>}
        {isRequired && (
          <Text ml="4px" color="danger500" fontWeight="bolder">
            *
          </Text>
        )}
      </Flex>
      {mask ? (
        <InputMask
          {...props}
          name={name}
          mask={mask}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          maxwidth
        />
      ) : (
        <Input
          {...props}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      )}
    </Flex>
  );
}

export default InputGeneric;

InputGeneric.propTypes = {
  name: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  isRequired: propTypes.bool,
  label: propTypes.string,
  type: propTypes.string,
  placeholder: propTypes.string,
  value: propTypes.any,
  mask: propTypes.string,
};

InputGeneric.defaultProps = {
  label: null,
  type: 'text',
  placeholder: null,
  value: '',
  mask: null,
  isRequired: false,
};
