import styled, { css } from 'styled-components';

import Flex from '../Flex';

export const Container = styled(Flex)`
  border-radius: 5px;

  svg {
    width: 20px;
    height: 20px;
    color: ${props => props.theme.colors.white};
  }

  ${props =>
    props.fixed &&
    css`
      position: fixed;
      top: 0px;
      right: 0px;
      z-index: 10;
      width: calc(100% - 60px);
      min-height: 60px;
      padding: 0px 15px;
      margin: 0;
      border: 0;
      border-radius: 0;

      svg {
        width: 30px;
        height: 30px;
      }
    `}
`;
