import gql from 'graphql-tag';

export const templateParams = gql`
  query templateParams($profileId: Int!) {
    templateParams(profileId: $profileId) {
      name
      category
      description {
        pt
        en
        es
      }
      profileIds
    }
  }
`;
