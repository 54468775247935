import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  body {
    @media screen {
      background-color: ${props => props.theme.colorContainer};
    }
    font-family: 'Nunito Sans', sans-serif;
    display: flex;

    zoom: reset;
    zoom: 100%;
  }

  #root {
    width: 100%;
  }
`;
