import { useRef, useEffect } from 'react';

const useOutsideClick = callback => {
  const mainBoxRef = useRef();

  useEffect(() => {
    const handleClick = event => {
      if (mainBoxRef.current && !mainBoxRef.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [mainBoxRef]);

  return mainBoxRef;
};

export default useOutsideClick;
