import styled from 'styled-components';
import Flex from '../Flex';
import Text from '../Text';

export const Container = styled(Flex)`
  border-radius: 4px;
  padding: 16px;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  svg {
    min-width: 20px;
    min-height: 20px;
  }
`;

export const Title = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.highBlack};
`;

export const Subtitle = styled(Title)`
  font-weight: 300;
  line-height: 100%;
`;
