import styled from 'styled-components';

export const Label = styled.label`
  color: ${props => props.theme.colors.blackShades(0.8)};
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
`;
