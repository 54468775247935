import gql from 'graphql-tag';
import { eventActivityFragment } from './fragments';

export default gql`
  query eventActivity($eventId: ID!, $eventDate: String) {
    eventActivity(eventId: $eventId, eventDate: $eventDate) {
      ...eventActivityParts
    }
  }
  ${eventActivityFragment}
`;
