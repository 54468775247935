import gql from 'graphql-tag';

export const reportTemplates = gql`
  query reportTemplates {
    reportTemplates {
      id
      fieldName
      isCustom
      reportFilters {
        id
        key
        isRequired
        sequence
        isAdvanced
      }
    }
  }
`;

export const cancelReport = gql`
  query cancelReport($id: ID!) {
    cancelReport(id: $id)
  }
`;

export const paginationInfoFragment = gql`
  fragment PaginationInfoParts on Pagination {
    page
    totalPages
    pageSize
    totalResults
    hasPrevious
    hasNext
  }
`;

export const usersReportData = gql`
  query usersReportData($reportData: ReportDataInput!) {
    usersReportData(reportData: $reportData) {
      collection {
        personName: personname
        personId: personid
        profileName: profilename
        unityGroupName: unitygroupname
        unityName: unityname
        personEmail: personemail
        personPhone: personphone
        accessProfiles: accessprofiles
        tagAmount
        rfAmount
        appactivated
        creationDate
        creationTime
        additionalInformation
      }
      pagination {
        ...PaginationInfoParts
      }
    }
  }
  ${paginationInfoFragment}
`;

export const eventsReportData = gql`
  query eventsReportData($reportData: ReportDataInput) {
    eventsReportData(reportData: $reportData) {
      collection {
        eventId: eventid
        eventDate: eventdate
        eventTime: eventtime
        eventType: eventtype
        eventTypeValue: eventtypevalue
        placeName: placename
        personName: personname
        personProfile: personprofile
        unityGroupName: unitygroupname
        unityName: unityname
        treatmentEndDate: treatmentenddate
        treatmentEndTime: treatmentendtime
        attendantProfile: attendantprofile
        attendantName: attendantname
        additionalInformation: additionalinformation
        deviceName: devicename
        eventCreationDate: eventcreationdate
        treatment
      }
      pagination {
        ...PaginationInfoParts
      }
    }
  }
  ${paginationInfoFragment}
`;

export const vehiclesReportData = gql`
  query vehiclesReportData($reportData: ReportDataInput) {
    vehiclesReportData(reportData: $reportData) {
      collection {
        unityGroupName: unitygroupname
        unityName: unityname
        vehicleType: vehicletype
        vehicleModel: vehiclemodel
        vehiclePlate: vehicleplate
        vehiclePersonName: vehiclepersonname
      }
      pagination {
        ...PaginationInfoParts
      }
    }
  }
  ${paginationInfoFragment}
`;
