import gql from 'graphql-tag';
import { dwellerForReportFragment } from '../fragments';

export const dwellers = gql`
  query dwellers($filters: QueryInput!) {
    dwellers(filters: $filters) {
      collection {
        name
        email
        profileId
        phone
        personId
        profileName
        username
        creationDate
        syncStatus
        personContextId
        treeNodeId
        profile {
          name
          fieldName
          personContextId
          id
          creationDate
        }
        unity {
          id
          name
          placeTypeLabel
          treeNodeId
          serializedParams {
            extension
          }
        }
        unityGroup {
          id
          name
          placeTypeLabel
          treeNodeId
          serializedParams {
            prefixPABX
          }
        }
        condominium {
          id
          name
          treeNodeId
          personContextParams {
            id
            name
            value {
              prefixPABX
            }
          }
        }
        accesses {
          rf {
            id
            lastUpdate
            creationDate
            rfId
            counter
          }
          tag {
            id
            lastUpdate
            creationDate
            tagId
          }
          qrCode {
            id
            lastUpdate
            creationDate
          }
          fingerPrint {
            id
            lastUpdate
            creationDate
            fingerId
          }
        }
        condominiumGroup {
          id
          name
          treeNodeId
        }
      }
      pagination {
        totalPages
        totalResults
        page
        pageSize
      }
    }
  }
`;

export const fullDwellers = gql`
  query fullDwellers($filters: QueryInput!) {
    fullDwellers(filters: $filters) {
      collection {
        name
        email
        profileId
        phone
        personId
        profileName
        username
        creationDate
        syncStatus
        personContextId
        treeNodeId
        profile {
          name
          fieldName
          personContextId
          id
          creationDate
        }
        unity {
          id
          name
          placeTypeLabel
          treeNodeId
          serializedParams {
            extension
          }
        }
        unityGroup {
          id
          name
          placeTypeLabel
          treeNodeId
          serializedParams {
            prefixPABX
          }
        }
        condominium {
          id
          name
          treeNodeId
          personContextParams {
            id
            name
            value {
              prefixPABX
            }
          }
        }
        accesses {
          rf {
            id
            lastUpdate
            creationDate
            rfId
            counter
          }
          tag {
            id
            lastUpdate
            creationDate
            tagId
          }
          qrCode {
            id
            lastUpdate
            creationDate
          }
          fingerPrint {
            id
            lastUpdate
            creationDate
            fingerId
          }
        }
        condominiumGroup {
          id
          name
          treeNodeId
        }
        documents {
          id
          value
          documentTypeId
          documentTypeName
        }
        personContextParams {
          id
          value
          name
        }
        AdditionalContacts {
          id
          contact
          contactTypeId
        }
      }
      pagination {
        totalPages
        totalResults
        page
        pageSize
      }
    }
  }
`;

export const partnerDwellers = gql`
  query partnerDwellers($filters: UsersQueryInput) {
    partnerDwellers(filters: $filters) {
      pagination {
        totalPages
        totalResults
        page
        pageSize
      }
      collection {
        personContextId: personcontextid
        personContextCreationDate: personcontextcreationdate
        syncStatus: syncstatus
        treeNodeId: treenodeid
        treeNodeParentTreeNodeId: treenodeparenttreenodeid
        personId: personid
        name: personname
        email: personemail
        profileId: profileid
        profileName: profilename
        profileFieldName: profilefieldname
        hasFingerprint: hasfingerprint
        hasQrCode: hasqrcode
        hasRf: hasrf
        hasTag: hastag
        hasFaceId: hasfaceid
        unityName: unityname
        unityGroupName: unitygroupname
        condominiumName: condominiumname
      }
    }
  }
`;

export const blockedPartnerDwellers = gql`
  query blockedPartnerDwellers($filters: UsersQueryInput) {
    blockedPartnerDwellers(filters: $filters) {
      pagination {
        totalPages
        totalResults
        page
        pageSize
      }
      collection {
        blockername: blockername
        blockerprofile: blockerprofile
        blockedon: blockedon
        reason: reason
        personContextId: personcontextid
        personContextCreationDate: personcontextcreationdate
        syncStatus: syncstatus
        treeNodeId: treenodeid
        treeNodeParentTreeNodeId: treenodeparenttreenodeid
        personId: personid
        name: personname
        email: personemail
        profileId: profileid
        profileName: profilename
        profileFieldName: profilefieldname
        hasFingerprint: hasfingerprint
        hasQrCode: hasqrcode
        hasRf: hasrf
        hasTag: hastag
        hasFaceId: hasfaceid
        unityName: unityname
        unityGroupName: unitygroupname
        condominiumName: condominiumname
      }
    }
  }
`;

export const monitoringDwellers = gql`
  query monitoringDwellers($filters: QueryInput, $callLogId: String) {
    monitoringDwellers(filters: $filters, callLogId: $callLogId) {
      pagination {
        totalPages
        totalResults
        page
        pageSize
      }
      collection {
        personContextId: personcontextid
        personContextCreationDate: personcontextcreationdate
        treeNodeId: treenodeid
        treeNodeParentTreeNodeId: treenodeparenttreenodeid
        personId: personid
        personName: personname
        personEmail: personemail
        personPhone: personphone
        personCreationDate: personcreationdate
        personAdditionalPhonenumber: personadditionlphonenumber
        profileId: profileid
        profileName: profilename
        profileFieldName: profilefieldname
        personContextParamsCallPriority: personcontextparamscallpriority
        personContextParamsPrefixPabx: personcontextparamsprefixpabx
        personContextParamsObservation: personcontextparamsobservation
        personContextParamsContactOrder: personcontextparamscontactorder
        personContextParamsAllowedAccesses: personcontextparamsallowedaccesses {
          today {
            start {
              hours
              minutes
            }
            end {
              hours
              minutes
            }
            startTime
            endTime
            daysOfWeek
            gmt
          }
          tomorrow {
            start {
              hours
              minutes
            }
            end {
              hours
              minutes
            }
            startTime
            endTime
            daysOfWeek
            gmt
          }
        }
        personDocuments: persondocuments {
          id
          value
          documentTypeId
          documentTypeName
        }
        additionalContacts: additionalcontacts {
          id
          contact
          contactTypeId
        }
        unityName: unityname
        unityGroupName: unitygroupname
        unitySerializedParams: unityserializedparams
        unityGroupSerializedParams: unitygroupserializedparams
        guest
        gadget {
          appOs
          appName
          appVersion
          lastAccessTime
        }
        videocall {
          dialStatus
        }
        isOutsideContactTime: isoutsidecontacttime
      }
    }
  }
`;

export const getDwellersForReport = gql`
  query dwellersForReport($sourceNodeId: Int!, $searchText: String!) {
    dwellersForReport(sourceNodeId: $sourceNodeId, searchText: $searchText) {
      ...dwellerForReportParts
    }
  }
  ${dwellerForReportFragment}
`;
