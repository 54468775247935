import { forwardRef } from 'react';
import propTypes from 'prop-types';
import * as S from './styles';

const ModalMobile = forwardRef(
  ({ onClick, buttonText, subtitles, title, loading }, ref) => {
    return (
      <S.Wrapper ref={ref}>
        {title && <S.Title>{title}</S.Title>}
        {!!subtitles.length && !loading &&
          subtitles.map(subtitle => (
            <S.Subtitle key={subtitle}>{subtitle}</S.Subtitle>
          ))}
        {buttonText && !loading && <S.Button onClick={onClick}>{buttonText}</S.Button>}
      </S.Wrapper>
    );
  },
);

export default ModalMobile;

ModalMobile.displayName = 'ModalMobile';

ModalMobile.propTypes = {
  onClick: propTypes.func,
  title: propTypes.string,
  subtitles: propTypes.array,
  buttonText: propTypes.string,
  loading: propTypes.bool,
};

ModalMobile.defaultProps = {
  onClick: () => null,
  title: '',
  subtitles: [],
  buttonText: '',
  loading: false,
};
