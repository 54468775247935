export const inviteStatus = {
  expired: 0,
  valid: 1,
  invalid: 2,
};

export const porterDeliveryStep = Object.freeze({
  0: 'welcome',
  1: 'phone number',
  2: 'group unity',
  3: 'unity',
  4: 'open drawer',
  5: 'confirm deposit',
  6: 'feedback success',
});

export const porterDeliveryStepNames = Object.freeze({
  welcome: 0,
  phoneNumber: 1,
  groupUnity: 2,
  unity: 3,
  openDrawer: 4,
  confirmDeposit: 5,
  feedbackSuccess: 6,
});

export default inviteStatus;
