import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '@kiper/ui';
import context from '../context';
import loadingScreenGif from '../../../../assets/images/summit-gif-loading-submit-face.gif';

import * as S from './styles';

const prefix = 'porter-summit-invite.modal.loading';

const StepLoading = () => {
  const { loading, handleChangeStep } = useContext(context);
  const { t } = useTranslation('invite');

  useEffect(() => {
    if (!loading) {
      handleChangeStep(1);
    }
  }, [loading]);

  return (
    <S.StepLoadingContainer>
      <S.Title>{t(`${prefix}.sending-photo`)}</S.Title>
      <Flex
        flexDirection="column"
        gridGap="24px"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <S.LoadingScreenIcon
          src={loadingScreenGif}
          alt={t(`${prefix}.screen-icon-alt`)}
        />
        <S.Description>{t(`${prefix}.please-wait`)}</S.Description>
      </Flex>
    </S.StepLoadingContainer>
  );
};

export default StepLoading;
