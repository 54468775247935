import { Flex } from '@kiper/ui';
import propTypes from 'prop-types';
import { MdArrowForwardIos, MdOutlineEdit, MdCheck } from 'react-icons/md';
import { FamiliarFaceAndZoneIcon } from '../SvgIcons';
import * as S from './styles';

export const STATUS = Object.freeze({
  SUCCESS: 'success',
  EDIT: 'edit',
  REGISTER: 'register',
});

function FacialRegisterButton({
  disabled,
  onClick,
  status,
  title,
  description,
  show,
}) {
  const icon = {
    [STATUS.REGISTER]: <FamiliarFaceAndZoneIcon size={24} color="#374AA0" />,
    [STATUS.EDIT]: <MdOutlineEdit size={20} color="#374AA0" />,
    [STATUS.SUCCESS]: <MdCheck size={28} color="#24A148" />,
  };

  const handleClick = () => {
    onClick();
  };

  if (!show) return null;

  return (
    <S.Wrapper status={status} onClick={handleClick} disabled={disabled}>
      <Flex height="100%" width="fit-content">
        <S.IconContainer>{icon[status]}</S.IconContainer>
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        width="100%"
        gridGap="4px"
      >
        <S.Title isRegister={status === STATUS.REGISTER}>{title}</S.Title>
        <S.Description>{description}</S.Description>
      </Flex>
      {status === STATUS.REGISTER && (
        <Flex height="100%" width="fit-content">
          <MdArrowForwardIos size={24} />
        </Flex>
      )}
    </S.Wrapper>
  );
}

export default FacialRegisterButton;

FacialRegisterButton.propTypes = {
  onClick: propTypes.func.isRequired,
  show: propTypes.bool.isRequired,
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  disabled: propTypes.bool,
  status: propTypes.oneOf([STATUS.SUCCESS, STATUS.EDIT, STATUS.REGISTER])
    .isRequired,
};

FacialRegisterButton.defaultProps = {
  disabled: false,
};
