/**
 * Authentication middleware
 * @param {Function} next
 */
export default function authenticate(next) {
  if (!next) throw new Error('next fn required.');
  return (parent, args, context, info) => {
    if (!context || !context.token) throw new Error('unauthorized');
    return next({ parent, args, context, info });
  };
}
