import styled, { css } from 'styled-components';

export const LoaderWrapper = styled.div`
  display: contents;

  ${props =>
    props.fullHeight &&
    css`
      position: absolute;
    `};
`;
