import propTypes from 'prop-types';
import Label from '../Label';
import InputMask from '../InputMask';
import Flex from '../Flex';

const TimeField = ({ text, isEditable, value, handleChangeTime }) => {
  return (
    <Flex flexDirection="column">
      <Label info block>
        {text}
      </Label>
      {!isEditable ? (
        <Label info bolder>
          {value}
        </Label>
      ) : (
        <InputMask
          mask="99:99"
          value={value}
          delay={1000}
          onChange={handleChangeTime}
        />
      )}
    </Flex>
  );
};

export default TimeField;

TimeField.propTypes = {
  text: propTypes.string.isRequired,
  isEditable: propTypes.bool.isRequired,
  value: propTypes.any.isRequired,
  handleChangeTime: propTypes.func.isRequired,
};
