import styled, { keyframes, css } from 'styled-components';
import {
  Card as card,
  Row,
  Col as col,
  Button,
  FormGroup as formGroup,
  Badge as badge,
} from 'reactstrap';
import { FiAlertCircle } from 'react-icons/fi';

export const AlertIcon = styled(FiAlertCircle)``;

export const Container = styled(Row)`
  display: flex;
  justify-content: space-between;
  margin-top: 59px;
`;
export const Col = styled(col)``;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
`;

export const LoginImage = styled(Col)`
  padding: 0px;
  img {
    width: 100%;
    height: 100%;
  }
  ${props =>
    props.reverse === 'true'
      ? css`
          transition: all 0.6s ease-in-out;
          transform: translateX(75%);
        `
      : css`
          transition: all 0.6s ease-in-out;
          transform: translateX(0%);
        `}
`;

export const LoginCard = styled(Col)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  align-self: center;

  ${props =>
    props.reverse === 'true'
      ? css`
          transition: all 0.5s ease-in;
          transform: translateX(-130%);
        `
      : css`
          transition: all 0.5s ease-in;
          transform: translateX(0%);
        `}
`;

export const Title = styled.h3`
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.7);
`;

export const Subtitle = styled.p`
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.7);
`;

export const Card = styled(card)`
  /* width: 100%;
  height: 100%; */
  border-radius: 10px;
  border: solid 1px #d9d9da;
`;

const rotate = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

export const ButtonSubmit = styled(Button).attrs(props => ({
  disabled: !!props.loading,
}))`
  margin-bottom: 20px;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }

  ${props =>
    props.loading &&
    css`
      svg {
        margin-left: 10px;
        animation: ${rotate} 2s linear infinite;
      }
    `}
`;

export const Badge = styled(badge)`
  background-color: #fff;
  border-radius: 100px;
  border: solid 1px #9bd5b7;
  color: rgba(0, 0, 0, 0.7);
  display: inline-flex;
  align-items: center;
  padding: 5px;
  margin-bottom: 34px;
`;

export const FormGroup = styled(formGroup)`
  display: flex;
  flex-direction: column;
  margin-bottom: 39px;
  label {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.5px;

    &:last-child {
      text-align: end;
      margin-top: 5px;
      color: rgba(0, 0, 0, 0.7);
    }
  }
`;

export const ButtonLink = styled(Button)`
  padding: 0px;
  text-align: center;
  vertical-align: initial;

  &:hover {
    text-decoration: underline;
  }
`;

export const LinkContainer = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Label = styled.span`
  color: ${props => props.theme.colors.mediumBlack};
`;
