import styled from 'styled-components';

import { display, justifyItems, flexDirection, flexGrow } from 'styled-system';

import Box from '../Box';

export const Flex = styled(Box)`
  display: flex;
  cursor: inherit;
  ${display}
  ${justifyItems}
  ${flexDirection}
  ${flexGrow}
`;
