import styled from 'styled-components';
import { Flex as flex } from '..';
import datePicker from '../DatePicker';

export const Flex = styled(flex)`
  gap: 15px;
`;

export const DatePicker = styled(datePicker)`
  width: 75%;
`;
