import propTypes from 'prop-types';
import {
  PlaceHolderLine,
  PlaceHolderParagraph,
  PlaceHolderMediaObject,
} from './PlaceHolder';

const labelEnum = {
  null: PlaceHolderParagraph,
  paragraph: PlaceHolderParagraph,
  mediaObject: PlaceHolderMediaObject,
  line: PlaceHolderLine,
};

const PlaceHolder = ({ type, lines, ...props }) => {
  const Component = labelEnum[type];
  return <Component {...props} lines={lines} />;
};

PlaceHolder.propTypes = {
  type: propTypes.string,
  lines: propTypes.number,
};

PlaceHolder.defaultProps = {
  type: null,
  lines: 1,
};

export default PlaceHolder;
