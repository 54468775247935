import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import context from '../context';
import faceRegisterTipOne from '../../../../assets/images/summit-face-register-intro.svg';
import faceRegisterTipTwo from '../../../../assets/images/summit-face-tip-2.svg';
import faceRegisterTipThree from '../../../../assets/images/summit-face-tip-3.svg';
import faceRegisterTipFour from '../../../../assets/images/summit-face-tip-4.svg';
import * as S from './styles';

const prefix = 'porter-summit-invite.modal.capture-tips';

const StepFaceCaptureTips = () => {
  const { handleChangeStep } = useContext(context);
  const { t } = useTranslation('invite');

  const tips = [
    {
      icon: <S.IconsImg src={faceRegisterTipOne} alt="Dica 1" />,
      rule: t(`${prefix}.face-position`),
    },
    {
      icon: <S.IconsImg src={faceRegisterTipTwo} alt="Dica 2" />,
      rule: t(`${prefix}.bright-place`),
    },
    {
      icon: <S.IconsImg src={faceRegisterTipThree} alt="Dica 3" />,
      rule: t(`${prefix}.not-use-accessories`),
    },
    {
      icon: <S.IconsImg src={faceRegisterTipFour} alt="Dica 4" />,
      rule: t(`${prefix}.not-use-glasses`),
    },
  ];

  return (
    <>
      <S.WrapperCaptureTips>
        <S.Title>{t(`${prefix}.title`)}</S.Title>
        <S.TipsWrapper>
          {tips.map(tip => (
            <S.Tip key={tip.rule}>
              <S.IconWrapper>{tip.icon}</S.IconWrapper>
              <S.TipDescription>{tip.rule}</S.TipDescription>
            </S.Tip>
          ))}
        </S.TipsWrapper>
      </S.WrapperCaptureTips>

      <S.Button
        variant="contained"
        color="porterPrimary"
        block
        centered
        onClick={() => handleChangeStep(1)}
      >
        {t(`${prefix}.confirm-step`)}
      </S.Button>
    </>
  );
};
export default StepFaceCaptureTips;
