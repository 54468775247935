import propTypes from 'prop-types';

import { MentionsInput, Mention as MentionDefault } from 'react-mentions';

import Box from '../Box';
import { StepWrapper } from './styles';

const Mention = ({
  items,
  placeholder,
  trigger,
  onChange,
  value,
  name,
  bordered,
  inputProps,
  ...props
}) => {
  const disabledOption = items.some(x => x.disabled);

  return (
    <StepWrapper bordered={bordered} disabled={disabledOption} {...props}>
      <Box className="direction-input-wrapper" {...props}>
        <Box className="input-wrapper">
          <MentionsInput
            className="direction-input"
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            {...inputProps}
          >
            <MentionDefault
              name={name}
              trigger={trigger}
              data={items}
              className="temp"
              {...props}
            />
          </MentionsInput>
        </Box>
      </Box>
    </StepWrapper>
  );
};

Mention.propTypes = {
  name: propTypes.string,
  value: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  trigger: propTypes.string,
  placeholder: propTypes.string,
  items: propTypes.array,
  inputProps: propTypes.object,
  bordered: propTypes.bool,
};

Mention.defaultProps = {
  trigger: '/',
  name: undefined,
  placeholder: '',
  items: [],
  inputProps: null,
  bordered: false,
};

export default Mention;
