import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import context from '../context';
import * as S from './styles';

const prefix = 'face-invitation.tips-capture';

const StepFaceCaptureTips = () => {
  const { handleChangeStep } = useContext(context);
  const { t } = useTranslation('invite');

  const tips = [
    { icon: <S.FaceUnlockIcon />, rule: t(`${prefix}.face-position`) },
    { icon: <S.LightBulbOnIcon />, rule: t(`${prefix}.bright-place`) },
    { icon: <S.RedHatOffIcon />, rule: t(`${prefix}.not-use-accessories`) },
    { icon: <S.GlassesOffIcon />, rule: t(`${prefix}.not-use-glasses`) },
  ];

  return (
    <>
      <S.WrapperCaptureTips>
        <S.Title>{t(`${prefix}.title`)}</S.Title>
        <S.TipsWrapper>
          {tips.map(tip => (
            <S.Tip key={tip.rule}>
              <S.IconWrapper>{tip.icon}</S.IconWrapper>
              <S.TipDescription>{tip.rule}</S.TipDescription>
            </S.Tip>
          ))}
        </S.TipsWrapper>
      </S.WrapperCaptureTips>

      <S.Button
        variant="contained"
        color="porterPrimary"
        block
        centered
        onClick={() => handleChangeStep(1)}
      >
        {t(`${prefix}.confirm-step`)}
      </S.Button>
    </>
  );
};

export default StepFaceCaptureTips;
