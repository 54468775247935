import { KiperButton } from '@kiper/ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: 100%;
  background: white;
  position: fixed;
  z-index: 1;
  bottom: 0;
  padding: 32px;
  gap: 16px;
  text-align: center;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
`;

export const Title = styled.h1`
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  padding: 0;
  color: black;
  width: 100%;
  margin: 0;
`;

export const Subtitle = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  width: 100%;
  color: ${props => props.theme.colors.blackShades(0.64)};
`;

export const Button = styled(KiperButton)`
  background: ${props => props.theme.colors.invitePorterPrimary};
  outline: none;
  border: none;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;

  :hover {
    background-color: ${props => props.theme.colors.invitePorterPrimary};
    opacity: 0.7;
  }

  span {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
