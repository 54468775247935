import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  background: white;
  font-family: 'Poppins', sans-serif;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 16px;

  max-width: 100%;

  @media (min-width: 375px) {
    width: 100%;
    max-width: 375px;
  }
`;

export const ErrorWrapper = styled.h1`
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: black;
  background: white;
  padding: 24px;
  margin: 0;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: white;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  padding: 0;
  width: 100%;
  height: fit-content;
`;

export const Logo = styled.img`
  height: 28px;
  width: auto;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  margin: 0;
`;

export const InfoLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 10px;
`;

export const CamerasWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 8px;
`;

export const SubTitle = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: ${props => props.theme.colors.black};
  width: 100%;
  margin: 0;
`;

export const UnavailableCamerasDescription = styled.h3`
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  color: ${props => props.theme.colors.blackShades(0.7)};
  width: 100%;
  margin: 0;
`;

export const InfoTitle = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: fit-content;
  margin: 0;
  max-width: 35%;
  color: ${props => props.theme.colors.blackShades(0.87)};
`;

export const InfoDescription = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  max-width: 65%;
  text-align: right;
  color: ${props => props.theme.colors.blackShades(0.7)};
`;

export const AuthCodeHash = styled.footer`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: ${props => props.theme.colors.blackShades(0.7)};
`;

export const ThumbnailVideo = styled.img`
  height: auto;
  width: 100%;
  border-radius: 8px;
`;
