import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '../Button';
import { Container, Img, Col, Title, Message } from './styles';

const EmptyState = ({
  placeholder,
  placeholderStyles,
  action,
  text,
  message,
  to,
  children,
  large,
}) => (
  <Container>
    {placeholder && (
      <Img src={placeholder} alt={text} style={placeholderStyles} />
    )}
    <Col md={large ? 8 : 6}>
      <Title>{text}</Title>
      <Message dangerouslySetInnerHTML={{ __html: message }} />
      {to && action && (
        <Link to={to}>
          <Button type="button" color="#fff">
            {action}
          </Button>
        </Link>
      )}
      {children}
    </Col>
  </Container>
);

export default EmptyState;

EmptyState.propTypes = {
  text: propTypes.string,
  action: propTypes.string,
  placeholder: propTypes.string,
  to: propTypes.string,
  message: propTypes.string,
  children: propTypes.element,
  placeholderStyles: propTypes.object,
  large: propTypes.bool,
};

EmptyState.defaultProps = {
  text: '',
  message: '',
  action: null,
  to: null,
  children: null,
  placeholder: null,
  placeholderStyles: {},
  large: false,
};
