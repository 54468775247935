export const byCountryCode = {
  AF: { name: 'Afghanistan', dialCode: '+93', code: 'AF', emoji: '🇦🇫' },
  AX: { name: 'Aland Islands', dialCode: '+358', code: 'AX', emoji: '🇦🇽' },
  AL: { name: 'Albania', dialCode: '+355', code: 'AL', emoji: '🇦🇱' },
  DZ: { name: 'Algeria', dialCode: '+213', code: 'DZ', emoji: '🇩🇿' },
  AS: { name: 'American Samoa', dialCode: '+1684', code: 'AS', emoji: '🇦🇸' },
  AD: { name: 'Andorra', dialCode: '+376', code: 'AD', emoji: '🇦🇩' },
  AO: { name: 'Angola', dialCode: '+244', code: 'AO', emoji: '🇦🇴' },
  AI: { name: 'Anguilla', dialCode: '+1264', code: 'AI', emoji: '🇦🇮' },
  AQ: { name: 'Antarctica', dialCode: '+672', code: 'AQ', emoji: '🇦🇶' },
  AG: {
    name: 'Antigua and Barbuda',
    dialCode: '+1268',
    code: 'AG',
    emoji: '🇦🇬',
  },
  AR: { name: 'Argentina', dialCode: '+54', code: 'AR', emoji: '🇦🇷' },
  AM: { name: 'Armenia', dialCode: '+374', code: 'AM', emoji: '🇦🇲' },
  AW: { name: 'Aruba', dialCode: '+297', code: 'AW', emoji: '🇦🇼' },
  AU: { name: 'Australia', dialCode: '+61', code: 'AU', emoji: '🇦🇺' },
  AT: { name: 'Austria', dialCode: '+43', code: 'AT', emoji: '🇦🇹' },
  AZ: { name: 'Azerbaijan', dialCode: '+994', code: 'AZ', emoji: '🇦🇿' },
  BS: { name: 'Bahamas', dialCode: '+1242', code: 'BS', emoji: '🇧🇸' },
  BH: { name: 'Bahrain', dialCode: '+973', code: 'BH', emoji: '🇧🇭' },
  BD: { name: 'Bangladesh', dialCode: '+880', code: 'BD', emoji: '🇧🇩' },
  BB: { name: 'Barbados', dialCode: '+1246', code: 'BB', emoji: '🇧🇧' },
  BY: { name: 'Belarus', dialCode: '+375', code: 'BY', emoji: '🇧🇾' },
  BE: { name: 'Belgium', dialCode: '+32', code: 'BE', emoji: '🇧🇪' },
  BZ: { name: 'Belize', dialCode: '+501', code: 'BZ', emoji: '🇧🇿' },
  BJ: { name: 'Benin', dialCode: '+229', code: 'BJ', emoji: '🇧🇯' },
  BM: { name: 'Bermuda', dialCode: '+1441', code: 'BM', emoji: '🇧🇲' },
  BT: { name: 'Bhutan', dialCode: '+975', code: 'BT', emoji: '🇧🇹' },
  BO: {
    name: 'Bolivia, Plurinational State of',
    dialCode: '+591',
    code: 'BO',
    emoji: '🇧🇴',
  },
  BA: {
    name: 'Bosnia and Herzegovina',
    dialCode: '+387',
    code: 'BA',
    emoji: '🇧🇦',
  },
  BW: { name: 'Botswana', dialCode: '+267', code: 'BW', emoji: '🇧🇼' },
  BR: { name: 'Brazil', dialCode: '+55', code: 'BR', emoji: '🇧🇷' },
  IO: {
    name: 'British Indian Ocean Territory',
    dialCode: '+246',
    code: 'IO',
    emoji: '🇮🇴',
  },
  BN: {
    name: 'Brunei Darussalam',
    dialCode: '+673',
    code: 'BN',
    emoji: '🇧🇳',
  },
  BG: { name: 'Bulgaria', dialCode: '+359', code: 'BG', emoji: '🇧🇬' },
  BF: { name: 'Burkina Faso', dialCode: '+226', code: 'BF', emoji: '🇧🇫' },
  BI: { name: 'Burundi', dialCode: '+257', code: 'BI', emoji: '🇧🇮' },
  KH: { name: 'Cambodia', dialCode: '+855', code: 'KH', emoji: '🇰🇭' },
  CM: { name: 'Cameroon', dialCode: '+237', code: 'CM', emoji: '🇨🇲' },
  CA: { name: 'Canada', dialCode: '+1', code: 'CA', emoji: '🇨🇦' },
  CV: { name: 'Cape Verde', dialCode: '+238', code: 'CV', emoji: '🇨🇻' },
  KY: { name: 'Cayman Islands', dialCode: '+ 345', code: 'KY', emoji: '🇰🇾' },
  CF: {
    name: 'Central African Republic',
    dialCode: '+236',
    code: 'CF',
    emoji: '🇨🇫',
  },
  TD: { name: 'Chad', dialCode: '+235', code: 'TD', emoji: '🇹🇩' },
  CL: { name: 'Chile', dialCode: '+56', code: 'CL', emoji: '🇨🇱' },
  CN: { name: 'China', dialCode: '+86', code: 'CN', emoji: '🇨🇳' },
  CX: { name: 'Christmas Island', dialCode: '+61', code: 'CX', emoji: '🇨🇽' },
  CC: {
    name: 'Cocos (Keeling) Islands',
    dialCode: '+61',
    code: 'CC',
    emoji: '🇨🇨',
  },
  CO: { name: 'Colombia', dialCode: '+57', code: 'CO', emoji: '🇨🇴' },
  KM: { name: 'Comoros', dialCode: '+269', code: 'KM', emoji: '🇰🇲' },
  CG: { name: 'Congo', dialCode: '+242', code: 'CG', emoji: '🇨🇬' },
  CD: {
    name: 'Congo, The Democratic Republic of the Congo',
    dialCode: '+243',
    code: 'CD',
    emoji: '🇨🇩',
  },
  CK: { name: 'Cook Islands', dialCode: '+682', code: 'CK', emoji: '🇨🇰' },
  CR: { name: 'Costa Rica', dialCode: '+506', code: 'CR', emoji: '🇨🇷' },
  CI: { name: "Cote d'Ivoire", dialCode: '+225', code: 'CI', emoji: '🇨🇮' },
  HR: { name: 'Croatia', dialCode: '+385', code: 'HR', emoji: '🇭🇷' },
  CU: { name: 'Cuba', dialCode: '+53', code: 'CU', emoji: '🇨🇺' },
  CY: { name: 'Cyprus', dialCode: '+357', code: 'CY', emoji: '🇨🇾' },
  CZ: { name: 'Czech Republic', dialCode: '+420', code: 'CZ', emoji: '🇨🇿' },
  DK: { name: 'Denmark', dialCode: '+45', code: 'DK', emoji: '🇩🇰' },
  DJ: { name: 'Djibouti', dialCode: '+253', code: 'DJ', emoji: '🇩🇯' },
  DM: { name: 'Dominica', dialCode: '+1767', code: 'DM', emoji: '🇩🇲' },
  DO: {
    name: 'Dominican Republic',
    dialCode: '+1849',
    code: 'DO',
    emoji: '🇩🇴',
  },
  EC: { name: 'Ecuador', dialCode: '+593', code: 'EC', emoji: '🇪🇨' },
  EG: { name: 'Egypt', dialCode: '+20', code: 'EG', emoji: '🇪🇬' },
  SV: { name: 'El Salvador', dialCode: '+503', code: 'SV', emoji: '🇸🇻' },
  GQ: {
    name: 'Equatorial Guinea',
    dialCode: '+240',
    code: 'GQ',
    emoji: '🇬🇶',
  },
  ER: { name: 'Eritrea', dialCode: '+291', code: 'ER', emoji: '🇪🇷' },
  EE: { name: 'Estonia', dialCode: '+372', code: 'EE', emoji: '🇪🇪' },
  ET: { name: 'Ethiopia', dialCode: '+251', code: 'ET', emoji: '🇪🇹' },
  FK: {
    name: 'Falkland Islands (Malvinas)',
    dialCode: '+500',
    code: 'FK',
    emoji: '🇫🇰',
  },
  FO: { name: 'Faroe Islands', dialCode: '+298', code: 'FO', emoji: '🇫🇴' },
  FJ: { name: 'Fiji', dialCode: '+679', code: 'FJ', emoji: '🇫🇯' },
  FI: { name: 'Finland', dialCode: '+358', code: 'FI', emoji: '🇫🇮' },
  FR: { name: 'France', dialCode: '+33', code: 'FR', emoji: '🇫🇷' },
  GF: { name: 'French Guiana', dialCode: '+594', code: 'GF', emoji: '🇬🇫' },
  PF: {
    name: 'French Polynesia',
    dialCode: '+689',
    code: 'PF',
    emoji: '🇵🇫',
  },
  GA: { name: 'Gabon', dialCode: '+241', code: 'GA', emoji: '🇬🇦' },
  GM: { name: 'Gambia', dialCode: '+220', code: 'GM', emoji: '🇬🇲' },
  GE: { name: 'Georgia', dialCode: '+995', code: 'GE', emoji: '🇬🇪' },
  DE: { name: 'Germany', dialCode: '+49', code: 'DE', emoji: '🇩🇪' },
  GH: { name: 'Ghana', dialCode: '+233', code: 'GH', emoji: '🇬🇭' },
  GI: { name: 'Gibraltar', dialCode: '+350', code: 'GI', emoji: '🇬🇮' },
  GR: { name: 'Greece', dialCode: '+30', code: 'GR', emoji: '🇬🇷' },
  GL: { name: 'Greenland', dialCode: '+299', code: 'GL', emoji: '🇬🇱' },
  GD: { name: 'Grenada', dialCode: '+1473', code: 'GD', emoji: '🇬🇩' },
  GP: { name: 'Guadeloupe', dialCode: '+590', code: 'GP', emoji: '🇬🇵' },
  GU: { name: 'Guam', dialCode: '+1671', code: 'GU', emoji: '🇬🇺' },
  GT: { name: 'Guatemala', dialCode: '+502', code: 'GT', emoji: '🇬🇹' },
  GG: { name: 'Guernsey', dialCode: '+44', code: 'GG', emoji: '🇬🇬' },
  GN: { name: 'Guinea', dialCode: '+224', code: 'GN', emoji: '🇬🇳' },
  GW: { name: 'Guinea-Bissau', dialCode: '+245', code: 'GW', emoji: '🇬🇼' },
  GY: { name: 'Guyana', dialCode: '+595', code: 'GY', emoji: '🇬🇾' },
  HT: { name: 'Haiti', dialCode: '+509', code: 'HT', emoji: '🇭🇹' },
  VA: {
    name: 'Holy See (Vatican City State)',
    dialCode: '+379',
    code: 'VA',
    emoji: '🇻🇦',
  },
  HN: { name: 'Honduras', dialCode: '+504', code: 'HN', emoji: '🇭🇳' },
  HK: { name: 'Hong Kong', dialCode: '+852', code: 'HK', emoji: '🇭🇰' },
  HU: { name: 'Hungary', dialCode: '+36', code: 'HU', emoji: '🇭🇺' },
  IS: { name: 'Iceland', dialCode: '+354', code: 'IS', emoji: '🇮🇸' },
  IN: { name: 'India', dialCode: '+91', code: 'IN', emoji: '🇮🇳' },
  ID: { name: 'Indonesia', dialCode: '+62', code: 'ID', emoji: '🇮🇩' },
  IR: {
    name: 'Iran, Islamic Republic of Persian Gulf',
    dialCode: '+98',
    code: 'IR',
    emoji: '🇮🇷',
  },
  IQ: { name: 'Iraq', dialCode: '+964', code: 'IQ', emoji: '🇮🇶' },
  IE: { name: 'Ireland', dialCode: '+353', code: 'IE', emoji: '🇮🇪' },
  IM: { name: 'Isle of Man', dialCode: '+44', code: 'IM', emoji: '🇮🇲' },
  IL: { name: 'Israel', dialCode: '+972', code: 'IL', emoji: '🇮🇱' },
  IT: { name: 'Italy', dialCode: '+39', code: 'IT', emoji: '🇮🇹' },
  JM: { name: 'Jamaica', dialCode: '+1876', code: 'JM', emoji: '🇯🇲' },
  JP: { name: 'Japan', dialCode: '+81', code: 'JP', emoji: '🗾' },
  JE: { name: 'Jersey', dialCode: '+44', code: 'JE', emoji: '🇯🇪' },
  JO: { name: 'Jordan', dialCode: '+962', code: 'JO', emoji: '🇯🇴' },
  KZ: { name: 'Kazakhstan', dialCode: '+77', code: 'KZ', emoji: '🇰🇿' },
  KE: { name: 'Kenya', dialCode: '+254', code: 'KE', emoji: '🇰🇪' },
  KI: { name: 'Kiribati', dialCode: '+686', code: 'KI', emoji: '🇰🇮' },
  KP: {
    name: "Korea, Democratic People's Republic of Korea",
    dialCode: '+850',
    code: 'KP',
    emoji: '🇰🇵',
  },
  KR: {
    name: 'Korea, Republic of South Korea',
    dialCode: '+82',
    code: 'KR',
    emoji: '🇰🇷',
  },
  KW: { name: 'Kuwait', dialCode: '+965', code: 'KW', emoji: '🇰🇼' },
  KG: { name: 'Kyrgyzstan', dialCode: '+996', code: 'KG', emoji: '🇰🇬' },
  LA: { name: 'Laos', dialCode: '+856', code: 'LA', emoji: '🇱🇦' },
  LV: { name: 'Latvia', dialCode: '+371', code: 'LV', emoji: '🇱🇻' },
  LB: { name: 'Lebanon', dialCode: '+961', code: 'LB', emoji: '🇱🇧' },
  LS: { name: 'Lesotho', dialCode: '+266', code: 'LS', emoji: '🇱🇸' },
  LR: { name: 'Liberia', dialCode: '+231', code: 'LR', emoji: '🇱🇷' },
  LY: {
    name: 'Libyan Arab Jamahiriya',
    dialCode: '+218',
    code: 'LY',
    emoji: '🇱🇾',
  },
  LI: { name: 'Liechtenstein', dialCode: '+423', code: 'LI', emoji: '🇱🇮' },
  LT: { name: 'Lithuania', dialCode: '+370', code: 'LT', emoji: '🇱🇹' },
  LU: { name: 'Luxembourg', dialCode: '+352', code: 'LU', emoji: '🇱🇺' },
  MO: { name: 'Macao', dialCode: '+853', code: 'MO', emoji: '🇲🇴' },
  MK: { name: 'Macedonia', dialCode: '+389', code: 'MK', emoji: '🇲🇰' },
  MG: { name: 'Madagascar', dialCode: '+261', code: 'MG', emoji: '🇲🇬' },
  MW: { name: 'Malawi', dialCode: '+265', code: 'MW', emoji: '🇲🇼' },
  MY: { name: 'Malaysia', dialCode: '+60', code: 'MY', emoji: '🇲🇾' },
  MV: { name: 'Maldives', dialCode: '+960', code: 'MV', emoji: '🇲🇻' },
  ML: { name: 'Mali', dialCode: '+223', code: 'ML', emoji: '🇲🇱' },
  MT: { name: 'Malta', dialCode: '+356', code: 'MT', emoji: '🇲🇹' },
  MH: {
    name: 'Marshall Islands',
    dialCode: '+692',
    code: 'MH',
    emoji: '🇲🇭',
  },
  MQ: { name: 'Martinique', dialCode: '+596', code: 'MQ', emoji: '🇲🇶' },
  MR: { name: 'Mauritania', dialCode: '+222', code: 'MR', emoji: '🇲🇷' },
  MU: { name: 'Mauritius', dialCode: '+230', code: 'MU', emoji: '🇲🇺' },
  YT: { name: 'Mayotte', dialCode: '+262', code: 'YT', emoji: '🇾🇹' },
  MX: { name: 'Mexico', dialCode: '+52', code: 'MX', emoji: '🇲🇽' },
  FM: {
    name: 'Micronesia, Federated States of Micronesia',
    dialCode: '+691',
    code: 'FM',
    emoji: '🇫🇲',
  },
  MD: { name: 'Moldova', dialCode: '+373', code: 'MD', emoji: '🇲🇩' },
  MC: { name: 'Monaco', dialCode: '+377', code: 'MC', emoji: '🇲🇨' },
  MN: { name: 'Mongolia', dialCode: '+976', code: 'MN', emoji: '🇲🇳' },
  ME: { name: 'Montenegro', dialCode: '+382', code: 'ME', emoji: '🇲🇪' },
  MS: { name: 'Montserrat', dialCode: '+1664', code: 'MS', emoji: '🇲🇸' },
  MA: { name: 'Morocco', dialCode: '+212', code: 'MA', emoji: '🇲🇦' },
  MZ: { name: 'Mozambique', dialCode: '+258', code: 'MZ', emoji: '🇲🇿' },
  MM: { name: 'Myanmar', dialCode: '+95', code: 'MM', emoji: '🇲🇲' },
  NA: { name: 'Namibia', dialCode: '+264', code: 'NA', emoji: '🇳🇦' },
  NR: { name: 'Nauru', dialCode: '+674', code: 'NR', emoji: '🇳🇷' },
  NP: { name: 'Nepal', dialCode: '+977', code: 'NP', emoji: '🇳🇵' },
  NL: { name: 'Netherlands', dialCode: '+31', code: 'NL', emoji: '🇳🇱' },
  AN: {
    name: 'Netherlands Antilles',
    dialCode: '+599',
    code: 'AN',
    emoji: '🇳🇱',
  },
  NC: { name: 'New Caledonia', dialCode: '+687', code: 'NC', emoji: '🇳🇨' },
  NZ: { name: 'New Zealand', dialCode: '+64', code: 'NZ', emoji: '🇳🇿' },
  NI: { name: 'Nicaragua', dialCode: '+505', code: 'NI', emoji: '🇳🇮' },
  NE: { name: 'Niger', dialCode: '+227', code: 'NE', emoji: '🇳🇪' },
  NG: { name: 'Nigeria', dialCode: '+234', code: 'NG', emoji: '🇳🇬' },
  NU: { name: 'Niue', dialCode: '+683', code: 'NU', emoji: '🇳🇺' },
  NF: { name: 'Norfolk Island', dialCode: '+672', code: 'NF', emoji: '🇳🇫' },
  MP: {
    name: 'Northern Mariana Islands',
    dialCode: '+1670',
    code: 'MP',
    emoji: '🇲🇵',
  },
  NO: { name: 'Norway', dialCode: '+47', code: 'NO', emoji: '🇳🇴' },
  OM: { name: 'Oman', dialCode: '+968', code: 'OM', emoji: '🇴🇲' },
  PK: { name: 'Pakistan', dialCode: '+92', code: 'PK', emoji: '🇵🇰' },
  PW: { name: 'Palau', dialCode: '+680', code: 'PW', emoji: '🇵🇼' },
  PS: {
    name: 'Palestinian Territory, Occupied',
    dialCode: '+970',
    code: 'PS',
    emoji: '🇵🇸',
  },
  PA: { name: 'Panama', dialCode: '+507', code: 'PA', emoji: '🇵🇦' },
  PG: {
    name: 'Papua New Guinea',
    dialCode: '+675',
    code: 'PG',
    emoji: '🇵🇬',
  },
  PY: { name: 'Paraguay', dialCode: '+595', code: 'PY', emoji: '🇵🇾' },
  PE: { name: 'Peru', dialCode: '+51', code: 'PE', emoji: '🇵🇪' },
  PH: { name: 'Philippines', dialCode: '+63', code: 'PH', emoji: '🇵🇭' },
  PN: { name: 'Pitcairn', dialCode: '+872', code: 'PN', emoji: '🇵🇳' },
  PL: { name: 'Poland', dialCode: '+48', code: 'PL', emoji: '🇵🇱' },
  PT: { name: 'Portugal', dialCode: '+351', code: 'PT', emoji: '🇵🇹' },
  PR: { name: 'Puerto Rico', dialCode: '+1939', code: 'PR', emoji: '🇵🇷' },
  QA: { name: 'Qatar', dialCode: '+974', code: 'QA', emoji: '🇶🇦' },
  RO: { name: 'Romania', dialCode: '+40', code: 'RO', emoji: '🇷🇴' },
  RU: { name: 'Russia', dialCode: '+7', code: 'RU', emoji: '🇷🇺' },
  RW: { name: 'Rwanda', dialCode: '+250', code: 'RW', emoji: '🇷🇼' },
  RE: { name: 'Reunion', dialCode: '+262', code: 'RE', emoji: '🇷🇪' },
  BL: {
    name: 'Saint Barthelemy',
    dialCode: '+590',
    code: 'BL',
    emoji: '🇧🇱',
  },
  SH: {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    dialCode: '+290',
    code: 'SH',
    emoji: '🇸🇭',
  },
  KN: {
    name: 'Saint Kitts and Nevis',
    dialCode: '+1869',
    code: 'KN',
    emoji: '🇰🇳',
  },
  LC: { name: 'Saint Lucia', dialCode: '+1758', code: 'LC', emoji: '🇱🇨' },
  MF: { name: 'Saint Martin', dialCode: '+590', code: 'MF', emoji: '🇫🇷' },
  PM: {
    name: 'Saint Pierre and Miquelon',
    dialCode: '+508',
    code: 'PM',
    emoji: '🇵🇲',
  },
  VC: {
    name: 'Saint Vincent and the Grenadines',
    dialCode: '+1784',
    code: 'VC',
    emoji: '🇻🇨',
  },
  WS: { name: 'Samoa', dialCode: '+685', code: 'WS', emoji: '🇼🇸' },
  SM: { name: 'San Marino', dialCode: '+378', code: 'SM', emoji: '🇸🇲' },
  ST: {
    name: 'Sao Tome and Principe',
    dialCode: '+239',
    code: 'ST',
    emoji: '🇸🇹',
  },
  SA: { name: 'Saudi Arabia', dialCode: '+966', code: 'SA', emoji: '🇸🇦' },
  SN: { name: 'Senegal', dialCode: '+221', code: 'SN', emoji: '🇸🇳' },
  RS: { name: 'Serbia', dialCode: '+381', code: 'RS', emoji: '🇷🇸' },
  SC: { name: 'Seychelles', dialCode: '+248', code: 'SC', emoji: '🇸🇨' },
  SL: { name: 'Sierra Leone', dialCode: '+232', code: 'SL', emoji: '🇸🇱' },
  SG: { name: 'Singapore', dialCode: '+65', code: 'SG', emoji: '🇸🇬' },
  SK: { name: 'Slovakia', dialCode: '+421', code: 'SK', emoji: '🇸🇰' },
  SI: { name: 'Slovenia', dialCode: '+386', code: 'SI', emoji: '🇸🇮' },
  SB: { name: 'Solomon Islands', dialCode: '+677', code: 'SB', emoji: '🇸🇧' },
  SO: { name: 'Somalia', dialCode: '+252', code: 'SO', emoji: '🇸🇴' },
  ZA: { name: 'South Africa', dialCode: '+27', code: 'ZA', emoji: '🇿🇦' },
  SS: { name: 'South Sudan', dialCode: '+211', code: 'SS', emoji: '🇸🇸' },
  GS: {
    name: 'South Georgia and the South Sandwich Islands',
    dialCode: '+500',
    code: 'GS',
    emoji: '🇬🇸',
  },
  ES: { name: 'Spain', dialCode: '+34', code: 'ES', emoji: '🇪🇸' },
  LK: { name: 'Sri Lanka', dialCode: '+94', code: 'LK', emoji: '🇱🇰' },
  SD: { name: 'Sudan', dialCode: '+249', code: 'SD', emoji: '🇸🇩' },
  SR: { name: 'Suriname', dialCode: '+597', code: 'SR', emoji: '🇸🇷' },
  SJ: {
    name: 'Svalbard and Jan Mayen',
    dialCode: '+47',
    code: 'SJ',
    emoji: '🇳🇴',
  },
  SZ: { name: 'Swaziland', dialCode: '+268', code: 'SZ', emoji: '🇸🇿' },
  SE: { name: 'Sweden', dialCode: '+46', code: 'SE', emoji: '🇸🇪' },
  CH: { name: 'Switzerland', dialCode: '+41', code: 'CH', emoji: '🇨🇭' },
  SY: {
    name: 'Syrian Arab Republic',
    dialCode: '+963',
    code: 'SY',
    emoji: '🇸🇾',
  },
  TW: { name: 'Taiwan', dialCode: '+886', code: 'TW', emoji: '🇹🇼' },
  TJ: { name: 'Tajikistan', dialCode: '+992', code: 'TJ', emoji: '🇹🇯' },
  TZ: {
    name: 'Tanzania, United Republic of Tanzania',
    dialCode: '+255',
    code: 'TZ',
    emoji: '🇹🇿',
  },
  TH: { name: 'Thailand', dialCode: '+66', code: 'TH', emoji: '🇹🇭' },
  TL: { name: 'Timor-Leste', dialCode: '+670', code: 'TL', emoji: '🇹🇱' },
  TG: { name: 'Togo', dialCode: '+228', code: 'TG', emoji: '🇹🇬' },
  TK: { name: 'Tokelau', dialCode: '+690', code: 'TK', emoji: '🇹🇰' },
  TO: { name: 'Tonga', dialCode: '+676', code: 'TO', emoji: '🇹🇴' },
  TT: {
    name: 'Trinidad and Tobago',
    dialCode: '+1868',
    code: 'TT',
    emoji: '🇹🇹',
  },
  TN: { name: 'Tunisia', dialCode: '+216', code: 'TN', emoji: '🇹🇳' },
  TR: { name: 'Turkey', dialCode: '+90', code: 'TR', emoji: '🇹🇷' },
  TM: { name: 'Turkmenistan', dialCode: '+993', code: 'TM', emoji: '🇹🇲' },
  TC: {
    name: 'Turks and Caicos Islands',
    dialCode: '+1649',
    code: 'TC',
    emoji: '🇹🇨',
  },
  TV: { name: 'Tuvalu', dialCode: '+688', code: 'TV', emoji: '🇹🇻' },
  UG: { name: 'Uganda', dialCode: '+256', code: 'UG', emoji: '🇺🇬' },
  UA: { name: 'Ukraine', dialCode: '+380', code: 'UA', emoji: '🇺🇦' },
  AE: {
    name: 'United Arab Emirates',
    dialCode: '+971',
    code: 'AE',
    emoji: '🇦🇪',
  },
  UK: { name: 'United Kingdom', dialCode: '+44', code: 'UK', emoji: '🇬🇧' },
  US: { name: 'United States', dialCode: '+1', code: 'US', emoji: '🇺🇸' },
  UY: { name: 'Uruguay', dialCode: '+598', code: 'UY', emoji: '🇺🇾' },
  UZ: { name: 'Uzbekistan', dialCode: '+998', code: 'UZ', emoji: '🇺🇿' },
  VU: { name: 'Vanuatu', dialCode: '+678', code: 'VU', emoji: '🇻🇺' },
  VE: {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    dialCode: '+58',
    code: 'VE',
    emoji: '🇻🇪',
  },
  VN: { name: 'Vietnam', dialCode: '+84', code: 'VN', emoji: '🇻🇳' },
  VG: {
    name: 'Virgin Islands, British',
    dialCode: '+1284',
    code: 'VG',
    emoji: '🇻🇬',
  },
  VI: {
    name: 'Virgin Islands, U.S.',
    dialCode: '+1340',
    code: 'VI',
    emoji: '🇻🇮',
  },
  WF: {
    name: 'Wallis and Futuna',
    dialCode: '+681',
    code: 'WF',
    emoji: '🇼🇫',
  },
  YE: { name: 'Yemen', dialCode: '+967', code: 'YE', emoji: '🇾🇪' },
  ZM: { name: 'Zambia', dialCode: '+260', code: 'ZM', emoji: '🇿🇲' },
  ZW: { name: 'Zimbabwe', dialCode: '+263', code: 'ZW', emoji: '🇿🇼' },
};
