import gql from 'graphql-tag';
import { eventSchedulerSettingsFragment } from './fragments';

export const getAllEventSchedulerSettings = gql`
  query eventSchedulerSettings($condominiumPersonContextId: String!) {
    eventSchedulerSettings(
      condominiumPersonContextId: $condominiumPersonContextId
    ) {
      ...eventSchedulerSettingsParts
    }
  }
  ${eventSchedulerSettingsFragment}
`;
