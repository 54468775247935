import styled from 'styled-components';
import { CustomInput } from 'reactstrap';

const cursorModifier = (
  { disabled, $loading } = { disabled: false, $loading: false },
) => {
  if (disabled && !$loading) return 'not-allowed';
  if (disabled && $loading) return 'wait';
  return 'pointer';
};

export default styled(CustomInput).attrs({
  type: 'checkbox',
  className: 'custom-switch',
})`
  display: flex;
  align-items: center;

  input {
    cursor: ${props => cursorModifier(props)};
  }

  label {
    cursor: ${props => cursorModifier(props)};
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.25px;
    word-break: break-word;
  }
`;
