import gql from 'graphql-tag';

export const confirm = gql`
  mutation confirmAccount(
    $confirmationCode: String!
    $email: String!
    $password: String!
  ) {
    confirm(
      confirmationCode: $confirmationCode
      email: $email
      password: $password
    )
  }
`;
