import smartlookClient from 'smartlook-client';

export const initializeSmartLook = (key, isProduction) =>
  isProduction && smartlookClient.init(key);

export const useSmartLook = ({ user, isProduction, consentMessage }) => {
  const identify = () => {
    if (isProduction) {
      smartlookClient.consentForms(consentMessage);
      smartlookClient.consentAPI(consentMessage);
      smartlookClient.consentIP(consentMessage);

      smartlookClient.identify(user?.personContextId, {
        name: user?.name,
        email: user?.email,
        partner: user?.partner?.name,
        role: user?.profileName,
      });
    }
  };

  const initialized = () => smartlookClient.initialized();

  return {
    identify,
    initialized,
  };
};
