import { useState, useContext } from 'react';
import { Flex, Modal } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@kiper/hooks';
import useRemoteConfig from '../../../../hooks/useRemoteConfig';
import { InviteGuestContext } from '../../../../store/InviteGuest';
import logoPorter from '../../../../assets/images/white-logo-porter.svg';
import logoSummitDownloadLink from '../../../../assets/images/summit-download-link.svg';
import logoPorterLogoIcon from '../../../../assets/images/summit-porter-logo-icon.svg';
import logoPorterSummitOfficial from '../../../../assets/images/summit-event-official-logo.svg';
import logoCentroSulBrand from '../../../../assets/images/summit-centrosul-logo.svg';
import { FacialInvitationPorterSummitModal } from '../../../../components/Modals';
import helpUseAppGif from '../../../../assets/animations/helpUseAppGif.gif';
import inviteGuestInvalid from '../../../../assets/images/invite-guest-invalid.svg';
import { RegisterFacial } from './components';
import * as S from './styles';

const MAPS_LOCAL_EVENT_PLACE_URL = 'https://maps.app.goo.gl/f3J4iPGSUPK1vZ8e9';

const SUMMIT_APP_URL = 'https://portereventos2024.yazo.app.br/app-links';

const PORTER_LANDING_PAGE_URL =
  'https://lp.porter.com.br/porterportaria/?utm_source=Google&utm_medium=ppc&utm_campaign=%5BONGOING%5D+%5BSEARCH%5D+%5BCPA%5D+Portaria&utm_id=Portaria&https://porter.com.br/&utm_source=Googleads&utm_medium=search&utm_campaign=portaria&utm_content=&utm_term=portaria%20virtual&gad_source=1&gclid=Cj0KCQjw4Oe4BhCcARIsADQ0cslcY2jXVKm1Brd1eW0FL-JxDlrW4bnrE_-TzLOcz-o04xLIQo6pnQgaApLzEALw_wcB';

const PorterSummitEditionInvite = () => {
  const [t] = useTranslation('invite');
  const [openModal, toggleModal] = useToggle(false);

  const [toggleModalFaceCapture, setToggleModalFaceCapture] = useState(false);

  const {
    refetchInviteGuest,
    guestHash,
    formattedValues,
    inviteInvalid,
    condominium,
    inviteExpired,
  } = useContext(InviteGuestContext);

  const isActiveRemoteExpireInvite = useRemoteConfig({
    path: 'inviteGuest.activeRemoteExpireInvite',
    condominium: Number(condominium?.personContextId),
  });

  const handleToggleModalRegisterFacial = () =>
    setToggleModalFaceCapture(prevState => !prevState);

  const isExpired = inviteExpired || isActiveRemoteExpireInvite;

  return (
    <>
      {toggleModalFaceCapture && (
        <FacialInvitationPorterSummitModal
          onToggle={handleToggleModalRegisterFacial}
          guestHash={guestHash}
          refetch={refetchInviteGuest}
        />
      )}
      {openModal && (
        <Modal
          open={openModal}
          toggle={toggleModal}
          size="sm"
          title={t('feedback.modal-title')}
        >
          <Flex alignItems="center" justifyContent="center">
            <img src={helpUseAppGif} alt="helpAppKiper" />
          </Flex>
        </Modal>
      )}

      <S.Wrapper>
        <S.Content>
          <S.InviteContainer>
            {inviteInvalid && (
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gridGap="24px"
              >
                <S.InviteInvalid background={inviteGuestInvalid} />
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  gridGap="8px"
                >
                  <S.Span warning>{t(`feedback.invalid`)}</S.Span>
                  <S.Span>{t(`feedback.invalid-context`)}</S.Span>
                </Flex>
              </Flex>
            )}

            {!inviteInvalid && (
              <>
                {isExpired && (
                  <>
                    <RegisterFacial.ExpiredHeader />
                    <RegisterFacial.Expired />
                  </>
                )}

                {!isExpired && !formattedValues.hasFaceId && (
                  <>
                    <RegisterFacial.DefaultHeader />
                    <RegisterFacial.Create
                      onRegisterFacial={handleToggleModalRegisterFacial}
                    />
                  </>
                )}

                {!isExpired && formattedValues.hasFaceId && (
                  <>
                    <RegisterFacial.DefaultHeader />
                    <RegisterFacial.Edit
                      onEditFacial={handleToggleModalRegisterFacial}
                    />
                  </>
                )}

                <S.AnchorsWrapper>
                  <S.SummitAppDownloadAnchor
                    href={SUMMIT_APP_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <S.WrapperIcon>
                      <img
                        src={logoSummitDownloadLink}
                        alt={t('porter-summit-invite.download-app.button')}
                      />
                    </S.WrapperIcon>
                    <S.AnchorDescription>
                      <S.AnchorTitleText>
                        {t('porter-summit-invite.download-app.title')}
                      </S.AnchorTitleText>
                      <S.AnchorDescriptionText>
                        {t('porter-summit-invite.download-app.description')}
                      </S.AnchorDescriptionText>
                    </S.AnchorDescription>
                  </S.SummitAppDownloadAnchor>
                  <S.PorterLandingPageAnchor
                    href={PORTER_LANDING_PAGE_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <S.WrapperIcon>
                      <img
                        src={logoPorterLogoIcon}
                        alt={t('porter-summit-invite.learn-more.button')}
                      />
                    </S.WrapperIcon>
                    <S.AnchorDescription>
                      <S.AnchorTitleText>
                        {t('porter-summit-invite.learn-more.title')}
                      </S.AnchorTitleText>
                      <S.AnchorDescriptionText>
                        {t('porter-summit-invite.learn-more.description')}
                      </S.AnchorDescriptionText>
                    </S.AnchorDescription>
                  </S.PorterLandingPageAnchor>
                </S.AnchorsWrapper>
                <S.BrandAreaFooter>
                  <S.BrandWrapper
                    href={SUMMIT_APP_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={logoPorterSummitOfficial} alt="Porter Summit" />
                    <S.DateChip>
                      {t('porter-summit-invite.event-info.date')}
                    </S.DateChip>
                    <S.ScheduleTimeText>
                      {t('porter-summit-invite.event-info.schedule')}
                    </S.ScheduleTimeText>
                  </S.BrandWrapper>
                  <S.BrandWrapper
                    href={MAPS_LOCAL_EVENT_PLACE_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={logoCentroSulBrand}
                      alt={t('porter-summit-invite.location.venue-name')}
                    />
                    <S.LinkMaps>
                      {t('porter-summit-invite.location.address')}
                    </S.LinkMaps>
                  </S.BrandWrapper>
                </S.BrandAreaFooter>
              </>
            )}
          </S.InviteContainer>
          <S.InviteFooter>
            <S.Img
              src={logoPorter}
              alt={t('porter-summit-invite.footer.sponsor-logo-alt')}
            />
          </S.InviteFooter>
        </S.Content>
      </S.Wrapper>
    </>
  );
};

export default PorterSummitEditionInvite;
