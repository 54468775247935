/* eslint-disable react/forbid-prop-types */
import propTypes from 'prop-types';
import { CardBody } from 'reactstrap';

import {
  Container,
  LoginImage,
  LoginCard,
  Card,
  ButtonLink,
  LinkContainer,
  Label,
} from './styles';

import loginImage from '../../../assets/images/login-image.svg';

const Login = ({
  t,
  currentStep,
  formikBag,
  setCurrentStep,
  steps,
  openTerms,
  openPrivacy,
}) => (
  <Container>
    <LoginImage
      md="7"
      reverse={(currentStep.key === steps.forgot.key).toString()}
    >
      <img src={loginImage} alt="login" />
    </LoginImage>
    <LoginCard
      md="5"
      reverse={(currentStep.key === steps.forgot.key).toString()}
    >
      <Card>
        <CardBody>
          <currentStep.component
            {...formikBag}
            setCurrentStep={setCurrentStep}
            steps={steps}
          />
        </CardBody>
      </Card>
      <LinkContainer>
        <ButtonLink color="link" onClick={openPrivacy}>
          {t('privacy-policy')}
        </ButtonLink>
        &nbsp;
        <Label>{t('and')}</Label>
        &nbsp;
        <ButtonLink onClick={openTerms} color="link">
          {t('use-terms')}
        </ButtonLink>
      </LinkContainer>
    </LoginCard>
  </Container>
);

export default Login;

Login.propTypes = {
  setCurrentStep: propTypes.func.isRequired,
  steps: propTypes.object.isRequired,
  t: propTypes.func.isRequired,
  currentStep: propTypes.object.isRequired,
  formikBag: propTypes.object.isRequired,
  openTerms: propTypes.func.isRequired,
  openPrivacy: propTypes.func.isRequired,
};
