import gql from 'graphql-tag';

export const configureSipAccount = gql`
  mutation configureSipAccount(
    $deviceId: Int!
    $condominiumId: Int!
    $typeSip: Int!
  ) {
    configureSipAccount(
      deviceId: $deviceId
      condominiumId: $condominiumId
      typeSip: $typeSip
    )
  }
`;

export const configureAta = gql`
  mutation configureAta($condominiumId: Int!, $typeSip: Int!) {
    configureAta(condominiumId: $condominiumId, typeSip: $typeSip)
  }
`;

export const resyncSipAccount = gql`
  mutation resyncSipAccount($sipAccountConfigurationId: Int!) {
    resyncSipAccount(sipAccountConfigurationId: $sipAccountConfigurationId)
  }
`;

export const removeSipAccount = gql`
  mutation removeSipAccount($sipAccountConfigurationId: Int!) {
    removeSipAccount(sipAccountConfigurationId: $sipAccountConfigurationId)
  }
`;
