import gql from 'graphql-tag';

export const condominiumAddressesFragment = gql`
  fragment condominiumAddressesParts on Address {
    streetName
    isPrincipal
    district {
      name
    }
    city {
      name
    }
    region {
      name
    }
    regionSmallName {
      name
    }
    country {
      name
    }
  }
`;

export const condominiumParamsFragment = gql`
  fragment condominiumParamsParts on CondominiumParamsType {
    name
    value
  }
`;

export const collectionCondominiumsFragment = gql`
  fragment collectionCondominiumsParts on Condominium {
    name
    email
    personId
    personContextId
    treeNodeId
    params {
      ...condominiumParamsParts
    }
    addresses {
      ...condominiumAddressesParts
    }
  }
  ${condominiumParamsFragment}
  ${condominiumAddressesFragment}
`;

export const paginationCondominiumsFragment = gql`
  fragment paginationCondominiumsParts on Pagination {
    totalPages
    totalResults
    page
    pageSize
  }
`;

export const condominiumsFragment = gql`
  fragment condominiumsParts on CondominiumListResponse {
    collection {
      ...collectionCondominiumsParts
    }
    pagination {
      ...paginationCondominiumsParts
    }
  }
  ${collectionCondominiumsFragment}
  ${paginationCondominiumsFragment}
`;
