import gql from 'graphql-tag';
import {
  guidedAttendanceTreeFragment,
  unityDwellersResponseFragment,
  continuousAttendanceResponseFragment,
  callPriorityFragment,
  preRegisterAuthorizationFragment,
  transientPersonSettingFragment,
  guidedAttendanceViewTemplateFragment,
  lastAttendancesByEventTypeFragment,
  condominiumCameraLiveFragment,
  transientPersonResponseFragment,
  dwellersWithDocumentsResponseFragment,
  condominiumCameraLiveV2Fragment,
  condominiumRuleQuestionFragment,
  condominiumRuleQuestionsWithTriageAndSubtriageFragment,
  unityDwellerPaginatedSearchResponseFragment,
  placesToOpenDoorResponseFragment,
} from './fragment';

export const getGuidedAttendance = gql`
  query guidedAttendance(
    $eventType: Int!
    $eventId: String
    $condominiumContextId: Int!
  ) {
    guidedAttendance(
      eventType: $eventType
      eventId: $eventId
      condominiumContextId: $condominiumContextId
    ) {
      ...guidedAttendanceTreeParts
    }
  }
  ${guidedAttendanceTreeFragment}
`;

export const insertGuidedAttendanceData = gql`
  mutation insertGuidedAttendanceData($data: GuidedAttendanceDataInput!) {
    insertGuidedAttendanceData(data: $data)
  }
`;

export const exitGuidedAttendance = gql`
  mutation exitGuidedAttendance($eventId: String!, $reason: String!) {
    exitGuidedAttendance(eventId: $eventId, reason: $reason)
  }
`;

export const getUnityDwellerSearch = gql`
  query condominiumUnityDwellerSearch(
    $condominiumContextId: Int!
    $search: String!
  ) {
    condominiumUnityDwellerSearch(
      condominiumContextId: $condominiumContextId
      search: $search
    ) {
      ...unityDwellersResponseParts
    }
  }
  ${unityDwellersResponseFragment}
`;

export const getUnitCallPriority = gql`
  query unitiesCallPriority($unityId: Int!) {
    unitiesCallPriority(unityId: $unityId) {
      ...callPriorityParts
    }
  }
  ${callPriorityFragment}
`;

export const getCondominiumCallPriority = gql`
  query condominiumCallPriority($condominiumPersonContextId: Int!) {
    condominiumCallPriority(
      condominiumPersonContextId: $condominiumPersonContextId
    ) {
      ...callPriorityParts
    }
  }
  ${callPriorityFragment}
`;

export const insertCallStatus = gql`
  mutation insertCallStatus(
    $eventId: String!
    $callStatusBody: CallStatusInput!
  ) {
    insertCallStatus(eventId: $eventId, callStatusBody: $callStatusBody)
  }
`;

export const getContinuousAttendance = gql`
  query continuousAttendance($condominiumContextId: Int!) {
    continuousAttendance(condominiumContextId: $condominiumContextId) {
      ...continuousAttendanceResponseParts
    }
  }
  ${continuousAttendanceResponseFragment}
`;

export const insertStepHistory = gql`
  mutation insertStepHistory($eventId: String!, $data: String!, $stepId: Int!) {
    insertStepHistory(eventId: $eventId, data: $data, stepId: $stepId)
  }
`;

export const getPreRegisteredAuthorizationsByType = gql`
  query preRegisteredAuthorizationsByType(
    $unityId: Int!
    $transientPersonType: Int!
    $searchText: String
  ) {
    preRegisteredAuthorizationsByType(
      unityId: $unityId
      transientPersonType: $transientPersonType
      searchText: $searchText
    ) {
      ...preRegisterAuthorizationParts
    }
  }
  ${preRegisterAuthorizationFragment}
`;

export const createTransientPerson = gql`
  mutation insertTransientPerson($transientPerson: TransientPersonInput!) {
    insertTransientPerson(transientPerson: $transientPerson) {
      ...transientPersonResponseParts
    }
  }
  ${transientPersonResponseFragment}
`;

export const updateTransientPerson = gql`
  mutation updateTransientPerson(
    $transientPerson: TransientPersonInput!
    $transientPersonId: Int!
  ) {
    updateTransientPerson(
      transientPerson: $transientPerson
      transientPersonId: $transientPersonId
    ) {
      ...transientPersonResponseParts
    }
  }
  ${transientPersonResponseFragment}
`;

export const syncCondoByTransientPhoto = gql`
  mutation syncCondominiumByTransientPersonPhoto(
    $eventId: String!
    $transientPersonId: String!
    $condominiumId: String!
  ) {
    syncCondominiumByTransientPersonPhoto(
      eventId: $eventId
      transientPersonId: $transientPersonId
      condominiumId: $condominiumId
    )
  }
`;

export const getTransientPersonSettings = gql`
  query transientPersonSettings(
    $condominiumContextId: Int!
    $transientPersonType: Int!
  ) {
    transientPersonSettings(
      condominiumContextId: $condominiumContextId
      transientPersonType: $transientPersonType
    ) {
      ...transientPersonSettingParts
    }
  }
  ${transientPersonSettingFragment}
`;

export const finishEvent = gql`
  mutation finishEvent($treatment: String, $closureReason: String) {
    finishEvent(treatment: $treatment, closureReason: $closureReason)
  }
`;

export const getGuidedAttendanceViewTemplate = gql`
  query guidedAttendanceViewTemplate {
    guidedAttendanceViewTemplate {
      ...guidedAttendanceViewTemplateParts
    }
  }
  ${guidedAttendanceViewTemplateFragment}
`;

export const getLastAttendancesByEventType = gql`
  query lastAttendancesByEventType(
    $condominiumPersonContextId: Int!
    $eventType: Int!
  ) {
    lastAttendancesByEventType(
      condominiumPersonContextId: $condominiumPersonContextId
      eventType: $eventType
    ) {
      ...lastAttendancesByEventTypeParts
    }
  }
  ${lastAttendancesByEventTypeFragment}
`;

export const updateIntrusionDetectionStatus = gql`
  mutation updateIntrusionDetectionStatus(
    $eventId: String!
    $intrusionDetected: Boolean!
  ) {
    updateIntrusionDetectionStatus(
      eventId: $eventId
      intrusionDetected: $intrusionDetected
    )
  }
`;

export const condominiumCameraLive = gql`
  query condominiumCameraLive(
    $condominiumPersonContextId: Int!
    $placeId: Int
    $startDateTime: String
  ) {
    condominiumCameraLive(
      condominiumPersonContextId: $condominiumPersonContextId
      placeId: $placeId
      startDateTime: $startDateTime
    ) {
      ...condominiumCameraLiveParts
    }
  }
  ${condominiumCameraLiveFragment}
`;

export const getCondominiumCameraLiveV2 = gql`
  query condominiumCameraLiveV2($condominiumPersonContextId: Int!) {
    condominiumCameraLiveV2(
      condominiumPersonContextId: $condominiumPersonContextId
    ) {
      ...condominiumCameraLiveV2Parts
    }
  }
  ${condominiumCameraLiveV2Fragment}
`;

export const dwellersWithDocuments = gql`
  query dwellersWithDocuments($unityId: Int!) {
    dwellersWithDocuments(unityId: $unityId) {
      ...dwellersWithDocumentsResponseParts
    }
  }
  ${dwellersWithDocumentsResponseFragment}
`;

export const eventLogInviteRegistration = gql`
  mutation eventLogInviteRegistration(
    $eventId: String!
    $name: String!
    $personContextId: Int!
  ) {
    eventLogInviteRegistration(
      eventId: $eventId
      name: $name
      personContextId: $personContextId
    )
  }
`;

export const getQuestionsAndAnswers = gql`
  query condominiumRuleQuestionsAndAnswers(
    $condominiumPersonContextId: Int!
    $subtriageId: Int!
  ) {
    condominiumRuleQuestionsAndAnswers(
      condominiumPersonContextId: $condominiumPersonContextId
      subtriageId: $subtriageId
    ) {
      ...condominiumRuleQuestionParts
    }
  }
  ${condominiumRuleQuestionFragment}
`;

export const getQuestionsAndAnswersByEventId = gql`
  query condominiumRuleQuestionsAndAnswersByEventId(
    $condominiumPersonContextId: Int!
    $eventId: String!
  ) {
    condominiumRuleQuestionsAndAnswersByEventId(
      condominiumPersonContextId: $condominiumPersonContextId
      eventId: $eventId
    ) {
      ...condominiumRuleQuestionsWithTriageAndSubtriageParts
    }
  }
  ${condominiumRuleQuestionsWithTriageAndSubtriageFragment}
`;

export const getUnityDwellerPaginated = gql`
  query condominiumUnityDwellerPaginatedSearch(
    $condominiumPersonContextId: Int!
    $filters: UnityDwellerPaginatedFilters!
  ) {
    condominiumUnityDwellerPaginatedSearch(
      condominiumPersonContextId: $condominiumPersonContextId
      filters: $filters
    ) {
      ...unityDwellerPaginatedSearchResponseParts
    }
  }
  ${unityDwellerPaginatedSearchResponseFragment}
`;

export const getPlacesToOpenDoor = gql`
  query placesToOpenDoor(
    $condominiumPersonContextId: Int!
    $unityId: Int
    $placeId: Int
  ) {
    placesToOpenDoor(
      condominiumPersonContextId: $condominiumPersonContextId
      unityId: $unityId
      placeId: $placeId
    ) {
      ...placesToOpenDoorResponseParts
    }
  }
  ${placesToOpenDoorResponseFragment}
`;
