import gql from 'graphql-tag';

export const relayCommand = gql`
  mutation relayCommand(
    $eventId: ID!
    $deviceId: ID!
    $relayId: ID!
    $command: String!
  ) {
    relayCommand(
      eventId: $eventId
      deviceId: $deviceId
      relayId: $relayId
      command: $command
    )
  }
`;

export default relayCommand;
