import { useCallback } from 'react';
import propTypes from 'prop-types';
import Flex from '../Flex';
import * as S from './styles';

const TabsMobile = ({ tabs, active, onChangeTab }) => {
  const handleChangeTab = useCallback(
    tab => () => {
      onChangeTab(tab);
    },
    [onChangeTab],
  );

  return (
    <S.Wrapper>
      {tabs.map((tab, index) => (
        <S.WrapperLink
          key={`tab-${index}-${Math.random()}`}
          onClick={handleChangeTab(tab)}
          active={index === active}
          tabsLength={tabs.length}
        >
          <Flex gridGap="8px">
            {tab.icon && <>{tab.icon}</>}
            <span>{tab.title}</span>
          </Flex>
        </S.WrapperLink>
      ))}
    </S.Wrapper>
  );
};

TabsMobile.propTypes = {
  tabs: propTypes.arrayOf(
    propTypes.shape({
      title: propTypes.node.isRequired,
      icon: propTypes.node,
      url: propTypes.string,
    }),
  ).isRequired,
  onChangeTab: propTypes.func.isRequired,
  active: propTypes.number.isRequired,
};

export default TabsMobile;
