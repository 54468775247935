import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const WrapperSelectOptions = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: auto;
  top: ${props => !!props.topDistance && `calc(${props.topDistance}px + 8px)`};
  box-sizing: border-box;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 0 2px ${({ theme }) => theme.colors.blackShades(0.48)};
  border-radius: 8px;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: ${props => !!props.maxHeight && `calc(${props.maxHeight})`};
`;

export const ListItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  outline: none;
  border: none;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  color: ${({ theme }) => theme.colors.blackShades(0.8)};
  padding: ${({ selectAllItem }) => (selectAllItem ? '16px' : '8px 16px')};
  gap: 8px;
  cursor: pointer;
  z-index: 0;

  background-color: ${({ theme, isSelectedOption }) =>
    isSelectedOption ? theme.colors.secondary50 : theme.colors.white};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary100};
  }

  &:focus {
    outline: none;
    border: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.colors.secondary100};
      color: ${({ theme }) => theme.colors.secondary500};
      cursor: not-allowed;
      opacity: 0.7;
    `};

  transition: background-color 0.15s ease-in-out;
`;

export const Error = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.colors.danger500};
`;

export const InputGroup = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  padding: 6px 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  overflow: hidden;
  min-height: 38px;
  height: auto;
  width: 100%;
  transition: border 0.1s ease-in;
  gap: 8px;
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.danger500 : theme.colors.secondary400};
  cursor: pointer;

  &:hover {
    border: 1px solid
      ${({ theme, active }) => !active && theme.colors.primary500};
  }

  ${({ theme, active }) =>
    active &&
    css`
      border: 1px solid ${theme.colors.primary500};
      svg {
        color: ${theme.colors.primary500};
        transition: color 0.2s ease-in;
      }

      button {
        border-left-color: ${theme.colors.primary500};
        transition: border-left-color 0.2s ease-in;
      }

      &:hover {
        border: 1px solid ${theme.colors.primary500};
      }
    `}
`;

export const WrapperMultiValues = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  gap: 8px;
`;

export const Tag = styled.li`
  padding: 0 8px;
  border-radius: 8px;
  height: auto;
  color: ${props => props.theme.colors.primary500};
  border: 1px solid ${props => props.theme.colors.primary500};
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  svg {
    color: ${props => props.theme.colors.primary500};
    height: 14px;
    width: auto;
  }
  pointer-events: none;
`;

export const TagValue = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  pointer-events: none;

  -webkit-user-select: none;
  user-select: none;
`;

export const Placeholder = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1;

  color: ${props => props.theme.colors.secondary400};
`;
