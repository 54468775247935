import styled, { css } from 'styled-components';

const colorDefault = 'neutral';

const colorVariants = {
  primary: {
    initial: 'primary500',
    hover: 'primary700',
    disabled: 'primary300',
  },
  secondary: {
    initial: 'secondary500',
    hover: 'secondary700',
    disabled: 'secondary300',
  },
  danger: { initial: 'danger500', hover: 'danger900', disabled: 'danger100' },
  warning: {
    initial: 'orange500',
    hover: 'orange900',
    disabled: 'orange100',
  },
  neutral: {
    initial: 'secondary900',
    hover: 'highBlack',
    disabled: 'secondary700',
  },
  white: {
    initial: 'white',
    text: 'black',
    hover: 'secondary200',
    disabled: 'secondary100',
  },
  emergency: {
    initial: 'white',
    text: 'danger500',
    hover: 'secondary200',
    disabled: 'secondary100',
  },
  yellow: {
    initial: 'warning500',
    hover: 'warning900',
    disabled: 'warning100',
  },
  porterPrimary: {
    initial: 'invitePorterPrimary',
    hover: 'invitePorterPrimary',
    disabled: 'secondary100',
  },
  violet: {
    initial: 'violet500',
    hover: 'violet900',
    disabled: 'secondary100',
  },
};

const buttonSizes = {
  xs: css`
    height: 24px;
    ${props =>
      !props.hasText &&
      props.icon &&
      !props.rightIcon &&
      css`
        width: 24px;
        min-width: 24px !important;
        padding-left: 0;
        padding-right: 0;
      `}
    svg {
      height: 16px;
      width: 16px;
      margin: 0;
    }
    span {
      font-size: 12px;
    }
  `,
  sm: css`
    height: 32px;
    ${props =>
      !props.hasText &&
      props.icon &&
      !props.rightIcon &&
      css`
        width: 32px;
        min-width: 32px !important;
        padding-left: 0;
        padding-right: 0;
      `}
    svg {
      height: 16px;
      width: 16px;
      margin: 0;
    }
    span {
      font-size: 14px;
    }
  `,
  md: css`
    height: 36px;
    ${props =>
      !props.hasText &&
      props.icon &&
      !props.rightIcon &&
      css`
        width: 36px;
        min-width: 36px !important;
        padding-left: 0;
        padding-right: 0;
      `}
    svg {
      height: 20px;
      width: 20px;
      margin: 0;
    }
    span {
      font-size: 14px;
    }
  `,

  lg: css`
    height: 40px;
    ${props =>
      !props.hasText &&
      props.icon &&
      !props.rightIcon &&
      css`
        width: 40px;
        min-width: 40px !important;
        padding-left: 0;
        padding-right: 0;
      `}

    svg {
      height: 24px;
      width: 24px;
      margin: 0;
      padding: 0;
    }
    span {
      font-size: 14px;
    }
  `,

  xl: css`
    height: 48px;
    ${props =>
      !props.hasText &&
      props.icon &&
      !props.rightIcon &&
      css`
        width: 48px;
        min-width: 48px !important;
        padding-left: 0;
        padding-right: 0;
      `}

    svg {
      height: 30px;
      width: 30px;
      margin: 0;
      padding: 0;
    }
    span {
      font-size: 16px;
    }
  `,

  fit: css`
    height: fit-content;
    ${props =>
      !props.hasText &&
      props.icon &&
      !props.rightIcon &&
      css`
        width: fit-content;
        min-width: fit-content !important;
        padding-left: 0;
        padding-right: 0;
      `}

    svg {
      height: fit-content;
      width: fit-content;
      margin: 0;
      padding: 0;
    }
    span {
      font-size: 14px;
    }
  `,
};

const buttonVariants = {
  contained: css`
    border-color: ${props =>
      props.theme.colors[
        colorVariants[props.color]?.initial ||
          colorVariants[colorDefault].initial
      ]};
    background-color: ${props =>
      props.theme.colors[
        colorVariants[props.color]?.initial ||
          colorVariants[colorDefault].initial
      ]};

    color: ${props =>
      `${props.theme.colors[
        colorVariants[props.color]?.text || colorVariants[colorDefault].text
      ] || props.theme.colors.white} !important`};

    > span,
    > svg,
    > circle {
      color: ${props =>
        `${props.theme.colors[
          colorVariants[props.color]?.text || colorVariants[colorDefault].text
        ] || props.theme.colors.white} !important`};
      stroke: ${props => `${props.theme.colors.white} !important`};
    }

    ${props =>
      !props.disabled &&
      css`
        &:hover {
          border-color: ${props.theme.colors[
            colorVariants[props.color]?.hover ||
              colorVariants[colorDefault].hover
          ]};
          background-color: ${props.theme.colors[
            colorVariants[props.color]?.hover ||
              colorVariants[colorDefault].hover
          ]};
        }
      `}
  `,

  outlined: css`
    background-color: transparent;
    border-color: ${props => props.theme.colors.secondary500};

    > span,
    > svg,
    > circle {
      color: ${props =>
        props.theme.colors[
          colorVariants[props.color]?.initial ||
            colorVariants[colorDefault].initial
        ]} !important;
      stroke: ${props =>
        props.theme.colors[
          colorVariants[props.color]?.initial ||
            colorVariants[colorDefault].initial
        ]} !important;
    }

    ${props =>
      !props.disabled &&
      css`
        &:hover {
          background-color: ${props.theme.colors.hoverGrey};

          border-color: ${props.theme.colors[
            colorVariants[props.color]?.hover ||
              colorVariants[colorDefault].hover
          ]};

          > span,
          > svg,
          > circle {
            color: ${props.theme.colors[
              colorVariants[props.color]?.hover ||
                colorVariants[colorDefault].hover
            ]} !important;
            stroke: ${props.theme.colors[
              colorVariants[props.color]?.hover ||
                colorVariants[colorDefault].hover
            ]} !important;
          }
        }
      `}
  `,

  out: css`
    background-color: transparent;

    ${props => `
      border-style: ${props.dashed ? 'dashed' : 'solid'};
      border-color: ${
        props.theme.colors[
          colorVariants[props.color]?.initial ||
            colorVariants[colorDefault].initial
        ]
      };
    `}

    > span,
    > svg,
    > circle {
      color: ${props =>
        props.theme.colors[
          colorVariants[props.color]?.initial ||
            colorVariants[colorDefault].initial
        ]} !important;
      stroke: ${props =>
        props.theme.colors[
          colorVariants[props.color]?.initial ||
            colorVariants[colorDefault].initial
        ]} !important;
    }

    ${props =>
      !props.disabled &&
      css`
        &:hover {
          background-color: ${props.theme.colors.hoverGrey};

          border-color: ${props.theme.colors[
            colorVariants[props.color]?.hover ||
              colorVariants[colorDefault].hover
          ]};

          > span,
          > svg,
          > circle {
            color: ${props.theme.colors[
              colorVariants[props.color]?.hover ||
                colorVariants[colorDefault].hover
            ]} !important;
            stroke: ${props.theme.colors[
              colorVariants[props.color]?.hover ||
                colorVariants[colorDefault].hover
            ]} !important;
          }
        }
      `}
  `,

  text: css`
    border-color: transparent;
    background-color: transparent;
    > span,
    > svg,
    > circle {
      color: ${props =>
        props.theme.colors[
          colorVariants[props.color]?.initial ||
            colorVariants[colorDefault].initial
        ]} !important;
      stroke: ${props =>
        props.theme.colors[
          colorVariants[props.color]?.initial ||
            colorVariants[colorDefault].initial
        ]} !important;
    }

    ${props =>
      !props.disabled &&
      css`
        &:hover {
          background-color: ${props.theme.colors.hoverGrey};

          > span,
          > svg,
          > circle {
            color: ${props.theme.colors[
              colorVariants[props.color]?.hover ||
                colorVariants[colorDefault].hover
            ]} !important;
            stroke: ${props.theme.colors[
              colorVariants[props.color]?.hover ||
                colorVariants[colorDefault].hover
            ]} !important;
          }
        }
      `}
  `,
};

const rounded = css`
  aspect-ratio: 1 / 1;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  > span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  > svg {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ButtonStyled = styled.button`
  padding-left: 12px;
  padding-right: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  border-radius: 8px;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

  ${props =>
    props.block &&
    css`
      width: 100% !important;
    `}

  ${props => props.size && buttonSizes[props.size]}
  ${props => props.size && buttonVariants[props.variant]}

  ${props =>
    props.expandable &&
    css`
      height: 100%;
    `}

  :focus {
    outline: 0;
  }

  ${props =>
    props.rightIcon &&
    css`
      padding-left: 8px;
      padding-right: 8px;
      gap: 4px;

      svg:last-child {
        ${props.dropdown &&
          css`
            height: 12px;
            width: 12px;
          `}
      }
    `}

  ${props => props.rounded && rounded};
`;
