import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import propTypes from 'prop-types';
import { useOutsideClick } from '@kiper/hooks';
import theme from '@kiper/theme';
import {
  MdExpandLess,
  MdExpandMore,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
} from 'react-icons/md';
import Divider from '../Divider';
import Spinner from '../Spinner';
import * as S from './styles';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

/**
 * CheckmarksSelect is a React component for rendering a multi-select dropdown with checkboxes.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.id - The ID for the component
 * @param {string} props.placeholder - The placeholder text for the component
 * @param {string} props.selectAllOptionsText - The text to display for the "select all" option
 * @param {string} props.unselectAllOptionsText - The text to display for the "unselect all" option
 * @param {string} props.error - Error message to display
 * @param {Array} props.options - The options for the dropdown
 * @param {Function} props.onAddSelection - Function to call when an option is selected
 * @param {Function} props.onRemoveSelection - Function to call when an option is deselected
 * @param {Function} props.onSelectedAllOptions - Function to call when all options are selected
 * @param {Array} props.selectedOptions - The currently selected options
 * @param {boolean} props.preventEmptySelect - If true, prevents the user from deselecting all options
 * @param {string} props.emptyOptionsListText - Text to display when there are no options
 * @param {boolean} props.loading - If true, shows a loading spinner
 * @returns {React.Element} The rendered component
 */
const CheckmarksSelect = ({
  id,
  placeholder,
  selectAllOptionsText,
  unselectAllOptionsText,
  error,
  options,
  onAddSelection,
  onRemoveSelection,
  onSelectedAllOptions,
  selectedOptions,
  preventEmptySelect,
  emptyOptionsListText,
  loading,
}) => {
  const selectRef = useRef();
  const inputGroupRef = useRef();
  const [maxHeightDropDown, setMaxHeightDropDown] = useState(null);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [inputHeight, setInputHeight] = useState(38);
  const [
    valueOfLastOptionForDisable,
    setValueOfLastOptionForDisable,
  ] = useState(null);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useLayoutEffect(() => {
    const height = selectRef?.current?.getBoundingClientRect().height;
    const bottom = selectRef?.current?.getBoundingClientRect().bottom;
    const overflowDiff = bottom - windowDimensions.height;
    const newMaxHeight = height - overflowDiff;

    if (newMaxHeight) setMaxHeightDropDown(`${newMaxHeight - 48}px`);

    const inputGroupHeight = inputGroupRef?.current?.getBoundingClientRect()
      .height;

    setInputHeight(inputGroupHeight);
  }, [
    selectRef?.current,
    windowDimensions,
    inputGroupRef?.current,
    selectedOptions,
    options,
  ]);

  function compareOptions(a, b) {
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    options.sort(compareOptions);
  }, [options]);

  const mainBoxRef = useOutsideClick(() => setOpenDropDown(false));

  useEffect(() => {
    if (preventEmptySelect && selectedOptions.length <= 1) {
      const [currentDisableOption] = selectedOptions;
      setValueOfLastOptionForDisable(currentDisableOption?.value || null);
    }
  }, [selectedOptions]);

  const handleChange = useCallback(
    (e, option) => {
      e.preventDefault();
      if (!option.value) return;

      const isOptionSelected = selectedOptions.some(
        selectedOption => selectedOption.value === option.value,
      );

      if (!isOptionSelected) {
        onAddSelection(option.value, option.label);
        setValueOfLastOptionForDisable(null);
      } else {
        onRemoveSelection(option.value);

        if (preventEmptySelect && selectedOptions.length === 2) {
          const [currentDisableOption] = selectedOptions.filter(
            selectedOption => option.value !== selectedOption.value,
          );
          setValueOfLastOptionForDisable(currentDisableOption?.value || null);
        }
      }
    },
    [selectedOptions, onAddSelection, onRemoveSelection],
  );

  const handleSelectAllOptions = useCallback(
    e => {
      e.preventDefault();
      if (selectedOptions.length === options.length)
        return onSelectedAllOptions([]);
      return onSelectedAllOptions(options);
    },
    [selectedOptions, options],
  );

  const findSelectedOption = option => {
    if (!option || !selectedOptions || !selectedOptions.length) return false;
    return !!selectedOptions.find(
      selectedItem => selectedItem.value === option.value,
    );
  };

  const isAllOptionsChecked = useMemo(
    () => selectedOptions.length === options.length,
    [selectedOptions, options],
  );

  const checkboxSettings = option => ({
    icon: findSelectedOption(option) ? (
      <MdCheckBox size={24} color={theme.colors.primary500} />
    ) : (
      <MdCheckBoxOutlineBlank size={24} color={theme.colors.blackShades(0.8)} />
    ),
  });

  const checkAllOptionsSettings = () => ({
    icon: isAllOptionsChecked ? (
      <MdCheckBox size={24} color={theme.colors.primary500} />
    ) : (
      <MdCheckBoxOutlineBlank size={24} color={theme.colors.blackShades(0.8)} />
    ),
  });

  const expandDropDownIcon = openDropDown ? (
    <MdExpandLess size={24} color={theme.colors.primary500} />
  ) : (
    <MdExpandMore size={24} color={theme.colors.secondary300} />
  );

  return (
    <S.Wrapper id={id} error={error} ref={mainBoxRef}>
      <S.InputGroup
        ref={inputGroupRef}
        active={openDropDown}
        error={error}
        onClick={() => setOpenDropDown(prevState => !prevState)}
      >
        <S.WrapperMultiValues>
          {selectedOptions.length ? (
            selectedOptions.map(tagValue => (
              <S.Tag key={crypto.randomUUID()}>
                <S.TagValue>{tagValue.label}</S.TagValue>
              </S.Tag>
            ))
          ) : (
            <S.Placeholder>{placeholder}</S.Placeholder>
          )}
        </S.WrapperMultiValues>
        {expandDropDownIcon}
      </S.InputGroup>
      {error && typeof error === 'string' && <S.Error>{error}</S.Error>}
      {loading && openDropDown && (
        <S.WrapperSelectOptions
          ref={selectRef}
          maxHeight={maxHeightDropDown}
          topDistance={inputHeight}
        >
          <S.ListItem disabled>
            <Spinner />
          </S.ListItem>
        </S.WrapperSelectOptions>
      )}
      {!loading && openDropDown && !!options?.length && (
        <S.WrapperSelectOptions
          ref={selectRef}
          maxHeight={maxHeightDropDown}
          topDistance={inputHeight}
        >
          {onSelectedAllOptions && (
            <>
              <S.ListItem
                selectAllItem
                isSelectedOption={isAllOptionsChecked}
                onClick={e => handleSelectAllOptions(e)}
              >
                {checkAllOptionsSettings().icon}
                {isAllOptionsChecked
                  ? unselectAllOptionsText
                  : selectAllOptionsText}
              </S.ListItem>
              <Divider />
            </>
          )}
          {options.map(option => (
            <S.ListItem
              key={option.value}
              isSelectedOption={findSelectedOption(option)}
              onClick={e => handleChange(e, option)}
              disabled={valueOfLastOptionForDisable === option?.value}
            >
              {checkboxSettings(option).icon}
              {option.label}
            </S.ListItem>
          ))}
        </S.WrapperSelectOptions>
      )}
      {!loading && openDropDown && !options?.length && (
        <S.WrapperSelectOptions
          ref={selectRef}
          maxHeight={maxHeightDropDown}
          topDistance={inputHeight}
        >
          <S.ListItem disabled>{emptyOptionsListText}</S.ListItem>
        </S.WrapperSelectOptions>
      )}
    </S.Wrapper>
  );
};

export default CheckmarksSelect;

CheckmarksSelect.propTypes = {
  id: propTypes.string.isRequired,
  unselectAllOptionsText: propTypes.string,
  selectAllOptionsText: propTypes.string,
  onAddSelection: propTypes.func.isRequired,
  onRemoveSelection: propTypes.func.isRequired,
  onSelectedAllOptions: propTypes.func,
  placeholder: propTypes.string.isRequired,
  emptyOptionsListText: propTypes.string.isRequired,
  selectedOptions: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string.isRequired,
      value: propTypes.any.isRequired,
    }),
  ),
  error: propTypes.string,
  preventEmptySelect: propTypes.bool,
  loading: propTypes.bool,
  options: propTypes.array.isRequired,
};

CheckmarksSelect.defaultProps = {
  selectedOptions: [],
  onSelectedAllOptions: null,
  error: '',
  unselectAllOptionsText: '',
  selectAllOptionsText: '',
  preventEmptySelect: false,
  loading: false,
};
