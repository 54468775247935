import gql from 'graphql-tag';

export const preRegisterCondominiumFilterFragment = gql`
  fragment preRegisterCondominiumFilterParts on PreRegisterCondominiumFilter {
    name
    treeNodeId
    personId
    personContextId
  }
`;

export const preRegisterUnityFilterFragment = gql`
  fragment preRegisterUnityFilterParts on PreRegisterUnityFilter {
    countPreRegister
    unity {
      name
      id
      defaultAccessProfile
    }
    unityGroup {
      name
      id
      defaultAccessProfile
    }
  }
`;

export const allPreRegisterUnitiesPendingApprovalsFragment = gql`
  fragment allPreRegisterUnitiesPendingApprovalsParts on AllPreRegisterUnitiesPendingApprovals {
    name
    profile {
      name
    }
    status
    email
    phone
    cpf
    id
    tagQuantity
    controlQuantity
    creationDate
  }
`;
