import gql from 'graphql-tag';

export const systemUserFragment = gql`
  fragment systemUser_Part on SystemUser {
    id
    name
    phone
    email
    profileId
    personContextId
    condominium {
      id
      name
      treeNodeId
    }
  }
`;

export const systemUser = gql`
  query systemUser($personId: ID!) {
    systemUser(personId: $personId) {
      ...systemUser_Part
    }
  }

  ${systemUserFragment}
`;

export const systemUserCreate = gql`
  mutation createSystemUser($user: SystemUserInput!) {
    systemUserCreate(user: $user) {
      ...systemUser_Part
    }
  }

  ${systemUserFragment}
`;

export const systemUserUpdate = gql`
  mutation updateSystemUser($user: SystemUserInput!) {
    systemUserUpdate(user: $user) {
      ...systemUser_Part
    }
  }

  ${systemUserFragment}
`;

export const systemUserRemove = gql`
  mutation removeSystemUser($personContextId: ID!) {
    systemUserRemove(personContextId: $personContextId)
  }
`;
