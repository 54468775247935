import propTypes from 'prop-types';

const GlassesOff = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.33374 0.134766L1.16735 1.30116L7.34247 7.47629H3.50068C2.55568 7.47629 1.66901 7.87296 1.06235 8.54962C0.455679 9.22629 0.175679 10.1596 0.304012 11.1513L0.887345 14.6396C1.12068 16.5063 2.77734 17.9763 4.66734 17.9763H8.16734C9.98734 17.9763 11.749 16.5996 12.169 14.908L12.8168 12.9506L24.3808 24.5146L25.6182 23.2772L25.6175 23.2764L25.5465 23.3475L2.33374 0.134766ZM22.6501 17.9763H23.334C25.2123 17.9763 26.8807 16.5063 27.1023 14.6863L27.6973 11.1046C27.814 10.1596 27.534 9.22629 26.939 8.54962C26.3323 7.87296 25.4457 7.47629 24.5007 7.47629H17.5007C16.5207 7.47629 15.6457 7.88462 15.0973 8.59629C14.9573 8.77129 14.8407 8.95796 14.7473 9.16796C14.4283 9.09959 14.0994 9.07574 13.7702 9.0964L17.6308 12.957L16.859 10.6263C16.8007 10.393 16.824 10.1713 16.9173 10.0313C17.0457 9.89129 17.244 9.80962 17.5007 9.80962H24.5007C24.7807 9.80962 25.0257 9.91462 25.189 10.1013C25.3523 10.2763 25.4223 10.533 25.3873 10.7663L24.7923 14.348C24.7107 15.0596 24.0223 15.643 23.334 15.643H20.3168L22.6501 17.9763ZM9.67581 9.80962L10.9686 11.1025L9.91734 14.2663C9.75401 14.9896 8.91401 15.643 8.16734 15.643H4.66734C3.96734 15.643 3.29068 15.0596 3.19735 14.3013L2.61401 10.813C2.57901 10.533 2.64901 10.2763 2.81235 10.1013C2.97568 9.91462 3.22068 9.80962 3.50068 9.80962H9.67581Z"
        fill={color}
      />
    </svg>
  );
};

GlassesOff.propTypes = {
  size: propTypes.number,
  color: propTypes.string,
};

GlassesOff.defaultProps = {
  size: 24,
  color: '#000',
};

export default GlassesOff;
