import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '@kiper/ui';
import context from '../context';
import faceRegisterIntro from '../../../../assets/images/summit-face-register-intro.svg';
import * as S from './styles';

const TERMS_PRIVACY_POLICY_URL =
  'https://privacidade.portergroup.com.br/politica-de-privacidade';

const prefix = 'porter-summit-invite.modal.terms-of-use';

const StepTermsOfUse = () => {
  const { handleChangeStep } = useContext(context);

  const [acceptTerms, setAcceptTerms] = useState(false);
  const { t } = useTranslation('invite');

  return (
    <>
      <S.StepContainer>
        <S.FacialIconContainer>
          <img src={faceRegisterIntro} alt={t(`${prefix}.icon-alt`)} />
        </S.FacialIconContainer>
        <Flex flexDirection="column">
          <S.Title>{t(`${prefix}.title`)}</S.Title>
          <S.SubTitle>{t(`${prefix}.subtitle`)}</S.SubTitle>
        </Flex>
        <Flex flexDirection="column" gridGap="16px">
          <S.Description>{t(`${prefix}.description`)}</S.Description>
          <S.CheckboxContainer>
            <S.Checkbox
              name="storePhoto"
              value={acceptTerms}
              id="checkbox-accept-terms-of-use"
              onChange={e => setAcceptTerms(e.target.checked)}
              checked={acceptTerms}
            />
            <S.LabelTerm htmlFor="checkbox-accept-terms-of-use">
              {t(`${prefix}.terms.accept`)}
              <a
                href={TERMS_PRIVACY_POLICY_URL}
                target="_blank"
                rel="noreferrer"
              >
                {t(`${prefix}.terms.link-text`)}
              </a>
            </S.LabelTerm>
          </S.CheckboxContainer>
        </Flex>
      </S.StepContainer>

      <S.Button
        variant="contained"
        size="lg"
        color="porterPrimary"
        block
        centered
        disabled={!acceptTerms}
        onClick={() => handleChangeStep(1)}
      >
        {t(`${prefix}.continue-button`)}
      </S.Button>
    </>
  );
};

export default StepTermsOfUse;
