import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const createCondominium = gql`
  mutation condominium($condominium: CondominiumInput!) {
    condominium(condominium: $condominium) {
      id
      personId
      profileId
      treeNodeId
    }
  }
`;
