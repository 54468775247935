import propTypes from 'prop-types';
import { Trans } from 'react-i18next';
import * as S from './styles';

const FooterDeliveryToCondominium = ({ onClick, title, buttonText }) => {
  return (
    <S.Wrapper>
      <Trans>
        <S.Title>{title}</S.Title>
      </Trans>
      <S.ButtonOutlined onClick={onClick}>{buttonText}</S.ButtonOutlined>
    </S.Wrapper>
  );
};

FooterDeliveryToCondominium.propTypes = {
  onClick: propTypes.func.isRequired,
  title: propTypes.string.isRequired,
  buttonText: propTypes.string.isRequired,
};

export default FooterDeliveryToCondominium;
