export default {
  /* primary */
  primary20: '#F3FEF9',
  primary50: '#E6F5ED',
  primary100: '#C2E5D3',
  primary200: '#9BD5B7',
  primary300: '#71C59B',
  primary400: '#50B986',
  primary500: '#29AD71',
  primary600: '#239E67',
  primary700: '#1C8C5A',
  primary800: '#177B4E',
  primary900: '#125B39',
  /* secondary */
  secondary50: '#F9F9FA',
  secondary100: '#F2F2F3',
  secondary200: '#E9E9EA',
  secondary300: '#D9D9DA',
  secondary400: '#B5B5B6',
  secondary500: '#959597',
  secondary600: '#6D6D6E',
  secondary700: '#5A5A5B',
  secondary800: '#48484C',
  secondary850: '#3c3c46',
  secondary900: '#1B1B1C',

  white: '#FFF',

  link: '#1188FF',

  success50: '#E3F1EA',
  success100: '#ACD4C1',
  success150: '#95C8AF',
  success200: '#83BDA1',
  success300: '#68A88B',
  success500: '#198754',
  success900: '#115C39',

  gray5: '#F3F3F4',
  gray50: '#E0E0E0',
  gray100: '#DBDBDC',
  gray600: '#475467',
  gray900: '#1C1C1D',

  danger50: '#FBE7E9',
  danger70: '#F6ADB466',
  danger100: '#F2B6BC',
  danger150: '#EFA3A9',
  danger200: '#E78F95',
  danger300: '#D76A73',
  danger500: '#DC3545',
  danger900: '#96242F',

  warning20: '#FEFBEE',
  warning50: '#FFF8E1',
  warning100: '#FFE9A6',
  warning150: '#FFDD8F',
  warning200: '#FFD378',
  warning300: '#FFBE4F',
  warning500: '#FFC107',
  warning700: '#E29D05',
  warning900: '#AD8305',

  info50: '#E0F6FA',
  info100: '#A3E5F0',
  info150: '#7ADDE7',
  info200: '#52D6E0',
  info300: '#2ACED9',
  info500: '#00B8D4',
  info900: '#007D90',

  purple50: '#EEE8F8',
  purple100: '#CBBBE9',
  purple150: '#B7A3E1',
  purple200: '#A18CD8',
  purple300: '#8A72CF',
  purple500: '#6F42C1',
  purple900: '#4B2D83',

  violet50: '#EBE9FF',
  violet100: '#C4BDFE',
  violet150: '#B1A4FD',
  violet200: '#9B8BFC',
  violet300: '#8372FA',
  violet500: '#5B47FB',
  violet900: '#3E30AB',

  orange50: '#FFF0E3',
  orange100: '#FED1AA',
  orange150: '#FEC596',
  orange200: '#FEB882',
  orange300: '#FE9E5A',
  orange500: '#FD7E14',
  orange900: '#AC560E',

  blue900: '#374AA0',

  info: '#00B8D4',

  eletricOrange: '#FF5E00',

  /* surface */
  whiteSurface: '#FFFFFF',
  lightnessGreenSurface: '#F7FEF8',
  greenSurface: '#027E76',
  strongGreenSurface: '#0D5B61',
  /* font emphasis */
  highBlack: 'rgba(0, 0, 0, 0.87)',
  mediumHighBlack: 'rgba(0, 0, 0, 0.7)',
  mediumBlack: 'rgba(0, 0, 0, 0.6)',
  lowBlack: 'rgba(0, 0, 0, 0.38)',
  highWhite: 'rgb(255, 255, 255)',
  mediumWhite: 'rgba(255, 255, 255, 0.7)',
  lowWhite: 'rgba(255, 255, 255, 0.5)',
  hoverGrey: 'rgba(0, 0, 0, 0.05)',
  lightGrey: 'rgba(0, 0, 0, 0.2)',
  lightGreyBorder: 'rgba(0, 0, 0, 0.1)',
  /* status */
  dangerPrimary: '#DC3545',
  dangerSecondary: '#EB4943',
  dangerLight: '#FACED4',
  warningPrimary: '#ED873D',
  warningSecondary: '#FDE558',
  warningLight: '#FFF9CA',
  infoPrimary: '#299DAD',
  infoPrimaryShades: opacity => `rgba(41, 157, 173, ${opacity})`,
  infoSecondary: '#3FCCE0',
  infoLight: '#B6EDF4',
  successPrimary: '#71C59B',
  successSecondary: '#9BD5B7',
  successLight: '#C2E5D3',
  extraPrimary: '#8866EE',
  extraSecondary: '#AA93EC',
  extraLight: '#D0C3F5',
  black: '#000000',
  blackShades: opacity => `rgba(0, 0, 0, ${opacity})`,
  blackPlayer: '#1C1C1D',
  infoAlert: '#32C3DB',

  /* table */
  tableHeader: '#EEEEF0',
  tableHover: '#F0F0F5',
  tableBg: '#F9F9FB',
  tableCellBorder: 'rgba(0, 0, 0, 0.12)',

  /* link */
  linkPurple: '#000EC7',

  /* alert */
  warningAlert: '#F0A247',

  /* branding */
  inviteKiperPrimary: '#0d5b61',
  inviteKiperSecondary: '#027e76',
  invitePorterPrimary: '#030049',
  invitePorterPrimaryShades: opacity => `rgba(3, 0, 73, ${opacity})`,
  invitePorterSecondary: '#374AA0',
  inviteFacialSuccess: '#24A148',

  breadcrumb: '#8c8a97',

  tabBorderColor: '#F3F3F4',

  backgroundWelcomeDelivery: '#1d2575',

  /* guided attendance colors */
  guidedAttendance: {
    text: {
      talk: '#E1FFED',
      action: '#D3EDFF',
      warning: '#F2B6BC',
    },
  },
};
