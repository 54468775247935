/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const listAvaiables = gql`
  query accessTagAvaiables($partnerTreeNodeId: Int) {
    accessTagAvaiables(partnerTreeNodeId: $partnerTreeNodeId) {
      tagId
    }
  }
`;
