import { createContext, useContext, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { runtimeConfig } from '@kiper/fns';
import { getFireInstance } from '../firebase';

export const FeatureFlagContext = createContext();

export const FeatureFlagProvider = ({ defaults, children }) => {
  const [flags, setFlags] = useState(defaults);
  const [loading, setLoading] = useState(true);

  const firebase = getFireInstance();
  const config = firebase.remoteConfig(firebase);

  useEffect(() => {
    config.settings.minimumFetchIntervalMillis = 1800000;
    config.defaultConfig = defaults;

    if (runtimeConfig.RAZZLE_ENV === 'production') {
      config
        .fetchAndActivate()
        .then(() => config.getAll())
        .then(remoteFlags => {
          const newFlags = {
            ...flags,
          };

          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(remoteFlags)) {
            newFlags[key] = JSON.parse(value.asString());
          }

          setFlags(newFlags);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <FeatureFlagContext.Provider value={{ loading, flags, defaults }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlag = () => useContext(FeatureFlagContext);

export const DEFAULT_FLAGS = {
  inviteGuest: {
    specialInvitePorterSummit: {
      users: [],
      condominiums: [1562],
      partners: [],
    },
    activeRemoteExpireInvite: {
      users: [],
      condominiums: [1],
      partners: [],
    },
  },
};

FeatureFlagProvider.propTypes = {
  defaults: propTypes.object.isRequired,
  children: propTypes.oneOfType([propTypes.node, propTypes.element]),
};

FeatureFlagProvider.defaultProps = {
  children: undefined,
};
