import { useMemo, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const useFilterSearchParams = ({
  key,
  options,
  filterLinkedFields,
  onChange,
}) => {
  const history = useHistory();
  const location = useLocation();

  const searchQueryRef = useRef(new URLSearchParams(location.search));

  const historyPush = ({ search }) =>
    history.push({
      search,
    });

  const removeParam = () => {
    searchQueryRef.current = new URLSearchParams(location.search);
    searchQueryRef.current.delete(key);
    if (
      filterLinkedFields &&
      Array.isArray(filterLinkedFields) &&
      filterLinkedFields.length
    ) {
      filterLinkedFields.forEach(fieldName =>
        searchQueryRef.current.delete(fieldName),
      );
    }
    historyPush({ search: searchQueryRef.current.toString() });
  };

  const appendParam = ({ queryValue }) => {
    searchQueryRef.current = new URLSearchParams(location.search);

    if (!searchQueryRef.current.has(key)) {
      searchQueryRef.current.append(key, queryValue);
    } else {
      searchQueryRef.current.set(key, queryValue);
    }
    historyPush({ search: searchQueryRef.current.toString() });
  };

  const setInputFilterValueFromQuery = () => {
    if (!key || !searchQueryRef.current.has(key)) return () => null;
    if (searchQueryRef.current.has(key)) {
      onChange({ key, queryValue: searchQueryRef.current.get(key) });
    } else {
      historyPush({ search: '' });
    }

    return null;
  };

  const withoutConditionsToSearchField = useMemo(
    () =>
      !options || !options.length || !key || !searchQueryRef.current.has(key),
    [options, searchQueryRef.current, key],
  );

  const setSelectFilterValueFromQuery = () => {
    if (withoutConditionsToSearchField) return () => null;
    if (options && searchQueryRef.current.has(key)) {
      const queryStringValueIndex = options.findIndex(
        item => String(item.value) === searchQueryRef.current.get(key),
      );
      if (queryStringValueIndex >= 0)
        onChange({ key, queryValue: options[queryStringValueIndex] });
    } else {
      historyPush({ search: '' });
    }

    return () => null;
  };

  const setSelectUnityFilterValueFromQuery = () => {
    if (withoutConditionsToSearchField) return () => null;
    if (options && searchQueryRef.current.has(key)) {
      const flatOptions = [];
      options.forEach(option =>
        option.options.forEach(optionPlace => flatOptions.push(optionPlace)),
      );

      const queryStringValueIndex = flatOptions.findIndex(
        item => String(item.value) === searchQueryRef.current.get(key),
      );

      if (queryStringValueIndex >= 0)
        onChange({
          key,
          queryValue: flatOptions[queryStringValueIndex] || null,
        });
    } else {
      historyPush({ search: '' });
    }

    return null;
  };

  const setSelectMultiValueFilterFromQuery = () => {
    if (withoutConditionsToSearchField) return () => null;
    const hasValueFromQuery = searchQueryRef.current.has(key);

    if (options && hasValueFromQuery) {
      const valuesFromQuery = searchQueryRef.current.get(key).split(',');
      const filteredValues = valuesFromQuery.flatMap(queryValue =>
        options.filter(option => option.value === queryValue),
      );

      if (filteredValues.length > 0) {
        onChange({ key, queryValue: filteredValues });
      }
    } else {
      historyPush({ search: '' });
    }

    return null;
  };

  return {
    removeParam,
    appendParam,
    setSelectUnityFilterValueFromQuery,
    setSelectMultiValueFilterFromQuery,
    setSelectFilterValueFromQuery,
    setInputFilterValueFromQuery,
  };
};

export default useFilterSearchParams;
