import gql from 'graphql-tag';
import { eventTypeTreatmentMenuFragments } from './fragments';

export default gql`
  query eventTypeTreatmentMenu {
    eventTypeTreatmentMenu {
      ...eventTypeTreatmentMenuParts
    }
  }
  ${eventTypeTreatmentMenuFragments}
`;
