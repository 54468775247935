import InviteGuest from './InviteGuest';

export default [
  {
    name: 'Invite guest',
    path: '/guest',
    exact: true,
    component: InviteGuest,
  },
];
