import propTypes from 'prop-types';
import Flex from '../Flex';
import * as S from './styles';

const AlertBar = ({
  background,
  color,
  icon,
  title,
  subtitle,
  button,
  ...props
}) => {
  return (
    <S.Container {...props} color={color} bg={background}>
      <Flex gridGap="8px" alignItems="center">
        {icon && icon}
        <Flex flexDirection="column" gridGap="8px">
          <S.Title>{title}</S.Title>
          {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
        </Flex>
      </Flex>
      {button && button}
    </S.Container>
  );
};

export default AlertBar;

AlertBar.propTypes = {
  background: propTypes.string.isRequired,
  title: propTypes.oneOfType([propTypes.string, propTypes.node]).isRequired,
  icon: propTypes.node,
  subtitle: propTypes.string,
  button: propTypes.node,
  color: propTypes.string,
};

AlertBar.defaultProps = {
  icon: undefined,
  subtitle: undefined,
  button: undefined,
  color: 'white',
};
