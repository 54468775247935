import styled, { css } from 'styled-components';

import { Table as table, Label as label } from 'reactstrap';

export const Container = styled(table)`
  margin-bottom: 0;
  border-top: 0;

  tbody {
    tr {
      :hover {
        ${props =>
          props.hover &&
          css`
            background-color: ${props.theme.colors.tableHover};
          `}
      }
      &[disabled] {
        &,
        &:hover,
        &:focus {
          background-color: ${props => props.theme.colors.secondary200};
          opacity: 0.7;
          color: ${props => props.theme.colors.secondary400};
        }
      }
      th,
      td {
        border-top-color: ${props => props.theme.colors.secondary100};
        &[disabled] {
          text-decoration: line-through;
        }
      }
      &:first-child {
        th,
        td {
          border-top-width: 0;
        }
      }
    }

    td {
      ${props =>
        props.align &&
        css`
          text-align: ${props.align};
        `}
      vertical-align: middle !important;
    }
  }
`;

export const Th = styled.th`
  border: none !important;
  cursor: pointer;
  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `}
  &:active {
    transform: translateY(2px);
  }

  label {
    font-size: 14px;
    font-weight: 600;
  }
`;

export const SortContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled(label)`
  margin-bottom: 0;
  cursor: pointer;
`;
