import gql from 'graphql-tag';

export const simplePersonFragment = gql`
  fragment simplePersonParts on SimplePerson {
    id
    name
  }
`;

export const deviceModelFragment = gql`
  fragment deviceModelParts on DeviceModel {
    id
    deviceTypeId
    name
    capabilities
    creationDate
  }
`;

export const deviceTypeFragment = gql`
  fragment deviceTypeParts on DeviceType {
    id
    name
    fieldName
    isCpu
    isController
    creationDate
  }
`;

export const deviceFragment = gql`
  fragment deviceParts on Device {
    id
    placeId
    parentDeviceId
    deviceTypeId
    deviceType {
      ...deviceTypeParts
    }
    serialNumber
    ip
    protocolVersion
    firmwareVersion
    name
    isOnline
    serializedParams
    creationDate
    lastUpdate
    updateStatus
    chipsetType
    syncStatus
    immutableData
    deviceModel {
      ...deviceModelParts
    }
  }
  ${deviceModelFragment}
  ${deviceTypeFragment}
`;

export const exportationFragment = gql`
  fragment exportationParts on Exportation {
    id
    exportationLink
    creationDate
    lastUpdate
    startDate
    endDate
    device {
      ...deviceParts
    }
    deviceId
    exportationStatusType
    itemsCount
    itemsPendingCount
    itemsProblemCount
    itemsCompletedCount
    person {
      ...simplePersonParts
    }
  }
  ${deviceFragment}
  ${simplePersonFragment}
`;

export const exportationItemFragment = gql`
  fragment exportationItemParts on ExportationItem {
    id
    exportationId
    creationDate
    lastUpdate
    startDate
    endDate
    exportationType
    exportationStatusType
    entityType
    entityId
  }
`;
