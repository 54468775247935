import styled from 'styled-components';
import Avatar from 'react-avatar';

export const Container = styled.a`
  position: relative;
  width: ${props => props.size || 38}px;
  height: ${props => props.size || 38}px;
  cursor: default;
  div {
    img {
      object-fit: cover;
    }
  }
`;

export const CustomAvatar = styled(Avatar)`
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`;
