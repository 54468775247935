import styled from 'styled-components';
import { KiperButton } from '@kiper/ui';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.secondary200};
  gap: 12px;
`;

export const Title = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;

  color: ${({ theme }) => theme.colors.blackShades(0.7)};

  strong {
    font-weight: 700;
  }
`;

export const ButtonOutlined = styled(KiperButton)`
  background-color: transparent;
  border: 1px solid ${props => props.theme.colors.invitePorterSecondary};
  outline: none;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;

  :hover {
    background-color: transparent;
    border: 1px solid ${props => props.theme.colors.invitePorterSecondary};
    color: ${props => props.theme.colors.invitePorterSecondary};
  }

  span {
    color: ${props => props.theme.colors.invitePorterSecondary} !important;
    width: 100%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    font-weight: 500;
  }
`;
