import { useContext } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex } from '@kiper/ui';
import context from '../context';
import facialSuccessIcon from '../../../../assets/images/summit-confirm-face-register.svg';
import * as S from './styles';

const prefix = 'porter-summit-invite.modal.feedback-success';

const StepFeedbackSuccess = props => {
  const { refetch, onToggle } = props;
  const { handleResetStep } = useContext(context);
  const { t } = useTranslation('invite');

  const handleBackToHome = () => {
    refetch();
    handleResetStep();
    onToggle();
  };

  return (
    <>
      <S.StepContainer>
        <S.Title>{t(`${prefix}.title`)}</S.Title>
        <Flex
          flexDirection="column"
          gridGap="24px"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <S.FeedbackSuccessIcon
            src={facialSuccessIcon}
            alt={t(`${prefix}.feedback-success-icon-alt`)}
          />
          <S.Description>{t(`${prefix}.description`)}</S.Description>
        </Flex>
      </S.StepContainer>
      <S.ButtonBackInitialPage
        variant="contained"
        block
        centered
        onClick={handleBackToHome}
      >
        {t(`${prefix}.back-to-home`)}
      </S.ButtonBackInitialPage>
    </>
  );
};

export default StepFeedbackSuccess;

StepFeedbackSuccess.propTypes = {
  refetch: propTypes.func.isRequired,
  onToggle: propTypes.func.isRequired,
};
