import gql from 'graphql-tag';

export const accessFacialDeviceFragment = gql`
  fragment accessFacialDeviceParts on AccessFacialDevice {
    id
    name
    isOnline
  }
`;

export const accessFacialPlaceFragment = gql`
  fragment accessFacialPlaceParts on AccessFacialPlace {
    id
    name
    devices {
      ...accessFacialDeviceParts
    }
  }
  ${accessFacialDeviceFragment}
`;

export const accessFacialCondominiumFragment = gql`
  fragment accessFacialCondominiumParts on AccessFacialCondominium {
    id
    name
    places {
      ...accessFacialPlaceParts
    }
  }
  ${accessFacialPlaceFragment}
`;

export const responseFacialFeedbackFragment = gql`
  fragment responseFacialFeedbackParts on ResponseFacialFeedback {
    status
    message
  }
`;

export const responseFacialIdentificationFragment = gql`
  fragment responseFacialIdentificationParts on ResponseFacialIdentification {
    response {
      ...responseFacialFeedbackParts
    }
  }
  ${responseFacialFeedbackFragment}
`;
