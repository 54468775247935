import styled from 'styled-components';
import * as UI from '..';

export const Container = styled(UI.Flex)`
  flex-direction: column;
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.lightGreyBorder};
  border-radius: 8px;
  padding: 16px 16px 16px 0;
  gap: 8px;
  align-self: flex-start;
  min-width: 240px;
`;

export const ListGroup = styled(UI.Flex)`
  flex-direction: column;
  gap: 8px;
`;

export const ListGroupItem = styled(UI.Flex)`
  padding-left: ${({ active }) => (active ? '21px' : '24px')};
  padding-top: 4px;
  padding-bottom: 4px;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  color: ${({ active, theme }) =>
    active ? theme.colors.primary500 : theme.colors.mediumHighBlack};

  border-left: ${({ active, theme }) =>
    active ? `3px solid ${theme.colors.primary500}` : 'none'};
`;

export const Title = styled(UI.Text)`
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  padding-left: 16px;
  width: 100%;
  color: ${props => props.theme.colors.highBlack};
`;
