import propTypes from 'prop-types';

import Button from '../Button';
import Text from '../Text';
import Flex from '../Flex';
import { Container } from './styles';

function Alert({
  background,
  icon: Icon,
  text,
  secondaryText,
  buttonText,
  buttonAction,
  fixed,
  ...props
}) {
  return (
    <Container
      justifyContent="space-between"
      justifyItems="center"
      alignItems="center"
      bg={background}
      fixed={fixed}
      px={20}
      py={15}
      mb={15}
      {...props}
    >
      <Flex flex={1} justifyContent="center" alignItems="center">
        <Icon />

        <Flex ml={15} flex={1}>
          <Text color="white">{text}</Text>
        </Flex>
        {secondaryText && (
          <Text ml={10} color="white">
            {secondaryText}
          </Text>
        )}
      </Flex>
      {buttonAction && buttonText && (
        <Button onClick={buttonAction}>{buttonText}</Button>
      )}
    </Container>
  );
}

export default Alert;

Alert.propTypes = {
  background: propTypes.string.isRequired,
  icon: propTypes.any.isRequired,
  text: propTypes.string.isRequired,
  secondaryText: propTypes.string,
  buttonText: propTypes.string,
  buttonAction: propTypes.func,
  fixed: propTypes.bool,
};

Alert.defaultProps = {
  secondaryText: undefined,
  buttonText: undefined,
  buttonAction: undefined,
  fixed: false,
};
