import gql from 'graphql-tag';

export const profileFragment = gql`
  fragment profileParts on DwellerAccessProfile {
    value
    label
    fieldName
  }
`;

export const accessProfilesFragment = gql`
  fragment accessProfilesParts on DwellerAccessProfiles {
    id
    name
    isInvite
    creationDate
    value
    label
  }
`;

export const treeNodeContextFragment = gql`
  fragment treeNodeContextParts on TreeContextSelect {
    id
    name
    placeTypeLabelId
    personContextId
    treeNodeId
    value
    label
  }
`;

export const timeOutputFragment = gql`
  fragment timeOutputParts on TimeOutPut {
    hours
    minutes
    time
  }
`;

export const intervalTimeRestrictionsFragment = gql`
  fragment intervalTimeRestrictionsParts on IntervalTimeOutPut {
    id
    start {
      ...timeOutputParts
    }
    end {
      ...timeOutputParts
    }
    daysOfWeek
  }
  ${timeOutputFragment}
`;

export const accessPeriodFragment = gql`
  fragment accessPeriodParts on AccessPeriod {
    start
    end
  }
`;

export const permissionsFragment = gql`
  fragment permissionsParts on Permission {
    id
    applicationFeatureId
    name
    ableToRead
    ableToWrite
    allowsEdit
    fieldName
  }
`;

export const bookingTimeFragment = gql`
  fragment bookingTimeParts on BookingTime {
    initDate
    endDate
  }
`;

export const contactOrderFragment = gql`
  fragment contactOrderParts on ContactOrder {
    intercom
    main
    additional
  }
`;

export const placeTemporaryAccessFragment = gql`
  fragment placeTemporaryAccessParts on PlaceTemporaryAccess {
    key
    place {
      id
      name
      temporaryAccessId
    }
    startDate
    endDate
  }
`;

export const dwellerAccessResponseFragment = gql`
  fragment dwellerAccessResponseParts on DwellerAccess {
    id
    creationDate
    accessibilityTime
    callPriority
    observation
    profile {
      ...profileParts
    }
    accessProfiles {
      ...accessProfilesParts
    }
    condominium {
      ...treeNodeContextParts
    }
    unityGroup {
      ...treeNodeContextParts
    }
    unity {
      ...treeNodeContextParts
    }
    typeUnityGroup {
      ...treeNodeContextParts
    }
    typeUnity {
      ...treeNodeContextParts
    }
    allowedAccesses {
      ...intervalTimeRestrictionsParts
    }
    contactSchedule {
      ...intervalTimeRestrictionsParts
    }
    accessPeriod {
      ...accessPeriodParts
    }
    permissions {
      ...permissionsParts
    }
    bookingTime {
      ...bookingTimeParts
    }
    contactOrder {
      ...contactOrderParts
    }
    placeTemporaryAccess {
      ...placeTemporaryAccessParts
    }
  }
  ${profileFragment}
  ${accessProfilesFragment}
  ${treeNodeContextFragment}
  ${intervalTimeRestrictionsFragment}
  ${accessPeriodFragment}
  ${permissionsFragment}
  ${bookingTimeFragment}
  ${contactOrderFragment}
  ${placeTemporaryAccessFragment}
`;

export const vehicleResponseFragment = gql`
  fragment vehicleResponseParts on Vehicle {
    id
    plate
    model
    type
    tag
    creationDate
  }
`;
