import gql from 'graphql-tag';

export const getCondominiumInfo = gql`
  query getCondominiumInfo($uuid: String!) {
    getCondominiumInfo(uuid: $uuid) {
      uuid
      name
      unityGroup {
        id
        name
        unity {
          id
          name
        }
      }
    }
  }
`;

export const getDeliveryReceipt = gql`
  query getDeliveryReceipt($uuid: String!) {
    getDeliveryReceipt(uuid: $uuid) {
      id
      packageDeliveryDate
      cameras
      condominium {
        name
        addressLine1
        addressLine2
      }
      unity {
        name
        group
      }
      deliveryPerson {
        phoneNumber
        cpf
      }
    }
  }
`;
