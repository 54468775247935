import axios, { CancelToken } from 'axios';
import qs from 'qs';
import runtimeConfig from '../runtimeConfig';

const createHttpConnection = headers =>
  axios.create({
    headers,
    baseURL: process.env.CLOUD_URL || runtimeConfig.CLOUD_URL,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

const createHttpHardwareConnection = headers =>
  axios.create({
    headers,
    baseURL: process.env.HARDWARE_URL,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

const createHttpMonitoringConnection = headers =>
  axios.create({
    headers,
    baseURL: process.env.MONITORING_URL || runtimeConfig.MONITORING_URL,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

export default {
  createHttpConnection,
  createHttpHardwareConnection,
  createHttpMonitoringConnection,
  CancelToken,
};
