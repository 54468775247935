import propTypes from 'prop-types';
import StyledLabel from './styles';

const Label = ({ block, center, info, bolder, ...props }) => (
  <StyledLabel
    {...props}
    block={Number(block)}
    center={Number(center)}
    info={Number(info)}
    bolder={Number(bolder)}
  />
);

export default Label;

Label.propTypes = {
  block: propTypes.bool,
  center: propTypes.bool,
  info: propTypes.bool,
  bolder: propTypes.bool,
};

Label.defaultProps = {
  block: false,
  center: false,
  info: false,
  bolder: false,
};
