import { css } from 'styled-components';

export default css`
  :root {
    ${({ theme }) => css`
      --primary: ${theme.colors.primary500};
      --secondary: ${theme.colors.secondary500};
      --success: ${theme.colors.success500};
      --info: ${theme.colors.info500};
      --warning: ${theme.colors.warning500};
      --danger: ${theme.colors.danger500};
    `}
  }

  .badge-primary {
    background-color: ${props => props.theme.colors.primary500};
  }

  .form-control {
    border-radius: 8px;

    &::placeholder {
      font-size: 12px;
      color: hsl(0, 0%, 50%);
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem ${props => props.theme.colors.primary100};
      border-color: ${props => props.theme.colors.primary500};
    }

    &:hover {
      border-color: ${props => props.theme.colors.primary500};
    }
  }

  .nav-link {
    font-size: 14px;
  }

  .custom-switch .custom-control-input {
    &:focus ~ .custom-control-label:before {
      box-shadow: 0 0 0 0.2rem ${props => props.theme.colors.primary100};
    }
    &:focus:not(:checked) ~ .custom-control-label::before {
      border-color: ${props => props.theme.colors.primary500};
    }
    &:checked ~ .custom-control-label::before {
      border-color: ${props => props.theme.colors.primary500};
      background-color: ${props => props.theme.colors.primary500};
    }

    &:disabled:checked ~ .custom-control-label::before {
      background-color: ${props => props.theme.colors.primary200} !important;
      border-color: ${props => props.theme.colors.primary200} !important;
    }

    &[disabled] ~ .custom-control-label::before {
      background-color: ${props => props.theme.colors.white} !important;
    }
  }
`;
