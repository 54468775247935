import gql from 'graphql-tag';
import { condominiumEventCondoMapFragment } from './fragments';

export const condoMap = gql`
  query condoMap($condominiumPersonContextId: ID!) {
    condoMap(condominiumPersonContextId: $condominiumPersonContextId) {
      ...condominiumEventCondoMapParts
    }
  }
  ${condominiumEventCondoMapFragment}
`;
