import styled from 'styled-components';
import { EmptyState as emptyState, H3 as h3 } from '@kiper/ui';

export const Container = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  gap: 8px;

  justify-content: center;
  align-items: center;

  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-auto-rows: 150px;
`;

export const H3 = styled(h3)`
  margin: 0;
  width: 100%;
`;

export const EmptyState = styled(emptyState)`
  max-width: 380px;
`;
