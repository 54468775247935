import gql from 'graphql-tag';
import { condominiumsFragment } from './fragments';

export const condominiums = gql`
  query condominiums($filters: QueryInput!) {
    condominiums(filters: $filters) {
      ...condominiumsParts
    }
  }
  ${condominiumsFragment}
`;
