import { useState } from 'react';
import { usePermission } from '@kiper/hooks';
import Context from './index';
import FacialInvitationModal from '../FacialInvitationModal';

const FacialInvitationProvider = props => {
  const permissionsCamera = usePermission('camera');

  const [photoUrl, setPhotoUrl] = useState(null);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userMediaError, setUserMediaError] = useState(false);

  const handleResetStep = () => {
    setStep(0);
  };

  const handleChangeStep = value => {
    setStep(x => x + value);
  };

  return (
    <Context.Provider
      value={{
        photoUrl,
        setPhotoUrl,
        step,
        handleResetStep,
        handleChangeStep,
        loading,
        setLoading,
        permissionsCamera,
        userMediaError,
        setUserMediaError,
      }}
    >
      <FacialInvitationModal {...props} />
    </Context.Provider>
  );
};

export default FacialInvitationProvider;
