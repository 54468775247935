import propTypes from 'prop-types';

import Spinner from '../Spinner';

import { ButtonStyled } from './styles';
import Text from '../Text';

const KiperButton = ({
  variant,
  size,
  icon,
  children,
  loading,
  disabled,
  color,
  rounded,
  options,
  rightIcon,
  expandable,
  centered,
  ...props
}) => {
  return (
    <ButtonStyled
      type="button"
      {...props}
      disabled={disabled}
      variant={variant}
      size={size}
      hasText={children}
      color={color}
      icon={icon}
      rounded={rounded}
      rightIcon={rightIcon}
      expandable={expandable}
      centered={centered}
    >
      {icon && loading && (
        <Spinner color={variant === 'contained' ? 'white' : 'secondary300'} />
      )}
      {icon && !loading && icon}

      {children && (
        <Text
          textAlign={rounded || centered ? 'center' : 'start'}
          width="100%"
          truncate
          mr={rightIcon ? '8px' : 0}
          ml={icon ? '8px' : 0}
        >
          {children}
        </Text>
      )}
      {rightIcon && rightIcon}
    </ButtonStyled>
  );
};

export default KiperButton;

KiperButton.propTypes = {
  variant: propTypes.string,
  size: propTypes.string,
  color: propTypes.string,
  loading: propTypes.bool,
  disabled: propTypes.bool,
  icon: propTypes.node,
  children: propTypes.node,
  rounded: propTypes.bool,
  rightIcon: propTypes.node,
  options: propTypes.array,
  expandable: propTypes.bool,
  centered: propTypes.bool,
};

KiperButton.defaultProps = {
  variant: 'contained',
  size: 'md',
  color: 'primary',
  loading: false,
  disabled: false,
  icon: null,
  children: null,
  rounded: false,
  rightIcon: false,
  options: null,
  expandable: false,
  centered: false,
};
