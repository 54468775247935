import { useContext, useMemo, useEffect, useRef } from 'react';
import { Flex, DefaultInputMask } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import Layout from './Layout';
import { context } from '../../store/PorterDelivery/DepositPackage';
import * as S from './styles';

const StepPhoneNumber = () => {
  const {
    handleNextStep,
    handleBackStep,
    setPhoneNumber,
    phoneNumber,
  } = useContext(context);

  const [t] = useTranslation('porter-delivery');

  const inputRef = useRef();

  const handleChangePhoneNumber = phoneNumberInput =>
    setPhoneNumber(prevState => ({ ...prevState, withMask: phoneNumberInput }));

  const isValid = useMemo(
    () => phoneNumber.withMask.replace(/\D/g, '').length >= 11,
    [phoneNumber.withMask],
  );

  const handleConfirmNumberStep = e => {
    e.preventDefault();

    const rawValueNumber = phoneNumber.withMask.replace(/\D/g, '');
    if (!isValid) {
      return;
    }

    setPhoneNumber(prevState => ({
      ...prevState,
      raw: `+55${rawValueNumber}`,
    }));

    localStorage.setItem(
      'phone',
      JSON.stringify({
        ...phoneNumber,
        raw: `+55${rawValueNumber}`,
      }),
    );

    handleNextStep();
  };

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
      setTimeout(() => {
        inputRef.current.click();
      }, 300);
    }
  }, [inputRef?.current]);

  const setInputRef = element => {
    if (!inputRef.current) {
      inputRef.current = element;
    }
  };

  return (
    <Layout onStepBack={handleBackStep}>
      <S.FormNumberPhone noValidate onSubmit={handleConfirmNumberStep}>
        <Flex
          width="100%"
          height="fit-content"
          gridGap="12px"
          alignItems="flex-start"
          justifyContent="center"
          flexDirection="column"
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
            gridGap="16px"
            width="100%"
            height="fit-content"
          >
            <S.Label>{t('phone-number.input-label')}</S.Label>
            <DefaultInputMask
              noValidate
              inputRef={el => setInputRef(el)}
              type="tel"
              pattern="[0-9]*"
              name="phone-number"
              mask="(99) 9 9999-9999"
              value={phoneNumber.withMask}
              onChange={handleChangePhoneNumber}
              placeholder="+55 (00) 0 0000-0000"
              autoComplete="off"
              autoFocus
            />
          </Flex>
          <S.Description>{t('phone-number.receipt')}</S.Description>
        </Flex>

        <S.Button type="submit" disabled={!isValid}>
          {t('phone-number.continue')}
        </S.Button>
      </S.FormNumberPhone>
    </Layout>
  );
};

export default StepPhoneNumber;
