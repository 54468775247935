import gql from 'graphql-tag';
import {
  dwellerAccessResponseFragment,
  vehicleResponseFragment,
} from './fragments';

export const insertAccess = gql`
  mutation dwellerAccessDataCreate(
    $personId: Int!
    $accessData: DwellerAccessData!
  ) {
    dwellerAccessDataCreate(personId: $personId, accessData: $accessData) {
      ...dwellerAccessResponseParts
    }
  }
  ${dwellerAccessResponseFragment}
`;

export const deleteAccess = gql`
  mutation dwellerAccessDataDelete($personContextId: Int!) {
    dwellerAccessDataDelete(personContextId: $personContextId)
  }
`;

export const updateAccess = gql`
  mutation dwellerAccessDataUpdate(
    $oldPersonContextId: Int!
    $accessData: DwellerAccessData!
  ) {
    dwellerAccessDataUpdate(
      oldPersonContextId: $oldPersonContextId
      accessData: $accessData
    ) {
      ...dwellerAccessResponseParts
    }
  }
  ${dwellerAccessResponseFragment}
`;

export const createExpectedGuestsByDweller = gql`
  mutation(
    $personContextId: Int!
    $expectedGuests: ExpectedGuestsByDwellerInput
  ) {
    dwellerCreateExpectedGuest(
      personContextId: $personContextId
      expectedGuests: $expectedGuests
    ) {
      creationDate
      name
      start
      end
      id
      accessProfiles {
        id
        name
      }
      isFreeAccess
      createPersonInvite {
        name
      }
      inviteds {
        id
        name
        documents {
          name
          value
          id
        }
      }
    }
  }
`;

export const deleteExpectedGuests = gql`
  mutation($inviteId: Int!) {
    dwellerRemoveExpectedGuest(inviteId: $inviteId)
  }
`;

export const dwellerBlockAppAccess = gql`
  mutation dwellerBlockAppAccessMutation(
    $personContextId: Int!
    $reason: String!
  ) {
    dwellerBlockAppAccess(personContextId: $personContextId, reason: $reason)
  }
`;

export const dwellerUnblockAppAccess = gql`
  mutation dwellerUnblockAppAccessMutation($personContextId: Int!) {
    dwellerUnblockAppAccess(personContextId: $personContextId)
  }
`;

export const removeVehicle = gql`
  mutation removeVehicle($id: Int!) {
    removeVehicle(id: $id)
  }
`;

export const createVehicle = gql`
  mutation createVehicle($vehicle: VehicleInput!, $personId: Int!) {
    createVehicle(vehicle: $vehicle, personId: $personId) {
      ...vehicleResponseParts
    }
  }
  ${vehicleResponseFragment}
`;

export const updateVehicle = gql`
  mutation updateVehicle($vehicle: VehicleInput!, $vehicleId: Int!) {
    updateVehicle(vehicle: $vehicle, vehicleId: $vehicleId) {
      ...vehicleResponseParts
    }
  }
  ${vehicleResponseFragment}
`;
