import gql from 'graphql-tag';
import paginationFragment from '../shared/paginationFragment';
import { eventTimelineFragment } from './fragments';

export default gql`
  query eventTimeline($eventId: ID!, $filters: QueryInput!) {
    eventTimeline(eventId: $eventId, filters: $filters) {
      collection {
        ...eventTimelineParts
      }
      pagination {
        ...paginationParts
      }
    }
  }
  ${eventTimelineFragment}
  ${paginationFragment}
`;
