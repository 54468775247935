import styled, { css } from 'styled-components';
import AsyncSelect from 'react-select/async';

export default styled(AsyncSelect)`
  & > div {
    border-radius: 8px;

    &:hover {
      border-color: ${props => props.theme.colors.primary500} !important;
    }

    ${props =>
      props.invalid &&
      css`
        border-color: ${props.theme.colors.dangerPrimary} !important;
      `}
  }
`;
