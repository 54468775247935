import styled, { css } from 'styled-components';

export const Input = styled.input`
  font-family: 'Nunito Sans', sans-serif;
  width: 100%;
  box-sizing: border-box;
  height: auto;
  padding: 6px 4px;
  border-radius: 8px;
  border: none;
  box-shadow: none !important;

  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.blackShades(0.8)};

  &:disabled {
    background-color: ${({ theme }) => theme.colors.secondary200};
    opacity: 50%;
  }

  :focus,
  &:focus-within,
  :focus-visible {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary400};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: color 0.15s ease-in-out 0s;
    color: ${({ theme }) => theme.colors.secondary300};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: inherit;
`;

const inputGroupBorderModifier = ({ theme }) => ({
  withBorder: `1px solid ${theme.colors.blackShades(0.8)}`,
  withoutBorder: 'none',
});

export const InputGroup = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  border-radius: 8px;
  overflow: hidden;
  height: 24px;
  width: 100%;
  transition: border 0.15s ease-in-out 0s;
  outline: none;
  background-color: inherit;
  cursor: text;

  ${({ theme, editInlineMode }) => css`
      svg {
        color: ${theme.colors.blackShades(0.7)};
      }

      &:hover {
        background-color: ${theme.colors.secondary300};
        transition: background-color 0.15s ease-in-out 0s,
          color 0.15s ease-in-out 0s;

        span {
          color: ${theme.colors.black};
        }

        svg {
          color: ${theme.colors.black};
        }
      }

      border: ${
        editInlineMode
          ? inputGroupBorderModifier({ theme }).withBorder
          : inputGroupBorderModifier({ theme }).withoutBorder
      };
    }
  `}
`;

export const InputValue = styled.p`
  font-family: inherit;
  display: inline-block;
  height: 100%;
  width: fit-content;

  margin: 0;
  padding: 0;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  background-color: inherit;
  color: ${({ theme }) => theme.colors.blackShades(0.7)};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;

  svg {
    height: 16px;
    width: auto;
  }
`;

export const InputIcon = styled.span`
  display: flex;
  align-items: center;
  padding: 8px 0;
  height: 100%;
  color: ${({ theme }) => theme.colors.blackShades(0.8)};

  svg {
    height: 16px;
    width: auto;
  }
`;
