import gql from 'graphql-tag';

export const list = gql`
  query attendanceDefaultMessages($eventType: ID, $condominiumId: ID) {
    attendanceDefaultMessages(
      eventType: $eventType
      condominiumId: $condominiumId
    ) {
      id
      personEventSettingId
      shortCut
      message
      creationDate
    }
  }
`;
