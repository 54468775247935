import gql from 'graphql-tag';

export const personApplications = gql`
  query PersonApplications($personId: Int!) {
    personApplications(personId: $personId) {
      id
      applicationKey
      name
      mainUrl
      icon
      isVisibleInAccount
    }
  }
`;
